* {
  font-family: "Nunito", sans-serif !important;
  list-style: none;
  outline: 0;
  box-sizing: border-box;
  text-decoration: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  color: #434649;
  font:
    1.2em Arial,
    Helvetica,
    sans-serif;
  background: #fff;
  min-width: 320px;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none;
  position: relative;
}

body.menu-open .page-overlay {
  visibility: visible;
}

body.menu-open .page-overlay {
  background-color: rgba(34, 34, 34, 0.33);
}

body.body-class--desktop {
  min-width: 768px;
}

body:not(.body-class--mobile).modal-open {
  margin-right: 0;
}

.page-overlay {
  width: 100%;
  height: 100%;
  z-index: 50;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  transition:
    background 0s ease,
    visibility 0s ease;
}

img {
  border-style: none;
  vertical-align: middle;
}

.responsive-full-image {
  text-align: center;
}

.responsive-full-image img {
  width: 100%;
}

a {
  color: #09c;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  background-color: transparent;
}

button {
  font:
    100% Arial,
    Helvetica,
    sans-serif;
}

input,
textarea,
select {
  font:
    100% Arial,
    Helvetica,
    sans-serif;
  vertical-align: middle;
  color: #434649;
}

button:hover,
input[type="image"]:hover,
input[type="submit"]:hover {
  cursor: pointer;
}

form,
fieldset {
  margin: 0;
  padding: 0;
  border-style: none;
}

form ::-webkit-input-placeholder {
  color: #434649;
}

form :-moz-placeholder {
  color: #434649;
}

form ::-moz-placeholder {
  color: #434649;
  opacity: 1;
}

form :-ms-input-placeholder {
  color: #434649;
}

header,
footer,
article,
section,
nav,
figure,
aside {
  display: block;
}

figure {
  margin: 0;
  padding: 0;
}

#page {
  max-width: 1330px;
  margin: 0 auto;
  padding: 0 10px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  max-width: 1366px;
  padding-left: 15px;
  padding-right: 15px;
}

@media screen and (min-width: 956px) {
  #page {
    padding-left: 18px;
    padding-right: 18px;
  }
}

.hidden {
  position: absolute;
  top: auto;
  left: -9999px;
}

.desktop-display-none {
  display: none;
}

.text-uppercase {
  text-transform: uppercase;
}

.show-large,
.hide-tablet,
.hide-mobile {
  display: none;
}

.hide-desktop {
  display: inline;
}

.hide {
  display: none;
}

.orange a {
  color: #ffffff;
  text-decoration: none;
}

.color-blue {
  color: #168dbf;
}

.upper {
  text-transform: uppercase;
}

.proper {
  text-transform: capitalize;
}

.login-form {
  box-sizing: border-box;
}

.content h1.block-header {
  border: medium none;
  display: block;
  font-size: 2em;
  line-height: 1.3em;
  font-weight: 700;
  border-bottom: 1px solid #e4e4e4;
  border-top: 1px solid #e4e4e4;
  margin: 0 0 10px;
  padding: 7px 0 6px;
  color: #44484b;
}

.content .h1-title {
  font-size: 1.364em;
  line-height: 1.2em;
  font-weight: bold;
}

.content .h1-title.confirmation h1 {
  font-size: 0.8em;
  position: relative;
}

.content .h1-title.confirmation h1::before {
  content: "";
  width: 15px;
  height: 15px;
  display: block;
  position: absolute;
  background: url(/dist/images/sprite4.png) no-repeat scroll 0 0 transparent;
  background-position: -127px -79px;
  border: 0;
  right: -25px;
  top: 0.3em;
}

.content h1 {
  margin: 0 0 10px;
  padding: 7px 0px 6px;
  // border-top: 1px solid #f6f6f6;
  // border-bottom: 1px solid #f6f6f6;
}

.content .h1-title h1 {
  display: inline;
  margin: 0;
  padding: 0;
  border: none;
}

.content .h1-title {
  margin: 0 0 10px;
  padding: 7px 0px 6px;
  border-top: 1px solid #f6f6f6;
  border-bottom: 1px solid #f6f6f6;
}

.plp-page.content p {
  font-size: 1.4rem;
}

.content p {
  margin: 0 0 12px;
}

.cfix:after,
.product-layout-split:after,
.financing ul.details li:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.mobile-video-holder {
  display: none;
}

@media (-webkit-min-device-pixel-ratio: 1.5) and (max-width: 767px),
(min-resolution: 144dpi) and (max-width: 767px) {

  .filter-form .slide .ui-slider-handle:before,
  .filter-form .slide li:before,
  .results-block .sort-form .filter-opener:before,
  .carousel-holder .opener:before,
  .carousel .prev:before,
  .carousel .next:before,
  .slideshow .prev:before,
  .slideshow .next:before,
  .product-block .instock:after,
  .add-links a:before {
    background-size: 249px 70px;
  }
}

@media (-webkit-min-device-pixel-ratio: 1.5) and (max-width: 767px),
(min-resolution: 144dpi) and (max-width: 767px) {

  .filter-form .slide .ui-slider-handle:before,
  .filter-form .slide li:before,
  .results-block .sort-form .filter-opener:before,
  .carousel-holder .opener:before,
  .carousel .prev:before,
  .carousel .next:before,
  .slideshow .prev:before,
  .slideshow .next:before,
  .product-block .instock:after,
  .add-links a:before {
    background-size: 249px 70px;
  }
}

.black-friday-banner {
  max-width: 100%;
  padding-bottom: 15px;
  padding-top: 5px;
}

body {
  font-size: 1.2em;
  line-height: 1.5;
}

#page {
  padding: 0 13px;
}

.hide-mobile {
  display: block;
}

thead.hide-mobile {
  display: table-header-group;
}

th.hide-mobile,
td.hide-mobile {
  display: table-cell !important;
}

.show-mobile {
  display: none !important;
}

.main-holder {
  display: table;
  margin: 0 0 7px;
  width: 100%;
  table-layout: fixed;
}

#main {
  margin: 0;
  float: left;
  width: 100%;
}

.content .h1-title {
  font-size: 2em;
  line-height: 1.1em;
  padding: 7px 0 6px 0px;
  margin: 0 0 10px;
}

@media screen and (min-width: 956px) {
  #page {
    padding: 0 18px;
  }

  .sidebar.right,
  .hide-tablet {
    display: block;
    position: static;
  }

  .show-tablet {
    display: none !important;
  }

  span.hide-tablet {
    display: inline !important;
  }

  #main {
    margin: 0 0 5px;
  }

  .gallery {
    margin: 0 0 20px;
  }

  .categories>div {
    width: 47%;
    margin: 0 0 19px;
  }

  .content .section-title {
    margin: 0 0 20px;
  }

  .hide-desktop {
    display: none;
  }
}

@media screen and (min-width: 1336px) {
  .content .h1-title {
    font-size: 2.5em;
    line-height: 1.3em;
    padding-bottom: 8px;
  }

  .content .section-title {
    font-size: 1.834em;
    line-height: 1em;
    padding: 7px 10px;
    border-radius: 5px;
  }

  .content .section-title.left {
    text-align: left;
    padding-left: 20px;
  }

  .content h2.section-title {
    font-size: 1.5em;
    line-height: 1.167em;
  }
}

#nav-back a {
  color: #000;
}

td.ui-datepicker-current-day a,
td.ui-datepicker-days-cell-over a {
  z-index: 49 !important;
}

.full {
  width: 100%;
  vertical-align: text-top;
  margin: 0 auto;
}

.full div,
.half div {
  padding: 6px 0;
  clear: both;
}

#qck-lin input[type="text"],
#qck-lin input[type="email"],
#qck-lin input[type="url"],
#qck-lin input[type="number"],
#qck-lin input[type="tel"],
#qck-lin input[type="password"],
#qck-lin select,
#qck-lin textarea {
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid #eee;
  font-size: 12px;
  line-height: 16px;
  padding: 2px;
  width: 134px;
}

.form dt {
  float: left;
  width: 120px;
}

.form dd {
  margin-left: 0;
}

.form p {
  padding: 4px 7px;
}

.form .font-size-small p {
  font-size: inherit;
}

.form input[type="text"]:focus,
.form input[type="email"]:focus,
.form input[type="url"]:focus,
.form input[type="tel"]:focus,
.form input[type="password"]:focus,
.form textarea:focus {
  outline: 0;
  border-color: #faa21b;
}

.req-star {
  color: red;
}

.two-third-input input,
.two-third-input select {
  width: 150px;
}

.half-input input,
.half-input select {
  width: 100px;
}

.third-input input,
.third-input select {
  width: 100px;
}

.form {
  text-align: center;
  margin: 0 auto;
}

.form * {
  text-align: left;
}

.clear,
.cl {
  clear: both;
}

.error {
  color: red;
}

.fright {
  float: right;
}

.fleft {
  float: left;
}

h3.header {
  font-style: normal;
}

.border-bottom {
  border-bottom: 1px solid #e0e0e0;
}

.display-block {
  display: block;
}

#dt-pck {
  margin: 0 auto;
}

.two-column {
  width: 100%;
  position: relative;
}

.two-column>div {
  width: 43%;
  display: inline-block;
  vertical-align: top;
  padding: 2px 3%;
  margin: 0 auto;
}

.column>div {
  padding: 3px;
  clear: both;
}

.column>div {
  padding: 3px;
  clear: both;
}

.underline {
  padding-bottom: 3px;
  border-bottom: 1px solid #ececec;
  box-shadow: 0 1px 0px #fff;
}

.padding10 {
  padding: 10px;
}

h3.header {
  color: #fff;
  font-size: 0.98em;
  font-weight: 700;
  font-style: italic;
  line-height: 1.167em;
  text-align: left;
  margin: 0 0 13px;
  padding: 9px 8px 7px;
  background-color: #168dbf;
}

h3.header.grey {
  color: #333;
  text-align: center;
  background: #ededed;
}

.grey-div h3.header.grey {
  background: #d5d5d5;
}

#secure-checkout-section h2 {
  font-size: 27px;
  line-height: 27px;
  font-weight: 900;
  padding: 0;
}

#secure-checkout-section h3 {
  font-size: 13px;
  font-weight: 900;
  padding: 0;
}

.secure-checkout-wrapper {
  width: 331px;
  margin: 0 auto;
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  color: #434649;
  padding-left: 33px;
  background-image: url(/dist/images/lock.png);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 33px 51px;
}

.secure-text {
  float: right;
  margin-top: 5px;
  margin-left: 6px;
  padding: 0;
}

.secure-text * {
  margin: 0;
}

.secure-text h3 {
  color: #83888b;
  text-transform: uppercase;
}

.secure-text span.orange {
  color: #faa21b;
}

.currently-logged-in {
  font-size: 12px;
}

.currently-logged-in .name {
  font-weight: bold;
}

.no-vat .c-vat {
  display: none;
}

.form-row>* {
  float: left;
  width: 96%;
}

#str_mobile_number {
  width: 200px;
}

.strong {
  font-weight: 700;
}

.fright-mobile {
  float: none;
}

.reg-bnr-cnt {
  padding: 4px 7px;
}

#header-wrapper {
  vertical-align: middle;
  height: 50px;
}

#header-wrapper h2 {
  line-height: 45px;
}

.full-wrapper {
  width: 100%;
  padding: 5px 0;
  clear: both;
}

.grey-background {
  background: none repeat scroll 0 0 #f6f6f6;
  border-bottom: 1px solid #e3e3e3;
  border-radius: 3px;
  box-shadow: 1px 1px 0 0 #fff inset;
  font-size: 120%;
  padding: 7px 10px 10px;
}

.grey-background h2 {
  font-size: 1.53em;
  line-height: 1.6em;
}

.button-wrapper {
  padding: 10px 6px 10px 6px;
  overflow: auto;
  text-align: center;
  height: auto;
}

.aleft {
  text-align: left;
}

.aright {
  text-align: right;
}

.acentre,
.acenter {
  text-align: center !important;
}

.fright {
  float: right;
}

.fleft {
  float: left;
}

.clear {
  clear: both;
}

a.url-black {
  color: #000;
}

.grey-div,
.grey-table {
  border-collapse: collapse;
  border-spacing: 0;
  background: none repeat scroll 0 0 #ededed;
  margin: 15px 0;
  overflow: hidden;
  border-radius: 3px;
}

.content .grey-div .section-title {
  border-radius: 0px;
}

.two-column-separate .separator {
  width: 0.8%;
  display: inline-block;
}

.two-column-separate {
  margin: 10px 0px;
}

.details dt {
  width: 150px;
  float: left;
  font-weight: bold;
}

.details dd {
  float: left;
}

.details {
  margin: 0px 10px 7px 10px;
}

.details>div {
  clear: both;
  padding: 5px 0px;
}

button.green {
  margin: 10px 0px;
}

.fright-mobile {
  float: right;
}

.box-content {
  padding: 10px;
}

.generic-form {
  float: left;
  margin: 0 0 20px;
  width: 100%;
}

.generic-form h2 {
  font-size: 18px;
  margin: 0 0 10px;
}

.generic-form .column {
  margin: 0 0 20px;
}

.generic-form .column,
.generic-form.two-column .column {
  max-width: 800px;
  padding: 0 0 0 3%;
}

.generic-form dt label,
.generic-form .column label {
  clear: left;
  display: block;
  float: left;
  line-height: 23px;
  padding-right: 1%;
  text-align: right;
  width: 36%;
}

.generic-form dt,
.generic-form .column dt {
  width: 34%;
}

.generic-form dt label,
.generic-form .column dt label {
  width: 98%;
}

.generic-form .form input,
.generic-form .form select,
.generic-form .form textarea,
.generic-form .column input,
.generic-form .column select,
.generic-form .column textarea {
  margin: 0 0 12px;
  width: 58%;
}

.generic-form .column dd input,
.generic-form .column dd select {
  margin: 0 0 5px;
}

.generic-form .gf-heading {
  font-weight: bold;
}

.generic-form .button-container {
  padding: 0;
  width: 100%;
}

.generic-form input[type="checkbox"] {
  width: auto;
  margin: 0;
}

.generic-form .errors,
.generic-form .business-errors {
  color: #ff0000;
  margin-left: 0;
  padding-left: 40px;
}

.generic-form .errors {
  margin-left: 29%;
  margin-top: 0;
}

.generic-form.personal-details-form .errors {
  margin: -10px 0 10px 29%;
  padding-left: 50px;
}

.form-checkout .errors {
  color: #ff0000;
  padding-left: 20px;
}

#register-section-content .column dd input,
#register-section-content .column dd select,
.chkt-wrp .generic-form input,
.chkt-wrp .generic-form select {
  margin: 0;
}

.important-form h3 {
  font-size: 14px;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

span.strikethrough {
  text-decoration: line-through;
  width: 100%;
  display: inline-block;
}

strong .c-sym,
strong .c-val {
  font-weight: 800;
}

span.hover-show-translation-link.get_translated_content {
  width: 100%;
}

section.sidebar-box.trustpilot-section {
  min-height: 142px;
  position: relative;
}

section.sidebar-box.trustpilot-section .trustpilot-static-widget {
  display: block;
  color: #888888;
  text-align: center;
  font-size: 11px;
  text-decoration: none;
}

section.sidebar-box.trustpilot-section .trustpilot-static-widget :hover {
  text-decoration: none;
}

section.sidebar-box.trustpilot-section .trustpilot-static-widget img {
  width: 100%;
  height: auto;
}

section.sidebar-box.trustpilot-section .trustpilot-static-widget img.trustpilot-logo {
  margin-bottom: 10px;
}

section.sidebar-box.trustpilot-section .trustpilot-static-widget img.trustpilot-stars {
  margin-bottom: 7px;
}

section.sidebar-box.trustpilot-section .trustpilot-widget.mini_widget_template_id {
  padding-top: 10px;
}

section.sidebar-box.trustpilot-section .trustpilot-widget.popup_widget_template_id {
  position: absolute !important;
  top: 83px;
  left: 0;
  height: 26px;
  width: 100%;
  overflow: hidden;
}

aside.sidebar .logout-btn {
  width: 128px;
}

hr.passive,
.modal-content hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eeeeee;
}

a.clear-link-underline {
  text-decoration: none;
}

input:focus,
textarea:focus,
select:focus {
  outline-color: #3b99fc;
}

form>.element-center {
  display: table;
  margin: 0 auto;
}

i.inline-info {
  position: relative;
  top: -1px;
}

.style-alt input[type="checkbox"].remember-me-checkbox {
  margin-right: 2px;
}

#login_form input.btn-primary.tablet {
  display: block;
  float: right;
}

#login_form div.remember-me-container.tablet {
  margin-top: 10px;
}

div.remember-me-container {
  display: inline-block;
  position: initial;
}

div.remember-me-row {
  line-height: 37px;
  text-align: right;
}

.v-center {
  display: table !important;
}

.v-center>span {
  display: table-cell !important;
  vertical-align: middle !important;
}

.content p.no-margin-bottom {
  margin-bottom: 0 !important;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  -webkit-tap-highlight-color: initial;
}

.heading-bar,
#basket-page h3.header,
#payment-page h3.header {
  background: #ededed;
  background: linear-gradient(to bottom, #ededed 0%, #e9e9e9 100%) repeat scroll 0 0 transparent;
  color: #595c5e;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 1px 0px #f3f3f3 inset;
}

.button,
button {
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.2s ease-out;
  border: none;
}

.button a:hover,
.button a.hover,
button a:hover,
button a.hover {
  text-decoration: none;
}

.button.blue-outline,
button.blue-outline {
  background-color: transparent;
  border: 2px solid #168dbf;
  color: #168dbf;
  padding: 10px 14px;
  border-radius: 3px;
}

.button.blue-outline:hover,
button.blue-outline:hover {
  background-color: transparent;
  color: #168dbf;
}

.button.blue-outline svg,
button.blue-outline svg {
  height: 20px;
  width: 20px;
  margin: 0 0 0 5px;
  vertical-align: middle;
}

.wistia_embed button {
  min-width: initial;
  min-height: initial;
}

div.button {
  padding: 0;
}

div.button a {
  display: block;
  padding: 10px 15px;
}

.button.orange,
button.orange {
  color: #fff;
  border-color: #fa8600;
  background-color: #fa8600;
  transition: background-color 0.2s ease-out;
  text-align: center;
  text-shadow: -1px -1px 0 rgba(235, 136, 0, 0.2);
  text-transform: uppercase;
}

.button.orange:hover,
.button.orange.active,
button.orange:hover,
button.orange.active {
  border-color: #e67b00;
  background-color: #e67b00;
}

.button.orange:disabled,
button.orange:disabled {
  border-color: #ededed;
  background-color: #ededed;
  cursor: not-allowed;
}

.button.vip,
button.vip {
  color: #fff;
  border-color: #b2964c;
  background-color: #b2964c;
  transition: background-color 0.2s ease-out;
}

.button.vip:hover,
.button.vip.active,
button.vip:hover,
button.vip.active {
  border-color: #a48a46;
  background-color: #a48a46;
}

.button.green,
#basket-summary-table tr.tablePromotionLine td.button,
button.green {
  color: #fff;
  border-color: #249c54;
  background-color: #249c54;
  transition: background-color 0.2s ease-out;
}

.button.green:hover,
#basket-summary-table tr.tablePromotionLine td.button:hover,
.button.green.active,
#basket-summary-table tr.tablePromotionLine td.button.active,
button.green:hover,
button.green.active {
  border-color: #208b4b;
  background-color: #208b4b;
}

#top-brands a.button {
  font-size: 12px;
  transition: all 0.2s;

  &:hover {
    transition: all 0.2s;
    transform: scale(1.1);
  }
}

.button.grey,
button.grey {
  color: #333;
  border-color: #d5d5d5;
  background-color: #d5d5d5;
  transition: background-color 0.2s ease-out;
}

.button.grey:hover,
.button.grey.active,
button.grey:hover,
button.grey.active {
  border-color: #cbcbcb;
  background-color: #cbcbcb;
}

.button.grey:hover,
.button.grey:active,
button.grey:hover,
button.grey:active {
  color: #333;
  border-color: #d5d5d5;
  background-color: #d5d5d5;
  transition: background-color 0.2s ease-out;
}

.button.grey:hover:hover,
.button.grey:hover.active,
.button.grey:active:hover,
.button.grey:active.active,
button.grey:hover:hover,
button.grey:hover.active,
button.grey:active:hover,
button.grey:active.active {
  border-color: #cbcbcb;
  background-color: #cbcbcb;
}

.button.register-submit[disabled],
button.register-submit[disabled] {
  color: #fff;
  border-color: #ededed;
  background-color: #ededed;
  transition: background-color 0.2s ease-out;
}

.button.register-submit[disabled]:hover,
.button.register-submit[disabled].active,
button.register-submit[disabled]:hover,
button.register-submit[disabled].active {
  border-color: #ededed;
  background-color: #ededed;
}

.button.register-submit[disabled] a,
button.register-submit[disabled] a {
  color: #42454a;
}

a.buton.compact,
button.compact {
  font-size: 0.85em;
  padding: 7px 12px;
}

a.buton.compact.grey,
button.compact.grey {
  color: #63656a;
  border: 0;
  background-color: #ededed;
}

.spacious a,
.spacious button {
  padding: 10px 35px;
}

.box-light-grey {
  background-color: #f6f6f6;
  padding: 2em;
}

.box-light-grey .box-title {
  font-size: 1.5em;
  margin: 0 0 13px;
}

.box-light-grey .box-content {
  padding: 0;
}

.box-light-grey p {
  line-height: 1.3;
}

.box-light-grey p:last-child {
  margin-bottom: 0;
}

.page-section {
  margin-bottom: 24px;
  margin-bottom: 10px;
}

.page-section:last-child {
  margin-bottom: 0;
}

.site-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  max-width: 1366px;
  height: 90px;
  padding: 0 15px;
  margin: 0 auto;

  @media (max-width: 767px) {
    height: 67px;
  }
}

@media (max-width: 423px) {
  .site-header {
    height: 45px;
    margin-bottom: 10px;
  }
}

.site-header #srch-str {
  font-size: 1.6rem;
}

@media screen and (min-width: 975px) {
  .site-header {
    padding: 0 18px;
  }
}

.site-header .header-logo,
.site-header .header-search,
.site-header .header-trustpilot,
.site-header .header-button-group {
  transition: margin 0.5s;
  box-sizing: border-box;
  margin: 0 8px;
}

.site-header .header-logo:first-child,
.site-header .header-search:first-child,
.site-header .header-trustpilot:first-child,
.site-header .header-button-group:first-child {
  margin-left: 0;
}

.site-header .header-logo:last-child,
.site-header .header-search:last-child,
.site-header .header-trustpilot:last-child,
.site-header .header-button-group:last-child {
  margin-right: 0;
}

@media screen and (min-width: 975px) {

  .site-header .header-logo,
  .site-header .header-search,
  .site-header .header-trustpilot,
  .site-header .header-button-group {
    margin: 0 20px;
  }
}

.site-header.confidence-header:after,
.site-header .header-logo {
  width: 180px;
}

.site-header .header-logo {
  flex-shrink: 0;
  width: 175px;
  height: 58px;

  @media (max-width: 423px) {
    width: 78px;
  }
}

.site-header .header-logo .home-link {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.site-header .header-logo img,
.site-header .header-logo svg {
  max-width: 100%;
  max-height: 100%;
}

.site-header .header-search {
  position: relative;
  flex-grow: 2;
  height: 45px;

  @media (max-width: 767px) {
    display: none;
  }

  &--category {
    &:not(:last-child) {
      margin-bottom: 15px;
      border-bottom: 1px solid #b3aaaa;
      padding-bottom: 10px;
    }
  }

  &--result {
    padding: 10px 20px;

    p {
      font-size: 13px;
      margin: 0;
      margin-bottom: 3px;
      color: #b3aaaa;
    }

    a {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-size: 20px;
      color: #222;
      line-height: 30px;
    }

    @media (max-width: 992px) {
      display: none !important;
    }

    .product-discount-rec,
    .newDiscount {
      display: none !important;
    }

    .product-name {
      width: auto !important;
    }

    position: absolute;
    width: 100%;
    height: 60dvh;
    overflow-y: scroll;
    top: 49px;
    z-index: 100;
    background: #fff;
    -webkit-box-shadow: 0px 0px 11px -4px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 11px -4px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 11px -4px rgba(66, 68, 90, 1);
    border-radius: 5px;

    .new-price {
      font-size: 16px !important;
      padding-bottom: 0;
    }

    .product-card {
      display: flex;
      flex-direction: row;
      height: auto !important;
    }

    .img-container {
      height: 50px !important;
      margin-bottom: 0 !important;
      max-width: 50px !important;
    }

    .content {
      display: flex;
      margin-top: 0 !important;

      p {
        margin-bottom: 0 !important;
      }
    }

    .product-card-detail {
      display: flex;
      align-items: center;
    }
  }
}

.site-header .header-search form {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.site-header .header-search .header-search-input,
.site-header .header-search .header-search-button {
  height: 100%;
}

.site-header .header-search .tt-hint,
.site-header .header-search .header-search-input {
  font-size: 16px;
  box-sizing: border-box;
  width: 100%;
  height: 45px;
  padding: 12px 9px 9px 9px;
}

.site-header .header-search .tt-hint {
  border-width: 1px;
}

.site-header .header-search .header-search-input {
  flex-grow: 2;
  border: 0;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-radius: 3px 0 0 3px;
  background-color: #f4f4f4;
  -webkit-appearance: none;
}

.site-header .header-search .header-search-input::-webkit-input-placeholder {
  color: #83888b;
}

.site-header .header-search .header-search-input::-moz-placeholder {
  color: #83888b;
}

.site-header .header-search .header-search-input:-ms-input-placeholder {
  color: #83888b;
}

.site-header .header-search .header-search-input::-ms-input-placeholder {
  color: #83888b;
}

.site-header .header-search .header-search-input::placeholder {
  color: #83888b;
}

.site-header .header-search .header-search-input:focus {
  padding: 11px 8px 8px 8px;
  border-top: 1px solid #222;
  border-left: 1px solid #222;
  border-bottom: 1px solid #222;
  outline: none;
}

.site-header .header-search .header-search-button {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 45px;
  flex-shrink: 0;
  padding: 0;
  border-width: 2px 2px 2px 0;
  border-style: solid;
  border-color: #222;
  border-radius: 0 3px 3px 0;
  background-color: #222;
  background-image: url("../assets/img/icon-search.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  direction: ltr;
  text-indent: -9999px;
  overflow: hidden;
  min-width: auto;
}

.site-header .header-search .header-search-button:focus,
.site-header .header-search .header-search-button.active {
  border-top: 2px solid #f2811e;
  border-right: 2px solid #f2811e;
  border-bottom: 2px solid #f2811e;
  outline: none;
}

.site-header .header-search .search-form-error .header-search-input {
  padding: 11px 8px 8px 8px;
  border-top: 2px solid #d9534f;
  border-left: 2px solid #d9534f;
  border-bottom: 2px solid #d9534f;
}

.site-header .header-search .search-form-error .header-search-button {
  border-top: 2px solid #d9534f;
  border-right: 2px solid #d9534f;
  border-bottom: 2px solid #d9534f;
}

.site-header .header-search .tt-dropdown-menu {
  box-sizing: border-box;
  width: calc(100% + 45px);
}

.site-header .header-trustpilot a {
  color: #434649;
  display: block;
  font-size: 11px;
  height: 58px;
  line-height: 10px;
  text-align: center;
  text-decoration: none;
  width: 80px;
}

.site-header .header-trustpilot a svg {
  height: 20px;
  width: 100%;
}

.site-header .header-button-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.site-header .header-button-group .header-button {
  position: relative;
  box-sizing: border-box;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  margin: 0 5px;
  background-color: #f4f4f4;
  border: 1px solid #eee;
  transition: margin 0.5s;
}

.site-header .header-button-group .header-button:hover {
  background-color: #ddd;
  border: 1px solid #ccc;
}

.site-header .header-button-group .header-button a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 100% !important;
}

.site-header .header-button-group .header-button:first-child,
.site-header .header-button-group .header-button:nth-child(2) {
  margin-left: 0;
}

@media screen and (min-width: 975px) {
  .site-header .header-button-group .header-button {
    margin: 0 8px;
  }

  .site-header .header-button-group .header-button:nth-child(2) {
    margin-left: 8px;
  }
}

.site-header .header-button-group .header-button:last-child {
  margin-right: 0;
}

.site-header .header-button-group .header-button svg {
  position: relative;
  width: 25px;
  height: 25px;
}

.site-header .header-button-group .header-button.icon-help-information {
  display: none;
}

@media screen and (min-width: 975px) {
  .site-header .header-button-group .header-button.icon-help-information {
    display: block;
  }
}

.site-header .header-button-group .header-button.icon-my-account.customer--logged-out:hover {
  background-color: #ddd;
  border-color: #ccc;
}

.site-header .header-button-group .header-button.icon-my-account.customer--logged-in,
.site-header .header-button-group .header-button.icon-my-account.customer--vip-gold,
.site-header .header-button-group .header-button.icon-my-account.customer--vip-platinum {
  border-width: 2px;
}

.site-header .header-button-group .header-button.icon-my-account.customer--logged-in {
  background-color: transparent;
  border-color: #222;
}

.site-header .header-button-group .header-button.icon-my-account.customer--logged-in:hover {
  background-color: transparent;
}

.site-header .header-button-group .header-button.icon-my-account.customer--vip-gold {
  background-color: #f8f3e8;
  border-color: #d1af63;
}

.site-header .header-button-group .header-button.icon-my-account.customer--vip-gold:hover {
  background-color: #f3ebd8;
}

.site-header .header-button-group .header-button.icon-my-account.customer--vip-platinum {
  background-color: #eceeee;
  border-color: #a0a8ab;
}

.site-header .header-button-group .header-button.icon-my-account.customer--vip-platinum:hover {
  background-color: #e2e5e6;
}

.site-header .header-button-group .header-button.icon-my-account svg {
  // width: 54px;
  // height: 54px;
}

.site-header .header-button-group .header-button.icon-my-account.customer--vip-gold svg,
.site-header .header-button-group .header-button.icon-my-account.customer--vip-platinum svg {
  bottom: -2px;
  width: 40px;
  height: 40px;
}

.site-header .header-button-group .header-button.icon-basket {
  position: relative;
}

.site-header .header-button-group .header-button.icon-basket.not-empty {
  background-color: #fff;
  border: 2px solid #222;
}

.site-header .header-button-group .header-button.icon-basket.not-empty:hover {
  background-color: transparent;
}

.site-header .header-button-group .header-button.icon-basket.not-empty svg {
  margin: 7px;
}

.site-header .header-button-group .header-button.icon-basket.not-empty svg .icon-basket_svg__st0 {
  fill: #222;
}

.site-header .header-button-group .header-button.icon-basket a {
  height: 56px;
  width: 56px;
}

// .site-header .header-button-group .header-button.icon-basket a svg {
//   height: 40px;
//   width: 40px;
// }
.site-header .header-button-group .header-button.icon-basket span.basket-item-count {
  background-color: #636596;
  border-radius: 100%;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  height: 18px;
  left: 6px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 15px;
  width: 18px;
}

.site-header .header-button-group .header-button.icon-language .icon-lang:before {
  border: 2px solid #eee;
}

.icon-language {
  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.site-header .header-button-group .header-button .tooltip {
  position: absolute;
  top: 46px;
  left: 50%;
  z-index: 1000;
  display: none;
  justify-content: center;
  width: 200px;
  padding-top: 26px;
  transform: translateX(-50%);
}

.site-header .header-button-group .header-button .tooltip:after {
  content: "";
  display: block;
  position: absolute;
  top: 16px;
  left: 50%;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border-color: #eee;
  border-style: solid;
  border-width: 1px 0 0 1px;
  background-color: #f4f4f4;
  box-shadow: -1px -1px 0px rgba(0, 0, 0, 0.07);
  transform: translateX(-50%) rotateZ(45deg);
}

.site-header .header-button-group .header-button .tooltip .tooltip-content {
  box-sizing: border-box;
  padding: 20px;
  border: 1px solid #eee;
  background-color: #f4f4f4;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  min-width: 380px;
}

@media screen and (max-width: 975px) {
  .site-header .header-button-group .header-button .tooltip .tooltip-content {
    position: relative;
    left: -25%;
  }
}

@media screen and (-moz-touch-enabled: 0) {
  .site-header .header-button-group .header-button:hover .tooltip {
    display: flex;
  }
}

@media screen and (pointer: fine) and (hover: hover) {
  .site-header .header-button-group .header-button:hover .tooltip {
    display: flex;
  }
}

.site-header.confidence-header:after {
  content: " ";
}

.site-header #secure-checkout-section {
  margin: 0;
}

.site-header .secure-checkout-wrapper {
  width: 100%;
}

#header {
  position: relative;
  max-width: 1330px;
  padding: 5px 13px 9px;
  margin: 0 auto;
}

.header-box {
  padding: 0 0 9px;
  z-index: 1;
  position: relative;
}

.logo {
  float: left;
  width: 165px;
  height: 64px;
  background-repeat: no-repeat;
  overflow: hidden;
  text-indent: -9999px;
  margin: 3px 0 0 5px;
}

.logo a {
  display: block;
  height: 100%;
}

.add-links {
  padding: 0;
  margin: 0;
  list-style: none;
  float: right;
  width: 180px;
  background: #f6f6f6;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  box-shadow:
    0 0 3px 1px rgba(0, 0, 0, 0.11),
    inset 1px 1px 0px 0px #fff;
  font-size: 0.91em;
  line-height: 1.2em;
}

.add-links a {
  display: block;
  color: #434649;
  position: relative;
  padding: 4px 3px 4px 31px;
  letter-spacing: -1px;
}

.add-links a:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 7px;
  background-repeat: no-repeat;
}

.add-links a.tel:before {
  width: 8px;
  height: 18px;
  margin-top: -9px;
  background-position: -166px 0;
  left: 12px;
}

.add-links a.basket:before {
  width: 20px;
  height: 16px;
  margin-top: -8px;
  background-position: -175px 0;
}

.add-links a b {
  display: block;
  letter-spacing: 0;
}

.header-box:after {
  content: "";
  display: block;
  clear: both;
}

.add-links .phone-fine-print {
  padding: 4px 3px 4px 8px;
  font-size: 0.7em;
  line-height: 8px;
  margin-top: 0;
  color: #83888b;
}

@media screen and (max-width: 768px) {
  .add-links {
    width: 40%;
  }
}

#secure-checkout-section {
  margin: 0 0 0 173px;
  text-align: center;
  font-weight: 900;
  color: #434649;
}

.lang-container a.lang:before {
  width: 28px;
  height: 28px;
  background-size: 560px 28px;
  border-radius: 100%;
  display: inline-block;
}

.lang-container a:before {
  margin: 0;
  top: 10px;
  left: 50%;
  height: 22px;
  background: url(/dist/images/sprite11.png) no-repeat;
  content: " ";
}

.header-box-mobile {
  display: flex;
  padding-left: 8px;
}

#hamburger-container {
  flex-grow: 1;
  margin-top: 12px;
}

#logo-container {
  flex-grow: 1;
}

#icon-container {
  display: flex;
  flex-grow: 0.8;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  margin-top: 13px;
}

#icon-container {
  margin-top: 11px;
}

#header {
  padding-top: 10px;
  padding-bottom: 11px;
}

.header-box {
  padding: 0;
}

.header-box:after {
  content: " ";
  display: none;
  clear: none;
}

.logo {
  width: 174px;
  height: 67px;
  background: url(/dist/images/sprite11.png) no-repeat -202px 0;
  margin: 3px 0 0 1px;
}

.logo.de {
  width: 174px;
  height: 67px;
  background: url(/dist/images/logo_multi_de.png) no-repeat -202px 0;
  margin: 3px 0 0 1px;
}

.logo.mdavis {
  width: 176px;
  height: 68px;
  background: url(/dist/images/logo-multi-mdavis.png) no-repeat 0 -80px;
  margin: 3px 0 0 1px;
}

.add-links {
  width: auto;
  background: #f6f6f6;
  border-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: none;
  text-align: center;
  font-size: 0.834em;
  line-height: 1.2em;
  overflow: hidden;
}

.add-links li {
  border-left: 1px solid #e3e3e3;
  float: left;
  width: 82px;
}

.add-links li:first-child {
  border-left: none;
}

.add-links a {
  text-align: center;
  padding: 36px 7px 8px;
  margin: 1px -1px 0 0;
  letter-spacing: 0;
  min-height: 24px;
  outline: none;
}

.add-links li:first-child a {
  border-radius: 3px 0 0 3px;
}

.add-links li:last-child a {
  border-radius: 0 3px 3px 0;
}

.add-links a:before {
  margin: 0;
  top: 10px;
  left: 50%;
  height: 22px;
  background: url(/dist/images/sprite11.png) no-repeat;
  content: " ";
}

.add-links a.help:before {
  width: 17px;
  margin-left: -8px;
  background-position: 0 -79px;
}

.add-links a.account:before {
  width: 20px;
  margin-left: -10px;
  background-position: -18px -79px;
}

.add-links a.account span.account-sash-platinum {
  background-image: url("/dist/images/account-sash-platinum.gif");
  display: block;
  height: 44px;
  margin: -61px 0 0 -6px;
  width: 44px;
}

.add-links a.basket:before {
  width: 26px;
  height: 22px;
  margin-top: 0;
  margin-left: -13px;
  background-position: -39px -79px;
}

.add-links a.checkout:before {
  width: 29px;
  margin-left: -14px;
  background-position: -66px -79px;
}

.add-links a.lang:before {
  width: 29px;
  height: 20px;
  margin-left: -14px;
}

.lang:before {
  top: 11px !important;
  background-image: url("/dist/images/lang-flags.png?v10") !important;
  background-position: -58px -132px !important;
}

.lang.be:before {
  background-position: 0 0 !important;
}

.lang.cz:before {
  background-position: 0px -22px !important;
}

.lang.dk:before {
  background-position: 0px -44px !important;
}

.lang.de:before {
  background-position: 0px -66px !important;
}

.lang.gr:before {
  background-position: 0px -88px !important;
}

.lang.es:before {
  background-position: 0px -110px !important;
}

.lang.fr:before {
  background-position: 0px -132px !important;
}

.lang.ie:before {
  background-position: -29px -0px !important;
}

.lang.it:before {
  background-position: -29px -22px !important;
}

.lang.nl:before {
  background-position: -29px -44px !important;
}

.lang.no:before {
  background-position: -29px -66px !important;
}

.lang.at:before {
  background-position: -29px -88px !important;
}

.lang.pl:before {
  background-position: -29px -110px !important;
}

.lang.pt:before {
  background-position: -29px -132px !important;
}

.lang.ru:before {
  background-position: -58px -0px !important;
}

.lang.ch:before {
  background-position: -58px -22px !important;
}

.lang.si:before {
  background-position: -58px -44px !important;
}

.lang.sk:before {
  background-position: -58px -66px !important;
}

.lang.fi:before {
  background-position: -58px -88px !important;
}

.lang.se:before {
  background-position: -58px -110px !important;
}

.lang.us:before {
  background-position: 0 -154px !important;
}

.lang.en:before,
.lang.com:before {
  background-position: -58px -132px !important;
}

.add-links a b {
  font-size: 1.2em;
  line-height: 1em;
}

.add-links a:hover,
.add-links a:focus {
  text-decoration: none;
  background: #ededed;
  padding-bottom: 58px;
  margin-bottom: -50px;
}

.add-links a.checkout {
  background: #faa100 url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZhYTEwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmNTZhMDMiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: linear-gradient(to bottom, #faa100 0%, #f56a03 100%);
  color: #fff;
  margin: -1px;
  border-color: #fa8700;
  padding-top: 37px;
  padding-bottom: 9px;
  box-shadow: inset 0 1px 0 0 #fcc154;
}

@media screen and (min-width: 956px) {
  #secure-checkout-section {
    margin: 0 180px;
    text-align: center;
  }

  #header {
    padding: 6px 18px 8px;
  }

  .logo {
    width: 201px;
    height: 77px;
    background-position: 0 0;
    margin: 0;
  }

  .add-links {
    margin-top: 3px;
  }

  .add-links li {
    width: 93px;
  }
}

body.body-class--mobile .logo,
body.mobile-checkout .logo {
  float: left;
  width: 165px;
  height: 64px;
  background-repeat: no-repeat;
  overflow: hidden;
  text-indent: -9999px;
  margin: 3px 0 0 5px;
}

body.body-class--mobile .logo a,
body.mobile-checkout .logo a {
  display: block;
  height: 100%;
}

body.body-class--mobile #header,
body.mobile-checkout #header {
  z-index: 100;
  background: white;
  padding-top: 5px;
  margin-bottom: 10px;
}

body.body-class--mobile .header-item,
body.mobile-checkout .header-item {
  margin-bottom: 5px;
}

body.body-class--mobile .header-item:last-child,
body.mobile-checkout .header-item:last-child {
  margin-bottom: 0;
}

body.body-class--mobile .lang-container,
body.mobile-checkout .lang-container {
  padding-top: 0;
}

body.body-class--mobile .lang-container a,
body.mobile-checkout .lang-container a {
  outline: 0;
}

body.body-class--mobile a.icon-lang:before,
body.mobile-checkout a.icon-lang:before {
  border: 2px solid #ddd;
  box-sizing: content-box;
}

body.body-class--mobile a.call-us,
body.mobile-checkout a.call-us {
  color: #434649;
  text-decoration: none;
  display: block;
  width: 28px;
  height: 28px;
}

body.body-class--mobile a#basket,
body.mobile-checkout a#basket {
  color: #434649;
  text-decoration: none;
  display: block;
  width: 28px;
  height: 28px;
}

body.body-class--mobile #hamburger-container,
body.mobile-checkout #hamburger-container {
  display: inline-block;
  vertical-align: middle;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  margin-top: 12px;
}

body.body-class--mobile .hamburger-menu-icon,
body.mobile-checkout .hamburger-menu-icon {
  height: 30px;
  width: 30px;
}

body.body-class--mobile .hamburger-menu-icon>span,
body.mobile-checkout .hamburger-menu-icon>span {
  position: absolute;
  left: -9999px;
}

body.body-class--mobile #logo-container a,
body.mobile-checkout #logo-container a {
  display: block;
  width: 140px;
  height: 52px;
  padding-top: 4px;
}

body.body-class--mobile #phone-icon,
body.mobile-checkout #phone-icon {
  width: 28px;
  height: 28px;
}

body.body-class--mobile #shopping-basket-icon,
body.mobile-checkout #shopping-basket-icon {
  margin-right: 8px;
  width: 28px;
  height: 28px;
  position: relative;
}

body.body-class--mobile .notification-badge,
body.mobile-checkout .notification-badge {
  position: absolute;
  right: 15px;
  background: #f9a11b;
  width: 18px;
  height: 18px;
  text-align: center;
  border-radius: 100%;
  font-size: 14px;

  color: white;
  line-height: 19px;
  font-weight: bold;
}

body.body-class--mobile:not(.body-class--has-adaptive) #header,
body.mobile-checkout:not(.body-class--has-adaptive) #header {
  padding: 5px 10px 9px 10px;
}

body.body-class--mobile:not(.body-class--has-adaptive) .notification-badge,
body.mobile-checkout:not(.body-class--has-adaptive) .notification-badge {
  top: 0;
}

body.body-class--mobile:not(.body-class--has-adaptive) .lang-container,
body.mobile-checkout:not(.body-class--has-adaptive) .lang-container {
  padding: 0;
}

body.body-class--mobile .basket-count-font-small,
body.mobile-checkout .basket-count-font-small {
  font-size: 11px !important;
}

body.body-class--mobile .basket-count-font-normal,
body.mobile-checkout .basket-count-font-normal {
  font-size: 13px !important;
}

body.body-class--mobile .logo,
body.mobile-checkout .logo {
  background-position: 0 0;
  background: url("/dist/images/sprite-m.png") no-repeat 0 0;
  background-size: 249px 70px;
  width: 165px;
  height: 64px;
  padding-bottom: 3px;
}

body.body-class--mobile #logo-container a.com,
body.mobile-checkout #logo-container a.com {
  background-size: 188px 53px;
}

body.body-class--mobile .logo.at,
body.mobile-checkout .logo.at {
  background: url(/dist/images/sprites/at.png) no-repeat 0 0;
  background-size: 360px 72px;
}

body.body-class--mobile .logo.be,
body.mobile-checkout .logo.be {
  background: url(/dist/images/sprites/be.png) no-repeat 0 0;
  background-size: 360px 72px;
}

body.body-class--mobile .logo.ch,
body.mobile-checkout .logo.ch {
  background: url(/dist/images/sprites/ch.png) no-repeat 0 0;
  background-size: 360px 72px;
}

body.body-class--mobile .logo.cz,
body.mobile-checkout .logo.cz {
  background: url(/dist/images/sprites/cz.png) no-repeat 0 0;
  background-size: 360px 72px;
}

body.body-class--mobile .logo.de,
body.mobile-checkout .logo.de {
  background: url(/dist/images/sprites/de.png) no-repeat 0 0;
  background-size: 360px 72px;
}

body.body-class--mobile .logo.dk,
body.mobile-checkout .logo.dk {
  background: url(/dist/images/sprites/dk.png) no-repeat 0 0;
  background-size: 360px 72px;
}

body.body-class--mobile .logo.es,
body.mobile-checkout .logo.es {
  background: url(/dist/images/sprites/es.png) no-repeat 0 0;
  background-size: 360px 72px;
}

body.body-class--mobile .logo.fi,
body.mobile-checkout .logo.fi {
  background: url(/dist/images/sprites/fi.png) no-repeat 0 0;
  background-size: 360px 72px;
}

body.body-class--mobile .logo.fr,
body.mobile-checkout .logo.fr {
  background: url(/dist/images/sprites/fr.png) no-repeat 0 0;
  background-size: 360px 72px;
}

body.body-class--mobile .logo.ie,
body.mobile-checkout .logo.ie {
  background: url(/dist/images/sprites/ie.png) no-repeat 0 0;
  background-size: 360px 72px;
}

body.body-class--mobile .logo.it,
body.mobile-checkout .logo.it {
  background: url(/dist/images/sprites/it.png) no-repeat 0 0;
  background-size: 360px 72px;
}

body.body-class--mobile .logo.mdavis,
body.mobile-checkout .logo.mdavis {
  background: url(/dist/images/sprites/mdavis.png) no-repeat 0 0;
  background-size: 360px 72px;
}

body.body-class--mobile .logo.mdownton,
body.mobile-checkout .logo.mdownton {
  background: url(/dist/images/sprites/mdownton.png) no-repeat 0 0;
  background-size: 360px 72px;
}

body.body-class--mobile .logo.nl,
body.mobile-checkout .logo.nl {
  background: url(/dist/images/sprites/nl.png) no-repeat 0 0;
  background-size: 360px 72px;
}

body.body-class--mobile .logo.no,
body.mobile-checkout .logo.no {
  background: url(/dist/images/sprites/no.png) no-repeat 0 0;
  background-size: 360px 72px;
}

body.body-class--mobile .logo.pl,
body.mobile-checkout .logo.pl {
  background: url(/dist/images/sprites/pl.png) no-repeat 0 0;
  background-size: 360px 72px;
}

body.body-class--mobile .logo.pt,
body.mobile-checkout .logo.pt {
  background: url(/dist/images/sprites/pt.png) no-repeat 0 0;
  background-size: 360px 72px;
}

body.body-class--mobile .logo.se,
body.mobile-checkout .logo.se {
  background: url(/dist/images/sprites/se.png) no-repeat 0 0;
  background-size: 360px 72px;
}

body.body-class--mobile .logo.si,
body.mobile-checkout .logo.si {
  background: url(/dist/images/sprites/si.png) no-repeat 0 0;
  background-size: 360px 72px;
}

body.body-class--mobile .logo.sk,
body.mobile-checkout .logo.sk {
  background: url(/dist/images/sprites/sk.png) no-repeat 0 0;
  background-size: 360px 72px;
}

body.body-class--mobile div.header-box-mobile,
body.mobile-checkout div.header-box-mobile {
  display: flex;
  display: -webkit-flex;
  flex-grow: 1;
  -webkit-flex-grow: 1;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  padding-left: 11px;
}

body.body-class--mobile #logo-container,
body.mobile-checkout #logo-container {
  height: 52px;
  flex-grow: 1;
  -webkit-flex-grow: 1;
}

body.body-class--mobile #logo-container.logo-av svg,
body.mobile-checkout #logo-container.logo-av svg {
  height: 40px;
  width: 100%;
}

body.body-class--mobile #icon-container,
body.mobile-checkout #icon-container {
  flex-grow: 0.8;
  -webkit-flex-grow: 0.8;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  margin-top: 13px;
}

body.body-class--mobile .search-form,
body.mobile-checkout .search-form {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  border: 1px solid #d5d5d5;
  box-shadow: 0 0 0 1px transparent;
  transition:
    border 0.2s,
    box-shadow 0.1s;
  height: 40px;
}

body.body-class--mobile .search-form.search-form-error,
body.mobile-checkout .search-form.search-form-error {
  border: 1px solid #d9534f;
  box-shadow: 0 0 0 1px #d9534f;
}

body.body-class--mobile .search-form.search-form-focused,
body.mobile-checkout .search-form.search-form-focused {
  border: 1px solid #fa8600;
  box-shadow: 0 0 0 1px #fa8600;
}

body.body-class--mobile .search-form.search-form-focused input:focus::-webkit-input-placeholder,
body.mobile-checkout .search-form.search-form-focused input:focus::-webkit-input-placeholder {
  color: rgba(99, 101, 106, 0.5);
}

body.body-class--mobile .search-form.search-form-focused input:focus::-moz-placeholder,
body.mobile-checkout .search-form.search-form-focused input:focus::-moz-placeholder {
  color: rgba(99, 101, 106, 0.5);
}

body.body-class--mobile .search-form.search-form-focused input:focus:-ms-input-placeholder,
body.mobile-checkout .search-form.search-form-focused input:focus:-ms-input-placeholder {
  color: rgba(99, 101, 106, 0.5);
}

body.body-class--mobile .search-form.search-form-focused input:focus::-ms-input-placeholder,
body.mobile-checkout .search-form.search-form-focused input:focus::-ms-input-placeholder {
  color: rgba(99, 101, 106, 0.5);
}

body.body-class--mobile .search-form.search-form-focused input:focus::placeholder,
body.mobile-checkout .search-form.search-form-focused input:focus::placeholder {
  color: rgba(99, 101, 106, 0.5);
}

body.body-class--mobile .search-form .field-holder,
body.mobile-checkout .search-form .field-holder {
  margin: 0 47px 0 0;
}

body.body-class--mobile .search-form input[type="submit"],
body.mobile-checkout .search-form input[type="submit"] {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  line-height: 30px;
  padding: 0 5px 2px;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.45);
  border: none;
  border-radius: 0 3px 3px 0;
  -webkit-appearance: none;
  float: none;
  width: 47px;
  height: 100%;
  outline-width: 0;
  background: rgba(0, 0, 0, 0);
}

body.body-class--mobile .search-form input[type="search"],
body.body-class--mobile .search-form .tt-hint,
body.mobile-checkout .search-form input[type="search"],
body.mobile-checkout .search-form .tt-hint {
  border: none;
  background: none;
  width: 100%;
  border-radius: 0;
  -webkit-appearance: none;
  float: left;
  box-sizing: border-box;
  color: #434649;
  height: 38px;
  line-height: normal;
  font-size: 18px;
  padding: 8px 20px 8px 14px;
  outline: none;
}

body.body-class--mobile .search-form input[type="search"]::-webkit-input-placeholder,
body.body-class--mobile .search-form .tt-hint::-webkit-input-placeholder,
body.mobile-checkout .search-form input[type="search"]::-webkit-input-placeholder,
body.mobile-checkout .search-form .tt-hint::-webkit-input-placeholder {
  color: #757a7f;
}

body.body-class--mobile .search-form input[type="search"]:-ms-input-placeholder,
body.body-class--mobile .search-form .tt-hint:-ms-input-placeholder,
body.mobile-checkout .search-form input[type="search"]:-ms-input-placeholder,
body.mobile-checkout .search-form .tt-hint:-ms-input-placeholder {
  color: #757a7f;
}

body.body-class--mobile .search-form input[type="search"]::-moz-placeholder,
body.body-class--mobile .search-form .tt-hint::-moz-placeholder,
body.mobile-checkout .search-form input[type="search"]::-moz-placeholder,
body.mobile-checkout .search-form .tt-hint::-moz-placeholder {
  color: #757a7f;
  opacity: 1;
}

body.body-class--mobile .search-form input[type="search"]:-moz-placeholder,
body.body-class--mobile .search-form .tt-hint:-moz-placeholder,
body.mobile-checkout .search-form input[type="search"]:-moz-placeholder,
body.mobile-checkout .search-form .tt-hint:-moz-placeholder {
  color: #757a7f;
  opacity: 1;
}

body.body-class--mobile .twitter-typeahead .tt-hint,
body.mobile-checkout .twitter-typeahead .tt-hint {
  color: #ccc;
  padding-left: 10px;
}

body.body-class--mobile .twitter-typeahead:focus,
body.mobile-checkout .twitter-typeahead:focus {
  outline: none;
}

body.body-class--mobile .tt-dropdown-menu,
body.mobile-checkout .tt-dropdown-menu {
  background: #fff;
  width: 100%;
  padding-right: 40px;
  border-color: #dbdbdb;
  border-radius: 6px 6px 6px 6px;
  box-shadow: 0 0 0 1px #b9b9b9 inset;
  font-weight: normal;
  overflow: hidden;
  padding-right: 48px;
}

body.body-class--mobile .tt-suggestion,
body.mobile-checkout .tt-suggestion {
  margin-right: -47px;
  border-bottom: 1px solid #ececec;
  padding: 4px 12px 2px 6px;
  font-size: 14px;
  padding: 7px 15px 7px 15px;
}

body.body-class--mobile .tt-suggestions,
body.mobile-checkout .tt-suggestions {
  padding: 1px 1px 0px;
}

body.body-class--mobile .search-wrapper,
body.mobile-checkout .search-wrapper {
  position: relative;
}

body.body-class--mobile .tt-suggestion.tt-is-under-cursor,
body.mobile-checkout .tt-suggestion.tt-is-under-cursor {
  background: #f6f6f6;
}

body.body-class--mobile .twitter-typeahead .tt-hint,
body.mobile-checkout .twitter-typeahead .tt-hint {
  padding-left: 14px;
}

body.body-class--mobile #input-image,
body.mobile-checkout #input-image {
  display: block;
  background-image: url(/dist/images/magnifying_glass_search.png);
  background-size: 24px 24px;
  background-color: #63656a;
  background-position: center;
  background-repeat: no-repeat;
  float: right;
  border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -webkit-border-radius: 0 3px 3px 0;
  margin-top: -1px;
  margin-right: -1px;
  height: 40px;
  background-size: 23px 23px;
}

body.body-class--mobile #srch-str,
body.mobile-checkout #srch-str {
  outline-width: 0;
}

body.body-class--mobile .search-box,
body.mobile-checkout .search-box {
  top: 0;
  width: 100%;
}

body.body-class--mobile .search-box .search-position,
body.mobile-checkout .search-box .search-position {
  position: relative;
  margin: 0;
}

body.body-class--mobile .search-box .box-holder,
body.mobile-checkout .search-box .box-holder {
  margin: 0;
  max-width: none;
}

body.body-class--mobile .search-box p,
body.mobile-checkout .search-box p {
  display: block;
  margin: 3px 0 4px;
}

body.body-class--mobile .search-box p span,
body.mobile-checkout .search-box p span {
  color: #fa8600;
}

@media screen and (min-width: 1336px) {

  body.body-class--mobile #inventory-list-sort,
  body.mobile-checkout #inventory-list-sort {
    position: absolute;
  }
}

body.body-class--mobile .carousel li.page-result a,
body.mobile-checkout .carousel li.page-result a {
  color: #434649;
}

body.body-class--mobile .carousel li.page-result a h3,
body.mobile-checkout .carousel li.page-result a h3 {
  height: 25px;
  overflow: hidden;
}

body.body-class--mobile #order-total-container,
body.mobile-checkout #order-total-container {
  flex-grow: 1;
  -webkit-align-items: baseline;
}

body.body-class--mobile #order-total-container .order-total-copy,
body.mobile-checkout #order-total-container .order-total-copy {
  display: inline-block;
  margin-bottom: 0;
  margin-top: 4px;
}

body.body-class--mobile #order-total-container .order-total-price,
body.mobile-checkout #order-total-container .order-total-price {
  color: #249c54;
  margin-top: 0;
  font-size: 1.8em;
}

.search-form {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  border: 1px solid #d5d5d5;
  box-shadow: 0 0 0 1px transparent;
  transition:
    border 0.2s,
    box-shadow 0.1s;
  height: 38px;
  box-sizing: border-box;
  height: 33px;
}

.search-form.search-form-error {
  border: 1px solid #d9534f;
  box-shadow: 0 0 0 1px #d9534f;
}

.search-form.search-form-focused {
  border: 1px solid #fa8600;
  box-shadow: 0 0 0 1px #fa8600;
}

.search-form.search-form-focused input:focus::-webkit-input-placeholder {
  color: rgba(99, 101, 106, 0.5);
}

.search-form.search-form-focused input:focus::-moz-placeholder {
  color: rgba(99, 101, 106, 0.5);
}

.search-form.search-form-focused input:focus:-ms-input-placeholder {
  color: rgba(99, 101, 106, 0.5);
}

.search-form.search-form-focused input:focus::-ms-input-placeholder {
  color: rgba(99, 101, 106, 0.5);
}

.search-form.search-form-focused input:focus::placeholder {
  color: rgba(99, 101, 106, 0.5);
}

.search-form .field-holder {
  margin: 0 47px 0 0;
}

.search-form input[type="submit"] {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  line-height: 30px;
  padding: 0 5px 2px;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.45);
  border: none;
  border-radius: 0 3px 3px 0;
  -webkit-appearance: none;
  float: none;
  width: 47px;
  height: 40px;
  outline-width: 0;
  background: rgba(0, 0, 0, 0);
  width: 43px;
  height: 35px;
}

.search-form input[type="search"],
.search-form .tt-hint {
  border: none;
  background: none;
  width: 100%;
  border-radius: 0;
  -webkit-appearance: none;
  float: left;
  box-sizing: border-box;
  color: #434649;
  height: 38px;
  line-height: normal;
  font-size: 18px;
  padding: 8px 20px 8px 14px;
  font-size: 16px;
  height: 33px;
}

.search-form input[type="search"]::-webkit-input-placeholder,
.search-form .tt-hint::-webkit-input-placeholder {
  color: #757a7f;
}

.search-form input[type="search"]:-ms-input-placeholder,
.search-form .tt-hint:-ms-input-placeholder {
  color: #757a7f;
}

.search-form input[type="search"]::-moz-placeholder,
.search-form .tt-hint::-moz-placeholder {
  color: #757a7f;
  opacity: 1;
}

.search-form input[type="search"]:-moz-placeholder,
.search-form .tt-hint:-moz-placeholder {
  color: #757a7f;
  opacity: 1;
}

.twitter-typeahead .tt-hint {
  color: #ccc;
  padding-left: 10px;
}

.twitter-typeahead:focus {
  outline: none;
}

.tt-dropdown-menu {
  background: #fff;
  width: 100%;
  padding-right: 40px;
  border-color: #dbdbdb;
  border-radius: 6px 6px 6px 6px;
  box-shadow: 0 0 0 1px #b9b9b9 inset;
  font-weight: normal;
  overflow: hidden;
  padding-right: 48px;
}

.tt-suggestion {
  margin-right: -47px;
  border-bottom: 1px solid #ececec;
  padding: 4px 12px 2px 6px;
  font-size: 14px;
  padding: 7px 15px 7px 15px;
}

.tt-suggestions {
  padding: 1px 1px 0px;
}

.search-wrapper {
  position: relative;
}

.tt-suggestion.tt-is-under-cursor {
  background: #f6f6f6;
}

.twitter-typeahead .tt-hint {
  padding-left: 14px;
}

#input-image {
  display: inline-block;
  background-image: url(/dist/images/magnifying_glass_search.png);
  background-size: 24px 24px;
  background-color: #63656a;
  background-position: center;
  background-repeat: no-repeat;
  float: right;
  border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -webkit-border-radius: 0 3px 3px 0;
  margin-top: -1px;
  margin-right: -1px;
  background-size: 23px 23px;
}

#srch-str {
  outline-width: 0;
}

.search-box {
  font-size: 1.334em;
  line-height: 1.2em;
  font-weight: bold;
  height: 80px;
  padding: 14px 0 0;
  position: absolute;
  top: 0px;
  width: 100%;
  left: 0px;
  box-sizing: border-box;
  padding: 14px 13px 0;
}

@media screen and (min-width: 956px) {
  .search-box {
    padding: 14px 18px 0;
  }
}

.search-box .search-position {
  position: relative;
  margin: 0 274px 0 202px;
}

@media screen and (min-width: 956px) {
  .search-box .search-position {
    margin: 0 411px 0 209px;
  }
}

.search-box .box-holder {
  margin: 0 auto;
  max-width: 392px;
}

.search-box p {
  display: block;
  margin: 3px 0 4px;
}

.search-box p span {
  color: #fa8600;
}

@media screen and (min-width: 1336px) {
  #inventory-list-sort {
    position: absolute;
  }
}

.carousel li.page-result a {
  color: #434649;
}

.carousel li.page-result a h3 {
  height: 25px;
  overflow: hidden;
}

.no-search-result-text {
  font-size: 1.167em;
  line-height: 1.286em;
  margin: 10px 11px;
  padding: 0;
  text-align: center;
  margin: 0 11px;
}

.search-results .h1-title.instant-search-title h1 {
  font-size: 1em;
  line-height: 1em;
}

.search-results-page-search-results {
  width: 100%;
}

.search-results-page-filters {
  width: 230px;
  display: flex;
  flex-direction: column;
}

.search-results-page .h1-title {
  font-size: 2.5em;
  line-height: 1.3em;
  padding-bottom: 8px;
  font-weight: bold;
  margin-left: 265px;
}

@media screen and (max-width: 768px) {
  .search-results-page .h1-title {
    margin-left: 0;
  }
}

.search-results-page.no-results .result-list {
  border: none;
}

.search-results-page-wrapper {
  display: flex;
  flex-direction: row;
}

.search-filters-wrapper {
  max-width: 265px;
  width: 100%;
}

.search-filter-box-wrapper {
  width: 230px;
  margin-bottom: 10px;
}

.search-filter-box-wrapper-numeric .r-g4m-select-custom--numeric-filter .r-g4m-select-custom__dropdown {
  box-sizing: border-box;
}

.search-error-message {
  background-color: #ffaaaa;
  color: #721c24;
  border-radius: 3px;
  padding: 5px;
  margin-bottom: 10px;
  margin-right: 16px;
}

.search-sort-section {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  gap: 10px;
}

.search-sort-wrapper {
  width: 230px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1024px) {
  .search-sort-section {
    gap: 0px;
  }

  .search-results-page-search-results {
    order: 1;
    width: 100%;
  }

  .search-results-page {
    flex-direction: column;
  }

  .search-sort-wrapper {
    flex: 1;
    margin-right: 10px;
  }

  .search-sort-wrapper.last {
    margin-right: 0px;
  }

  .search-error-message {
    width: 100%;
    margin-right: 0;
  }
}

body.av-search-page .search-filters-wrapper {
  margin-top: -38px;
}

body.av-search-page .search-filters-wrapper .clear-all-filters-option-wrapper {
  width: 230px;
  text-align: right;
  margin-bottom: 6px;
}

body.av-search-page .search-filters-wrapper .clear-all-filters-option-wrapper .clear-all-filters-option {
  font-size: 12px;
  font-weight: bold;
  color: #1c9ace;
  background: none;
  border: none;
  padding: 0;
}

body.av-search-page .search-filters-wrapper .clear-all-filters-option-wrapper .clear-all-filters-option:hover {
  cursor: pointer;
  text-decoration: underline;
}

body.av-search-page .search-filters-wrapper .r-g4m-select-native--single-filter .r-g4m-select-native__text,
body.av-search-page .search-filters-wrapper .r-g4m-select-native--multi-filter .r-g4m-select-native__text {
  padding: 10px 15px;
  text-align: left;
}

body.av-search-page .search-filters-wrapper .r-g4m-select-custom__dropdown {
  width: 150%;
}

#main {
  margin: 0 0 10px;
}

span.translation-link {
  display: none !important;
  max-height: 16px !important;
  width: 20px !important;
  background: #ffd57a !important;
  border-radius: 3px !important;
  margin: -5px 5px 5px 5px !important;
  padding: 2px !important;
  font-size: 10px !important;
  color: #3b3131 !important;
  top: 5px !important;
  right: -5px !important;
  line-height: 10px !important;
  z-index: 1000 !important;
  text-shadow: none !important;
  cursor: pointer !important;
  font-weight: bold !important;
}

.hover-show-translation-link:hover .translation-link {
  display: inline-block !important;
}

.hover-show-translation-link {
  display: inline-block !important;
  position: relative !important;
  border-radius: 5px !important;
}

.hover-show-translation-link:hover {
  border: 1px dashed #e0e0e0 !important;
  z-index: 9999 !important;
  color: #ba114a !important;
  display: inline-block !important;
  text-shadow: none !important;
  text-decoration: none !important;
  background: #fff !important;
  background: rgba(255, 255, 255, 0.5) !important;
  padding: 10px !important;
  margin: -11px !important;
  box-sizing: border-box !important;
}

.hover-show-translation-link:hover span {
  color: #ba114a !important;
}

.hover-show-translation-link:hover span.translation-link {
  color: #3b3131 !important;
}

.pr {
  display: block;
  background-color: #cff09e;
  width: 100%;
  padding: 2px 5px;
  margin: 5px 0px;
  font-size: 10px;
  border-bottom: 3px #ccc solid;

  clear: both;
}

.pr .header {
  font-weight: bold;
  margin: 0;
  font-size: 12px;
}

.header-local {
  width: 100%;
  display: block;
  text-align: center;
  padding: 10px 0;
  background-color: #ff69b4;
  font-weight: bold;
  color: white;
}

.header-local a {
  font-weight: bold;
  color: white;
}

#cmscnt h2,
.filter-box h2,
.content .section-title {
  text-align: left;
  font-size: 1.1em;
  line-height: 1.167em;
  font-weight: normal;
  border-radius: 3px;
  padding: 0;
  margin: 0 0 20px;
}

#cmscnt h2 a,
.filter-box h2 a,
.content .section-title a {
  text-decoration: none;
}

.section-title a {
  color: #fff;
}

@media screen and (min-width: 1336px) {
  #main {
    margin: 0 0 5px;
  }
}

.clear-floats {
  overflow: hidden;
}

.more-info-icon {
  background: url(/dist/images/sprite11.png) no-repeat -228px -118px;
  display: inline-block;
  width: 13px;
  height: 13px;
  margin: 3px 3px 3px 5px;
  vertical-align: middle;
  text-indent: 13px;
  overflow: hidden;
}

.more-info-icon.float-right {
  float: right;
}

.tooltip-container {
  position: relative !important;
}

.tooltip-container .tooltip-source {
  display: inline-block;
}

.tooltip-container .tooltip-source:hover,
.tooltip-container .tooltip-source.active {
  cursor: pointer;
}

.tooltip-container .tooltip-source:hover~.tooltip-target,
.tooltip-container .tooltip-source:hover~.tooltip-target-desktop,
.tooltip-container .tooltip-source.active~.tooltip-target,
.tooltip-container .tooltip-source.active~.tooltip-target-desktop {
  display: block;
}

.tooltip-target,
.tooltip-target-desktop {
  display: none;
  position: absolute;
  width: 444px;
  left: -462px;
  top: 0;
  background-color: #ffffff;
  border: 2px solid #ccc;
  z-index: 100;
  font-size: 11px;
  box-sizing: content-box !important;
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
}

.tooltip-target ul,
.tooltip-target-desktop ul {
  padding-left: 40px;
}

.tooltip-target .arrow,
.tooltip-target-desktop .arrow,
.tooltip-target .arrow-left,
.tooltip-target-desktop .arrow-left,
.tooltip-target .arrow-right,
.tooltip-target-desktop .arrow-right {
  background-color: #ccc;
  position: absolute;
  height: 17px;
  width: 17px;
  top: 2px;
  transform: rotate(-45deg);
  z-index: -1;
}

.tooltip-target .arrow-left,
.tooltip-target-desktop .arrow-left {
  background-image: url(/dist/images/arrow_left.gif);
  left: -13px;
  top: -3px;
  width: 10px;
}

.tooltip-target .arrow-right,
.tooltip-target-desktop .arrow-right {
  background-image: url(/dist/images/arrow_right.gif);
  top: -2px;
  width: 11px;
  left: 444px;
}

.tooltip-target .content,
.tooltip-target-desktop .content {
  color: #333;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 11px 7px;
}

.tooltip-target .close,
.tooltip-target-desktop .close {
  background-color: #ccc;
  font-weight: bold;
  text-align: left;
  padding-left: 8px;
  padding-bottom: 5px;
  padding-top: 2px;
}

.tooltip-target.information .payments_th,
.information.tooltip-target-desktop .payments_th {
  width: 115px;
}

.tooltip-target p:last-of-type,
.tooltip-target-desktop p:last-of-type {
  margin-bottom: 0;
}

.pdp-details .tooltip-container.modal-override .tooltip-source:hover~.tooltip-target,
.pdp-details .tooltip-container.modal-override .tooltip-source:hover~.tooltip-target-desktop {
  display: none;
}

.pdp-details .tooltip-target,
.pdp-details .tooltip-target-desktop {
  background-color: #f4f4f4;
  width: 465px;
  padding: 10px;
  top: -22px;
  left: -505px;
  border-color: #eee;
  border-width: 1px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  line-height: 1em;
}

.pdp-details .tooltip-target .close,
.pdp-details .tooltip-target-desktop .close {
  background: inherit;
  padding-bottom: 0;
  font-size: 1.3rem;
}

.pdp-details .tooltip-target .content,
.pdp-details .tooltip-target-desktop .content {
  font-weight: normal;
  font-size: 1.2rem;
}

.pdp-details .tooltip-target .content table,
.pdp-details .tooltip-target-desktop .content table {
  margin-bottom: 2px;
}

.pdp-details .tooltip-target .content #g4m-stock-location,
.pdp-details .tooltip-target-desktop .content #g4m-stock-location {
  margin-bottom: 3px;
}

.pdp-details .tooltip-target.tooltip-arrow-right:before,
.pdp-details .tooltip-arrow-right.tooltip-target-desktop:before {
  content: "";
  background-color: inherit;
  border-color: #eee;
  box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.3);
  border-width: 1px;
  height: 20px;
  width: 20px;
  top: 21px;
  left: auto;
  right: -11px;
  position: absolute;
  transform: rotate(-45deg);
}

.tooltip-target-desktop {
  display: block;
}

.financing .information.tooltip-target,
.financing .information.tooltip-target-desktop {
  top: -300px;
}

.financing .information.tooltip-target .content .arrow-right,
.financing .information.tooltip-target-desktop .content .arrow-right {
  top: 294px;
}

.logo {
  width: 174px;
  height: 67px;
  background: url(/dist/images/sprite11.png) no-repeat -202px 0;
  margin: 3px 0 0 1px;
}

@media (-webkit-min-device-pixel-ratio: 1.5) and (max-width: 767px),
(min-resolution: 144dpi) and (max-width: 767px) {
  .logo {
    background-position: 0 0;
    background: url("/dist/images/sprite-m2x.png") no-repeat 0 0;
    background-size: 249px 70px;
    width: 165px;
    height: 64px;
    padding: 0;
    margin: 0;
  }
}

.main-heading {
  margin: 0 0 11px;
}

.main-heading .back {
  float: right;
  display: none;
  font-weight: bold;
  color: #434649;
  text-decoration: underline;
  position: relative;
  padding: 0 0 0 18px;
}

.main-heading .back:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 14px;
  height: 7px;
  margin-top: -4px;
}

.main-heading .back:hover {
  text-decoration: none;
}

.sidebar-box .phone .value {
  font-size: 0.9em;
}

#general-breadcrumb-wrapper {
  margin: 0 0 11px;
  width: 100%;
}

#general-breadcrumb-wrapper .back {
  float: right;
  font-weight: bold;
  color: #434649;
  text-decoration: underline;
  position: relative;
  padding: 0 0 0 18px;
}

#general-breadcrumb-wrapper .back:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 14px;
  height: 7px;
  margin-top: -4px;
}

#general-breadcrumb-wrapper .back:hover {
  text-decoration: none;
}

#inv-breadcrumb {
  font-size: 0.819em;
  overflow: hidden;
  line-height: 1.334em;
  color: #83888b;
  background: #f6f6f6;
  padding: 9px 10px;
  border: 1px solid #f6f6f6;
  border-radius: 5px;
}

#inv-breadcrumb ol {
  margin: 0;
  padding: 0;
}

#inv-breadcrumb ol li {
  list-style-type: none;
}

#inv-breadcrumb .breadcrumb-item {
  float: left;
  margin: 0 3px 0 0;
}

#inv-breadcrumb a {
  color: #83888b;
}

#inv-breadcrumb .breadcrumb-item:first-child a {
  font-weight: bold;
  color: #434649;
  text-decoration: underline;
}

#inv-breadcrumb .breadcrumb-item:first-child a:hover {
  text-decoration: none;
}

#general-breadcrumb {
  font-size: 0.819em;
  overflow: hidden;
  line-height: 1.334em;
  color: #83888b;
  background: #f6f6f6;
  padding: 9px 10px;
  border: 1px solid #f6f6f6;
  border-bottom-color: #ececec;
  border-radius: 5px;
}

#general-breadcrumb ol {
  margin: 0;
  padding: 0;
}

#general-breadcrumb ol li {
  list-style-type: none;
}

#general-breadcrumb .breadcrumb-item {
  float: left;
  margin: 0 3px 0 0;
}

#general-breadcrumb a {
  color: #83888b;
}

#general-breadcrumb .breadcrumb-item:first-child a {
  font-weight: bold;
  color: #434649;
  text-decoration: underline;
}

#general-breadcrumb .breadcrumb-item:first-child a:hover {
  text-decoration: none;
}

#general-breadcrumb-wrapper {
  margin: 0 0 11px;
}

.image-text-links {
  padding: 0;
  margin: 0 -10px;
  list-style: none;
  text-align: center;
}

.image-text-links li {
  display: inline-block;
  vertical-align: top;
  width: 141px;
  height: 68px;
  box-sizing: border-box;
  font-size: 1em;
  line-height: 1.2em;
  text-align: center;
  background: #e5e5e5;
  padding: 1px 1px 18px;
  border-radius: 10px 10px 7px 7px;
  position: relative;
  margin: 0 5px 9px;
  z-index: 1;
  overflow: hidden;
}

.image-text-links li.no-image {
  background: none;
  display: inline-block;
  font-size: 11px;
  font-weight: bold;
  height: 50px;
  margin: 10px;
  padding-left: 20px;
  text-align: center;
  width: 121px;
  padding: 0px;
}

.image-text-links li.no-image a {
  color: #000;
}

.image-text-links span {
  background: #fff url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmNmY2ZjYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: linear-gradient(to bottom, #fff 0%, #f6f6f6 100%);
  box-shadow: inset 0 0 0 3px #fff;
  height: 100%;
  display: block;
  border-radius: 7px;
}

.image-text-links span:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 1px;
  height: 100%;
  margin-left: -4px;
}

.image-text-links li:before {
  content: attr(title);
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 2px;
  font-weight: bold;
  text-align: center;
}

p.copy {
  margin: 0;
}

.filter-box .btn-holder:after,
#inv-sdesc .heading:after,
.main-heading:after,
.search-form:after {
  content: "";
  display: block;
  clear: both;
}

.content-page img,
.content-page iframe {
  max-width: 100%;
}

.company-box {
  float: left;
  width: 33%;
}

.company-box .company-box-title {
  font-size: 1.7em;
  margin-bottom: 7px;
}

.company-box .company-box-title .company-box-title-first {
  font-weight: bold;
}

.company-box .company-box-num {
  margin-bottom: 3px;
}

.company-box .company-box-inv {
  margin-bottom: 3px;
}

.company-box a {
  text-decoration: underline;
  margin-bottom: 3px;
}

.country-list {
  padding: 0;
  list-style: none;
  color: #434649;
  font-size: 0.834em;
  line-height: 1em;
}

.country-list li {
  display: inline-block;
  vertical-align: top;
  padding: 0 0 0 42px;
  position: relative;
  width: 66px;
  margin: 7px 3px 8px 13px;
  min-height: 20px;
}

.country-list li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -8px;
  width: 27px;
  height: 20px;
  background-repeat: no-repeat;
}

.webp .country-list li:before {
  background-image: url("../assets/img/lang-flags.webp");
}

.no-webp .country-list li:before,
.no-js .country-list li:before {
  // background-image: url("../assets/img/lang-flags.png");
}

.country-list a {
  color: #83888b;
  font-size: 0.8em;
  margin: 0 0 0 7px;
}

.country-list b+a {
  margin-left: 0;
}

.country-list b {
  display: block;
  white-space: nowrap;
}

.country-list .be:before {
  background-position: 0 0px;
}

.country-list .cz:before {
  background-position: 0px -22px;
}

.country-list .dk:before {
  background-position: 0px -44px;
}

.country-list .de:before {
  background-position: 0px -66px;
}

.country-list .gr:before {
  background-position: 0px -88px;
}

.country-list .es:before {
  background-position: 0px -110px;
}

.country-list .fr:before {
  background-position: 0px -132px;
}

.country-list .ie:before {
  background-position: -29px -0px;
}

.country-list .it:before {
  background-position: -29px -22px;
}

.country-list .nl:before {
  background-position: -29px -44px;
}

.country-list .no:before {
  background-position: -29px -66px;
}

.country-list .at:before {
  background-position: -29px -88px;
}

.country-list .pl:before {
  background-position: -29px -110px;
}

.country-list .pt:before {
  background-position: -29px -132px;
}

.country-list .ru:before {
  background-position: -58px -0px;
}

.country-list .ch:before {
  background-position: -58px -22px;
}

.country-list .si:before {
  background-position: -58px -44px;
}

.country-list .sk:before {
  background-position: -58px -66px;
}

.country-list .fi:before {
  background-position: -58px -88px;
}

.country-list .se:before {
  background-position: -58px -110px;
}

.country-list .us:before {
  background-position: 0px -154px;
}

.country-list .en:before,
.country-list .com:before {
  background-position: -58px -132px;
}

.social-networks {
  padding: 11px 0 0;
  margin: 0;
  list-style: none;
  text-align: justify;
  border-top: 1px solid #ececec;
  box-shadow: inset 0 1px 0 0 #fff;
  line-height: 0;
  letter-spacing: -3px;
}

.social-networks:after {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 0;
  font-size: 0;
  line-height: 0;
  content: "";
}

.social-networks li {
  display: inline-block;
  vertical-align: middle;
  letter-spacing: 0;
  line-height: 1.25em;
}

.social-networks a {
  display: block;
  height: 28px;
  width: 28px;
  overflow: hidden;
  text-indent: -9999px;
  direction: ltr;
  background: url(/dist/images/social-icons-sprite.png) no-repeat;
  background-size: 112px 28px;
  border-radius: 2px;
  box-shadow: 0 2px 0 0 #d9d9d9;
}

.social-networks .facebook {
  background-position: 0 0;
}

.social-networks .twitter {
  background-position: -28px 0;
}

.social-networks .youtube {
  background-position: -56px 0;
}

.social-networks .instagram {
  background-position: -84px 0;
}

.sidebar.right {
  position: absolute;
  left: -9999px;
  top: -9999px;
}

.sitemap .department {
  padding-left: 0;
  list-style-type: none;
}

.sitemap .section-title {
  margin-top: 25px;
}

.sitemap .section {
  padding-left: 0;
  display: table;
}

.sitemap .subsection {
  display: inline-table;
  width: 145px;
  padding-left: 0;
  padding-right: 5px;
  margin-bottom: 20px;
  list-style-position: outside;
  list-style-type: circle;
}

.sitemap .subsection li {
  margin-left: 15px;
}

.sitemap .subsection .subsection-heading {
  list-style-type: none;
  margin-left: 0;
}

.sitemap .subsection .subsection-heading a {
  font-size: 14px;
  font-weight: bold;
  color: #494643;
  list-style-type: none;
}

#inv-breadcrumb {
  font-size: 1em;
  overflow: hidden;
  line-height: 1.25em;
  background: none;
  padding: 0;
  border: none;
  box-shadow: none;
}

#general-breadcrumb {
  overflow: hidden;
  font-size: 1em;
  line-height: 1.25em;
  background: none;
  padding: 0;
  border: none;
  box-shadow: none;
}

.image-text-links {
  padding-top: 6px;
}

.image-text-links li {
  margin: 0 10px 20px;
}

.slide-holder {
  border-color: #e3e3e3;
  padding: 6px 0 8px;
  margin: 0 0 17px;
}

.slide-holder .slide {
  position: static !important;
}

.sidebar {
  float: left;
  width: 160px;
  margin: 0 0 0 -100%;
}

.sidebar.right {
  float: right;
  margin: 0 0 0 -160px;
}

section.sidebar-box {
  color: #83888b;
  font-size: 1em;
  line-height: 1.25em;
  margin: 0 0 12px;
  padding: 7px 10px 10px;
  background: #f6f6f6;
  border-radius: 3px;
}

section.sidebar-box ul.payment-list li.gcs {
  background-color: #f5f5f5;
  margin: 0;
}

section.sidebar-box.mini-security {
  padding: 7px 10px 0;
}

section.sidebar-box.payment-methods ul {
  margin: 0;
  padding: 0;
  text-align: center;
}

section.sidebar-box.payment-methods li {
  display: inline-block;
  list-style-type: none;
  padding: 5px 0 5px 5px;
  line-height: 30px;
  width: 49.9%;
  max-width: 68px;
  box-sizing: border-box;
}

section.sidebar-box.payment-methods li.full-width {
  width: 100%;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

section.sidebar-box.payment-methods img {
  max-width: 100%;
  vertical-align: middle;
}

.sidebar-box a {
  color: #222;
}

.sidebar-box>h2 {
  font-size: 1.3rem;
}

.sidebar-box>h2 {
  margin: 0 -10px 8px;
  text-align: center;
  line-height: 1.24em;
  padding: 9px 5px 10px;
  color: #fff;
  background: #63656a;
  border-radius: 3px 3px 0 0;
}

.sidebar-box>h2.red {
  border-bottom-color: #414446;
  background: #e94a2c url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2U5NGEyYyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM5ZjMzMWYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: linear-gradient(to bottom, #e94a2c 0%, #9f331f 100%);
}

.sidebar-box>h2:first-child {
  margin-top: -7px;
}

.sidebar-box .list,
.payment-list,
.brands {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-box .list li,
.payment-list li,
.brands li {
  border-top: 1px solid #e3e3e3;
}

.sidebar-box .list li:first-child,
.payment-list li:first-child,
.brands li:first-child {
  border-top: none;
}

.sidebar-box .list {
  line-height: 1.167em;
  margin: 0 0 10px;
}

.sidebar-box .list li {
  padding: 8px 0;
  word-break: break-word;
}

.sidebar-box .list a {
  display: inline-block;
  vertical-align: top;
  padding-left: 44px;
  min-height: 28px;
  color: #83888b;
  position: relative;
}

.sidebar-box .list a:hover {
  text-decoration: none;
}

.sidebar-box .list a:before {
  content: "";
  width: 32px;
  height: 32px;
  left: 0;
  top: 50%;
  margin-top: -16px;
  background: url(/dist/images/sprite11.png) no-repeat;
  position: absolute;
}

.sidebar-box .list .delivery-countries:before {
  background-position: -260px -104px;
}

.sidebar-box .list .delivery:before {
  background-position: -144px -79px;
}

.sidebar-box .list .returns:before {
  background-position: -177px -79px;
}

.sidebar-box .list .money-back:before {
  background-position: -210px -68px;
}

.sidebar-box .list .warranty:before {
  background-position: -243px -68px;
}

.sidebar-box .video,
.sidebar-box .fnc-bx-vid {
  display: block;
  background: #f6f6f6;
  text-align: center;
  position: relative;
  font-weight: bold;
  color: #83888b;
  padding: 0 0 8px;
  margin: 0 0 8px;
}

.sidebar-box .video img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 0 8px;
}

.sidebar-box .video:after {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: -9999px;
  z-index: 19;
  background: url(/dist/images/bg-white.png);
  position: absolute;
}

.sidebar-box .video:hover {
  text-decoration: none;
}

.sidebar-box .video:hover:after {
  left: 0;
  content: " ";
}

.sidebar-box address {
  font-style: normal;
  margin: 0;
  padding: 7px 0 0;
}

.sidebar-box address .phone-fine-print {
  font-size: 0.7em;
  line-height: 9px;
  display: block;
}

.sidebar-box address .phone {
  position: relative;
  display: block;
  font-size: 1.417em;
  line-height: 2em;
  color: #333;
  padding: 0 0 2px 20px;
  min-height: 34px;
}

.sidebar-box address .phone:before {
  content: "";
  position: absolute;
  left: -1px;
  top: 50%;
  width: 16px;
  height: 36px;
  background: url(/dist/images/sprite11.png) no-repeat -276px -68px;
  margin-top: -18px;
}

.sidebar-box address .phone:before {
  width: 20px;
  height: 29px;
  background: url(/dist/images/svgs/icon-phone-vertical.svg);
  margin-top: 0;
  top: 8%;
  margin-left: 5px;
}

.sidebar-box address .tel:before {
  left: -4px;
}

.sidebar-box address .tel b {
  font-size: 0.75em;
}

.sidebar-box address .service b {
  font-size: 0.75em;
}

.sidebar-box address .service:before {
  left: -7px;
  width: 24px;
  height: 29px;
  background: url(/dist/images/service-icon-blk.png) no-repeat;
}

.sidebar-box address .row b,
.sidebar-box address .phone b {
  display: block;
}

.sidebar-box address .row {
  display: block;
  border-top: 1px solid #e3e3e3;
  padding: 13px 4px 0;
  margin-top: 10px;
}

.sidebar-box address .row.top-row {
  border-top: none;
  margin-top: 0;
  padding-top: 0;
  box-shadow: none;
}

.sidebar-box .email {
  font-weight: normal;
}

.sidebar-box address .row.inv {
  text-align: center;
  font-weight: bold;
}

.sidebar-box address .row.inv a.investor-link,
.sidebar-box address .row.inv a.locations-link {
  color: #83888b;
  text-decoration: none;
}

.sidebar-box address a {
  color: #63656a;
  text-decoration: underline;
}

.sidebar-box address a:hover {
  text-decoration: none;
}

.brands li,
.payment-list li {
  text-align: center;
  padding: 8px 0;
}

.brands li.gcs,
.payment-list li.gcs {
  overflow: hidden;
  padding-bottom: 2px;
}

.brands li {
  padding: 2px 0;
}

.brands li:first-child,
.payment-list li:first-child {
  padding-top: 0;
}

.brands li:before,
.payment-list li:before {
  display: inline-block;
  vertical-align: middle;
  content: "";
  height: 46px;
  width: 1px;
  margin: 0 -4px 0 0;
}

.payment-list li.gcs:before {
  display: none;
}

.gcs {
  margin-left: -1.5px;
}

.gcs #gts-c {
  z-index: 50 !important;
}

.brands img,
.payment-list img {
  vertical-align: middle;
}

.payment-list a,
.payment-list .payment-logo {
  display: inline-block;
  vertical-align: middle;
  text-indent: -9999px;
  overflow: hidden;
  background: url(/dist/images/sprite11.png) no-repeat 0 -150px;
}

.payment-list .thawte {
  width: 112px;
  height: 28px;
  background-position: 0 -150px;
}

.payment-list .mastercard {
  height: 37px;
  background-size: contain;
  background-position: center 0;
  width: 83px;
  background-image: url(/dist/images/payment/MastercardSecurecode.png);
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .payment-list .mastercard {
    background-image: url(/dist/images/payment/MastercardSecurecode-2x.png);
  }
}

.payment-list .visa {
  height: 37px;
  background-size: contain;
  background-position: center 0;
  width: 68px;
  background-image: url(/dist/images/payment/VerifiedByVisa.png);
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .payment-list .visa {
    background-image: url(/dist/images/payment/VerifiedByVisa-2x.png);
  }
}

.payment-list .amex {
  width: 104px;
  height: 36px;
  background-position: -354px -148px;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .payment-list .amex {
    height: 36px;
    background-size: contain;
    background-position: center 0;
    background-image: url(/dist/images/payment/AmexSafekey-2x.png);
  }
}

.payment-list .int-shoping {
  width: 96px;
  height: 43px;
  background-position: -293px -150px;
}

.payment-list .int-delivery {
  width: 93px;
  height: 43px;
  background-position: 0 -183px;
}

.payment-list .trustedshops {
  width: 60px;
  height: 60px;
  margin: 2px 0 0;
  background-position: -293px -150px;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .payment-list .trustedshops {
    height: 60px;
    background-size: contain;
    background-position: center 0;
    background-image: url(/dist/images/payment/Trustedshops-2x.png);
  }
}

.brands {
  margin: 0 0 20px;
  border-bottom: 1px solid #e3e3e3;
}

.sidebar-box .brands img {
  left: 50%;
  max-height: calc(100% - 2px);
  position: absolute;
  top: 50%;
  width: 85%;
  transform: translateY(-50%) translateX(-50%);
}

.sidebar-box .big-deals,
.sidebar-box .special-deals {
  color: #83888b;
  position: relative;
  top: -7px;
  left: -10px;
  display: block;
  overflow: hidden;
  width: calc(100% + 20px);
  height: calc(100% + 17px);
  margin: 0 0 -17px 0;
}

.sidebar-box .special-deals .text {
  position: absolute;
  left: 15px;
  top: 17px;
  color: #83888b;
  font-size: 1.167em;
  line-height: 1.143em;
}

.sidebar-box .special-deals:hover {
  text-decoration: none;
}

.sidebar-box .special-deals .text strong {
  text-transform: uppercase;
  display: block;
  margin: 0 0 2px;
  font-size: 1.715em;
  line-height: 0.917em;
}

.sidebar-box .share-this {
  padding: 5px 3px 0;
}

.sidebar-box .share-this>div {
  display: block;
  position: relative;
  overflow: hidden;
  height: 20px;
  margin: 0 0 10px;
}

.aside-block,
.aside {
  overflow: hidden;
  font-size: 1em;
  line-height: 1.417em;
  color: #44474a;
}

.aside-block p,
.aside p {
  margin: 0 0 1.417em;
}

.aside-block h2,
.aside h2 {
  font-size: 3rem;
  line-height: 1em;
  margin: 0 0 10px;
}

.aside .block1 h2 {
  margin: 0 0 16px;
}

.aside-block,
.aside .block {
  float: left;
  background: #fff;
  box-sizing: border-box;
  border: 1px solid #f6f6f6;
  border-radius: 5px;
  padding: 16px 18px;
  width: 41.2%;
  margin: 0 0 0 2.7%;
}

.aside .block:first-child {
  margin-left: 0;
}

.aside .block1 {
  width: 56.1%;
}

.aside .block1.full {
  width: 100%;
}

.aside .post {
  border-top: 1px solid #dedede;
  font-size: 1em;
  line-height: 1.25em;
  padding: 11px 0 8px;
}

.aside .post h3 {
  font-size: 1.167em;
  line-height: 1.143em;
  margin: 0 0 11px;
}

.aside .more-news-list h3 a,
.aside .post h3 a {
  color: #44474a;
}

.aside .post p {
  margin: 0 0 2px;
}

.aside .post img {
  display: block;
  max-width: 100%;
  width: auto;
  height: auto;
  margin: 0 0 6px;
}

.aside .more-news-list {
  padding: 0;
  margin: 0;
  list-style: none;
  line-height: 1.167em;
}

.aside .more-news-list li {
  padding: 7px 3px 10px;
  border-top: 1px solid #dedede;
}

.aside .more-news-list li:first-of-type {
  border-top: none;
}

.aside .more-news-list h3 {
  line-height: 1.25em;
  margin: 0 0 3px;
}

.aside .more-news-list p {
  font-style: italic;
  margin: 0 0 4px;
}

.aside .more-news-list .time {
  display: block;
}

.aside.footer-about-g4m p,
.aside .more-news-list h3 {
  font-size: 1.8rem;
  line-height: normal;
}

.aside-block {
  float: none;
  width: auto;
  margin: 0 auto 18px;
  padding: 16px 15px 12px;
}

.aside-block p {
  margin: 0 0 7px;
}

.aside-block h2 {
  border-bottom: 1px solid #dedede;
  padding: 0 0 10px;
  margin: 0 0 5px;
}

.country-list {
  padding: 0;
  margin: 0 -20px 0 0;
  list-style: none;
  color: #434649;
  font-size: 0.834em;
  line-height: 1em;
}

.country-list li {
  display: inline-block;
  vertical-align: top;
  padding: 0 0 0 42px;
  position: relative;
  width: 66px;
  margin: 7px 3px 8px 13px;
  min-height: 20px;
}

.country-list li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -8px;
  width: 27px;
  height: 20px;
  background-repeat: no-repeat;
}

.webp .country-list li:before {
  background-image: url("../assets/img/lang-flags.webp?v10");
}

.no-webp .country-list li:before,
.jon-js .country-list li:before {
  background-image: url(/dist/images/lang-flags.png?v10);
}

.country-list a {
  color: #83888b;
  font-size: 0.8em;
  margin: 0 0 0 7px;
}

.country-list b+a {
  margin-left: 0;
}

.country-list b {
  display: block;
  white-space: nowrap;
}

.country-list .be:before {
  background-position: 0 0px;
}

.country-list .cz:before {
  background-position: 0px -22px;
}

.country-list .dk:before {
  background-position: 0px -44px;
}

.country-list .de:before {
  background-position: 0px -66px;
}

.country-list .gr:before {
  background-position: 0px -88px;
}

.country-list .es:before {
  background-position: 0px -110px;
}

.country-list .fr:before {
  background-position: 0px -132px;
}

.country-list .ie:before {
  background-position: -29px -0px;
}

.country-list .it:before {
  background-position: -29px -22px;
}

.country-list .nl:before {
  background-position: -29px -44px;
}

.country-list .no:before {
  background-position: -29px -66px;
}

.country-list .at:before {
  background-position: -29px -88px;
}

.country-list .pl:before {
  background-position: -29px -110px;
}

.country-list .pt:before {
  background-position: -29px -132px;
}

.country-list .ru:before {
  background-position: -58px -0px;
}

.country-list .ch:before {
  background-position: -58px -22px;
}

.country-list .si:before {
  background-position: -58px -44px;
}

.country-list .sk:before {
  background-position: -58px -66px;
}

.country-list .fi:before {
  background-position: -58px -88px;
}

.country-list .se:before {
  background-position: -58px -110px;
}

.country-list .en:before,
.country-list .com:before {
  background-position: -58px -132px;
}

.footer-holder p {
  margin: 0;
}

.footer-holder a {
  color: #83888b;
}

p.copy {
  display: none;
}

.footer-block {
  background: #fff;
  margin: 0 0 18px;
  padding: 20px 20px 16px;
  border-radius: 5px;
  display: block;
}

.footer-block:after {
  content: "";
  display: block;
  clear: left;
}

.footer-block .footer-logo {
  float: left;
  margin: 4px 20px 0 -2px;
}

.footer-block .box {
  float: right;
  text-align: right;
  font-size: 0.917em;
  line-height: 1.1em;
  margin: 0 0 0 20px;
}

.footer-block .box h3 {
  font-size: 1.273em;
  line-height: 1em;
  margin: 0 0 1px;
}

.footer-block .box p {
  margin: 0;
  line-height: 18px;
}

.footer-block .box>div {
  margin: 19px 0 0;
}

.footer-block .box>div:first-child {
  margin-top: 0;
}

.footer-block .box a {
  text-decoration: underline;
  color: #434649;
}

.footer-block .box a:hover {
  text-decoration: none;
}

.footer-block .text-box {
  overflow: hidden;
  text-align: center;
  font-size: 1.167em;
  line-height: 1.429em;
  padding: 16px 0 0;
}

.footer-block .text-box p {
  margin: 0 0 2px;
}

.footer-block .text-box p strong {
  font-size: 1.286em;
  line-height: 1.112em;
}

.accessories-list:after,
.accessories-form .btn-holder:after,
.media:after,
.product-block:after,
#header:after {
  content: "";
  display: block;
  clear: both;
}

@media screen and (min-width: 956px) {

  .sidebar.right,
  .hide-tablet {
    display: block;
    position: static;
  }

  .sidebar-box {
    margin: 0 0 18px;
  }

  .aside .post {
    padding: 7px 0 8px;
  }

  .aside .block1,
  .aside .block {
    width: 32%;
    margin: 0 0 0 2%;
    padding-bottom: 19px;
  }

  .aside .block1.full,
  .aside .block.full {
    width: 100%;
  }

  .aside-block {
    width: 100%;
  }

  .country-list {
    margin-left: 32px;
  }

  .footer-block .box>div {
    margin-top: 17px;
  }

  .footer-block .box {
    font-size: 1em;
    line-height: 1.167em;
  }

  .footer-block .text-box {
    font-size: 1.334em;
    line-height: 1.375em;
    padding: 0 0 0 3px;
  }

  .footer-block .text-box p {
    margin: 0 0 2px;
  }

  .footer-block .text-box p strong {
    font-size: 1.25em;
  }

  .footer-holder p {
    overflow: hidden;
  }

  p.copy {
    display: block;
    float: right;
    margin: 0 0 0 5px;
  }
}

.content-page .page-information-showroom img {
  float: none;
  display: block;
  margin: 0 auto;
  height: 340px;
  width: 565px;
}

.content-page .page-information-showroom iframe {
  width: 100%;
}

.content-page .center-img {
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.content-page .center-img img {
  float: none;
}

.content-page.header-copy-PLP {
  width: 100%;
}

.result-list {
  padding: 0;
  margin: 0;
  list-style: none;
  border-bottom: 1px solid #f6f6f6;
  border-bottom-color: #e3e3e3;
}

.result-list li {
  position: relative;
}

.result-list .restricted-inv-notice-row .list-row-container {
  display: block;
  border-top: 1px solid #f6f6f6;
  padding: 10px 25px 10px 10px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  border-top-color: #e3e3e3;
  padding: 15px 180px 15px 14px;
}

.result-list .restricted-inv-notice-row .list-row-container:hover {
  background-color: #f6f6f6;
}

.result-list .restricted-inv-notice-row .list-row-container:after,
.result-list .restricted-inv-notice-row .list-row-container:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  border: solid transparent;
  border-width: 5px 0 5px 6px;
  border-left-color: #d9dadb;
  margin-top: -5px;
  content: " ";
  display: none;
}

.result-list .restricted-inv-notice-row .list-row-container:after {
  border-left-color: #fff;
  margin-right: 2px;
}

.result-list a {
  color: #434649;
  text-decoration: none;
}

.result-list a.button {
  color: #fff;
  height: auto;
  line-height: 20px;
  padding: 5px 10px;
  min-width: 140px;
}

.result-list a .price {
  display: none;
}

.result-list .inv-del-est i {
  display: none;
}

.result-list .description {
  padding-top: 5px;
  margin-left: 107px;
  margin-right: 150px;
  overflow: hidden;
}

@supports (display: flex) {
  .result-list .description {
    padding-top: 0;
    display: flex;
    justify-content: center;
    min-height: 92px;
    flex-direction: column;
  }
}

@media screen and (min-width: 1336px) {
  .result-list .description {
    margin-right: 170px;
  }
}

.result-list .description .description-text-wrap p:last-of-type {
  margin: 0;
}

.result-list .description .price {
  display: none;
}

.result-list .description p.restricted-inv-notice {
  font-size: 0.92em;
}

.result-list .plt-rw-img {
  float: left;
  border: 1px solid #ededed;
  margin: 0 10px 0 0;
}

.result-list .plt-rw-img img {
  max-width: 100%;
  width: 87px;
  height: 89px;
}

.result-list h3 {
  font-size: 1em;
  line-height: 1.182em;
  margin: 0 0 7px;
  font-size: 1.167em;
  line-height: 1.215em;
  margin: 0 0 5px;
}

@media screen and (min-width: 1336px) {
  .result-list h3 {
    font-size: 1.5em;
    line-height: 1.2em;
    margin: 0 0 9px;
  }
}

.result-list p {
  margin: 0 0 9px;
}

.result-list .button {
  display: none;
  display: block;
  font-size: 14px;
  height: 32px;
}

.result-list .price {
  display: block;
  position: relative;
  color: #d00000;
  font-weight: bold;
  font-size: 1.364em;
  line-height: 1.2em;
  padding: 0.8em 0 0;
  overflow: hidden;
  font-size: 2.167em;
  line-height: 1em;
  padding: 0.65em 0 5px;
}

.result-list .price.no-rrp {
  padding: 0 0 5px;
}

.result-list .price .rrp {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.6em;
  line-height: 1.1em;
  text-transform: uppercase;
  text-decoration: line-through;
  color: #434649;
  font-weight: normal;
  font-size: 0.462em;
  line-height: 1.1em;
}

.result-list .date {
  color: #3e76a2;
  font-weight: bold;
}

.result-list .box {
  display: none;
  position: absolute;
  left: 100%;
  margin-left: -160px;
  top: 0px;
  bottom: 0px;
  z-index: 5;
  max-width: 150px;
  padding: 4px;
  display: block;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

@media screen and (min-width: 1336px) {
  .result-list .box {
    margin-left: -184px;
    max-width: 175px;
  }
}

.result-list .box.incrrp {
  padding: 15px 0;
}

.result-list .in-stock {
  color: #249c54;
  font-weight: bold;
}

.result-list .third-party,
.result-list .in-stock,
.result-list .not-available {
  margin: 0;
}

.result-list .third-party,
.result-list .not-available {
  color: #3e76a2;
  font-weight: bold;
}

.result-list .in-stock:after,
.result-list .third-party:after {
  margin: -5px 0 0 8px;
  content: " ";
}

.search-result-page-result-list .search-result-page-box {
  margin-left: -160px;
  max-width: 155px;
}

.search-result-page-result-list .restricted-inv-notice-row .list-row-container {
  padding: 15px 25px 15px 15px;
}

.content .inventory-detail-alt-lang-link {
  color: #8c8e90;
}

.content .inventory-detail-alt-lang-link p {
  margin-bottom: 12px;
}

.content .inventory-detail-alt-lang-link p>a {
  color: inherit;
  text-decoration: underline;
}

.returns-warranty-price-display {
  font-weight: bold;
}

.information-warranty-popup-table {
  table-layout: fixed;
  width: 100%;
  color: #000;
}

.information-warranty-popup-table th {
  background-color: #5f5f5f;
  color: #fff;
  font-weight: bold;
}

.information-warranty-popup-table td {
  padding: 2px;
  border: 1px solid #fff;
}

.information-warranty-popup-table tr {
  background-color: #fafafa;
}

.icon-loading-warranty-popup {
  display: inline-block;
  min-width: 20px;
  height: 16px;
  background: url("/dist/images/icon/loader.gif") transparent center center no-repeat;
  font-size: 3rem;
}

.product-block {
  position: relative;
  margin: 0 0 14px;
  border-bottom: 1px solid #f5f6f6;
  padding: 10px 10px 3px;
}

.tooltip-source .free {
  color: #249c54;
  font-weight: bold;
}

.product-layout-split .split-left,
.product-layout-split .split-right {
  position: relative;
  width: 50%;
  box-sizing: border-box;
}

.product-layout-split .split-left {
  float: left;
}

.product-layout-split .split-right {
  float: right;
}

.product-layout-split .split-left {
  width: 54.1%;
}

.product-layout-split .split-right {
  width: 45.85%;
  padding: 0 12px 0 32px;
}

.product-layout-split .split-right.product-details {
  padding: 22px 0 10px 32px;
}

.product-layout-split .split-right.product-details .returns-information {
  margin-bottom: 10px;
}

.g4m-stock-hover {
  border-collapse: collapse;
}

.tooltip-container p#g4m-stock-location {
  margin-left: 0;
  margin-bottom: 0;
}

.g4m-stock-hover-hub {
  text-align: left;
  font-weight: normal;
}

.g4m-stock-hover-count,
.g4m-stock-hover-count-showroom {
  text-align: left;
  padding-left: 15px;
  font-weight: normal;
}

.content .section-title {
  clear: both;
}

.products-list {
  float: left;
  clear: both;
}

.products-list .restricted-inv-notices {
  padding: 4px 0 0;
}

.products-list .restricted-inv-notices>p {
  color: #888888;
  font-size: 0.917em;
  line-height: 1.182em;
  margin: 2px 0 5px 0;
}

.product-block p {
  margin: 0 0 10px;
}

.product-block .inv-prc-gbp {
  position: relative;
  font-size: 2.728em;
  line-height: 1em;
  color: #fa8600;
  font-weight: bold;
  padding: 0.5em 0 10px;
  margin: 0;
}

.product-block .inv-prc-gbp.inv-prc-visual {
  color: #97bc3c;
}

.product-block .inv-prc-gbp .inv-rrp {
  font-weight: normal;
  position: absolute;
  left: 0;
  top: 0;
  color: #d9dadb;
  font-size: 0.4em;
  line-height: 1.167em;
}

.product-block .instock {
  font-weight: bold;
  font-size: 1.1em;
  line-height: 1.25em;
}

.product-block .nostock {
  font-weight: bold;
  font-size: 1.1em;
  line-height: 1.25em;
  float: left;
}

.product-block .instock:after {
  display: inline-block;
  vertical-align: middle;
  content: "";
  width: 52px;
  height: 22px;
  background-position: -196px 0;
  background-repeat: no-repeat;
}

.product-block .button {
  text-transform: uppercase;
  font-size: 16px;
  min-width: 140px;
  margin: 0 0 10px;
  padding-top: 11px;
}

.product-block .social {
  margin: 7px 0;
}

#warranty_info {
  width: 500px;
  height: 400px;
  overflow-y: auto;
}

.warranty-block em {
  display: inline-block;
}

.moneyBackGuaranteeDaysDefault {
  margin-bottom: 21px;
}

.moneyBackGuaranteeDaysDefault.no-margin {
  margin-bottom: 0;
}

.moneyBackGuaranteeDaysExtended.has-element-after,
.moneyBackGuaranteeDaysDefault.has-element-after {
  margin-bottom: 10px;
}

#inv-sdesc {
  padding: 0 10px;
}

.h2-title {
  margin: 0 0 12px;
}

.h2-title span,
#inv-sdesc h2 {
  font-size: 1.1em;
  line-height: 1.167em;
  margin: 0 0 12px;
  font-weight: bold;
}

.h2-title h2,
.h2-title span {
  margin: 0;
  display: inline;
}

.prod-info {
  padding: 0 0 4px;
  border-bottom: 1px solid #eceded;
}

.prod-info dl {
  margin: 0 0 12px;
  overflow: hidden;
}

.prod-info dl dt {
  float: left;
  font-weight: bold;
}

.prod-info h3 {
  font-size: 1.1em;
  line-height: 1.2em;
  margin: 0 0 10px;
}

.prod-info h4 {
  font-size: 1em;
  line-height: 1.1em;
  margin: 0 0 3px;
}

.prd-ref p {
  margin: 20px 0 12px;
  font-size: 1.167em;
  font-weight: bold;
}

.product-bullets .prd-logo {
  display: inline;
}

.product-bullets .list {
  padding: 8px 10px 0;
  margin: 7px -10px 0;
  list-style: none;
  color: #4983ad;
  border-top: 1px solid #f5f6f6;
  font-weight: bold;
}

.product-bullets .list li {
  padding: 0 0 4px 14px;
  position: relative;
}

.product-bullets .list li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.45em;
  width: 4px;
  height: 4px;
  background: #4983ad;
  border-radius: 5px;
}

.slide-holder img {
  float: right;
  margin: 0px 0px 10px 10px;
}

.slide-holder .opener,
.prod-info .opener {
  display: block;
  color: #434649;
  font-size: 1.1em;
  line-height: 1.334em;
  padding: 8px 38px 8px 11px;
  font-weight: bold;
  position: relative;
  background: #f6f6f6;
  border: 1px solid #f6f6f6;
  border-radius: 5px;
  margin: 0 0 10px;
  clear: both;
}

.slide-holder .opener:after,
.slide-holder .opener:before,
.prod-info .opener:after,
.prod-info .opener:before {
  content: "";
  position: absolute;
  right: 15px;
  top: 50%;
  border: solid transparent;
  border-width: 6px 5px 0;
  margin-top: -2px;
  border-top-color: #dedede;
  border-bottom-color: #dedede;
}

.slide-holder .opener:after,
.prod-info .opener:after {
  border-top-color: #f6f6f6;
  border-bottom-color: #f6f6f6;
  margin-top: -4px;
}

.slide-holder.active .opener:after,
.slide-holder.active .opener:before,
.prod-info.active .opener:after,
.prod-info.active .opener:before {
  border-width: 0 5px 6px;
  margin-top: -4px;
  content: " ";
}

.slide-holder.active .opener:after,
.prod-info.active .opener:after {
  margin-top: -2px;
}

.prod-info .slide {
  padding: 0 11px;
}

.slide-holder {
  border-bottom: 1px solid #f6f6f6;
  margin: 0 0 10px;
  line-height: 1.167em;
}

.slide-holder .opener {
  margin: 10px -11px;
}

.products-list {
  padding: 0;
  margin: 0 -2.3% 0 0;
  list-style: none;
  letter-spacing: -4px;
  font-size: 1em;
  line-height: 1.167em;
  min-width: 100%;
}

span.sale {
  display: none;
}

.products-list .prd-cntr {
  float: left;
  width: 47.7%;
  margin: 0 2.3% 2.3% 0;
  letter-spacing: 0;
  text-align: center;
}

.products-list a {
  display: block;
  border: 1px solid #ededed;
  position: relative;
  color: #333;
  padding: 74% 5px 8px;
  border-radius: 3px;
  height: 6.9em;
}

.products-list .prd-img-ctnr {
  display: block;
  position: absolute;
  top: 0;
  left: 15%;
  right: 15%;
  height: 66%;
  overflow: hidden;
}

.products-list .prd-img-ctnr:before,
.accessories-list .prd-img:before {
  display: inline-block;
  vertical-align: middle;
  content: "";
  width: 1px;
  height: 100%;
  margin: 0 -4px 0 0;
}

.products-list .prd-img-ctnr img {
  width: 90%;
  height: auto;
  vertical-align: middle;
}

.accessories-list .prd-img a {
  display: inline-block;
}

.accessories-list .prd-img img {
  vertical-align: middle;
  width: auto;
  height: auto;
  max-width: 100%;
  pointer-events: none;
}

.products-list .prd-cntr .prd-name {
  font-size: 1.2rem;
  line-height: 1.2em;
  min-height: 3.5em;
  max-height: 3.5em;
  padding: 8px 0 0 0;
  margin: 0;
}

.products-list .holder {
  position: absolute;
  left: 0;
  bottom: 6px;
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  font-size: 12px;
  line-height: 12px;
}

.products-list .prd-prc {
  display: inline-block;
  vertical-align: top;
  color: #d00000;
  font-weight: bold;
  margin-left: 2px;
}

.products-list .holder>span {
  display: block;
  position: absolute;
  bottom: 0;
}

.products-list .holder .prd-prc {
  left: 6px;
  margin-bottom: -1px;
  text-align: left;
  font-size: 1.9rem;
  line-height: 16px;
  font-weight: bold;
}

@media screen and (min-width: 1100px) {
  .products-list .holder .prd-prc {
    font-size: 2.1rem;
    line-height: 18px;
  }
}

.products-list .holder .prd-inf .prd-inf-long {
  color: #249c54;
  font-weight: bold;
}

.products-list .holder .prd-inf.third-party .prd-inf-long,
.products-list .holder .prd-inf.not-available .prd-inf-long {
  color: inherit;
  font-weight: normal;
}

.products-list .holder .prd-inf.not-available-for-order {
  left: 0;
  margin-top: 10px;
  padding: 0 6px;
  position: relative;
  text-align: center;
}

.products-list .holder .prd-inf.not-available-for-order>span {
  color: #888888;
  font-size: 0.8em;
}

@media screen and (max-width: 1099px) {
  .products-list .holder .prd-inf {
    right: 8px;
  }

  .products-list .holder .prd-inf .prd-inf-long {
    display: none;
  }
}

@media screen and (min-width: 1100px) {
  .products-list .holder .prd-inf {
    right: 28px;
  }

  .products-list .holder .prd-inf .prd-inf-short {
    display: none;
  }
}

.products-list .prd-det {
  display: none;
  margin: 0 0 8px;
}

.products-list .prd-det p {
  font-size: 1.2rem;
}

.products-list .prd-inf {
  color: #7d8185;
  line-height: 1;
  text-align: right;
}

.products-list .prd-inf.in-stock {
  color: #249c54;
  font-weight: bold;
}

.products-list .prd-cntr .prd-name {
  font-size: 12px;
}

.products-list .holder {
  bottom: 10px;
}

.products-list .holder .prd-prc {
  right: 11px;
}

.products-list .holder .prd-inf {
  left: 11px;
}

.products-list .prd-inf>span {
  font-size: 12px;
  line-height: 12px;
}

.products-list .prd-inf.not-available:after {
  display: none;
}

@media screen and (min-width: 1100px) {
  .products-list .prd-inf>span {
    font-size: 12px;
  }

  .products-list .prd-inf:after {
    content: "";
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-left: 5px;
    vertical-align: middle;
    background: url(/dist/images/sprite11.png) no-repeat -126px -79px;
    position: absolute;
    bottom: 0;
    right: -22px;
  }
}

.products-list a:hover {
  text-decoration: none;
}

.results-block .toolbar h2,
.carousel-holder .opener {
  font-size: 1.1em;
  line-height: 1.334em;
  font-weight: normal;
  text-shadow: -1px -1px 0 rgba(62, 66, 70, 0.3);
  padding: 9px 10px 7px;
  color: #fff;
  background: #6aabd0 url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzZhYWJkMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMzZjc3YTIiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: linear-gradient(to bottom, #6aabd0 0%, #3f77a2 100%);
  margin: 0 0 10px;
  border-radius: 3px;
  position: relative;
  display: block;
}

.social.show-mobile>div {
  display: inline;
}

.social.show-mobile>.gg-pl1>div {
  width: 60px !important;
}

.social.show-mobile>.tw-twt>* {
  width: 60px !important;
}

.social.show-mobile>.pn-pin>a>img {
  margin-top: -15px !important;
}

.results-block .sort-form .holder {
  font-size: 0.819em;
  line-height: 0;
  font-weight: bold;
  text-align: justify;
  margin: 0 0 10px;
}

.results-block .sort-form .holder:after {
  content: "";
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 0;
}

.results-block .sort-form .filter-opener {
  line-height: 1.334em;
  text-align: left;
  display: inline-block;
  vertical-align: middle;
  padding: 8px 40px 8px 10px;
  box-sizing: border-box;
  width: 48%;
  position: relative;
  background: #f6f6f6;
  height: 30px;
  border: none;
  border-top: 1px solid #f6f6f6;
  border-radius: 3px;
  box-shadow:
    inset 0 1px 0 0 #fff,
    0 1px 3px 0 rgba(0, 0, 0, 0.1);
  letter-spacing: 0;
  color: #434649;
}

.results-block .sort-form .filter-opener:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 33px;
  background-repeat: no-repeat;
  background-position: -166px -39px;
}

.results-block .sort-form select {
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  width: 48%;
  margin: 0;
  line-height: 1.334em;
  text-align: left;
  letter-spacing: 0;
}

.results-block .toolbar .section-title {
  margin: 0 0 13px;
  padding: 9px 0 7px 0;
}

.box.restricted-inv-notices {
  padding: 8px 0 0;
  margin-top: 15px;
}

.box.restricted-inv-notices>p {
  color: #888888;
  font-size: 100.01%;
  line-height: 1.334em;
}

.holder p.restricted-inv-notice,
.description p.restricted-inv-notice {
  color: #888888;
  font-size: 0.8em;
  line-height: 1.3em;
  margin: 11px 0 6px 0;
  padding: 0 6px;
}

.holder p.restricted-inv-notice-grid {
  margin-top: 0;
}

.restricted-inv-notice-row .description p.restricted-inv-notice {
  font-size: 0.92em;
  padding-left: 0;
}

.slideshow {
  margin: 14px 0 13px;
  position: relative;
}

.slideshow h3 {
  font-size: 1.1em;
  line-height: 1.334em;
  padding: 8px 10px;
  color: #fff;
  background: #6aabd0 url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzZhYWJkMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMzZjc3YTIiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: linear-gradient(to bottom, #6aabd0 0%, #3f77a2 100%);
  margin: 0 0 13px;
  border-radius: 3px;
  position: relative;
  z-index: 26;
}

.slideshow .gmask {
  width: 50%;
  position: relative;
  overflow: hidden;
  padding: 0 25%;
}

.carousel .gmask:after,
.carousel .gmask:before,
.slideshow .gmask:after,
.slideshow .gmask:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 45px;
  background: url(/dist/images/bg-white.png);
  z-index: 11;
}

.slideshow .gmask:after,
.slideshow .gmask:before {
  width: 25%;
}

.carousel .gmask:after,
.slideshow .gmask:after {
  left: auto;
  right: 0;
}

.carousel .gmask ul,
.slideshow .products-list {
  margin: 0;
  width: 9999px;
  z-index: 10;
}

.slideshow .products-list li {
  float: left;
  margin: 0;
  width: auto;
}

.slideshow .products-list a {
  border: none;
  height: auto;
  padding: 0;
}

.slideshow .products-list .prd-img-ctnr {
  position: static;
  height: auto;
}

.slideshow .products-list .prd-name {
  font-size: 1.364em;
  line-height: 1.2em;
  margin: 0 0 3px;
  font-weight: normal;
}

.slideshow .products-list .restricted-inv .prd-name {
  min-height: 0;
}

.slideshow .products-list .prd-prc {
  font-size: 1.637em;
  line-height: 1em;
}

.slideshow .products-list a:after {
  display: none;
  content: " ";
}

.carousel .prev,
.carousel .next,
.slideshow .prev,
.slideshow .next {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 20px;
  background: url(/dist/images/transparent.png);
  text-indent: -9999px;
  overflow: hidden;
  z-index: 25;
}

.carousel .next,
.slideshow .next {
  left: auto;
  right: 0;
}

.carousel .prev:before,
.carousel .next:before,
.slideshow .prev:before,
.slideshow .next:before {
  content: "";
  position: absolute;
  left: 0;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-position: -166px -23px;
  top: 50%;
}

.carousel .prev:before,
.carousel .next:before {
  margin-top: -8px;
}

.carousel .next:before,
.slideshow .next:before {
  background-position: -182px -23px;
  left: auto;
  right: 0;
}

.filter-box .box {
  border-top: 1px solid #e3e3e3;
  padding: 12px 14px 14px;
}

.filter-box .box:first-child {
  border-top: none;
  box-shadow: none;
  padding-top: 4px;
}

.filter-box .box .heading {
  overflow: hidden;
}

.filter-box .box .box-slide {
  padding: 6px 0 0;
  width: 92%;
}

.filter-box .box .slide-frame {
  width: 100%;
  position: relative;
}

.filter-box .box .opener {
  float: right;
  width: 19px;
  height: 20px;
  background: url(/dist/images/transparent.png);
  text-indent: -9999px;
  overflow: hidden;
  position: relative;
}

.filter-box .box .opener:after,
.filter-box .box .opener:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 9px;
  height: 1px;
  background: #9b9b9b;
  margin: -1px 0 0;
}

.filter-box .box .opener:after {
  width: 1px;
  height: 10px;
  margin: -5px 4px 0 0;
}

.filter-box .box.open .opener:after {
  display: none;
  content: " ";
}

.filter-box .box h3 {
  margin: 0;
  overflow: hidden;
  font-size: 1em;
  line-height: 1.5em;
}

.filter-form .box ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.filter-form .box ul label {
  word-break: break-word;
}

.filter-box .box.productfinder-title {
  padding: 4px 0 4px 4px;
}

.filter-box .box.productfinder-title:first-child .btn-holder {
  padding: 0 0 10px;
}

.filter-box .box.productfinder-title .btn-holder {
  padding: 10px 0;
  display: flex;
  flex-direction: column-reverse;
}

.filter-box .box.productfinder-title .btn-holder p {
  text-align: center;
  margin: 0 0 10px;
}

.filter-box .box.productfinder-title .btn-holder i {
  display: none;
}

.filter-form input[type="checkbox"] {
  width: 16px;
  height: 15px;
  margin: 0 2px 0 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
}

.check-list.filter-scroll {
  max-height: 342px;
  overflow: hidden;
  overflow-y: scroll;
}

.filter-form .check-list {
  color: #434649;
}

.filter-form .check-list li {
  margin: 7px 0 0;
}

.filter-form .check-list li:first-child {
  margin-top: 0;
}

.filter-form .check-list label i {
  color: #83888b;
}

.filter-form .slide {
  font-size: 0.917em;
  line-height: 1.1em;
  font-weight: bold;
  color: #434649;
  position: relative;
  margin: 9px 0 2.6em;
  height: 4px;
  background: #d7d7d7;
  border: 1px solid #bababa;
  border-radius: 3px;
}

.filter-form .slide #min-input-label {
  margin-top: 22px;
  margin-left: -11px;
  display: block;
  float: left;
}

.filter-form .slide #max-input-label {
  margin-top: 22px;
  display: block;
  float: right;
}

.filter-form .slide .ui-slider-range,
.filter-form .slide .price-slider {
  position: absolute;
  height: 4px;
  background: #95b2c7;
  border: 1px solid #7ea6c5;
  border-radius: 3px;
  box-shadow:
    inset 0 1px 0 0 #c1d2df,
    0 1px 1px 0 #b6b6b6;
  top: -1px;
  left: 0;
  width: 100%;
}

.filter-form .slide .price-slider.ui-slider {
  background: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.filter-form .slide .ui-slider-handle,
.filter-form .slide li {
  position: absolute;
  top: -9px;
  margin-left: -10px;
  padding: 32px 0 0;
  text-align: center;
  cursor: pointer;
  min-width: 16px;
  max-width: 28px;
  color: #434649;
}

.filter-form .slide .ui-slider-handle:focus,
.filter-form .slide li:focus {
  outline: none;
}

.filter-form .slide .ui-slider-handle:hover {
  text-decoration: none;
}

.filter-form .slide .ui-slider-handle:before,
.filter-form .slide li:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 16px;
  height: 30px;
  margin-left: -8px;
  background-repeat: no-repeat;
  background-position: -220px -23px;
}

.filter-box {
  margin: 0 0 12px;
  background: #f6f6f6;
  padding: 0 0 10px;
  border-radius: 3px;
  font-size: 1.1em;
  line-height: 1.25em;
}

.filter-box.sidebar-box {
  color: #434649;
}

.filter-box .btn-holder {
  text-align: left;
  line-height: 1.084em;
}

.filter-box .box:first-child .btn-holder {
  padding: 0 0 20px;
}

.filter-box .btn-holder .go {
  float: right;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 18px;
  padding: 3px 7px;
  font-weight: bold;
  border: 1px solid #a6bc6d;
  -webkit-border-radius: 3px;
  margin: -5px 0 0 5px;
  color: #fff;
  border-color: #249c54;
  background-color: #249c54;
  transition: background-color 0.2s ease-out;
}

.filter-box .btn-holder .go:hover,
.filter-box .btn-holder .go.active {
  border-color: #208b4b;
  background-color: #208b4b;
}

.filter-box .btn-holder .go:hover {
  text-decoration: underline;
}

.filter-box .btn-holder p {
  overflow: hidden;
  margin: 0;
}

.filter-box .btn-holder p i {
  font-style: normal;
  color: #249c54;
}

.filter-box h2 {
  border-radius: 3px 3px 0 0;
  font-size: 1em;
  line-height: 1.1em;
  font-weight: bold;
}

.main-heading-desc img {
  float: right;
  margin: 0 0 10px 10px;
}

.product-block #inv-prc-tax,
.product-block .inv-prc-tax {
  color: #83888b;
}

.result-list .in-stock:after,
.result-list .third-party:after,
.product-block .instock:after,
.product-block .third-party .instock:after {
  margin: 0 0 6px 5px;
  background: url(/dist/images/sprite11.png) no-repeat -126px -79px;
  width: 17px;
  height: 17px;
  content: "";
  display: inline-block;
  vertical-align: middle;
}

.add-basket-block .tooltip-source .g4m-tick:after,
.pricing-block .tooltip-source .g4m-tick:after {
  margin: 0 0 6px 0;
  background: url(/dist/images/sprite11.png) no-repeat -126px -79px;
  width: 17px;
  height: 17px;
  content: "";
  display: inline-block;
  vertical-align: middle;
}

.product-block .instock {
  color: #249c54;
  font-weight: bold;
}

.product-block .third-party .instock {
  color: inherit;
  font-weight: inherit;
}

span.dlv-get-tomorrow {
  font-weight: bold;
  color: #434649;
}

span.delivery-advice-date {
  font-weight: bold;
  color: #434649;
}

span.delivery-estimate-date {
  font-weight: bold;
  color: #434649;
}

.moneyBackGuaranteeDaysDefault {
  font-size: 1.5rem;
}

.restricted-inv-notices {
  margin: 0 0 1.2em 0;
}

.single-column-responsive-layout .pdp-page .restricted-inv-notice {
  font-size: 0.9375em;
  margin: 0 0 1.2em 0;
  color: #434649;
}

.slide-holder {
  float: left;
}

.free-delivery #inv-del-est {
  line-height: 1.7em;
}

.pricing-block .main-price {
  margin-bottom: 15px;
}

.product-block {
  margin: -10px 0;
  border-color: #e3e3e3;
  padding: 0;
  position: relative;
}

.product-layout-split:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  left: 54.15%;
  background: #e3e3e3;
}

.product-block p {
  margin: 0 0 1px;
}

.product-block .inv-prc-gbp {
  font-size: 2.5em;
  line-height: 1em;
  color: #fa8600;
  padding: 0;
}

.product-block .inv-prc-gbp .inv-rrp {
  font-weight: normal;
  position: static;
  color: #83888b;
  font-size: 0.5em;
  line-height: 1.3em;
}

.product-block .inv-prc-visual-ex {
  font-size: 1.6em;
}

.product-block #inv-prc-tax,
.product-block .inv-prc-tax {
  font-size: 0.834em;
  line-height: 1em;
  margin: -1px 0 4px;
  display: block;
}

.bol-visual .inv-prc-tax {
  padding-bottom: 6px;
}

.product-block .instock {
  font-weight: bold;
  float: left;
  color: #249c54;
}

.product-block .instock,
.product-block .nostock {
  font-size: 1.3em;
  margin: 0 0 6px;
  line-height: 1.5em;
}

.product-block .instock .more-info-icon,
.product-block .nostock .more-info-icon {
  margin: 5px 5px;
}

.product-block .third-party .instock {
  color: inherit;
  font-weight: bold;
}

.warranty-container {
  line-height: 1.8em;
}

.product-block #inv-del-est {
  margin: 0 0 5px;
  font-size: 1.22em;
}

.product-block .button {
  margin: 8px 0 15px;
  min-height: 16px;
}

.product-block p a {
  color: #434649;
}

.product-block input[type="checkbox"] {
  margin: -3px 0 0 3px;
  padding: 0;
  vertical-align: middle;
  display: inline-block;
}

.product-block .wnty-pre-txt {
  color: #249c54;
  font-weight: bold;
}

.product-block .smallText {
  color: #83888b;
}

.product-block .prd-logo {
  display: block;
  margin: 8px 0 11px;
}

.product-block .list {
  padding: 0;
  margin: 0 0 10px;
  font-size: 1em;
  line-height: 1.25em;
  border-top: none;
  font-weight: bold;
}

.product-block .list li {
  padding: 0 0 6px 12px;
}

#inv-sdesc {
  padding: 32px 0 0 0;
  line-height: 1.167em;
  position: relative;
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 20px;
}

#inv-sdesc .desc-box {
  padding-right: 32px;
}

.h2-title span,
#inv-sdesc h2 {
  font-size: 1.2em;
  line-height: 1.25em;
  margin: 0;
}

#inv-sdesc .heading {
  overflow: hidden;
  margin: 0 0 8px;
}

#inv-sdesc .viewfull {
  display: inline-block;
  vertical-align: top;
  position: relative;
  font-size: 1.167em;
  line-height: 1.215em;
  color: #434649;
  padding: 0 17px 0 0;
  margin: 8px 0 26px;
  font-weight: bold;
}

#inv-sdesc .viewfull:before,
.promo-code .promo-code-opener:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  border: solid transparent;
  border-width: 8px 5px 0;
  border-top-color: #606769;
  margin-top: -4px;
}

#inv-sdesc .product-bullets .prd-logo {
  margin-top: -15px;
}

#main .content .media {
  margin-left: -10px;
}

.media .box {
  width: 126px;
  height: 62px;
  background: #ededed;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  float: left;
  margin: 0 0 10px 10px;
}

.media .download,
.media .movie {
  display: block;
  overflow: hidden;
  position: relative;
  padding: 39px 5px 5px 16px;
  height: 19px;
  font-size: 12px;
  line-height: 19px;
  color: #434649;
}

.media .download:hover,
.media .movie:hover {
  text-decoration: none;
}

.media .download:before,
.media .movie:before {
  content: "";
  position: absolute;
  width: 28px;
  height: 24px;
  background: url(/dist/images/sprite11.png) no-repeat -129px -119px;
  left: 50%;
  margin-left: -14px;
  top: 6px;
}

.media .movie:before {
  width: 32px;
  height: 29px;
  margin-left: -16px;
  background-position: -158px -119px;
}

.media .download strong,
.media .movie strong {
  display: block;
  font-weight: normal;
  position: absolute;
  padding: 0 0 0 40px;
}

.media .download strong span,
.media .movie strong span {
  color: #1183cd;
  font-weight: bold;
  font-size: 14px;
}

.media .download strong:after,
.media .movie strong:after {
  content: "";
  position: absolute;
  left: 28px;
  height: 100%;
  width: 1px;
  background: #f7f7f7;
  border-left: 1px solid #d8d8d8;
  top: 0;
}

.media .download strong:before,
.media .movie strong:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  background: url(/dist/images/sprite11.png) no-repeat -191px -119px;
  width: 17px;
  height: 17px;
  margin-top: -8px;
}

.media .download strong:before {
  background-position: -210px -119px;
  width: 18px;
  height: 20px;
  margin-top: -10px;
}

.media .video {
  position: relative;
  display: block;
  height: 100%;
  white-space: nowrap;
}

.media .video img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  vertical-align: middle;
  margin: 1px 0 0;
}

.media .video:hover:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(/dist/images/bg-white.png);
}

#inv-sdesc .slide {
  padding: 20px 0;
  width: 100%;
  overflow: hidden;
  position: relative;
}

#inv-sdesc .slide .video {
  padding: 0 20px;
}

.section {
  overflow: hidden;
}

#cmscnt h2,
.content .section-title {
  text-align: center;
  font-size: 1.5em;
  line-height: 1.167em;
}

.accessories-form {
  line-height: 1.25em;
  margin: 0 0 12px;
  padding: 6px 0 0;
}

.accessories-form .btn-holder {
  text-align: left;
}

.accessories-form .btn-holder table {
  float: right;
}

.accessories-form .btn-holder table tr td {
  vertical-align: middle;
}

.accessories-form .button {
  float: right;
  margin: -1px 0 0 20px;
  white-space: nowrap;
}

.accessories-list {
  padding: 0 0 3px;
  margin: 0;
  list-style: none;
}

.accessories-list li {
  float: left;
  width: 130px;
  margin: 0 7px 2px 7px;
  box-sizing: border-box;
  border: 1px solid #ededed;
  position: relative;
  z-index: 1;
  border-radius: 3px;
  text-align: center;
  padding: 10px 5px 11px;
}

.accessories-list li:before {
  border-radius: 0 0 3px 3px;
  height: 28px;
}

.accessories-list .prd-img {
  display: block;
  margin: 0 0 7px;
  height: 80px;
}

.accessories-list h3 {
  font-size: 0.917em;
  line-height: 1.182em;
  margin: 0 0 3px;
  height: 40px;
  max-height: 40px;
  overflow: hidden;
}

.accessories-list h3 a {
  color: #434649;
}

.accessories-list .price {
  font-size: 1.167em;
  line-height: 1.215em;
  color: #d00000;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
}

.accessories-list input[type="checkbox"] {
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  margin: 0 0 0 5px;
  height: 16px;
  width: 16px;
}

.prod-info {
  border: none;
}

.prod-info .slide {
  position: static !important;
  padding: 4px 0 0;
}

.prod-info h3 {
  font-size: 1.334em;
  line-height: 1.1em;
  margin: 0 0 2px;
}

.prod-info p {
  margin: 0 0 17px;
}

.movie-block img {
  max-width: 100%;
  height: auto;
  width: auto;
}

.carousel-holder {
  margin: 0 0 11px;
}

.content .carousel-holder .section-title,
.content .results-block .section-title {
  text-align: left;
  font-size: 1.167em;
  line-height: 1.215em;
  padding: 7px 15px;
  margin: 0 0 11px;
  font-weight: bold;
}

.carousel.js-slide-hidden {
  position: relative !important;
  left: 0 !important;
  top: 0 !important;
}

.carousel .gmask {
  padding: 0 50px;
}

.carousel .gmask li {
  width: 95px;
  padding: 8px 10px 10px;
  border-top: 1px dotted #e5e5e5;
  border-bottom: 1px dotted #e5e5e5;
  border-radius: 0;
  box-shadow: none;
  background-clip: padding-box;
}

.carousel h3 {
  font-size: 0.917em;
  line-height: 1.182em;
  margin: 0 0 7px;
}

.carousel .prev,
.carousel .next {
  bottom: auto;
  top: 50%;
  text-indent: -9999px;
  overflow: hidden;
  width: 29px;
  height: 29px;
  margin-top: -14px;
  background: url(/dist/images/sprite11.png) no-repeat -398px -119px;
}

.carousel .next {
  background-position: -428px -119px;
}

.results-block .toolbar {
  overflow: hidden;
  margin: 0 0 10px;
  border-top: 1px dotted #e5e5e5;
  border-bottom: 1px dotted #e5e5e5;
  background: #f6f6f6;
  padding: 11px 40px 10px;
  background-clip: padding-box;
}

.results-block .toolbar .heading {
  float: right;
}

.results-block .toolbar h2 {
  margin: 0;
  background: none;
  box-shadow: none;
  color: #434649;
  font-size: 1em;
  line-height: 1.286em;
  text-shadow: none;
  padding: 0;
}

.results-block .sort-form .holder {
  margin: 0;
  text-align: left;
  float: left;
  font-size: 1em;
  line-height: 1.5em;
}

.results-block .sort-form .holder:after {
  content: " ";
  display: none;
}

.results-block .sort-form select {
  display: block;
  width: 180px;
}

.content .results-block .toolbar {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 15px;
}

.content .results-block .toolbar .results-block-title {
  width: 50%;
}

.content .results-block .toolbar .results-block-title h1,
.content .results-block .toolbar .results-block-title h2,
.content .results-block .toolbar .results-block-title .no-search-result-text {
  margin: 0;
  line-height: 1.167em;
  padding: 7px 15px;
  text-align: right;
}

.content .results-block .toolbar .results-block-title .results-block-title-loading-img {
  margin-right: 50px;
}

.content .results-block .toolbar .results-block-title-left h1,
.content .results-block .toolbar .results-block-title-left h2 {
  text-align: left;
  padding-left: 0;
  border: none;
}

.content .results-block .toolbar .results-block-title-right h1,
.content .results-block .toolbar .results-block-title-right h2 {
  padding-right: 0;
}

.content .results-block .toolbar #inventory-list-sort {
  position: relative;
  width: 100%;
  padding: 11px 0 5px 0;
}

span.sale {
  position: absolute;
  text-indent: -9999px;
  overflow: hidden;
  width: 55px;
  height: 55px;
  background: url(/dist/images/sprite11.png) no-repeat -402px 0;
  top: 0;
  left: 0;
  display: block;
  margin: 0;
  border: none;
  z-index: 10;
  text-align: left;
}

.sashleft {
  display: block;
  width: 55px;
  height: 55px;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  border: none;
}

.product-block .sashleft {
  left: 54.15%;
}

.slideshow {
  margin: 0;
  position: relative;
}

.slideshow .gmask {
  width: auto;
  padding: 0;
}

.slideshow .gmask:after,
.slideshow .gmask:before {
  content: " ";
  display: none;
  background: none;
}

.slideshow .products-list {
  margin: 0;
  width: 100% !important;
  z-index: 10;
}

.slideshow .products-list li {
  float: none;
  width: 100% !important;
  margin: 0;
}

.slideshow .products-list li {
  border-top: 1px solid #e3e3e3;
  padding: 13px 0 11px;
}

.slideshow .products-list li:first-child {
  border: none;
  box-shadow: none;
  padding-top: 4px;
}

.slideshow .products-list a {
  padding: 0;
  height: auto;
}

.slideshow .products-list .prd-name {
  font-size: 0.917em;
  line-height: 1.182em;
  margin: 0 0 3px;
  font-weight: normal;
}

.slideshow .products-list .prd-img-ctnr {
  margin-bottom: 8px;
  position: static;
  height: auto;
}

.slideshow .products-list .prd-prc {
  font-size: 1.5em;
  line-height: 1em;
}

.slideshow .products-list .prd-img-ctnr img {
  border: 1px solid #ececec;
}

.slideshow .prev,
.slideshow .next {
  display: none;
}

.sort-form .filter-wrap {
  height: auto !important;
}

.filter-form .slide .ui-slider-handle:before,
.filter-form .slide li:before {
  background: url(/dist/images/sprite11.png) no-repeat -439px -85px;
  width: 16px;
  height: 27px;
}

.filter-box {
  width: 140px;
}

.filter-box .box {
  padding: 12px 0 14px 4px;
}

.filter-box .btn-holder .go {
  margin-top: 5px;
}

.products-list {
  margin: 0 -3% 0 0;
}

.products-list .prd-cntr {
  width: 30.4%;
  margin: 0 2.9% 2.9% 0;
}

.accessories-form {
  padding-top: 0;
}

@media screen and (min-width: 1336px) {
  .show-large {
    display: block;
  }

  .products-list {
    margin: 0 -2% 0 0;
  }

  .products-list .prd-cntr {
    width: 31.3%;
    margin: 0 2% 2% 0;
  }

  .products-list .prd-det {
    display: block;
  }

  .products-list a {
    height: 10.8rem;
  }

  .products-list .prd-name {
    margin-bottom: 2px;
  }

  .products-list .holder {
    bottom: 7px;
    height: 27px;
    left: 0;
    padding: 0 14px 0 18px;
    position: absolute;
    font-size: 1em;
  }

  .holder p.restricted-inv-notice,
  .description p.restricted-inv-notice {
    margin: 10px -13px 0 0;
  }

  .image-box {
    float: left;
    width: 51.15%;
  }

  .image-box .gmask li {
    z-index: 10;
  }

  .products-list .prd-img-ctnr {
    top: 3%;
  }

  .products-list .holder .prd-prc {
    font-size: 2.2rem;
    line-height: 1;
    margin-left: 2px;
  }

  .products-list .holder .prd-inf {
    bottom: 1px;
  }

  .product-block:before {
    content: "  ";
  }

  .product-block .product-dateils {
    padding: 32px 65px 37px 58px;
    width: 43.5%;
  }

  .product-block .inv-prc-gbp {
    font-size: 2.5em;
    line-height: 1em;
  }

  .product-block .inv-prc-visual-ex {
    font-size: 2em;
  }

  .product-block .inv-prc-gbp .inv-rrp {
    font-size: 0.5em;
    line-height: 1.3em;
  }

  .product-block .button {
    font-size: 16px;
    height: 16px;
  }

  .prod-info .slide {
    padding: 8px 2px 0;
  }

  .results-block .toolbar .heading {
    float: left;
    width: 100%;
  }

  .results-block .toolbar h2 {
    margin: 0 200px;
    text-align: center;
    font-size: 1.167em;
    line-height: 1.286em;
  }

  .products-list .prd-prc {
    font-size: 1.834em;
    line-height: 1.2em;
  }
}

.image-box {
  float: left;
  width: 51.15%;
}

.image-box .gmask {
  height: 317px;
  margin-bottom: 10px;
}

.image-box .gmask img {
  height: auto;
  width: auto;
  max-width: 100%;
}

.image-box .gmask ul {
  height: 390px;
  padding-bottom: 100%;
  width: 100%;
  list-style: none;
}

.image-box .gmask ul li {
  padding: 0;
  position: absolute;
  z-index: 0;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  box-sizing: border-box;
}

.image-box .gmask ul li.video .video-overlay {
  width: 100%;
  height: 317px;
  display: none;
  background-position: center;
  cursor: pointer;
  background-size: cover;
}

.image-box .gmask ul li.video iframe {
  width: 100%;
}

.image-box .gmask ul li.active {
  z-index: 11;
}

.image-box .gmask ul li object embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  margin: 0 5%;
  height: 317px;
}

.image-box .gmask ul li a {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
}

.image-box .gmask .zoomWrapperImage img {
  max-width: none;
}

.image-box li.image:not(:first-of-type),
.image-box .switcher-wrapper {
  display: none;
}

.image-box.gallery-js-ready li.image,
.image-box.gallery-js-ready .switcher-wrapper {
  display: block;
}

.switcher-wrapper,
.accessories-wrapper .switcher-wrapper {
  margin: 0 auto;
}

.switcher-wrapper .switcher {
  text-align: center;
  position: relative;
  margin: 0 auto 12px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 13px;
  overflow: hidden;
  width: 100%;
}

.switcher-wrapper .switcher:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
}

.switcher-wrapper .switcher+.switcher {
  margin-top: 0;
}

.switcher-wrapper .switcher ul {
  width: 9999px;
  position: relative;
  z-index: 5;
  padding: 0 33px;
}

.switcher-wrapper .pagination {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 22px;
  background: #f2f2f2;
  border: 1px solid #e5e5e5;
  height: 64px;
  z-index: 10;
}

.switcher-wrapper .pagination.hidden {
  display: none;
}

.switcher-wrapper .prev,
.switcher-wrapper .next {
  text-indent: -9999px;
  overflow: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  width: 20px;
  position: absolute;
  z-index: 49;
  cursor: pointer;
}

.switcher-wrapper .prev:after,
.switcher-wrapper .prev:before,
.switcher-wrapper .next:after,
.switcher-wrapper .next:before {
  content: "";
  position: absolute;
  left: 0;
  top: 40%;
  border: solid transparent;
  border-width: 8px 8px 8px 0;
  border-right-color: #888888;
  border-left-color: #888888;
  margin-top: -1px;
}

.switcher-wrapper .prev {
  left: 0;
  right: auto;
}

.switcher-wrapper .next {
  right: 0;
  left: auto;
}

.switcher-wrapper .prev:after {
  border-right-color: #f2f2f2;
  margin-left: 3px;
}

.switcher-wrapper .next:after {
  border-left-color: #f2f2f2;
  margin-right: 3px;
}

.switcher-wrapper .prev:after,
.switcher-wrapper .prev:before {
  right: auto;
  left: 5px;
}

.switcher-wrapper .next:after,
.switcher-wrapper .next:before {
  border-width: 8px 0 8px 8px;
  left: auto;
  right: 6px;
}

.image-box .switcher-wrapper {
  max-width: 495px;
  width: 100px;
}

.image-box .switcher-wrapper .switcher {
  white-space: nowrap;
}

.image-box .switcher-wrapper .switcher ul {
  font-size: 0;
}

.image-box .switcher-wrapper li {
  border: 1px solid #e5e5e5;
  float: left;
  margin: 0 0 0 10px;
  position: relative;
}

.image-box .switcher-wrapper li.active {
  border-bottom: 4px solid #fca613;
  height: 61px;
}

.image-box .switcher-wrapper li.active a {
  margin-bottom: 0;
}

.image-box .switcher-wrapper li.active .video-tagline {
  bottom: 0;
}

.image-box .switcher-wrapper li:first-child {
  margin-left: 0;
}

.image-box .switcher-wrapper li a {
  margin: 3px;
  position: relative;
  display: block;
  width: 58px;
  overflow: hidden;
}

.image-box .switcher-wrapper li a.video .video-thumbnail {
  display: block;
  margin-left: -39px;
  background-position: center;
  width: 136px;
  height: 58px;
}

.image-box .switcher-wrapper li a img {
  max-height: 58px;
}

.image-box .switcher-wrapper li .video-tagline {
  position: absolute;
  height: 12px;
  width: 65%;
  background-color: #000000;
  padding: 5px;
  left: 0;
  bottom: 3px;
  text-align: left;
  cursor: pointer;
}

.image-box .switcher-wrapper li .video-tagline span {
  color: #ffffff;
  font-size: 12px;
  line-height: 14px;
}

.image-box .switcher-wrapper li .video-tagline span.video-count {
  font-weight: bold;
  color: #fca613;
}

.image-box .switcher-wrapper p {
  font-weight: bold;
  text-align: left;
  margin: 0 0 5px;
}

.gmask.margin-half ul {
  position: absolute;
  top: 10%;
}

.gmask.margin-half ul .zoomWindow {
  top: -10% !important;
}

.gmask.margin-full ul {
  position: absolute;
  top: 18%;
}

.gmask.margin-full ul .zoomWindow {
  top: -18% !important;
}

@media screen and (min-width: 1336px) {
  .image-box .gmask {
    margin: 0 0 10px;
  }
}

#full-des {
  margin: 0;
  padding: 0;
}

#full-des h2.section-title {
  font-size: 1.5em;
  padding: 9px 10px 7px;
  font-weight: normal;
}

#full-des h3 {
  font-weight: bold;
  margin: 1em 0;
  font-size: 108%;
  margin-top: 0;
}

#full-des p {
  margin-bottom: 1em;
}

#full-des p.br {
  margin-bottom: 0.5em;
  height: 2px;
}

#inv-sdesc .short-description p {
  display: inline;
  margin: 0px;
}

#full-des h2 {
  margin: 1em 0;
  margin-top: 0;
  font-size: 123.1%;
  padding: 0;
}

#full-des li {
  margin: 0;
  padding: 0;
}

#full-des br {
  margin: 0;
  padding: 0;
}

.carousel-holder .opener:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  width: 10px;
  height: 7px;
  background-repeat: no-repeat;
  background-position: -209px -23px;
  margin-top: -3px;
}

.carousel-holder.active .opener:before {
  background-position: -198px -23px;
}

.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel .gmask {
  position: relative;
  overflow: hidden;
  padding: 0 45px;
}

.carousel ul {
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
}

.carousel .gmask li {
  float: left;
  width: 73px;
  padding: 6px 10px 8px;
  background: #f6f6f6;
  border-top: 1px solid #f6f6f6;
  border-radius: 3px;
  box-shadow:
    inset 0 1px 0 0 #fff,
    0 1px 3px 0 rgba(0, 0, 0, 0.1);
  margin: 0 10px 0 0;
}

.carousel h3 {
  font-size: 0.819em;
  line-height: 1.334em;
  margin: 0 0 4px;
  font-weight: normal;
}

.carousel h3 a {
  color: #434649;
}

.carousel img {
  width: 100%;
  height: auto;
}

.mobile-merch-banner {
  padding: 5px 0 15px;
}

.mobile-merch-banner a {
  display: inline-block;
}

.mobile-merch-banner img {
  max-width: 100%;
}

.page-home {
  max-width: 1366px;
  padding: 0 18px;
}

.page-home .section-promo {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  margin-bottom: 20px;
  max-height: 1044px;

  @media (max-width: 767px) {
    max-height: unset !important;
  }
}

@media screen and (min-width: 1024px) {
  .page-home .section-promo {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1023px) {
  .page-home .section-promo>*:not(.promo-main) {
    margin-right: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .page-home .section-promo>*:not(.promo-sidebar-brands) {
    margin-right: 20px;
  }
}

.page-home .section-promo .sidebar-box .big-deals img {
  border-radius: 3px;
  -webkit-border-radius: 3px;
}

.page-home .section-promo .banner-clearance .sidebar-box {
  border: none;
}

.page-home .section-promo .banner-clearance .sidebar-box .big-deals {
  top: 0;
  left: 0;
  max-width: 100%;
}

.page-home .section-promo .banner-promo .sidebar-box {
  border: none;
}

.page-home .section-promo .sidebar-box {
  padding: 0;
}

.page-home .section-promo .sidebar-box a {
  margin: 0;
}

.page-home .section-promo .sidebar-box a img {
  display: block;
  object-fit: cover;
  transition: all 0.2s;
}

.page-home .promo-main {
  flex-grow: 1;
  width: 100%;
}

.page-home .merchandising-banner {
  height: 300px;
  margin-bottom: 20px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;

  @media (max-width: 575px) {
    height: 161px;
  }
}

@media screen and (min-width: 1024px) {
  .page-home .merchandising-banner {
    margin-bottom: 8px;
  }
}

.page-home .merchandising-banner .gallery {
  height: 100%;
  background: #ededed;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.page-home .merchandising-banner .gallery-image {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: initial;

  @media (max-width: 575px) {
    height: 100%;
  }

  .gallery-left {
    position: absolute;
    top: 42%;
    transform: translate(-50%);
    z-index: 50;
    width: 50px;
    height: 50px;
    background-color: rgb(0 0 0 / 23%);
    left: 33px;
    border-radius: 100%;

    @media (max-width: 575px) {
      display: none;
    }
  }

  .gallery-right {
    position: absolute;
    top: 42%;
    transform: translate(-50%);
    right: -18px;
    z-index: 50;
    width: 50px;
    height: 50px;
    background-color: rgb(0 0 0 / 23%);
    border-radius: 100%;

    @media (max-width: 575px) {
      display: none;
    }
  }
}

.page-home .merchandising-banner .gallery-image-list {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
}

.page-home .merchandising-banner .gallery-image-list-item {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  background-repeat: no-repeat;
  transition: 0.8s ease-out opacity;
  background-position: center;
  background-size: 100% 100%;
}

.page-home .merchandising-banner .gallery-image-list-item.active {
  opacity: 1;
  z-index: 10;
}

.page-home .merchandising-banner .gallery-selector-list {
  height: 37px;
  width: 100%;
  display: flex;
  justify-content: stretch;
}

@media screen and (max-width: 768px) {
  .page-home .merchandising-banner .gallery-selector-list {
    padding: 0;
    margin: 0;
  }
}

.page-home .merchandising-banner .gallery-selector-list-item {
  height: 100%;
  width: 33.33%;
  display: block;
  float: left;
  font-size: 1.4rem;
  font-weight: bold;
}

.page-home .merchandising-banner .gallery-selector-list-item:not(:last-child) {
  border-right: 1px solid #e5e5e5;
}

.page-home .merchandising-banner .gallery-selector-list-item:hover .gallery-selector-link {
  color: #fff;
  background: #1c9ace;
}

.page-home .merchandising-banner .gallery-selector-list-item.active .gallery-selector-link {
  color: #fff;
  background: #1c9ace;
}

.page-home .merchandising-banner .gallery-selector-link {
  width: 100%;
  height: 100%;
  display: inline-block;
  padding: 8px 5px;
  text-align: center;
  text-decoration: none;
  color: #434649;
}

.page-home .promo-sidebar-banners {
  flex: 0 0 auto;
  width: 160px;

  .title {
    text-transform: capitalize;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.page-home .promo-sidebar-banners>*:not(:last-child) {
  margin-bottom: 20px;
}

@media screen and (min-width: 1024px) {
  .page-home .promo-sidebar-banners>*:not(:last-child) {
    margin-bottom: 20px;
  }
}

.page-home .banner-clearance {
  max-height: 740px;
}

.page-home .banner-promo {
  height: 200px;
}

.trading-brand--av .page-home .contact-us {
  height: auto;
}

.trading-brand--gear4music .page-home .contact-us {
  height: 300px;
}

.page-home .contact-us .smaller {
  font-size: 1.2rem;
}

.page-home .promo-sidebar-brands {
  flex: 0 0 auto;
  width: 160px;
}

@media screen and (max-width: 1023px) {
  .page-home .promo-sidebar-brands {
    display: none;
  }
}

.page-home .featured-products-title {
  font-size: 2.8rem;
  text-align: center;
  display: block;
}

.page-home .featured-products {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.page-home .featured-product {
  height: 382px;
}

@media screen and (max-width: 1023px) {
  .page-home .featured-product {
    width: calc(33.32333% - 10px);
    margin-bottom: 20px;
  }

  .page-home .featured-product:not(:nth-child(3n)) {
    margin-right: 15px;
  }

  .page-home .featured-product:nth-last-child(-n + 3):nth-child(3n + 1),
  .page-home .featured-product:nth-last-child(-n + 3):nth-child(3n + 1)~* {
    margin-bottom: 0;
  }

  .page-home .featured-product:not(:nth-child(-n + 9)) {
    margin-bottom: 0;
  }

  .page-home .featured-product:not(:nth-child(-n + 12)) {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .page-home .featured-product {
    width: calc(24.99% - 15px);
    margin-bottom: 20px;
  }

  .page-home .featured-product:not(:nth-child(4n)) {
    margin-right: 20px;
  }

  .page-home .featured-product:nth-last-child(-n + 4):nth-child(4n + 1),
  .page-home .featured-product:nth-last-child(-n + 4):nth-child(4n + 1)~* {
    margin-bottom: 0;
  }

  .page-home .featured-product:not(:nth-child(-n + 12)) {
    margin-bottom: 0;
  }

  .page-home .featured-product:not(:nth-child(-n + 16)) {
    display: none;
  }
}

.footer-about-g4m {
  display: flex;
  max-width: 1330px;
  padding: 0 18px;
  margin: 30px auto 0;
}

.footer-about-g4m>section.block {
  width: 50%;
  flex-grow: 1;
  background: #f6f6f6;
}

.footer-about-g4m .more-news-list>li {
  border-top: none;
}

.footer-about-g4m .more-news-list time {
  color: #83888b;
}

body:not(.body-class--mobile) #page {
  overflow: visible;
}

.nav-holder {
  position: relative;
  z-index: 60;
  max-width: none;
  padding: 0;
  margin: 0;
  background-color: #222;

  @media (max-width: 767px) {
    display: none;
  }
}

#nav {
  box-sizing: border-box;
  position: relative;
  display: none;
  max-width: 1362px;
  padding: 0;
  margin: 0 auto 20px auto;
  line-height: 1.2em;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
}

@media screen and (min-width: 1024px) {
  #nav {
    padding-left: 14px;
    padding-right: 14px;
  }
}

#nav h4 {
  margin: 0;
  height: 50px;
}

#nav a {
  display: block;
  position: relative;
  z-index: 1;
}

#nav a:hover {
  text-decoration: none;
}

#nav .header-at-top {
  display: table-cell;
  height: 100%;
}

#nav .sectionwrapper {
  margin-top: 0px;
}

#nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#nav>ul {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  width: 100%;
  position: relative;
}

// @media screen and (min-width: 1024px) {
//   #nav > ul:before,
//   #nav > ul:after {
//     content: "";
//     width: 1px;
//   }
//   #nav > ul:before {
//     left: -1px;
//     background-color: #4f5154;
//   }
//   #nav > ul:after {
//     right: -1px;
//     background-color: #6b6d71;
//   }
// }

#nav>ul>li {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 1px;
  min-height: 45px;
  background-color: #222;
  position: static;
  transition: all 0.2s;
}

#nav>ul>li,
#nav>ul>li:first-child,
#nav>ul>li:last-child {
  border-left: 1px solid #222;
  border-right: 1px solid #222;
  border-radius: 0;
}

#nav>ul>li:hover,
#nav>ul>li:first-child:hover,
#nav>ul>li:last-child:hover {
  border-radius: 0;
}

@media screen and (max-width: 1023px) {
  #nav>ul>li:first-child {
    border-left: 0;
  }

  #nav>ul>li:last-child {
    border-right: 0;
  }
}

#nav>ul>li>a {
  font-size: 1.1rem;
  font-weight: normal;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: all 0.2s;
}

@media screen and (max-width: 879px) {
  [lang="no"] #nav>ul>li>a {
    word-break: break-word;
  }

  [lang="de"] #nav>ul>li>a,
  [lang="sl"] #nav>ul>li>a {
    font-size: 1rem;
  }

  [lang="fi"] #nav>ul>li>a {
    font-size: 1rem;
    word-break: break-word;
  }

  [lang="en"] #nav>ul>li>a {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 880px) {
  #nav>ul>li>a {
    font-size: 1.1rem;
  }

  [lang="no"] #nav>ul>li>a,
  [lang="fi"] #nav>ul>li>a {
    word-break: break-word;
  }

  [lang="en"] #nav>ul>li>a {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 1024px) {
  #nav>ul>li>a {
    font-size: 1.2rem;
  }

  [lang="en"] #nav>ul>li>a {
    font-size: 1.3rem;
  }
}

@media screen and (min-width: 1200px) {
  #nav>ul>li>a {
    font-size: 1.3rem;
  }

  [lang="en"] #nav>ul>li>a {
    font-size: 1.3rem;
  }
}

#nav>ul>li>a span.web-menu-node {
  padding: 5px;
}

@media screen and (min-width: 880px) {
  #nav>ul>li>a span.web-menu-node {
    padding: 10px 5px;
  }
}

#nav>ul>li.hover,
#nav>ul>li:hover,
#nav>ul>li:hover:first-child {
  transition: all 0.2s;
  background-color: #fff;
  border-color: #fff;
  border-radius: 0;
}

#nav>ul>li.hover>a,
#nav>ul>li:hover>a,
#nav>ul>li.intent:hover>a,
body.menu-open #nav>ul>li.hover>a,
body.menu-open #nav>ul>li:hover>a,
body.menu-open #nav>ul>li.intent:hover>a {
  color: #222;
  transition: all 0.2s;
}

#nav .level_2 {
  display: flex;
  width: calc(100% - 2px);
  padding: 30px 20px;
  // border-top: 4px solid #f2811e;
  // border-bottom: 2px solid #eee;
  background-color: #f4f4f4;
  flex-wrap: wrap;
}

body.menu-open #nav.non-touchscreen-desktop>ul>li:hover>.level_2,
body.menu-open #nav>ul>li.hover>.level_2 {
  left: 1px;
}

#nav .menu_list_third li {
  margin: 0;
}

#nav .level_2 .menu_list_third a {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14px;
}

#nav .level_2 .menu_list_third a:hover {
  color: #f2811e;
  background-color: #ddd;
}

#nav {
  color: #fff;
  border-bottom: none;
  width: 100%;
  text-align: center;
  z-index: 50;
}

#nav>ul>li>a {
  color: #fff;
  position: relative;
  z-index: 102;
}

.menu-open #nav.non-touchscreen-desktop>ul>li:hover>a:after {
  content: "";
  display: block;
  height: 10px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 1;
}

#nav>ul>li.hover.has-drop-down>a:before,
.menu-open #nav.non-touchscreen-desktop>ul>li:hover.has-drop-down>a:before {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 10px;
  content: "";
  background: #727272;
}

.menu-open #nav>ul>li.hover>a:after {
  content: "";
  display: block;
  height: 10px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: -1;
  opacity: 1;
}

#nav>ul>li.hover.has-drop-down>a:before,
.menu-open #nav>ul>li.hover.has-drop-down>a:before {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 10px;
  content: "";
  background: #727272;
}

#nav .level_2 {
  position: absolute;
  top: 100%;
  left: -9999px;
  height: auto;
  box-sizing: border-box;
  font-size: 1.167em;
  line-height: 1.215em;
  text-align: left;
  overflow: hidden;
  z-index: 101;
}

.intent:hover .level_2 {
  left: 0px !important;
}

#nav .av_background_popular_brands {
  background: #fff;
  background: linear-gradient(90deg, #fff 84%, #e9e9e9 84%);
  padding-top: 10px;
}

#nav .level_2 a {
  padding: 0px 5px 3px 28px;
  color: #464646;
}

#nav .level_2 a.clearancetitle {
  color: #c20b06;
}

#nav .level_2 a img {
  height: 100px;
  width: 200px;
}

#nav .level_2 a img.popular-brands {
  height: auto;
  width: auto;
  padding: 8px 0 8px 42px;
}

#nav .level_2 a.sectiontitle {
  padding-left: 0px;
}

#nav .level_2 h4 a.sectiontitle {
  padding-left: 28px;
  font-size: 18px;
  transition: all 0.1s;
  line-height: normal;

  &:hover {
    transform: scale(1.1);
  }
}

#nav .level_2 h4 a.popular_brands_padding {
  padding-left: 70px;
  padding-top: 25px;
}

#nav .menu_list_third {
  font-size: 0.858em;
  line-height: 1.167em;
  padding: 2px 0px 6px;
  margin-bottom: 16px;
}

#nav .menu_list_third a.no_hover_effect:hover {
  background-color: transparent;
}

@media screen and (max-width: 955px) {
  #nav .level_2 a img.av-nav-image {
    display: none;
  }

  #nav .level_2 h4 a.popular_brands_padding {
    padding-top: 0;
  }
}

@media screen and (min-width: 956px) {
  #nav {
    font-size: 1.084em;
    line-height: 1em;
  }

  #nav .level_2 {
    font-size: 1.077em;
    line-height: 1.215em;
  }
}

@media screen and (min-width: 1336px) {
  #nav {
    font-size: 1.167em;
  }

  #nav .level_2 {
    font-size: 1em;
  }
}

#nav .breakpoint_4 a,
#nav .breakpoint_5 a,
#nav .breakpoint_6 a {
  color: #f0c !important;
}

#nav .sectionwrapper {
  float: left;
}

#nav .column_width_1 {
  width: 20%;
}

#nav .column_width_1>li {
  width: 100%;
}

#nav .column_width_2 {
  width: 40%;
}

#nav .column_width_2>li {
  width: 49.5%;
}

#nav .column_width_3 {
  width: 60%;
}

#nav .column_width_3>li {
  width: 33%;
}

#nav .column_width_4 {
  width: 80%;
}

#nav .column_width_4>li {
  width: 24.25%;
}

#nav .column_width_5 {
  width: 100%;
}

#nav .column_width_5>li {
  width: 19.2%;
}

#nav .header-at-top>.sectiontitle {
  width: 131px;
}

@media screen and (min-width: 956px) {
  #nav .header-at-top>.sectiontitle {
    width: 177px;
  }

  #nav .sectionwrapper {
    float: left;
  }

  #nav .column_width_1 {
    width: 16.6%;
  }

  #nav .column_width_1>li {
    width: 100%;
  }

  #nav .column_width_2 {
    width: 33.3%;
  }

  #nav .column_width_2>li {
    width: 49.9%;
  }

  #nav .column_width_3 {
    width: 50%;
  }

  #nav .column_width_3>li {
    width: 33%;
  }

  #nav .column_width_4 {
    width: 66.6%;
  }

  #nav .column_width_4>li {
    width: 24.25%;
  }

  #nav .column_width_5 {
    width: 83.3%;
  }

  #nav .column_width_5>li {
    width: 19.2%;
  }

  #nav .column_width_6 {
    width: 100%;
  }

  #nav .column_width_6>li {
    width: 16.6%;
  }
}

.gallery {
  position: relative;
  overflow: hidden;
  margin: 0 0 13px;
}

.gallery .mask {
  position: relative;
  overflow: hidden;
}

.gallery ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.gallery .mask li {
  position: relative;
  border-radius: 3px 3px 0 0;
  background: #ededed no-repeat 50% 0;
  min-height: 266px;
}

.gallery .text-block {
  padding: 34px 5px 10px 20px;
}

.gallery .brand-logo {
  display: block;
  margin: 0 0 20px;
}

.gallery h2 {
  font-size: 3.417em;
  line-height: 1em;
  margin: 0 0 5px;
}

.gallery h2 span {
  display: block;
  font-weight: normal;
  font-size: 0.464em;
  line-height: 1em;
  margin: 2px 0 0;
}

.gallery .price {
  display: block;
  margin: 0 0 22px;
  color: #faa21b;
  font-size: 3.417em;
  line-height: 1em;
}

.gallery .switcher {
  background: #ededed;
  border-radius: 0 0 6px 6px;
  padding: 4px 0;
  font-size: 1.167em;
  line-height: 1.286em;
  font-weight: bold;
}

.gallery .slide-tab-center {
  border-right: 2px solid #e5e5e5;
  border-left: 2px solid #e5e5e5;
}

.gallery .switcher ul {
  display: table;
  width: 100%;
}

.gallery .switcher li {
  display: table-cell;
  width: 33%;
}

.gallery .switcher li:first-child {
  border-left: none;
}

.gallery .switcher li:last-child {
  border-right: none;
}

.gallery .switcher a {
  margin: -4px 0;
  display: block;
  padding: 9px 5px;
  text-align: center;
  color: #333;
}

.gallery .switcher a:before {
  display: inline-block;
  vertical-align: middle;
  content: "";
  width: 1px;
  height: 100%;
  margin-right: -4px;
}

.gallery .switcher span {
  display: inline-block;
  vertical-align: middle;
}

.gallery .switcher li:first-child a {
  border-bottom-left-radius: 6px;
}

.gallery .switcher li:last-child a {
  border-bottom-right-radius: 6px;
}

.gallery .switcher a:hover,
.gallery .switcher .active a {
  color: #fff;
  text-decoration: none;
  background: #168dbf;
}

.btn-holder {
  text-align: center;
}

.hover-tooltip {
  background: #fff;
  border: 1px solid #e3e3e3;
  max-width: 200px;
  min-width: 50px;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  z-index: 1002;
}

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  cursor: pointer;
  overflow: hidden;
  z-index: 1104;
  display: none;
}

#fancybox-loading div {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 480px;
  background-image: url(/dist/images/fancybox/fancybox.png);
}

#fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1100;
  display: none;
}

#fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  overflow: auto;
  display: none;
}

#fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  z-index: 1101;
  outline: none;
  display: none;
}

#fancybox-outer {
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.6);
}

#fancybox-content {
  width: 0;
  height: 0;
  padding: 0;
  outline: none;
  position: relative;
  overflow: hidden;
  z-index: 1102;
  border: 0 solid #fff;
}

#fancybox-hide-sel-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 1101;
}

#fancybox-close {
  position: absolute;
  top: -15px;
  right: -15px;
  width: 30px;
  height: 30px;
  background: transparent url(/dist/images/fancybox/fancybox.png) -40px 0px;
  cursor: pointer;
  z-index: 1103;
  display: none;
}

#fancybox-error {
  color: #444;
  font: normal 12px/20px Arial;
  padding: 14px;
  margin: 0;
}

#fancybox-img {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  line-height: 0;
  vertical-align: top;
}

#fancybox-frame {
  width: 100%;
  height: 100%;
  border: none;
  display: block;
}

#fancybox-left,
#fancybox-right {
  position: absolute;
  bottom: 0px;
  height: 100%;
  width: 35%;
  cursor: pointer;
  outline: none;
  background: transparent url(/dist/images/fancybox/blank.gif);
  z-index: 1102;
  display: none;
}

#fancybox-left {
  left: 0;
}

#fancybox-right {
  right: 0;
}

#fancybox-left-ico,
#fancybox-right-ico {
  position: absolute;
  top: 50%;
  left: -9999px;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  cursor: pointer;
  z-index: 1102;
  display: block;
}

#fancybox-left-ico {
  background-image: url(/dist/images/fancybox/fancybox.png);
  background-position: -40px -30px;
}

#fancybox-right-ico {
  background-image: url(/dist/images/fancybox/fancybox.png);
  background-position: -40px -60px;
}

#fancybox-left:hover span {
  left: 20px;
}

#fancybox-right:hover span {
  left: auto;
  right: 20px;
}

.fancybox-bg {
  display: none;
}

#fancybox-title {
  font-size: 12px;
  z-index: 1102;
}

.fancybox-title-inside {
  padding-bottom: 10px;
  text-align: center;
  color: #333;
  background: #fff;
  position: relative;
}

.fancybox-title-outside {
  padding-top: 10px;
  color: #fff;
}

.fancybox-title-over {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  text-align: left;
}

#fancybox-title-over {
  padding: 10px;
  background-image: url(/dist/images/fancybox/fancy_title_over.png);
  display: block;
}

.fancybox-title-float {
  position: absolute;
  left: 0;
  bottom: -20px;
  height: 32px;
}

#fancybox-title-float-wrap {
  border: none;
  border-collapse: collapse;
  width: auto;
}

#fancybox-title-float-wrap td {
  border: none;
  white-space: nowrap;
}

#fancybox-title-float-left {
  padding: 0 0 0 15px;
  background: url(/dist/images/fancybox/fancybox.png) -40px -90px no-repeat;
}

#fancybox-title-float-main {
  color: #fff;
  line-height: 29px;
  font-weight: bold;
  padding: 0 0 3px 0;
  background: url(/dist/images/fancybox/fancybox-x.png) 0px -40px;
}

#fancybox-title-float-right {
  padding: 0 0 0 15px;
  background: url(/dist/images/fancybox/fancybox.png) -55px -90px no-repeat;
}

.me-plugin {
  position: absolute;
}

.media .mejs-container {
  height: 100% !important;
  width: 100% !important;
  position: relative;
}

.mejs-background {
  position: absolute;
  top: 0;
  left: 0;
}

.mejs-mediaelement {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mejs-container .mejs-controls {
  padding: 39px 6px 5px;
  height: 19px;
  position: relative;
}

.mejs-controls .mejs-button {
  float: left;
}

.mejs-controls .mejs-button button {
  cursor: pointer;
  display: block;
  font-size: 0;
  line-height: 0;
  text-decoration: none;
  margin: 0;
  padding: 0;
  height: 19px;
  width: 19px;
  border: 0 !important;
  background: url(/dist/images/transparent.png);
  position: relative;
  z-index: 5;
  font-size: 14px;
  line-height: 16px;
  padding: 10px 15px;
  display: inline-block;
  vertical-align: top;
  border: 1px solid #578fbb;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: none;
  min-width: 0px;
}

.mejs-controls .mejs-button button:focus {
  outline: 0px;
}

.mejs-container .mejs-controls .mejs-currenttime-container,
.mejs-container .mejs-controls .mejs-duration-container {
  display: none;
}

.mejs-controls .mejs-play button:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  border: solid transparent;
  border-width: 5px 0 5px 10px;
  border-left-color: #606769;
  margin: -5px 0 0 -5px;
}

.mejs-controls .mejs-pause button:before,
.mejs-controls .mejs-pause button:after {
  content: "";
  border: none;
  height: 10px;
  width: 3px;
  background: #606769;
  top: 50%;
  left: 50%;
  margin: -5px 0 0 -4px;
  position: absolute;
}

.mejs-controls .mejs-pause button:after {
  margin-left: 0;
}

.mejs-controls div.mejs-time-rail {
  float: left;
  padding: 7px 0 7px 7px;
  border-left: 1px solid #d0d0d0;
  box-shadow: inset 1px 0 0 0 #fff;
  position: relative;
}

.mejs-controls .mejs-time-rail span {
  position: absolute;
  width: 72px;
  height: 2px;
  border-radius: 2px;
  cursor: pointer;
  border: 1px solid transparent;
}

.mejs-controls .mejs-time-rail .mejs-time-total {
  position: static;
  display: block;
  background: #9d9d9d;
  border: none;
  height: 4px;
}

.mejs-controls .mejs-time-rail .mejs-time-buffering {
  width: 100%;
  background-image: linear-gradient(-45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
  background-size: 15px 15px;
  -webkit-animation: buffering-stripes 2s linear infinite;
  animation: buffering-stripes 2s linear infinite;
}

@-webkit-keyframes buffering-stripes {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 30px 0;
  }
}

@keyframes buffering-stripes {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 30px 0;
  }
}

.mejs-controls .mejs-time-rail .mejs-time-loaded {
  background: #b4b4b4;
  width: 0;
}

.mejs-controls .mejs-time-rail .mejs-time-current {
  width: 0;
  background: #4983ad;
  border-color: #407297;
}

.mejs-controls .mejs-time-rail .mejs-time-handle {
  display: none;
}

.mejs-controls .mejs-time-rail .mejs-time-float {
  position: absolute;
  display: none;
  background: #eee;
  width: 36px;
  height: 17px;
  border: solid 1px #333;
  top: -16px;
  margin-left: -18px;
  text-align: center;
  color: #111;
  z-index: 10;
}

.mejs-controls .mejs-time-rail .mejs-time-float-current {
  margin: 2px;
  width: 30px;
  display: block;
  text-align: center;
  left: 0;
  height: auto;
}

.mejs-controls .mejs-time-rail .mejs-time-float-current:after,
.mejs-controls .mejs-time-rail .mejs-time-float-current:before {
  position: absolute;
  width: 0;
  height: 0;
  border: solid transparent;
  border-width: 5px 5px 0;
  border-radius: 0;
  top: 100%;
  left: 50%;
  border-top-color: #333;
  margin-left: -5px;
  content: "";
}

.mejs-controls .mejs-time-rail .mejs-time-float-current:after {
  border-top-color: #eee;
  margin-top: -1px;
}

.mejs-controls .mejs-time-rail .mejs-time-float-corner {
  display: none;
}

.mejs-controls .mejs-volume-button {
  position: absolute;
  top: 6px;
  left: 5px;
  right: 5px;
}

.mejs-controls .mejs-volume-button button {
  display: block;
  height: 28px;
  width: 36px;
  margin: 0 auto;
  padding: 0;
  border: none;
  background: url(/dist/images/sprite11.png) no-repeat -402px -56px;
}

.mejs-controls .mejs-unmute button {
  background-position: -402px -85px;
}

.mejs-clear {
  clear: both;
}

.mejs-controls .mejs-horizontal-volume-slider {
  display: none;
}

.me-cannotplay a {
  color: #fff;
  font-weight: bold;
}

.me-cannotplay span {
  padding: 15px;
  display: block;
}

.zoomPad {
  position: relative;
  z-index: 99;
  cursor: zoom-in;
  max-width: 100%;
}

.zoomPreload {
  display: none;
  color: #333;
  font-size: 12px;
  text-decoration: none;
  border: 1px solid #ccc;
  background: #fff;
  padding: 8px;
  text-align: center;
  z-index: 110;
  width: 90px;
  height: 43px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.zoomPup {
  overflow: hidden;
  background-color: #fff;
  position: absolute;
  border: 1px solid #ccc;
  z-index: 101;
  cursor: zoom-in;
  opacity: 0.6;
  filter: alpha(opacity=60);
}

.zoomOverlay {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 101;
}

.zoomWindow {
  position: absolute;
  left: 110%;
  top: 40px;
  background: #fff;
  height: auto;
  z-index: 10000;
}

.zoomWrapper {
  position: relative;
  border: 1px solid #999;
  z-index: 110;
}

.zoomWrapperTitle {
  display: block;
  background: #999;
  color: #fff;
  height: 18px;
  line-height: 18px;
  width: 100%;
  overflow: hidden;
  text-align: center;
  font-size: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 120;
  opacity: 0.6;
  filter: alpha(opacity=60);
}

.zoomWrapperImage {
  display: block;
  position: relative;
  overflow: hidden;
  z-index: 110;
}

.zoomWrapperImage img {
  border: 0;
  display: block;
  position: absolute;
  z-index: 101;
}

.zoomIframe {
  z-index: -1;
  position: absolute;
  display: block;
  filter: alpha(opacity=0);
  opacity: 0.8;
}

.generic-table th {
  color: #fff;
  font-size: 0.98em;
  font-weight: 700;
  font-style: italic;
  line-height: 1.167em;
  text-align: left;
  margin: 0 0 13px;
  padding: 9px 8px 7px;
  border-left: 1px solid #4078a4;
  background-color: #168dbf;
}

.generic-table th.grey {
  color: #333;
  border-color: #63656a;
  background-color: #ededed;
}

.generic-table th:first-child {
  border-left: 0;
}

.generic-table th.pnl-hd {
  text-align: left;
}

.generic-table td {
  text-align: left;
  padding: 10px 0 10px 7px;
}

.generic-table td.reference {
  font-weight: bold;
  font-style: normal;
  text-decoration: underline;
}

.generic-table tr:nth-child(even) {
  background-color: #eee;
}

.generic-table tr.product-list p {
  font-style: italic;
  font-weight: bold;
  text-align: left;
  margin: 0 0 0 5%;
}

.generic-table.my-account-orders-table {
  border-collapse: initial;
}

.generic-table tr.blank-row {
  border: none;
  box-shadow: none;
  background-color: white;
}

.generic-table tr.blank-row td {
  box-shadow: none;
  padding: 10px 0 3px 7px;
}

#nws-pge h2#main-news-header {
  font-weight: 700;
  line-height: 1.167em;
  margin: 0px 0px 8px 0px;
  padding: 9px 0px 7px 0px;
  font-size: 1.3em;
  line-height: 1.167em;
  clear: both;
}

.nws-bx-gry h3 {
  color: #434649;
  font-size: 1.3em;
  font-weight: 700;
  line-height: 1.167em;
  text-align: center;
  margin: 0 0 13px;
  padding: 9px 6px 7px;
  border-radius: 3px 3px 0 0;
  background-color: #e5e5e5;
}

#nws-pge #nws-pge-r {
  float: left;
  width: 190px;
  margin-left: -200px;
  padding-left: 10px;
}

#nws-pge-mobile-categories,
.nws-select-wrapper {
  padding: 5px 10px;
  width: 100%;
  text-align: center;
}

#nws-page-b {
  width: 100%;
}

#nws-pge-r h5 {
  font-size: 100%;
}

#nws-pge #rec-nws,
#nws-pge #rel-nws {
  width: 49%;
  float: left;
}

#nws-pge #nws-pge-wrapper {
  float: left;
  width: 100%;
}

#nws-pge #rel-nws {
  margin-right: 2%;
}

#nws-pge div.newsArticleImage {
  float: left;
  max-width: 200px;
  margin: 8px;
  background-color: #168dbf;
  border-radius: 6px 6px 6px 6px;
  text-align: center;
  color: #fff;
}

#nws-pge .newsArticleImage img {
  display: block;
  margin: 0 auto;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  height: 200px;
  width: 200px;
}

#nws-pge .newsArticleImageTextBlock {
  display: block;
  font-weight: bold;
  padding: 10px;
  font-size: 86.5%;
}

#nws-pge .aright {
  text-align: right;
}

#nws-pge .nws-item {
  width: 250px;
  margin: 10px;
  height: 200px;
  vertical-align: top;
  display: inline-block;
  zoom: 1;
  position: relative;
  overflow: hidden;
  text-align: left;
}

#nws-pge .news-article {
  padding-top: 15px;
}

#nws-pge .nws-item img {
  padding: 12px 0 0 5px;
}

#nws-pge .nws-items {
  text-align: center;
  -ms-text-justify: distribute-all-lines;
  text-justify: distribute-all-lines;
  overflow-y: auto;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#nws-pge .nws-date {
  vertical-align: baseline;
}

#nws-pge #nws-slct {
  max-width: 300px;
  margin: 0 auto;
}

#nws-pge #nws-slct select {
  width: 140px;
}

#nws-pge .news-select-label {
  display: block;
  padding-left: 5px;
  padding-bottom: 3px;
}

#nws-pge .nws-bx-main-article>div {
  padding: 3px 7px;
}

#nws-pge .rel-link {
  color: #41464a;
}

#nws-pge .rel-date {
  color: #7195b5;
}

#nws-pge .nws-bx-wt {
  clear: both;
  margin: 5px 10px;
  overflow: hidden;
  padding: 0px 5px;
  border-bottom: 1px solid #d5d5d5;
}

#nws-pge .nws-bx-wt:last-child {
  border-bottom: 0;
}

#nws-pge .news-summary {
  font-style: italic;
}

#nws-pge h4 {
  margin: 0;
}

#nws-pge .nws-bx-main-article a {
  color: #000;
  font-weight: bold;
}

@media screen and (max-width: 909px) {

  #nws-pge #rec-nws,
  #nws-pge #rel-nws {
    width: 100%;
  }
}

#nws-pge .other-news img {
  height: 75px;
  width: 75px;
}

#refine-news h5 {
  padding: 10px 0;
  margin: 0;
}

#refine-news ul {
  list-style-type: none;
  padding: 15px 4px;
  margin: 0px;
  color: #7195b5;
  text-decoration: underline;
}

#refine-news a {
  color: #7195b5;
}

#refine-news .learn-more {
  padding: 7px 0px;
}

#refine-news .str_news_period_div {
  padding: 10px 5px;
}

#refine-news .underline {
  border-bottom: 1px solid #d5d5d5;
  box-shadow: none;
}

#information-wrapper .grey-div a:hover {
  text-decoration: none;
}

#information-wrapper li:hover {
  background-color: #e0e0e0 !important;
  background-color: linear-gradient(to bottom, #fcfcfc 0%, #f6f6f6 100%) repeat scroll 0 0 transparent !important;
}

h1.information-header {
  border: medium none;
  display: block;
  font-size: 2.5em;
  line-height: 1.3em;
  font-weight: 700;
  border-bottom: 1px solid #e4e4e4;
  border-top: 1px solid #e4e4e4;
  margin: 0 0 10px;
  padding: 7px 0 6px;
  color: #44484b;
}

#information-wrapper .small-column {
  min-width: 220px;
  width: 46%;
}

#information-wrapper .small-column h3.header {
  margin-bottom: 0;
}

#information-wrapper .small-column ul {
  margin: 0;
}

#information-wrapper .small-column li {
  padding-top: 5px;
}

#information-wrapper .double-column {
  max-width: 94%;
  width: 100%;
}

#information-wrapper .grey-div li {
  border-top: 0;
  font-weight: normal;
}

#information-wrapper .grey-div li a {
  color: #444549;
  float: left;
  padding: 5px 4px 0 60px;
  position: relative;
  display: block;
  height: 50px;
}

#information-wrapper .grey-div li a i {
  position: absolute;
  left: 15px;
  display: inline-block;
}

#information-wrapper .grey-div li a>span {
  display: inline-block;
}

#information-wrapper .grey-div.small-column li {
  overflow: hidden;
  display: block;
}

#information-wrapper .grey-div li {
  border-bottom: 1px solid #ececec;
  box-shadow: 0 1px 0px #fff;
}

#information-wrapper .grey-div li:last-child {
  border-bottom: 0;
  box-shadow: 0 0;
}

#information-wrapper .grey-div h4 {
  margin: 0px;
  color: #444549;
}

#information-wrapper .grey-div h4:after {
  content: ">>";
  font-size: 60%;
  margin-left: 6px;
  color: #444549;
}

#information-wrapper .grey-div.double-column h4:before {
  margin-right: 5px;
  color: #848889;
}

#information-wrapper .grey-div {
  display: inline-block;
  margin: 0 1% 20px;
  text-align: left;
  vertical-align: text-top;
}

#information-wrapper {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  vertical-align: top;
}

#information-wrapper ul {
  padding: 0;
}

#information-wrapper .double-column ul {
  margin: 5px 5px 20px;
  overflow: hidden;
  width: 100%;
  display: inline-block;
  vertical-align: top;
}

#information-wrapper .double-column li {
  min-width: 155px;
  margin: 4px 1%;
  display: inline-block;
  padding: 5px 1px;
  height: 20px;
}

#information-wrapper .double-column li h4 {
  padding: 1px;
}

#information-wrapper #nav-breadcrumbs a {
  color: #aaa;
}

#information-wrapper #nav-breadcrumbs {
  padding: 5px 0;
  font-size: 120%;
}

.information-table {
  table-layout: fixed;
  width: 100%;
  color: #000;
  font-size: 11px;
}

.information-table td {
  padding: 6px !important;
  border: 1px solid #fff;
}

#google_map {
  height: 250px;
  margin: 15px 0px;
}

#google_map button {
  min-width: auto;
}

.contact-us-page>div {
  width: 50%;
  box-sizing: border-box;
  vertical-align: top;
  display: inline-block;
  float: left;
}

.contact-us-page>div:first-of-type {
  width: 49%;
  margin-right: 1%;
}

.contact-us-page .grey-div {
  margin: 0px 2% 15px 0px;
}

.contact-us-page .grey-background {
  margin-bottom: 15px;
  background-repeat: no-repeat;
  background-position: 10px 15px;
}

.telephone-icon {
  background-image: url(/dist/images/contactus-telephone.png);
  background-position: 13px 15px;
}

.email-icon {
  background-image: url(/dist/images/contactus-email.png);
}

.service-icon {
  background-image: url(/dist/images/service-icon-grn.png);
}

.clock-icon {
  background-image: url(/dist/images/contactus-clock.png);
}

.contact-us-page h2 {
  font-size: 17px;
  margin: 0;
}

.contact-us-page .large {
  font-size: 17px;
}

.contact-us-page .form-row {
  float: left;
  margin: 2px 0px;
  width: 75%;
}

.contact-us-page .large-input {
  width: 100%;
}

.contact-us-page .phone-fine-print {
  display: block;
  font-size: 0.8em;
}

#basket-page .button {
  margin: 0 0 10px 0;
  box-sizing: border-box;
}

#payment-page input::-webkit-input-placeholder {
  color: #999;
}

#payment-page input:-moz-placeholder {
  color: #999;
}

#payment-page input::-moz-placeholder {
  color: #999;
}

#payment-page input:-ms-input-placeholder {
  color: #999;
}

.basket-icon:before {
  content: "";
  position: absolute;
  z-index: 1;
  width: 26px;
  height: 22px;
  margin-left: -35px;
  margin-top: 10px;
  background: url(/dist/images/sprite11.png) no-repeat scroll 0 0 transparent;
  background-position: -39px -79px;
}

h3.header.collapsable-mobile {
  position: relative;
}

.products {
  margin-bottom: 15px;
}

.chkt-wrp button,
.chkt-wrp .button {
  text-transform: uppercase;
}

.small-input {
  position: relative;
}

.small-input>dd {
  width: 30%;
}

.order-success-offset {
  margin-right: 15px;
}

@media screen and (max-width: 1229px) {
  .order-success-button-container {
    display: flex;
    flex-direction: column-reverse;
  }

  .order-success-button-container .btn {
    margin-top: 10px;
  }
}

.icon-indent {
  padding-left: 50px;
}

#basket-page h2 {
  font-size: 150%;
}

.basket-image-cell {
  max-width: 100px;
  text-align: center;
  padding: 4px !important;
  margin-bottom: 0 !important;
}

.basket-image-cell img {
  width: 60px;
  border: 1px solid #e6e6e6;
}

.not-registered {
  padding: 10px 10px 23px;
}

#basket-summary-table td {
  padding: 10px 0 5px 0;
}

.generic-table,
#basket-summary-table,
#basket-delivery,
#basket-total {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  background: none repeat scroll 0 0 #f6f6f6;
  border-bottom: 3px solid #e6e6e6;
  margin: 15px 0;
  overflow: hidden;
  border-radius: 2px;
}

.optional-extra,
.price,
.price-large {
  font-weight: 700;
  color: #97bc3c;
}

.price-large {
  font-size: 150%;
}

.total-title {
  font-size: 1.5em;
}

.green,
#basket-summary-table tr.tablePromotionLine td {
  color: #97bc3c;
}

span.wnty-incd {
  color: #97bc3c;
  display: block;
}

.generic-table tbody tr td,
#basket-summary-table tbody tr.product-listing td,
#basket-summary-table tbody tr.delivery-info td,
#basket-summary-table tbody tr#total-info td {
  border-top: 1px solid #ececec;
  box-shadow: 0 1px 0 0 #fff inset;
  margin: 1.5em 4px;
}

#basket-summary-table td.ord-rw-tot,
#basket-summary-table td.delivery-price {
  padding-left: 5px;
}

#basket-summary-table td.aright_tbl {
  text-align: right !important;
  padding-right: 2% !important;
}

#total-info {
  font-size: 150%;
  font-weight: bold;
}

#total-info td {
  vertical-align: top;
}

.generic-table tbody tr:first-child td,
#basket-summary-table tbody tr:first-child td {
  border: none;
  box-shadow: none;
}

#basket-summary-table .extended-warranty-summary {
  border: none !important;
  box-shadow: none !important;
  color: inherit;
}

#basket-page #basket-date-picker {
  width: 33%;
  float: left;
}

#delivery_date_calendar {
  width: 99% !important;
  margin: 0 auto;
  text-align: center;
}

#delivery_date_calendar.calendar-loading {
  min-height: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#delivery_date_calendar.calendar-loading:before {
  content: "";
  width: 30px;
  height: 30px;
  background-image: url(/dist/images/svgs/icon-loader.svg);
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-animation: calendar-loading 1.6s infinite linear;
  animation: calendar-loading 1.6s infinite linear;
}

@-webkit-keyframes calendar-loading {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes calendar-loading {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#basket-page .pnl-bd {
  vertical-align: top;
}

#basket-page #basket-delivery .pnl-bd {
  text-align: right;
}

#basket-page .pnl-bd>div {
  text-align: left;
}

#basket-delivery-options {
  width: auto;
  max-width: 62%;
  min-width: 62%;
  padding: 2px 10px 0 0;
}

#basket-delivery-options .aright {
  text-align: right !important;
}

#basket-delivery-options .aleft {
  text-align: left !important;
}

#basket-delivery-options .aright_tbl {
  text-align: right !important;
  padding-right: 4%;
}

#basket-page #basket-date-picker {
  padding: 2px 1.5%;
}

#basket-total #totals {
  font-weight: 700;
  line-height: 20px;
  padding-right: 40px;
  padding-bottom: 20px;
  min-width: 250px;
}

#basket-summary-table .basket-delete {
  width: 32px;
  text-align: center;
  max-width: 32px;
  vertical-align: top !important;
}

.product-listing {
  vertical-align: top;
}

.product-listing .product-title {
  color: inherit;
  font-weight: 700;
}

.product-listing .item-price {
  font-weight: 700;
  font-size: 1.1em;
}

.product-listing strong .c-val,
.product-listing strong .c-sym,
.product-listing strong .c-vat {
  font-weight: 700;
}

.product-listing .c-val,
.product-listing .c-sym,
.product-listing .c-vat {
  font-weight: normal;
}

.product-listing .item-price .c-val,
.product-listing .item-price .c-sym,
.product-listing .item-price .c-vat {
  font-weight: 700;
}

#basket-summary-table thead th {
  background: #ededed;
  background: linear-gradient(to bottom, #ededed 0%, #e9e9e9 100%) repeat scroll 0 0 transparent;
  color: #595c5e;
  font-weight: 700;
  line-height: 1.167em;
  margin: 0 0 13px;
  padding: 9px 6px 7px;
  text-align: 5px 5px;
  font-size: 0.98em;
  line-height: 1.167em;
  text-align: center;
  font-style: italic;
  border: 1px solid #e3e3e3;
}

#basket-summary-table span.pnl-hd {
  font-weight: bold;
}

#basket-summary-table .bsk-rw-tot {
  text-align: center;
}

.total-padding {
  padding-right: 90px !important;
}

#chkt-del-opt td.sc_price.selected {
  background-image: url(/dist/images/tick.png);
  background-position: 9999999990% top;
  background-repeat: no-repeat;
}

#basket-delivery-options .sc_price {
  font-weight: bold;
  padding-right: 52px;
  white-space: nowrap;
}

#basket-page h3.header,
#payment-page h3.header {
  font-size: 110%;
  text-align: left;
}

#basket-page .grey-div {
  border: 1px solid #e6e6e6;
  border-bottom: 3px solid #e3e3e3;
}

#basket-summary-table {
  background: #f6f6f6;
  border-bottom: 3px solid #e3e3e3;
  margin-bottom: 0px;
}

#basket-summary-table .total-sub-title {
  font-size: 1em;
  font-weight: normal;
  margin: 0;
  display: block;
}

#table-total .total-sub-title {
  font-size: 1em;
  font-weight: bold;
  margin: 0;
  clear: both;
  display: block;
  padding: 6px 0 0;
}

.generic-table thead tr:first-child th:first-child,
#basket-summary-table thead tr:first-child th:first-child {
  border-top-left-radius: 3px;
}

#basket-summary-table thead tr:first-child th:first-child {
  text-align: left;
  font-size: 110%;
}

.generic-table thead tr:first-child th:last-child,
#basket-summary-table thead tr:first-child th:last-child {
  border-top-right-radius: 3px;
}

.item-totals-td.is-custom-bundle {
  display: flex;
  align-items: flex-start;
}

#basket-page .ui-datepicker-title {
  background: #3f77a2;
  background: linear-gradient(to bottom, #6aabd0 0%, #3f77a2 100%) repeat scroll 0 0 transparent;
  color: #fff;
}

.generic-table thead th:first-child,
#basket-summary-table thead th:first-child {
  font-style: normal;
}

h3.header {
  font-style: normal;
}

.border-bottom {
  border-bottom: 1px solid #e0e0e0;
}

.sc-helper-text {
  background-position: left top;
  background-repeat: no-repeat;
  clear: left;
  font-size: 90%;
  padding: 5px 0 0 3px;
}

#basket-dispatch-info {
  text-align: right;
  padding-top: 20px;
  position: absolute;
  bottom: 10px;
  padding-right: 60px;
  width: 100%;
  font-size: 90%;
}

#service-category-list-container {
  padding-bottom: 7px;
  position: relative;
  min-height: 239px;
}

#basket-delivery-options td {
  padding: 7px 0px 7px 11px;
}

.display-block {
  display: block;
}

#basket-empty {
  padding: 5px !important;
  font-weight: 700;
}

#header-wrapper {
  vertical-align: middle;
  height: 50px;
}

#header-wrapper h2 {
  line-height: 45px;
}

#promoform {
  float: left;
}

.sc_input,
.sc_price {
  width: 15%;
  text-align: center;
}

.sc_price {
  min-width: 40px;
}

.sc_input,
.sc_price>label {
  text-align: center;
}

#chkt-del-opt {
  margin: 0 auto;
  border-collapse: collapse;
  width: 100%;
}

#chkt-del-opt tr.collect-in-store {
  background-image: url(/dist/images/bg_collect_in_store.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: right top;
  width: 145%;
  display: block;
}

#chkt-del-opt tr.collect-in-store label {
  color: #fff;
  display: block;
  padding-top: 15px;
  padding-left: 5%;
  font-weight: bold;
  font-size: 1.2em;
}

#chkt-del-opt tr.collect-in-store input[type="radio"] {
  padding-top: 15px;
  display: block;
  margin-top: 15px;
  margin-left: 40%;
}

#chkt-del-opt td.sc_price.selected {
  background-position: 90% 49%;
}

#dt-pck {
  margin: 0 auto;
}

.two-column,
.two-column-separate {
  width: 100%;
  position: relative;
  height: inherit;
}

.two-column>div {
  width: 43%;
  display: inline-block;
  vertical-align: top;
  padding: 2px 3%;
  margin: 0 auto;
}

.two-column-separate>div {
  width: 49%;
  vertical-align: top;
  margin: 0px auto;
  padding: 0px 0px 5px;
  display: inline-block;
}

.padding10 {
  padding: 10px;
}

.padding-spacious {
  padding: 20px;
}

.column>div {
  padding: 3px;
  clear: both;
}

#payment-details {
  width: 100%;
}

#payment-details .two-column>div {
  width: 46%;
}

#payment-details .two-column>div:first-of-type {
  width: 40%;
}

#payment-page {
  width: 100%;
}

#payment-page .row-info.product-listing td {
  border-top: none;
  box-shadow: none;
}

#payment-page .split-title-read-only {
  border-bottom: none;
  padding: 10px 20px 0px 20px;
}

#payment-page .product-listing {
  vertical-align: middle;
}

#payment-page .product-listing .custom-bundle-list {
  padding-top: 2px;
}

#payment-page .product-listing span.weee_desc {
  font-weight: normal;
  margin-bottom: 10px;
  display: block;
}

#payment-page #checkout_payment {
  width: 100%;
}

#payment-page #checkout_payment .errors {
  color: #ff0000;
  margin-left: 15px;
  width: 80%;
}

#payment-page #checkout_payment #payment_method_21 .errors {
  width: 100%;
  margin-left: 0;
  padding-left: 0;
}

.pymt-opt,
.disabled-pymt-opt {
  padding: 0 0 20px;
  text-align: left;
  display: flex;
}

.pymt-opt:is(.pymt-opt-8, .pymt-opt-17)+.pymt-opt,
.disabled-pymt-opt:is(.pymt-opt-8, .pymt-opt-17)+.pymt-opt {
  margin-top: -7px;
}

.pymt-opt input.int_payment_method_group_id[type="radio"],
.disabled-pymt-opt input.int_payment_method_group_id[type="radio"] {
  cursor: pointer;
  display: block;
  margin: 12px 10px 0 0;
  position: absolute;
}

.pymt-opt input.int_payment_method_group_id[type="radio"]#pymt_method_30,
.disabled-pymt-opt input.int_payment_method_group_id[type="radio"]#pymt_method_30 {
  margin: 14px 10px 0 0;
}

.pymt-opt input.int_payment_method_group_id[type="radio"]#pymt_method_6,
.disabled-pymt-opt input.int_payment_method_group_id[type="radio"]#pymt_method_6 {
  margin: 13px 10px 0 0;
}

.pymt-opt label.int_payment_method_group_label,
.disabled-pymt-opt label.int_payment_method_group_label {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-left: 22px;
}

.pymt-opt label.int_payment_method_group_label span>img,
.disabled-pymt-opt label.int_payment_method_group_label span>img {
  cursor: pointer;
  margin-right: 7px;
}

.pymt-opt .detail,
.disabled-pymt-opt .detail {
  font-size: 0.9em;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.pymt-opt .detail output,
.disabled-pymt-opt .detail output {
  color: #97bc3c;
  font-weight: bold;
}

.pymt-opt span,
.disabled-pymt-opt span {
  cursor: pointer;
  display: table-cell;
  line-height: 2;
}

.pymt-opt span.pymt-name,
.disabled-pymt-opt span.pymt-name {
  display: inline-block;
}

.disabled-pymt-opt {
  flex-flow: wrap;
}

.disabled-pymt-opt img {
  margin-left: 7px;
}

.disabled-pymt-opt label {
  width: 100%;
}

.pymt-opt-bt-list {
  margin-top: -20px;
  padding-left: 0;
}

.pymt-opt-bt-list li {
  transform-style: preserve-3d;
  display: block;
  float: left;
  height: 40px !important;
  margin: 0 10px 1px 0;
  list-style-type: none;
}

.pymt-opt-bt-list li img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-width: 70px !important;
  max-height: 30px;
  cursor: pointer;
}

.payment-status {
  width: 100%;
  padding: 8px 0;
}

.pymt-grouping-header {
  margin-left: -5px;
  padding-left: 1px;
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 2px #cccccc solid;
  font-size: 12px;
}

.pymt-grouping-header.pymt-grp-first {
  padding-top: 10px;
  border-top: 0 none;
}

#payment_method_8 div dt,
#payment_method_17 div dt,
#payment_method_21 div dt {
  float: left;
  width: 38%;
  padding: 3px 1%;
  text-align: right;
}

#payment_method_8 div dd,
#payment_method_17 div dd,
#payment_method_21 div dd {
  float: left;
  width: 60%;
}

#payment_method_8 div dd input[type="checkbox"],
#payment_method_17 div dd input[type="checkbox"],
#payment_method_21 div dd input[type="checkbox"] {
  margin-top: 3px;
}

#payment_method_8 div .ver-nbr-inf,
#payment_method_17 div .ver-nbr-inf,
#payment_method_21 div .ver-nbr-inf {
  text-align: right;
}

#payment_method_8 div>div,
#payment_method_17 div>div,
#payment_method_21 div>div {
  padding: 4px 1%;
}

#payment_method_8 div>.registered-card-address,
#payment_method_8 div>.registered-card-address-address-lines,
#payment_method_8 div>.g4m-checkbox,
#payment_method_17 div>.registered-card-address,
#payment_method_17 div>.registered-card-address-address-lines,
#payment_method_17 div>.g4m-checkbox,
#payment_method_21 div>.registered-card-address,
#payment_method_21 div>.registered-card-address-address-lines,
#payment_method_21 div>.g4m-checkbox {
  padding: 0;
}

#payment_method_8 #new_card_details select,
#payment_method_17 #new_card_details select,
#payment_method_21 #new_card_details select {
  width: 67px;
}

.clearfix:before,
.site-header .secure-checkout-wrapper:before,
.clearfix:after,
.site-header .secure-checkout-wrapper:after,
.purchase-info .info-row .info-row-stock:after {
  content: " ";
  display: table;
}

.clearfix:after,
.site-header .secure-checkout-wrapper:after,
.purchase-info .info-row .info-row-stock:after {
  clear: both;
}

#payment_method_21 #finance-klarna input[type="text"],
#payment_method_21 #finance-klarna input[type="password"],
#payment_method_21 #finance-klarna select {
  width: 100%;
  box-sizing: border-box;
}

#payment_method_21 #finance-klarna input[type="checkbox"] {
  margin-right: 10px;
}

#payment_method_21 #finance-klarna .container {
  padding: 0 1%;
  max-width: 300px;
}

#payment_method_21 #finance-klarna .container .header {
  padding: 0;
}

#payment_method_21 #finance-klarna .container+.container {
  margin-top: 5px;
  padding-top: 5px;
}

#payment_method_21 #finance-klarna .container ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#payment_method_21 #finance-klarna .container ul li {
  margin: 0;
  padding: 0;
  line-height: normal;
}

#payment_method_21 #finance-klarna .container ul li+li {
  margin-top: 5px;
}

#payment_method_21 #finance-klarna .container.breakdown .product li,
#payment_method_21 #finance-klarna .container.extra-info li {
  margin: 4px 0;
  min-height: 2em;
  line-height: 2em;
}

#payment_method_21 #finance-klarna .container.breakdown .product li:last-child,
#payment_method_21 #finance-klarna .container.extra-info li:last-child {
  margin-bottom: 0;
}

#payment_method_21 #finance-klarna .container.breakdown .product li label,
#payment_method_21 #finance-klarna .container.extra-info li label {
  float: left;
  padding-right: 10px;
  box-sizing: border-box;
}

#payment_method_21 #finance-klarna .container.breakdown .product li output,
#payment_method_21 #finance-klarna .container.extra-info li output {
  display: block;
  font-weight: bold;
}

#payment_method_21 #finance-klarna .container.breakdown input[type="radio"] {
  width: 20px;
}

#payment_method_21 #finance-klarna .container.breakdown .products>li+li {
  border-top: 1px solid #e9e9e9;
  padding-top: 5px;
}

#payment_method_21 #finance-klarna .container.breakdown .products select,
#payment_method_21 #finance-klarna .container.breakdown .products .product,
#payment_method_21 #finance-klarna .container.breakdown .products #klarna-use-case {
  margin-left: 20px;
  display: block;
}

#payment_method_21 #finance-klarna .container.breakdown .products select {
  margin-top: 5px;
  width: calc(100% - 20px);
}

#payment_method_21 #finance-klarna .container.breakdown .products .product li output {
  text-align: right;
}

#payment_method_21 #finance-klarna .container.extra-info {
  border-top: 1px solid #e9e9e9;
}

#payment_method_21 #finance-klarna .container.extra-info li {
  margin: 5px 0;
}

#payment_method_21 #finance-klarna .container.extra-info li label {
  width: 58%;
  font-weight: bold;
}

#payment_method_21 #finance-klarna .container.extra-info li input,
#payment_method_21 #finance-klarna .container.extra-info li select {
  width: 40%;
}

#payment_method_21 #finance-klarna .container .terms {
  margin: 0;
}

#payment_method_21 #klarna-finance-product {
  width: 100%;
  max-width: none;
}

#payment_method_21 #klarna-addresses p {
  padding: 0;
}

#payment_method_21 #klarna-addresses ol {
  list-style-type: none;
  padding: 0;
  margin: 5px 0;
}

#payment_method_21 #klarna-addresses ol li {
  margin: 2px 0;
}

#payment_method_21 #klarna-addresses ol li:last-child {
  margin-bottom: 0;
}

#payment_method_21 #klarna-addresses ol li label {
  margin-left: 5px;
}

#afm-warning {
  margin: 0 20px;
}

#afm-warning img,
#afm-warning table {
  display: inline-block;
  vertical-align: top;
}

#afm-warning table {
  width: 80%;
  width: calc(100% - 101px - 10px - 2px);
  margin-left: 10px;
  text-align: center;
}

#afm-warning table thead tr:nth-child(2) {
  width: 10%;
}

#afm-warning table thead tr:nth-child(1),
#afm-warning table thead tr:nth-child(6) {
  width: 15%;
}

#afm-warning table thead tr:nth-child(3),
#afm-warning table thead tr:nth-child(4),
#afm-warning table thead tr:nth-child(5) {
  width: 20%;
}

.payment_method_details dt {
  position: relative;
}

.ver-nbr-inf {
  position: absolute;
  min-width: 60px;
  right: 5px;
  top: -8px;
}

#str_verification_number-element input,
#str_issue_number-element input {
  width: 28%;
}

.full-wrapper {
  width: 100%;
  padding: 5px 0;
  clear: both;
}

#chkt-del-opt .sc_input {
  padding: 0;
}

#chkt-del-opt .sc_description_toggle {
  padding: 0;
  cursor: pointer;
  width: 23px;
  width: 0;
}

#chkt-del-opt .sc_description_toggle div {
  height: 100%;
}

#chkt-del-opt .sc_description_toggle div span {
  cursor: pointer;
}

#chkt-del-opt .sc-web-dsc-cursor {
  position: relative;
  display: table-cell;
  width: 23px;
  height: 100%;
}

#chkt-del-opt .sc-web-dsc-cursor:before {
  content: "";
  border-style: solid;
  position: absolute;
  width: 0;
  height: 0;
  top: 2px;
  left: 6px;
  border-width: 9.6px 6px 0;
  border-color: #606769 transparent;
}

#chkt-del-opt .sc-web-dsc-cursor.sc-web-dsc-cursor-expanded:before {
  border-width: 0 6px 9.6px;
  border-color: #606769 transparent;
}

#chkt-del-opt .sc_input:checked {
  display: none;
}

#chkt-del-opt .sc_description {
  vertical-align: middle;
  padding: 0;
  padding-left: 11px;
}

#chkt-del-opt .sc_description label {
  width: 100%;
  display: block;
}

#chkt-del-opt .sc_description label div {
  display: table;
  width: initial;
  cursor: pointer;
  margin: 3px 0;
}

#chkt-del-opt .svc-desc-mobile td {
  background-color: lightgrey;
  padding-left: 2em;
  padding-right: 3px;
}

#chkt-del-opt .svc-desc-mobile td ul {
  padding-left: 2em;
}

#chkt-del-opt .svc-desc-mobile.hidden-svc-desc-mobile {
  display: none;
}

#chkt-del-opt .svc-desc-mobile {
  display: none;
}

#chkt-del-opt tr.selected-row {
  background-color: #e9e9e9;
}

.reg-cmp-lnk.input-sub {
  text-align: left;
  padding-left: 35%;
}

.reg-cmp-lnk.input-sub p {
  padding-left: 0;
}

#reg-cmp-dtl-lnk {
  color: inherit;
  text-decoration: underline;
}

#pymt-slct-wrapper {
  max-width: 350px;
  margin: 0 auto;
}

#pymt-slct {
  margin-left: 30px;
}

#pymt-slct .pymt-opt-21 img,
#pymt-slct .pymt-opt-27 img {
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
}

#chk-comp-msg {
  padding: 15px 0px;
}

#chk-comp-headline {
  text-transform: uppercase;
  font-size: 18px;
  margin-top: 11px;
}

#pymt-actn {
  margin: 0 auto;
}

#pymt-actn #finance-duration {
  margin: -4px 0 0;
  padding: 2px 40px 2px 10px;
  position: absolute;
  right: 0;
}

#pymt-actn>div {
  display: none;
}

#pymt-actn>div:first-of-type {
  display: block;
}

#payment-status .two-column-separate>div {
  margin: 0 0 15px 0;
}

#pymt-actn ul.details li.option div.g4m-select::after {
  margin-top: 7px;
}

#pymt-actn ul.details li.option label {
  float: left;
  padding: 4px 10px 0 0;
}

.delivery-title {
  text-align: right;
}

#str_mobile_number {
  width: 200px;
}

.strong {
  font-weight: 700;
}

.fright-mobile {
  float: none;
}

.reg-bnr-cnt {
  padding: 4px 20px;
}

.reg-bnr-cnt-opt {
  margin: 12px 0 0 0;
  padding: 4px 20px;
}

#reg-form-email-consent {
  margin-right: 6px;
}

@-moz-document url-prefix() {
  #reg-form-email-consent {
    vertical-align: middle;
  }
}

.style-alt .row .col-xs-12.optional-details-email-consent {
  margin-bottom: 25px;
}

.style-alt .panel .panel-body.panel-body-form .row .pull-right dd,
.style-alt .panel .panel-body.panel-body-form-modal .row .pull-right dd {
  float: right !important;
}

.style-alt .row.optional-details-privacy-terms {
  margin: 0;
  padding: 0 20px;
}

.centred-msg {
  display: block;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 0 5% 0 5%;
}

.two-column-separate .separator {
  width: 0.8%;
  display: inline-block;
}

.two-column-separate {
  margin: 10px 0px;
}

.single-column {
  margin: 0 3%;
}

.details dt {
  width: 150px;
  float: left;
  font-weight: bold;
}

.details dd {
  float: left;
}

.details {
  margin: 0px 10px 7px 10px;
}

.details>div {
  clear: both;
  padding: 5px 0px;
}

button.green {
  margin: 10px 0px;
}

#header-wrapper h2.non-eu-header {
  line-height: 150%;
}

.non-eu-price {
  color: #97bc3c;
}

#non-eu-warning .non-eu-price {
  color: #d80c0d;
}

.non-eu-ex-local {
  color: #d48646;
}

.bol_visual #basket_total_grand {
  color: #d48646;
}

#totals .basket_total_with_local_taxes {
  font-size: 2em;
  padding-left: 15px;
}

#basket_local_taxes {
  color: #d80c0d;
  padding-left: 3px;
}

.ver-nbr-inf a,
.ver-nbr-inf span {
  font-size: 75%;
  color: #000;
}

.checkout-helper {
  font-size: 75%;
}

.checkout-helper a {
  color: #000;
}

.checkout-helper .more-info-icon {
  margin-left: 2px;
}

.checkout-helper .tooltip-target .arrow-right,
.checkout-helper .tooltip-target-desktop .arrow-right {
  margin-left: -6px;
}

.g4m-checkbox-label .checkout-helper {
  display: inline-block;
}

.g4m-checkbox-label .checkout-helper .more-info-icon {
  margin: -3px 3px 0 10px;
}

.progress-map {
  width: 344px;
  height: 33px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  margin-left: -25px;
}

.progress-map>.progress-bar {
  width: 244px;
  height: 33px;
  background-image: url(/dist/images/progress-bar.png);
  background-repeat: repeat-x;
  background-position: left center;
  margin: 0 -42px 0 42px;
}

.progress-map>.progress-bar a,
.progress-map>.progress-bar span {
  height: 35px;
  width: 82px;
  float: left;
  background-image: url(/dist/images/progress-block.png);
  background-repeat: no-repeat;
  background-position: center center;
  display: block;
}

.progress-map>.progress-bar .selected {
  background-image: url(/dist/images/progress-block-selected.png);
}

.progress-map>.progress-bar *:first-child {
  width: 40px;
  background-position: left center;
}

.progress-map>.progress-bar .last {
  width: 40px;
  background-position: right center;
}

.progress-map>.progress-text {
  width: 100%;
}

.progress-map>.progress-text>a,
.progress-map>.progress-text>span {
  width: 82px;
  float: left;
  text-align: center;
  display: block;
  color: #000;
}

.progress-map>.progress-text>a:hover {
  text-decoration: none;
}

.progress-map>.progress-text>.selected {
  color: #97bc3c;
}

.mobile-login-form {
  clear: both;
  margin: 30px 0 0;
}

.mobile-login-form .grey-div {
  margin: 0;
}

#basket-page .grey-button {
  background: none repeat scroll 0 0 #f6f6f6;
  border-bottom: 1px solid #e3e3e3;
  border-radius: 3px;
  box-shadow: 1px 1px 0 0 #fff inset;
  font-size: 0.91em;
  padding: 10px 0px;
  text-align: center;
  color: #000;
  font-weight: 700;
  width: 49%;
  margin: 3px 0;
  overflow: hidden;
  height: inherit;
  float: left;
  margin-right: 2%;
}

#basket-page .grey-button:last-of-type {
  margin-right: 0%;
}

.details>.underline {
  float: left;
  width: 100%;
}

#payment-status {
  padding-bottom: 10px;
}

#register-section-content,
.register-login-dsktp {
  margin: 15px 0 5px;
}

#register-section-content .column {
  margin: 0;
}

.pca-lookup-container dl {
  margin-bottom: 0;
}

.reg-form-email-consent-container {
  padding-top: 20px;
}

.reg-form-email-consent-container p {
  margin-bottom: 0;
}

.chg-sc-msg {
  padding: 0 10px 10px 10px;
}

.basket-box-content {
  margin: 0 3%;
}

.header-mobile-visual-ex {
  font-size: 1.25em;
}

.header-mobile-visual-ex .price {
  color: #d48646;
}

.vis-tax-p {
  float: left;
  padding: 0 0 10px;
}

#int_stored_card {
  max-width: 250px;
}

#payment-page .grey-div {
  overflow: inherit !important;
}

.bank-map-selector {
  margin-left: 10px;
}

.bank-map-selector:disabled {
  background: #eee;
}

.no-left-padding {
  padding-left: 0;
}

#basket-summary-table .wnty-rw td {
  padding: 0px;
}

#basket_local_taxes {
  float: right;
}

.table-total.bol_visual {
  overflow: hidden;
  margin: 0 0 4px;
}

.quotation-conf {
  font-size: 30px;
}

.sz-vat-message h3 {
  font-weight: normal;
}

#basket-summary-table tr.tablePromotionLine td {
  font-weight: bold;
}

.disabled-pymt-opt .disabled-pymt-opt-label {
  color: #999;
  display: inline-block;
  margin: 0;
  padding-left: 15px;
}

.disabled-pymt-opt .disabled-pymt-opt-label span img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.5;
  cursor: not-allowed;
}

.disabled-pymt-opt-more {
  margin: 5px 0 0 22px;
}

.disabled-pymt-opt-message {
  padding: 10px 0 20px 0;
  color: #999;
}

.disabled-pymt-opt .disabled-pymt-opt-message {
  padding: 5px 0 0 22px;
}

.checkout-details-address-container {
  padding: 15px 15px 10px;
  border-bottom: 1px solid #ececec;
  box-shadow: inset 0 1px 0 0 #fff;
}

.checkout-details-address-container:last-child {
  border-bottom: none;
  box-shadow: none;
}

.sc-selection-order-details-container {
  margin-bottom: 20px;
}

.sc-selection-order-details-container .g4m-order-details-container .table.table-g4m-basket .collapsed-row {
  height: 0;
}

.sc-selection-order-details-container .product-listing td.remove-line-cell:last-child:after {
  content: "";
  display: block;
  width: 11px;
}

#basket-container>div:first-child>.border-bottom {
  margin-bottom: 17px;
}

.pnl.panel-address-context-change-message {
  background-color: #f6f6f6;
}

.pnl.panel-address-context-change-message .header {
  color: #595c5e;
  font-size: 14px;
  padding: 8px 15px;
  margin: 0;
  background-color: #ededed;
}

.pnl.panel-address-context-change-message .chg-sc-msg {
  font-size: 13px;
  padding: 8px 15px;
}

.pnl.panel-address-context-change-message.split-type-single {
  margin-bottom: 0;
  border-radius: 3px 3px 0 0;
}

.return-to-basket-message {
  margin-top: 17px;
}

.return-to-basket-message a {
  color: #168dbf;
}

#password-info-txt>.password-info-align {
  max-width: calc(120px + 65%);
  padding-left: 0;
  padding-right: 3px;
}

.form-checkout select,
.form-login select {
  background-color: #fff;
}

.new-card-details {
  margin: 0 0 12px;
}

.payment_method_details .checkbox.g4m-checkbox {
  margin: 12px 0 0;
}

.payment_method_details .checkbox.g4m-checkbox label.g4m-checkbox-inner {
  display: block;
}

.payment_method_details .checkbox.g4m-checkbox .cr {
  margin-top: -2px;
}

.registered-card-address {
  margin-top: 12px;
}

#payment_method_33 .additional-payment-details .registered-card-address:first-child {
  margin-top: 0;
}

.registered-card-address>* {
  font-size: 12px;
  line-height: 17px;
}

.registered-card-address .registered-card-address-label,
.registered-card-address .registered-card-address-address-lines {
  margin-bottom: 3px;
  display: block;
}

.registered-card-address .registered-card-address-label {
  font-weight: 700;
}

.registered-card-address .change-billing-address {
  display: inline-block;
}

p.terms-and-conditions {
  width: 100%;
  margin-top: 20px;
  color: #9b9b9b;
}

p.terms-and-conditions a {
  font-weight: bold;
  color: #83888b;
  text-decoration: underline;
}

.feature--plp p.terms-and-conditions {
  margin-top: 20px;
}

.paypal-checkout-btn.paypal-checkout-btn.paypal-checkout-btn {
  background-color: #ffc439;
  text-transform: none;
  line-height: 24px;
  color: #45494b;
  font-size: 1.4rem;
  padding-left: 32px;
  padding-right: 32px;
}

.paypal-checkout-btn.paypal-checkout-btn.paypal-checkout-btn>span {
  line-height: normal;
  display: inline-block;
  vertical-align: middle;
}

.paypal-checkout-btn.paypal-checkout-btn.paypal-checkout-btn>span.payment-provider-logo.pay-pal {
  margin-left: 4px;
}

.paypal-checkout-btn.paypal-checkout-btn.paypal-checkout-btn:hover {
  background-color: #f6b343;
}

.payment-provider-logo {
  display: inline-block;
  background-image: url(/dist/images/payment/paypal-rect-sm.png);
  background-repeat: no-repeat;
  height: 0;
  overflow: hidden;
  direction: ltr;
  text-indent: -9999px;
}

.payment-provider-logo.pay-pal {
  width: 75px;
  padding-top: 18px;
}

.enter-new-card-trigger {
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  color: #09c;
}

.enter-new-card-trigger:hover {
  text-decoration: none;
}

#payment-page input.cc-number::-webkit-input-placeholder {
  font-size: 2.2rem;
}

#payment-page input.cc-number::-moz-placeholder {
  font-size: 2.2rem;
}

#payment-page input.cc-number:-ms-input-placeholder {
  font-size: 2.2rem;
}

#payment-page input.cc-number::-ms-input-placeholder {
  font-size: 2.2rem;
}

#payment-page input.cc-number::placeholder {
  font-size: 2.2rem;
}

#payment-page input.error,
#payment-page select.error {
  border-color: #d00000;
  color: #d00000;
}

#payment-page div.error {
  color: #d00000;
  display: none;
  padding-top: 5px;
}

.mt0 {
  margin-top: 0;
}

.mb0 {
  margin-bottom: 0;
}

.form-checkout .btn-container .checkout-btn {
  margin-bottom: 10px;
}

.form-checkout .checkout-btn {
  min-width: 205px;
}

.margin-bottom-small+.checkout-information-block,
.style-alt .checkout-success-thank-you+.checkout-information-block {
  margin-top: 1.2em;
}

.checkout-information-block .title-order-details {
  margin: 0 0 0.2em;
}

.checkout-information-block p:last-child {
  margin-bottom: 0;
}

.promo-code {
  font-size: 12px;
  font-weight: normal;
  float: left;
  padding-left: 5%;
}

.promo-code .promo-code-opener,
.promo-code .promo-code-content {
  padding: 8px 22px 8px 0;
}

.promo-code .promo-code-opener {
  position: relative;
  color: #434649;
  text-decoration: none;
}

.promo-code .promo-code-opener.active:before {
  border-width: 0 5px 8px;
  border-bottom-color: #606769;
  border-top-color: transparent;
}

.promo-code .promo-code-content input#promocode {
  max-width: 100%;
  width: auto;
  border-radius: 3px;
  box-shadow: none;
  border: 1px solid #eee;
  font-size: 12px;
  line-height: 16px;
  padding: 2px;
  margin-top: 10px;
  margin-right: 7px;
}

.promo-code .promo-code-content .add-promo,
.promo-code .promo-code-content .rem-promo {
  text-transform: uppercase;
  min-width: 40px;
  padding: 3px 8px;
  margin-top: 8px;
}

.halfwl,
.halfwr {
  width: 49%;
  float: left;
}

.halfwl {
  margin-right: 2%;
}

.wpn-half-content {
  padding: 0 20px;
}

.prd-info {
  overflow: hidden;
}

.prd-cntr .prd-name {
  max-height: 2.7em;
  overflow: hidden;
  padding: 0 10px 10px 10px;
}

.wgt-sct.wgt-ttl table {
  width: 100%;
}

@media screen and (min-width: 1336px) {
  .prd-cntr .prd-name {
    padding: 10px;
  }
}

.centered-list {
  width: 230px;
  margin: 0 auto;
  list-style: none;
}

.centered-list li {
  padding: 3px 0;
}

.centered-list input {
  float: right;
}

.pref-update-success {
  font-size: 16px;
  margin: 0 40px 0 10px;
  line-height: 40px;
  position: relative;
}

.pref-update-success::before {
  content: "";
  width: 15px;
  height: 15px;
  display: block;
  position: absolute;
  background: url(/dist/images/sprite4.png) no-repeat scroll 0 0 transparent;
  background-position: -127px -79px;
  border: 0;
  right: -20px;
  top: 12px;
}

.unsubscribe_input {
  padding: 10px;
  margin: 0 auto;
  width: 80%;
  display: block;
}

.unsubscribe_input {
  width: 50%;
}

.login-page-two-col>div {
  width: 50%;
  box-sizing: border-box;
  vertical-align: top;
  float: left;
}

.login-page-two-col {
  clear: both;
}

.login-page-two-col>div:first-of-type {
  width: 49%;
  margin-right: 1%;
}

.login-page-two-col,
.guest-register-block {
  margin: 0px 0px 20px 0px;
  width: 100%;
}

.login-page-one-col .login-form label {
  padding-right: 1%;
  text-align: right;
}

.login-page-one-col .login-form label,
.login-page-two-col .login-form label {
  width: 33%;
  float: left;
  display: block;
}

.login-page-one-col .login-form input,
.login-page-two-col .login-form input {
  width: 60%;
}

.login-page-two-col .grey-div {
  clear: both;
  min-height: 130px;
}

.padding-login-fix>label {
  padding-top: 3px;
}

.login-form {
  float: left;
  margin: 0 0 20px;
  width: 100%;
}

.login-form>div {
  padding: 2px 0px 10px;
  clear: both;
}

.login-form-content {
  padding: 10px;
}

.guest-register-block {
  clear: left;
}

#qck-lin {
  margin: 10px 0px;
}

#qck-lin .btn-wrp {
  text-align: center;
  padding: 4px 0;
}

#qck-lin>input,
#qck-lin>button {
  margin: 4px 0px;
}

#qck-lin>input::-webkit-input-placeholder {
  color: #999;
  padding: 3px;
  font-size: 80%;
}

#qck-lin>input:-moz-placeholder {
  color: #999;
  padding: 3px;
  font-size: 80%;
}

#qck-lin>input::-moz-placeholder {
  color: #999;
  padding: 3px;
  font-size: 80%;
}

#qck-lin>input:-ms-input-placeholder {
  color: #999;
  padding: 3px;
  font-size: 80%;
}

.sidebar-box #qck-lin {
  border-bottom: 1px solid #ddd;
  box-shadow: none;
}

#mini-login-forgot-icon {
  background: url("/dist/images/sprite11.png") no-repeat 0 -39px;
  background-size: 229px 115px;
  width: 9px;
  height: 11px;
  float: left;
  margin: 2px 5px 2px 0px;
}

#mini-login .links a {
  color: #83888b;
}

#mini-login-register-icon {
  background: url("/dist/images/sprite11.png") no-repeat -9px -39px;
  background-size: 229px 115px;
  width: 10px;
  height: 11px;
  float: left;
  margin: 2px 5px 2px 0px;
}

.login-form .errors {
  color: #ff0000;
  margin-left: 29%;
  margin-top: 0;
  padding-left: 40px;
}

.login-page-one-col .login-form label {
  display: block;
  float: left;
}

.margin-bottom-responsive {
  margin-bottom: 15px;
}

a.icon-lang:before {
  background-position: 0 0;
  background-size: 560px 28px;
  border-radius: 100%;
  content: " ";
  display: inline-block;
  height: 28px;
  width: 28px;
}

.no-webp a.icon-lang:before,
.no-js a.icon-lang:before {
  background-image: url("/dist/images/flag_sprites_v4.1.png?v=1");
}

.webp a.icon-lang:before {
  background-image: url("../assets/img/flag_sprites_v4.webp?v=1");
}

a.icon-lang.en:before,
a.icon-lang.com:before {
  background-position: 0 0;
}

a.icon-lang.at:before {
  background-position: -28px 0;
}

a.icon-lang.be:before {
  background-position: -84px 0;
}

a.icon-lang.ch:before {
  background-position: -252px 0;
}

a.icon-lang.cz:before {
  background-position: -112px 0;
}

a.icon-lang.de:before {
  background-position: -196px 0;
}

a.icon-lang.dk:before {
  background-position: -56px 0;
}

a.icon-lang.es:before {
  background-position: -476px 0;
}

a.icon-lang.fi:before {
  background-position: -140px 0;
}

a.icon-lang.fr:before {
  background-position: -168px 0;
}

a.icon-lang.ie:before {
  background-position: -224px 0;
}

a.icon-lang.it:before {
  background-position: -280px 0;
}

a.icon-lang.nl:before {
  background-position: -308px 0;
}

a.icon-lang.no:before {
  background-position: -336px 0;
}

a.icon-lang.pl:before {
  background-position: -364px 0;
}

a.icon-lang.pt:before {
  background-position: -392px 0;
}

a.icon-lang.se:before {
  background-position: -504px 0;
}

a.icon-lang.si:before {
  background-position: -448px 0;
}

a.icon-lang.sk:before {
  background-position: -420px 0;
}

a.icon-lang.us:before {
  background-position: -532px 0;
}

.icon-lang {
  cursor: pointer;
}

.modal-content button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}

.modal-content button,
input {
  line-height: normal;
}

.modal-content button,
select {
  text-transform: none;
}

.modal-content button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

.modal-content button[disabled],
html input[disabled] {
  cursor: default;
}

.modal-content button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.img-rounded {
  border-radius: 6px;
}

.img-thumbnail {
  padding: 4px;
  line-height: 1.428571429;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.img-circle {
  border-radius: 50%;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}

.modal-open {
  overflow: hidden;
}

body.modal-open,
.modal-open .navbar-fixed-top,
.modal-open .navbar-fixed-bottom {
  margin-right: 15px;
}

.modal {
  display: none;
  overflow: auto !important;
  overflow-y: scroll !important;
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  z-index: 1040 !important;
}

.modal.fade .modal-dialog {
  transform: translate(0, -25%) !important;
  transition: transform 0.3s ease-out !important;
}

.modal.in .modal-dialog {
  transform: translate(0, 0) !important;
}

.modal-dialog {
  margin-left: auto !important;
  margin-right: auto !important;
  width: auto !important;
  padding: 10px !important;
  z-index: 1050 !important;
}

.modal-backdrop {
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  z-index: 1030 !important;
  background-color: #fff !important;
}

.modal-backdrop.fade {
  opacity: 0 !important;
  filter: alpha(opacity=0);
}

.modal-backdrop.in {
  opacity: 0.5 !important;
  filter: alpha(opacity=50);
}

.modal-content {
  position: relative !important;
  background-color: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px !important;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5) !important;
  background-clip: padding-box !important;
  outline: none !important;
  padding: 24px !important;
}

.modal-header {
  display: flex !important;
  align-items: center !important;
}

.modal-header .close {
  min-width: 18px;
  height: 18px;
  width: 18px;
  background-image: url(/dist/images/svgs/modal-close.svg);
  background-color: #fff;
  background-repeat: no-repeat;
}

.modal-title {
  margin: 0;
  line-height: 1.2;
  font-size: 16px;
  flex-grow: 1;
}

.modal-body {
  position: relative;
  overflow: hidden;
}

.modal-footer {
  margin-top: 15px;
  padding: 19px 20px 20px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

.modal-footer:before,
.modal-footer:after {
  content: " ";
  display: table;
}

.modal-footer:after {
  clear: both;
}

.modal-footer .btn+.btn {
  margin-left: 5px;
  margin-bottom: 0;
}

.modal-footer .btn-group .btn+.btn {
  margin-left: -1px;
}

.modal-footer .btn-block+.btn-block {
  margin-left: 0;
}

.modal-dialog {
  right: auto !important;
  width: 700px !important;
  padding-top: 80px !important;
  padding-bottom: 30px !important;
  max-width: auto !important;
}

.modal-content {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.country-list {
  margin-left: 0;
}

.modal-content .country-list .lang-wrapper {
  height: 44px;
  border-bottom: 1px dotted #e5e5e5;
  margin-right: 16px;
  padding: 10px 0;
  display: block;
}

.country-list li {
  width: 100%;
}

.lang-column {
  display: inline-block;
  vertical-align: top;
}

.lang-column-3 {
  width: 32%;
}

.lang-column-5 {
  width: 19.5%;
}

.lang-column-4 {
  width: 24.5%;
}

.country-list strong {
  display: block;
  font-size: 12px;
  padding: 5px 0 8px;
}

.country-list a {
  font-size: 12px;
  text-transform: uppercase;
  margin: 0 10px 0 0;
}

.country-list a:hover,
.country-list .active {
  text-decoration: underline;
  color: #ff9600;
}

.country-list .lang-wrapper:hover {
  background-color: #f6f6f6;
  cursor: pointer;
}

.modal-content button.close {
  float: right;
  cursor: pointer;
  -webkit-appearance: none;
  border: none;
}

.lang-wrapper:before {
  left: 6px;
}

.twitter-typeahead {
  float: left;
  width: 100%;
}

.mm-slideout {
  z-index: auto !important;
}

@media only screen and (max-width: 767px) {
  .country-list a span {
    padding: 2px 8px 2px 8px;
    text-decoration: underline;
    font-size: 14px;
    display: inline-block;
    min-width: 22px;
  }

  .twitter-typeahead {
    width: 95%;
  }

  .modal-open {
    overflow: hidden;
  }

  body.modal-open,
  .modal-open .navbar-fixed-top,
  .modal-open .navbar-fixed-bottom {
    margin-right: 0;
  }

  .modal-body {
    padding: 8px 0 20px;
  }

  .modal.fade .modal-dialog {
    transform: none;
    transition: none;
    opacity: 1;
  }

  .modal.in .modal-dialog {
    transform: none;
  }

  .country-list {
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }

  .country-list a {
    margin: 0 18px 0 0;
  }

  .country-list strong {
    padding: 5px 0 12px;
    margin-top: -7px;
  }

  .modal-backdrop {
    display: none;
  }

  .fade {
    display: none;
  }

  .lang-column-3 {
    width: 100%;
  }

  .twitter-typeahead {
    float: left;
    width: 100%;
  }

  .modal-content .country-list .lang-wrapper {
    margin: 0 3px 0 3px;
    padding: 6px 0;
  }
}

#ajax-loader {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  background: rgba(0, 0, 0, 0.5) url(/dist/images/loading-animation.gif) center center no-repeat;
}

#mb2_home_slide1,
#mb2_home_slide2,
#mb2_home_slide3 {
  cursor: pointer;
}

.merchandisingBannerContainer {
  margin: -4px 0px 8px;
  clear: both;
  padding-bottom: 4px;
}

#merchandising_banner {
  position: relative;
  height: 230px;
  overflow: hidden;
  font-size: 15px;
  min-width: 560px;
  padding: 0;
  margin: 0;
}

#merch_banner_min_width_enforcer {
  width: 598px;
  height: 1px;
  margin: 0;
  padding: 0;
  display: inline;
}

#merchandising_banner img {
  border: none;
}

.merch_banner_content_image {
  padding-left: 10px;
  padding-top: 10px;
}

#merchandising_banner a {
  text-decoration: none;
  color: black;
}

#merchandising_banner h1 {
  margin: 0;
  padding: 0 5px 5px 5px;
}

#merch_banner_corners_left,
#merch_banner_corners_right {
  z-index: 10;
}

#merch_banner_corners_left {
  position: absolute;
  top: 0;
  left: 0;
}

#merch_banner_corners_right {
  position: absolute;
  top: 0;
  right: 0;
}

.merch_banner_sash {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}

.merch_banner_mid_image_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#merchandising_banner table {
  text-align: center;
}

#merch_banner_headings {
  width: 40%;
}

#merch_banner_headings,
#merch_banner_content {
  height: 206px;
}

#merch_banner_headings_container {
  height: 100% !important;
  width: 100% !important;
  z-index: 1;
}

.merch_banner_headings_slide {
  height: 100% !important;
  width: 100% !important;
}

.merch_banner_mid_banner {
  height: 50px;
}

.merch_banner_point_of_action {
  height: 30%;
  font-weight: bold;
}

.merch_banner_main_heading_large {
  font-weight: bold;
}

.merch_banner_main_heading_small {
  font-weight: normal;
  letter-spacing: -1px;
}

#merch_banner_content_container {
  height: 100% !important;
  width: 100% !important;
}

.merch_banner_content_slide {
  height: 100% !important;
  width: 100% !important;
  background: #fff;
}

#merch_banner_content_container a:hover {
  text-decoration: underline;
}

.merch_banner_title_wrapper {
  font-weight: bold;
  padding: 10px 2px 5px 10px;
  line-height: 22px;
  font-size: 0.75em;
}

.merch_banner_product_short_descs_1 {
  font-size: 14px;
}

.merch_banner_product_titles_1,
.merch_banner_product_titles_2 {
  font-weight: bold;
  padding-top: 5px;
}

.merch_banner_product_titles_2 {
  font-size: 0.8em;
}

.merch_banner_product_titles_4 {
  font-size: 0.7em;
  font-weight: bold;
}

.merch_banner_product_short_descs_1 {
  padding-top: 5px;
}

.merch_banner_product_prices_1,
.merch_banner_product_prices_2 {
  padding-top: 5px;
  color: orange;
  font-weight: bold;
}

.merch_banner_product_prices_2 {
  font-size: 0.9em;
}

.merch_banner_product_prices_4 {
  font-size: 0.8em;
  color: orange;
  font-weight: bold;
}

#merch_banner_labels {
  height: 24px;
  text-align: left;
  z-index: 1;
}

#merch_banner_labels_wrapper {
  position: relative;
  height: 100%;
}

#merch_banner_labels_container {
  height: 100%;
  z-index: 3;
}

#merch_banner_labels_container,
#merch_banner_label_pointer_container {
  position: absolute;
  top: 0;
  left: 0;
  text-align: left;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#merch_banner_label_pointer {
  position: relative;
  top: 0;
  left: 0;
  z-index: 2;
}

.merch_banner_product_short_descs_1001,
.merch_banner_product_titles_1001 a,
.merch_banner_product_titles_12 a,
.merch_banner_product_titles_13 a,
.merch_banner_product_titles_14 a,
.merch_banner_product_prices_1001 .from,
.merch_banner_product_prices_12 .from,
.merch_banner_product_prices_13 .from,
.merch_banner_product_prices_14 .from {
  color: #505050 !important;
}

.merch_banner_product_image_cell_1001 {
  padding: 0px;
}

.merch_banner_product_titles_1001 {
  font-weight: bold;
  padding: 5px;
  height: 34px;
  overflow: hidden;
}

.merch_banner_product_short_descs_1001 {
  padding-top: 5px;
  height: 58px;
  overflow: hidden;
}

.merch_banner_product_prices_1001 .from,
.merch_banner_product_prices_1001 .pounds,
.merch_banner_product_prices_1001 .pennys {
  letter-spacing: -2px;
}

.merch_banner_product_prices_1001 .from {
  padding-top: 5px;
  font-weight: normal;
}

.merch_banner_product_prices_1001 .pounds {
  padding-top: 5px;
  color: #ff9600;
  font-weight: bold;
  font-size: 1.9em;
}

.merch_banner_product_prices_1001 .pennys {
  padding-top: 5px;
  color: #ff9600;
  font-weight: normal;
  font-size: 0.43em;
}

.merch_banner_product_details_cell_1001 {
  padding: 4px;
  text-align: left !important;
}

.merch_banner_product_prices_12 .from {
  font-weight: normal;
  font-size: 0.9em;
}

.merch_banner_product_prices_12 .pounds {
  color: #ff9600;
  font-weight: bold;
  font-size: 1.75em;
}

.merch_banner_product_prices_12 .pennys {
  color: #ff9600;
  font-weight: normal;
  font-size: 0.44em;
}

.merch_banner_product_prices_12 .from,
.merch_banner_product_prices_12 .pounds,
.merch_banner_product_prices_12 .pennys {
  letter-spacing: -2px;
}

.merch_banner_product_titles_12 {
  font-weight: bold;
  padding: 3px;
  font-size: 0.8em;
  height: 33px;
  overflow: hidden;
}

.merch_banner_product_prices_13 .from {
  font-weight: normal;
  font-size: 0.73em;
}

.merch_banner_product_prices_13 .pounds {
  color: #ff9600;
  font-weight: bold;
  font-size: 1.45em;
}

.merch_banner_product_prices_13 .pennys {
  color: #ff9600;
  font-weight: normal;
  font-size: 0.43em;
}

.merch_banner_product_prices_13 .from,
.merch_banner_product_prices_13 .pounds,
.merch_banner_product_prices_13 .pennys {
  letter-spacing: -2px;
}

.merch_banner_product_titles_13 {
  font-weight: bold;
  padding: 3px;
  font-size: 0.75em;
  height: 26px;
  overflow: hidden;
}

.merch_banner_product_prices_14 .from {
  font-weight: normal;
  font-size: 0.75em;
}

.merch_banner_product_prices_14 .pounds {
  color: #ff9600;
  font-weight: bold;
  font-size: 1.3em;
}

.merch_banner_product_prices_14 .pennys {
  color: #ff9600;
  font-weight: normal;
  font-size: 0.5em;
}

.merch_banner_product_prices_14 .from,
.merch_banner_product_prices_14 .pounds,
.merch_banner_product_prices_14 .pennys {
  letter-spacing: -1px;
}

.merch_banner_product_titles_14 {
  font-weight: bold;
  padding: 3px;
  font-size: 0.75em;
  height: 50px !important;
  overflow: hidden;
}

#top-brands {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 706px;
  width: 100%;
}

#top-brands>.title {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

#top-brands>.btn-holder {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 55px;
  padding: 10px;
}

#top-brands>.brands {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

#top-brands .brand {
  flex: 1 1 auto;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  padding: 0 !important;
  height: 75px;
}

#top-brands .brand:before {
  content: none;
}

#top-brands .brand-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

#top-brands .brand-img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

ul#brand_pages_anchor_block {
  clear: both;
  list-style: none;
  margin: 10px 0px;
  padding: 10px;
}

#shop_by_brands a {
  color: black;
}

#shop_by_brands ul {
  float: left;
  list-style: none;
  margin: 10px 10px;
  min-width: 175px;
  overflow: auto;
  padding: 0 0 0 10px;
}

#shop_by_brands h3 {
  background-color: #f6f6f6;
  border-bottom: 1px dotted #e1e1e1;
  border-top: 1px dotted #e1e1e1;
  clear: both;
  font-size: 30px;
  margin: 10px 0;
  padding: 5px 20px;
}

#brand_pages_anchor_block li a {
  border: 1px solid #fff;
  background-color: #fff;
  box-shadow:
    2px 2px 2px #c7c7c7,
    -2px -2px 2px #f2f2f2;
  color: #8c9093;
  display: block;
  font-size: 12px;
  font-weight: bold;
  height: 18px;
  line-height: 18px;
  margin: 2px;
  padding: 3px;
  text-align: center;
  width: 18px;
}

#brand_pages_anchor_block li {
  float: left;
}

.vip-container {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
  padding: 0 6px 0 0;
}

@media screen and (min-width: 767px) {
  .vip-container {
    background-image: url(/dist/images/vip-background-1x.jpg);
    margin: 0 auto;
    max-width: 960px;
  }
}

@media screen and (max-width: 767px) {
  .vip-container {
    background-image: url(/dist/images/vip-background-2x.jpg);
  }
}

#vip-lin {
  position: relative;
  height: 270px;
  width: 253px;
  margin: 0 auto;
  padding: 330px 0 0;
  text-align: center;
  box-sizing: initial;
}

#vip-lin label {
  color: white;
  display: block;
  height: 20px;
  text-transform: uppercase;
  font-size: 1.1em;
  margin: 0 0 3px;
}

#vip-lin label.remember-me-label {
  color: #9a978e;
  display: inline-block;
}

#vip-lin .vip-field {
  height: 32px;
  width: 100%;
  background: transparent;
  border: 0;
  margin: 0 0 11px;
  padding: 0;
  text-indent: 10px;
}

#vip-lin .button.vip {
  width: 100%;
  margin: 13px 0 0;
  height: 35px;
  text-transform: uppercase;
}

#vip-lin .forgotten-password {
  color: #d4b569;
  display: block;
  margin: 4px 0 0;
  font-size: 1.1em;
  text-decoration: underline;
  text-transform: uppercase;
}

#vip-lin .forgotten-password.error {
  color: red;
}

#vip-lin .vip_login_failure {
  position: absolute;
  top: 311px;
  width: 253px;
  color: red;
}

#vip-lin .more-info-icon {
  background: url(/dist/images/sprite11.png) no-repeat -242px -118px;
}

.financing .heading,
.financing .threshold {
  line-height: 17px;
}

.financing .heading:before,
.financing .threshold:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: 17px;
  height: 17px;
  top: -1px;
  background: url(/dist/images/money-pound-icon.png) center center no-repeat;
}

.financing .heading {
  margin: 10px 0;
  font-weight: bold;
  padding-left: 21px !important;
}

.financing.product p.threshold {
  margin: 4px 0;
}

.financing.basket {
  float: left;
}

.financing.basket .heading {
  position: relative;
}

.financing.basket .expand-content {
  margin-bottom: 10px;
}

.financing.checkout p {
  padding: 0;
  margin-bottom: 10px;
}

.financing.checkout p:last-child {
  margin-bottom: 0;
}

.financing .information table {
  margin: 10px 0;
}

.financing .information p,
.financing .information .header {
  margin: 10px 2px;
}

.financing .information p:first-child,
.financing .information .header:first-child {
  margin-top: 0;
}

.financing .information th,
.financing .information td {
  padding: 4px;
  text-align: center;
}

.financing .information th {
  background-color: #555;
  color: #fff;
}

.financing .information tr {
  background-color: #e9e9e9;
}

.financing .information tr:nth-child(even) {
  background-color: #fafafa;
}

.financing .information .header {
  font-weight: bold;
}

.financing ul.details {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.financing ul.details li {
  margin: 4px 0;
  min-height: 2em;
  line-height: 2em;
}

.financing ul.details label {
  float: left;
  padding-right: 10px;
}

.financing ul.details output {
  display: block;
  font-weight: bold;
  text-align: right;
}

.financing ul.details select,
.financing ul.details #example-order-value {
  max-width: 100%;
  width: auto;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid #eee;
  font-size: 12px;
  line-height: 16px;
  padding: 2px;
}

.financing ul.details #show-figures-for {
  display: inline-block;
}

.financing ul.details #show-figures-for option:disabled {
  background-color: #e0e0e0;
  color: #afafaf;
}

.financing ul.details #example-order-value {
  padding-right: 10px;
  text-align: right;
}

.financing.active .opener:before {
  border-width: 0 5px 8px;
  border-bottom-color: #606769;
  border-top-color: transparent;
}

.financing-expandable {
  cursor: pointer;
}

.financing-expandable .opener:before {
  content: "";
  position: absolute;
  top: 50%;
  border: solid transparent;
  border-width: 8px 5px 0;
  border-top-color: #606769;
  margin-top: -4px;
  margin-left: 8px;
}

.show-tablet .financing a.scroll-to {
  text-decoration: none;
  color: #434649;
}

.show-tablet .financing .heading {
  position: relative;
}

@media screen and (max-width: 972px) {

  .financing .tooltip-source:hover~.tooltip-target,
  .financing .tooltip-source:hover~.tooltip-target-desktop {
    display: none;
  }
}

#finance-duration {
  padding: 2px 40px 2px 10px;
  margin-bottom: 0;
}

#finance-options-container .g4m-select:after {
  top: 20px;
}

#finance_options_wrapper .g4m-select:after {
  bottom: 14px;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-list {
  z-index: 103;
}

.slick-list .slick-loading {
  background: #fff url("../assets/img/loading-animation.gif") center center no-repeat;
}

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -10px;
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: #fff;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

.slick-slider {
  margin-bottom: 30px;
}

.slick-slider .pagination-wrapper {
  position: fixed;
  height: 100%;
  width: 20%;
  display: block;
  z-index: 101;
  top: 0;
}

.slick-slider .pagination-wrapper.left {
  left: 0;
}

.slick-slider .pagination-wrapper.right {
  right: 0;
}

.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: #000;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: #000;
  opacity: 0.75;
}

.slick-slide {
  width: 100%;
  margin-right: 84px;
  box-shadow: 0 0 10px 1px rgba(55, 55, 55, 0.75);
  -webkit-box-shadow: 0 0 10px 1px rgba(55, 55, 55, 0.75);
  -moz-box-shadow: 0 0 10px 1px rgba(55, 55, 55, 0.75);
}

@media screen and (min-width: 1700px) {
  .slick-slide {
    margin-right: 105px;
  }
}

.slick-slide:last-of-type {
  margin-right: 0;
}

.slick-slide img {
  width: 100%;
  height: 100%;
}

#large-carousel-wrapper,
#large-carousel-wrapper #large-carousel-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}

#large-carousel-wrapper #large-carousel-overlay {
  background-color: rgba(19, 19, 19, 0.7);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a1313137', endColorstr='#a1313137', GradientType=1);
}

#large-carousel-wrapper .large-carousel-content,
#large-carousel-wrapper #large-carousel-overlay .large-carousel-content {
  position: relative;
}

#large-carousel-wrapper .carousel-gradient,
#large-carousel-wrapper #large-carousel-overlay .carousel-gradient {
  background: linear-gradient(to right, #595959 0%, rgba(89, 89, 89, 0) 100%);
  width: 9%;
  height: 100%;
  top: 0;
  position: absolute;
  z-index: 103;
}

#large-carousel-wrapper .carousel-gradient.left,
#large-carousel-wrapper #large-carousel-overlay .carousel-gradient.left {
  left: 0;
}

#large-carousel-wrapper .carousel-gradient.right,
#large-carousel-wrapper #large-carousel-overlay .carousel-gradient.right {
  right: 0;
  transform: scaleX(-1);
  -ms-filter: "FlipH";
  -webkit-filter: FlipH;
  filter: FlipH;
}

@media screen and (min-width: 1700px) {

  #large-carousel-wrapper .carousel-gradient,
  #large-carousel-wrapper #large-carousel-overlay .carousel-gradient {
    width: 15%;
  }
}

#large-carousel-wrapper #close-carousel {
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 104;
  width: 25px;
  height: 26px;
  // background: url("../assets/img/carousel-close.png") no-repeat transparent;
}

#large-carousel-pagination {
  position: absolute;
  top: 0;
  left: 0;
}

#large-carousel-pagination .pagination {
  width: 44px;
  height: 120px;
  position: absolute;
  background: #c6c6c6;
  border: 1px solid #999999;
  z-index: 104;
  overflow: hidden;
  text-indent: -9999px;
  cursor: pointer;
}

@media screen and (min-width: 1700px) {
  #large-carousel-pagination .pagination {
    width: 66px;
    height: 179px;
  }
}

#large-carousel-pagination .pagination.next {
  right: -65px;
}

@media screen and (min-width: 1700px) {
  #large-carousel-pagination .pagination.next {
    right: -87px;
  }
}

#large-carousel-pagination .pagination.prev {
  left: -65px;
}

@media screen and (min-width: 1700px) {
  #large-carousel-pagination .pagination.prev {
    left: -85px;
  }
}

#large-carousel-pagination .pagination.next:before,
#large-carousel-pagination .pagination.prev:before,
#large-carousel-pagination .pagination.next:after,
#large-carousel-pagination .pagination.prev:after {
  content: "";
  position: absolute;
  left: 0;
  top: 34%;
  border: solid transparent;
  border-right-color: #858585;
  border-left-color: #858585;
  border-radius: 10px;
  border-width: 22px 22px 22px 0;
}

@media screen and (min-width: 1700px) {

  #large-carousel-pagination .pagination.next:before,
  #large-carousel-pagination .pagination.prev:before,
  #large-carousel-pagination .pagination.next:after,
  #large-carousel-pagination .pagination.prev:after {
    border-width: 32px 32px 32px 0;
  }
}

#large-carousel-pagination .pagination.prev:before,
#large-carousel-pagination .pagination.prev:after {
  right: auto;
  left: 5px;
}

#large-carousel-pagination .pagination.prev:before {
  left: 9px;
}

@media screen and (min-width: 1700px) {
  #large-carousel-pagination .pagination.prev:before {
    left: 14px;
  }
}

#large-carousel-pagination .pagination.prev:after {
  margin-left: 11px;
  border-right-color: #c6c6c6;
}

@media screen and (min-width: 1700px) {
  #large-carousel-pagination .pagination.prev:after {
    margin-left: 19px;
  }
}

#large-carousel-pagination .pagination.next:before,
#large-carousel-pagination .pagination.next:after {
  border-width: 22px 0 22px 22px;
  left: auto;
  right: 6px;
}

@media screen and (min-width: 1700px) {

  #large-carousel-pagination .pagination.next:before,
  #large-carousel-pagination .pagination.next:after {
    border-width: 32px 0 32px 32px;
  }
}

#large-carousel-pagination .pagination.next:before {
  right: 9px;
}

@media screen and (min-width: 1700px) {
  #large-carousel-pagination .pagination.next:before {
    right: 14px;
  }
}

#large-carousel-pagination .pagination.next:after {
  margin-right: 10px;
  border-left-color: #c6c6c6;
}

@media screen and (min-width: 1700px) {
  #large-carousel-pagination .pagination.next:after {
    margin-right: 19px;
  }
}

#trustpilot-service-reviews {
  float: right;
  width: 50%;
  max-width: 410px;
  margin-left: 25px;
  clear: right;
}

.trustpilot-review-list-container.loading {
  margin-left: 0;
}

#trustpilot-service-reviews {
  margin-bottom: 10px;
}

#trustpilot-service-reviews a {
  text-decoration: none;
}

#trustpilot-service-reviews .trustpilot-review-list-container.loading.placeholder {
  height: 400px;
  text-align: center;
  padding: 10px;
}

#trustpilot-service-reviews .trustpilot-review-list-container.loading {
  height: 75px;
  background-color: #ededed;
  position: relative;
}

#trustpilot-service-reviews .trustpilot-review-list-container.loading:before {
  content: attr(data-loading-txt);
  left: 32%;
  top: 34%;
  position: absolute;
  font-size: 1.2em;
}

#trustpilot-service-reviews ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

#trustpilot-service-reviews ul.trustpilot-header li {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 18px 19px 17px 20px;
}

#trustpilot-service-reviews ul.trustpilot-footer {
  margin-top: -5px;
}

#trustpilot-service-reviews ul.trustpilot-footer li {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #5f5f5f;
  padding: 10px 0 5px 0;
}

#trustpilot-service-reviews ul.trustpilot-footer img {
  max-height: 2.3em;
  margin-bottom: 0;
  position: relative;
  top: -2px;
  left: -10px;
}

#trustpilot-service-reviews ul li {
  background-color: #ededed;
  padding: 10px 20px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 1px;
}

#trustpilot-service-reviews ul li h2 {
  font-size: 1.2em;
  margin: 0;
}

#trustpilot-review-popup {
  display: block;
  padding: 13px 30px 8px;
  text-decoration: none;
  text-align: center;
  color: #fff;
}

#trustpilot-review-list-iframe {
  overflow: hidden;
}

#trustpilot-popup-iframe {
  position: fixed;
  z-index: 99;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-style: none;
  display: block;
  overflow: hidden;
}

#trustpilot-popup-close-overlay {
  width: 100%;
  height: 61px;
  display: block;
  max-width: 820px;
  position: fixed;
  margin: 0 auto;
  z-index: 100;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

#trustpilot-popup-close-overlay .trustpilot-popup-close-btn {
  position: absolute;
  bottom: 0;
  right: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

@media screen and (max-width: 875px) {
  #trustpilot-popup-close-overlay .trustpilot-popup-close-btn {
    right: 30px;
  }
}

.redirect-banner-box-wrap {
  cursor: pointer;
  max-height: 330px;
  transition: all 0.9s ease;
  overflow: hidden;
  margin-bottom: 10px;
  max-height: 150px;
  transition: all 1.2s ease;
}

.redirect-banner-box-wrap.closed {
  max-height: 0;
  margin-bottom: 0;
}

.redirect-banner-box {
  background: #63656a;
  border-radius: 3px;
  font-size: 1.334em;
  line-height: 1.2em;
  position: relative;
  padding: 10px 20px;
  overflow-y: hidden;
  height: auto;
  height: 95px;
}

.redirect-banner-box p {
  display: block;
  margin: 3px 0 2px 0;
  color: #fff;
  font-weight: normal;
  letter-spacing: 0;
  font-size: 0.9em;
  word-spacing: -1px;
}

.redirect-banner-box p.title {
  font-weight: bold;
}

.redirect-banner-box .visit-website-text,
.redirect-banner-box .redirect-banner-flag {
  display: inline-block;
}

.redirect-banner-box .redirect-banner-flag {
  position: relative;
  padding: 10px;
  width: 28px;
  height: 55px;
  margin-right: 5px;
  margin-top: 10px;
  width: 55px;
  height: 55px;
  margin-right: 20px;
  display: inline-block;
}

.webp .redirect-banner-box .redirect-banner-flag:before {
  background-image: url("../assets/img/flag_sprites_v4.webp?v=1");
}

.no-webp .redirect-banner-box .redirect-banner-flag:before,
.no-js .redirect-banner-box .redirect-banner-flag:before {
  background-image: url(/dist/images/flag_sprites_v4.1.png?v=1);
}

.redirect-banner-box .redirect-banner-flag:before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 28px;
  width: 100%;
  content: "";
  border-radius: 100%;
  background-position: -532px 0;
  background-size: 560px 28px;
  top: 55%;
  width: 56px;
  height: 56px;
  background-position: -1064px 0;
  background-size: 1120px 56px;
}

.redirect-banner-box .redirect-banner-flag-gb:before {
  background-position: 0 0;
  background-position: 0 0;
}

.redirect-banner-box .redirect-banner-flag-us:before {
  background-position: -532px 0;
  background-position: -1064px 0;
}

.redirect-banner-box .visit-website-text .tick-list-item {
  background: url(/dist/images/icon/tick_darkgreen.png) transparent left center no-repeat;
  background-size: 15px 13px;
  padding-left: 23px;
}

.redirect-banner-box .redirect-banner-buttons-container {
  float: right;
  width: 100%;
  margin: 20px 0 0;
  width: auto;
}

@media screen and (min-width: 784px) {
  .redirect-banner-box .redirect-banner-buttons-container {
    margin: 20px 8px;
  }
}

.redirect-banner-box .redirect-banner-buttons-container>button {
  line-height: 16px;
  padding: 9px 19px;
  margin: 0 2px;
}

@media screen and (max-width: 768px) {
  .redirect-banner-box .redirect-banner-buttons-container>button.redirect-banner-accept {
    width: 55%;
  }
}

@media screen and (max-width: 768px) {
  .redirect-banner-box .redirect-banner-buttons-container>button.redirect-banner-decline {
    width: 40%;
  }
}

.header-box-mobile {
  display: block;
  padding-left: 8px;
}

.header-box-mobile .header-box-container {
  width: 100%;
  display: flex;
  border-radius: 0;
  margin-bottom: 5px;
}

.header-box-mobile .lang-container {
  padding: 0 4px;
}

.header-box-mobile .lang-container .lang {
  margin: 0;
}

body.with-ie-banner {
  margin-top: 112px;
}

.ie-banner {
  background-color: #f2811e;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1030;
  margin: 0 auto;
}

.ie-banner.hidden {
  display: none;
}

.ie-banner__cont {
  color: #fff;
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  max-width: 1366px;
  margin: 0 auto;
  width: 100%;
  align-items: center;
  flex-direction: row;
}

.ie-banner__svg {
  margin-right: 18px;
  flex-shrink: 0;
  height: 50px;
  width: 50px;
  margin-left: 18px;
}

.ie-banner__blurb {
  font-size: 0.92308rem;
  margin: 10px 0;
  margin-right: 18px;
  font-size: 1.2rem;
}

@media screen and (min-width: 1023px) {
  .ie-banner__blurb {
    width: 75%;
  }
}

.ie-banner__header {
  font-size: 1rem;
  margin-top: 9px;
  font-size: 1.5rem;
}

.g4m-lazy-load-onview {
  opacity: 0;
  transition: opacity 0.1s;
}

.g4m-lazy-load-onview--complete {
  opacity: 1;
}

.body-class--mobile .footer {
  padding: 0;
}

.body-class--mobile .social-networks {
  border-top: none;
  -webkit-box-shadow: none;
  padding: 9px 0 0;
}

.body-class--mobile .social-networks a {
  border-radius: 999px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: url(/dist/images/social_icons_spritesheet_256x64.png) no-repeat;
  background-size: 128px 32px;
  box-shadow: none;
  border-top: none;
}

.body-class--mobile .social-networks .facebook {
  background-position: 0 0;
}

.body-class--mobile .social-networks .twitter {
  background-position: -32px 0;
}

.body-class--mobile .social-networks .youtube {
  background-position: -64px 0;
}

.body-class--mobile .social-networks .instagram {
  background-position: -96px 0;
}

.body-class--mobile .payment-list {
  display: block;
  margin: 0 auto;
  height: inherit;
  max-width: 330px;
  padding-top: 10px;
}

.body-class--mobile #mobile-footer-trustpilot {
  background-color: #fafafa;
  height: 30px;
  padding: 7px 10px 0;
  text-align: center;
}

.body-class--mobile #mobile-footer-trustpilot {
  margin-right: 0;
}

.body-class--mobile #footer-block-mobile {
  margin-right: 0;
}

.body-class--mobile #mobile-footer-trustpilot a {
  display: block;
  margin: 0 auto;
  max-width: 390px;
}

.body-class--mobile #mobile-footer-trustpilot a:after {
  display: block;
  content: "";
  clear: both;
}

.body-class--mobile #mobile-footer-trustpilot:after {
  content: "";
  display: block;
  clear: both;
}

.body-class--mobile #footer-trustpilot-score span {
  color: #424649;
}

.body-class--mobile .trustpilot-score {
  line-height: 23px;
  text-align: left;
}

.body-class--mobile #mobile-footer-trustpilot>a>div>img {
  width: 100%;
}

.body-class--mobile .trustpilot-score {
  flex-grow: 1;
  -ms-grid-row-align: center;
  align-self: center;
  color: #424649;
}

.body-class--mobile #trustpilot-stars-container {
  display: inline-block;
}

.body-class--mobile .trust-pilot-mobile {
  width: 33.3%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  display: block;
  float: left;
  max-width: 130px;
  padding: 0 4px;
}

.body-class--mobile #trustpilot-logo {
  padding-top: 2px;
}

.body-class--mobile #mobile-footer-links {
  min-width: 100%;
  background-color: #ededed;
  clear: both;
  font-size: 1.1em;
  line-height: 1.25em;
  padding: 7px 0;
}

.body-class--mobile #mobile-footer-links ul {
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0 40px;
}

.body-class--mobile #mobile-footer-links ul:after {
  content: "";
  display: block;
  clear: both;
}

.body-class--mobile #mobile-footer-links ul li {
  float: left;
  display: block;
  box-sizing: border-box;
  width: 50%;
}

.body-class--mobile #mobile-footer-links ul li:nth-child(odd) {
  clear: both;
  padding-right: 10px;
}

.body-class--mobile .payment-security-logo-container {
  width: 33.3%;
  box-sizing: border-box;
  padding: 0 0;
  display: block;
  float: left;
}

.body-class--mobile .payment-security-logos {
  width: 200px;
}

.body-class--mobile .payment-security-logo {
  width: 50%;
}

.body-class--mobile #mobile-footer-security {
  height: 50px;
  width: 100%;
  background-color: #f3f3f3;
}

.body-class--mobile .payment-security {
  width: 100%;
  height: 0;
  padding-bottom: 33%;
  background: url(/dist/images/payment-security-logos-mobile.png) no-repeat;
  background-size: 110% auto;
}

.body-class--mobile .payment-security-logo-container .thawte {
  background-position: 50% 0;
}

.body-class--mobile .payment-security-logo-container .mastercard {
  background-position: 50% 50%;
  margin-left: 5px;
}

.body-class--mobile .payment-security-logo-container .visa {
  background-position: 50% 100%;
}

.body-class--mobile #mobile-footer-links li {
  padding: 6px 0 6px;
}

.body-class--mobile #mobile-footer-links ul a {
  text-decoration: none;
  color: #43464b;
}

.body-class--mobile #mobile-footer-bottom {
  max-width: 100%;
  height: 40px;
  background-color: #333333;
  padding: 10px 20px;
}

.body-class--mobile #mobile-footer-bottom ul {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
}

.body-class--mobile #mobile-footer-company-info {
  color: #adadad;
}

.body-class--mobile #mobile-footer-bottom span {
  clear: both;
  color: #adadad;
  float: left;
}

.body-class--mobile #mobile-footer-social {
  padding: 3px 40px 3px 40px;
  background-color: #f3f3f3;
  height: 50px;
}

.body-class--mobile #footer-block-mobile>*>* {
  max-width: 400px;
  margin: 0 auto;
}

.trustpilot-score {
  font-size: 9px;
  line-height: 20px;
}

.payment-security-logo-container {
  padding: 0 11px;
}

.payment-security-logo-container .thawte {
  margin-left: 15px;
}

.payment-security-logo-container .visa {
  margin-left: -13px;
}

#mobile-footer-bottom span {
  font-size: 11px;
}

.trustpilot-score {
  font-size: 12px;
}

.trust-pilot-mobile {
  padding: 0 6px;
}

.trustpilot-score {
  font-size: 12px;
  line-height: 24px;
}

.trustpilot-score {
  font-size: 13px;
  line-height: 25px;
}

#footer {
  padding: 0;
  box-sizing: border-box;
  background: none;
  margin-top: 30px;
}

.body-class--basket #footer {
  margin-top: 0;
}

#footer .footer-content {
  display: flex;
  justify-content: space-between;
}

#footer .footer {
  background-color: #f6f6f6;
  max-width: 100%;
}

#footer .footer a {
  color: #1c9ace;
  text-decoration: underline;
}

#footer .footer .footer-block {
  box-sizing: border-box;
  background-color: #f6f6f6;
  border: none;
  margin: 0 auto 24px;
  max-width: 1330px;
  padding: 0 16px;
}

#footer .footer .footer-block.minimal {
  padding-top: 25px;
  max-width: 940px;
}

#footer .footer .footer-block .container-feature-brand,
#footer .footer .footer-block .payment-padding {
  padding-top: 20px;
}

#footer .footer .footer-block .container-payment .feature-brands,
#footer .footer .footer-block .container-feature-brand .feature-brands {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

#footer .footer .footer-block .container-payment .feature-brands a img,
#footer .footer .footer-block .container-feature-brand .feature-brands a img {
  margin-right: 28px;
}

#footer .footer .footer-block .container-payment .feature-brands a:last-of-type img,
#footer .footer .footer-block .container-feature-brand .feature-brands a:last-of-type img {
  margin-right: 0;
}

#footer .footer .footer-block .container-payment .payment-options,
#footer .footer .footer-block .container-feature-brand .payment-options {
  height: 100%;
  display: flex;
  align-items: center;
  list-style: none;
  justify-content: space-around;
  padding: 0;
  max-width: 1100px;
  min-height: 54px;
  margin: 0 auto;
}

#footer .footer .footer-block .container-payment .payment-options .opt-1,
#footer .footer .footer-block .container-feature-brand .payment-options .opt-1 {
  display: none;
}

@media only screen and (min-width: 878px) {

  #footer .footer .footer-block .container-payment .payment-options .opt-1,
  #footer .footer .footer-block .container-feature-brand .payment-options .opt-1 {
    display: block;
  }
}

#footer .footer .footer-block .container-payment .payment-options .opt-2,
#footer .footer .footer-block .container-feature-brand .payment-options .opt-2 {
  display: none;
}

@media only screen and (min-width: 988px) {

  #footer .footer .footer-block .container-payment .payment-options .opt-2,
  #footer .footer .footer-block .container-feature-brand .payment-options .opt-2 {
    display: block;
  }
}

#footer .footer .footer-block .container-payment .payment-options .opt-3,
#footer .footer .footer-block .container-feature-brand .payment-options .opt-3 {
  display: none;
}

@media only screen and (min-width: 1098px) {

  #footer .footer .footer-block .container-payment .payment-options .opt-3,
  #footer .footer .footer-block .container-feature-brand .payment-options .opt-3 {
    display: block;
  }
}

#footer .footer .footer-block .container-payment .payment-list .visa,
#footer .footer .footer-block .container-feature-brand .payment-list .visa {
  height: 37px;
  background-size: contain;
  background-position: center 0;
  width: 68px;
  background-image: url(/dist/images/payment/VerifiedByVisa.png);
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {

  #footer .footer .footer-block .container-payment .payment-list .visa,
  #footer .footer .footer-block .container-feature-brand .payment-list .visa {
    background-image: url(/dist/images/payment/VerifiedByVisa-2x.png);
  }
}

#footer .footer .footer-block .container-payment .payment-list .mastercard,
#footer .footer .footer-block .container-feature-brand .payment-list .mastercard {
  height: 37px;
  background-size: contain;
  background-position: center 0;
  width: 83px;
  background-image: url(/dist/images/payment/MastercardSecurecode.png);
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {

  #footer .footer .footer-block .container-payment .payment-list .mastercard,
  #footer .footer .footer-block .container-feature-brand .payment-list .mastercard {
    background-image: url(/dist/images/payment/MastercardSecurecode-2x.png);
  }
}

#footer .footer .footer-block .container-payment .payment-list .amex,
#footer .footer .footer-block .container-feature-brand .payment-list .amex {
  height: 37px;
  background-size: contain;
  background-position: center 0;
  background-image: url(/dist/images/payment/AmexSafekey.png);
  width: 85px;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {

  #footer .footer .footer-block .container-payment .payment-list .amex,
  #footer .footer .footer-block .container-feature-brand .payment-list .amex {
    background-image: url(/dist/images/payment/AmexSafekey-2x.png);
  }
}

#footer .footer .footer-block .container-payment .payment-list .trustedshops,
#footer .footer .footer-block .container-feature-brand .payment-list .trustedshops {
  background-image: url(/dist/images/payment/Trustedshops.png);
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  background-size: contain;
  background-position: center 0;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {

  #footer .footer .footer-block .container-payment .payment-list .trustedshops,
  #footer .footer .footer-block .container-feature-brand .payment-list .trustedshops {
    background-image: url(/dist/images/payment/Trustedshops-2x.png);
  }
}

#footer .footer .footer-block .container-payment .gcs>iframe,
#footer .footer .footer-block .container-feature-brand .gcs>iframe {
  width: 115px !important;
}

#footer .footer .footer-block .container-categories {
  height: 31px;
  margin-right: 10px;
  margin-bottom: 25px;
  margin-left: 10px;
}

#footer .footer .footer-block .container-categories ul {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  list-style: none;
  margin: 12px 0;
  padding: 0;
}

#footer .footer .footer-block .container-categories ul li {
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
  border-radius: 15px;
  box-shadow: 0px 0.5px #e5e5e5;
  line-height: 31px;
  margin-right: 20px;
  text-align: center;
  width: 133px;
}

#footer .footer .footer-block .container-categories ul li:last-of-type {
  margin-right: 0;
}

#footer .footer .footer-block .container-categories ul li a {
  color: #434649;
  display: block;
  padding-top: 1px;
  text-decoration: none;
}

#footer .footer .footer-block .company-box {
  float: inherit;
  margin: 0 16px 0 0;
  width: 33%;
}

#footer .footer .footer-block .company-box>span {
  display: block;
  margin-bottom: 3px;
  max-width: 235px;
}

#footer .footer .footer-block .company-box .company-box-title {
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 5px;
}

#footer .footer .footer-block .company-box .company-box-title>span {
  font-weight: normal;
}

#footer .footer .footer-block .company-box .music-for-all p {
  margin: 20px 0 0;
}

#footer .footer .footer-block .company-box .music-for-all a {
  display: inline-block;
  margin-top: 5px;
}

#footer .footer .footer-block .link-box {
  float: inherit;
  font-size: 1em;
  margin: 0 0 0 16px;
  text-align: right;
  width: 33%;
}

#footer .footer .footer-block .link-box>div {
  margin: 19px 0 0;
}

#footer .footer .footer-block .link-box>div:first-of-type {
  margin: 0;
}

#footer .footer .footer-block .link-box h3 {
  font-size: 2rem;
  line-height: 1em;
  margin: 0 0 1px;
}

#footer .footer .footer-block .link-box p {
  line-height: 1.8rem;
  margin: 0;
}

#footer .footer .footer-block .link-box .currently-logged-in>.name {
  word-break: break-word;
}

#footer .footer .footer-block .text-box {
  margin: 0;
  padding: 0;
}

#footer .footer .footer-block .text-box>li {
  margin-bottom: 26px;
}

#footer .footer .footer-block .text-box>li:last-child {
  margin-bottom: 0;
}

#footer .footer .footer-block .text-box .logo-box {
  height: 58px;
}

#footer .footer .footer-block .text-box .logo-box .home-link {
  display: block;
  height: 100%;
  width: 100%;
}

#footer .footer .footer-block .text-box .logo-box img,
#footer .footer .footer-block .text-box .logo-box svg {
  max-height: 100%;
  max-width: 100%;
}

#footer .footer .footer-block .text-box .social-box ul {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

#footer .footer .footer-block .text-box .social-box ul li {
  margin-right: 20px;
}

#footer .footer .footer-block .text-box .social-box ul li:last-of-type {
  margin-right: 0;
}

#footer .footer .footer-block .text-box .social-box ul li svg {
  width: 39px;
  height: 39px;
}

#footer .footer .footer-copy {
  display: flex;
  align-items: center;
  height: 39px;
  background-color: #333;
  color: #fff;
  text-align: center;
}

#footer .footer .footer-copy span {
  margin: 2px auto 0;
}

.site-security-modal #main_content {
  padding: 20px;
}

.site-security-modal #main_content h2 {
  font-size: 14px;
  padding-bottom: 10px;
}

.site-security-modal #main_content p {
  margin-bottom: 25px;
}

.site-security-modal #main_content #intro {
  font-weight: bold;
}

.site-security-modal #main_content #g4msecuritylogo {
  float: right;
  padding: 0 0 0 15px;
}

.criteo_header {
  display: none !important;
}

body.body-class--desktop #mm-nav {
  display: none;
}

.mm-menu {
  font-size: 14px;
  --mm-listitem-size: 40px;
  --mm-navbar-size: 40px;
  -webkit-font-smoothing: auto;
}

.mm-navbars_top>.mm-navbar {
  height: calc(var(--mm-navbar-size) - 1px);
}

.mm-searchfield__input {
  padding: 7px 10px 0;
}

.mm-searchfield input {
  min-height: 26px;
  max-height: 26px;
  line-height: 26px;
}

.mm-navbar__title {
  font-size: 13px;
}

.mmenu-clearance {
  color: #b8250f !important;
}

.mmenu-icons {
  width: 15px;
  height: 17px;
  background: url(/dist/images/mmenu-icons_v2.png);
  display: inline-block;
  vertical-align: middle;
  margin-right: 9px;
  margin-bottom: 1px;
  box-sizing: initial;
  background-size: 64px 17px;
}

.mmenu-icons.login {
  background-position: 0 0;
}

.mmenu-icons.news {
  background-position: 17px 0;
}

.mmenu-icons.logout {
  background-position: 35px 0;
}

.mmenu-icons.help {
  background-position: 50px 0;
}

.full_bottom_border:after {
  left: 0 !important;
}

.mm-clear:before,
.mm-clear:after,
.mm-next:before,
.mm-next:after,
.mm-prev:before,
.mm-prev:after {
  box-sizing: initial;
}

.mm-listitem__text strong {
  pointer-events: none;
}

#osk-container {
  visibility: hidden;
  position: fixed;
  overflow: hidden;
  padding: 1%;
  margin: 0;
  z-index: 100;
}

#osk-container>li {
  float: left;
  width: 5.81395349%;
  height: 3em;
  margin: 0 0.726744186% 5px 0;
  line-height: 3em;
  text-align: center;
  list-style-type: none;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}

#osk-container>.osk-capslock,
#osk-container>.osk-tab,
#osk-container>.osk-shift {
  clear: left;
}

#osk-container>.osk-dragger {
  display: none;
  width: 100%;
  cursor: move;
}

#osk-container>.osk-dragger:hover {
  position: static;
}

#osk-container>.osk-tab,
#osk-container>.osk-backspace {
  width: 14.9709302%;
}

#osk-container>.osk-capslock {
  width: 13.6337209%;
}

#osk-container>.osk-return {
  width: 13.6918605%;
}

#osk-container>.osk-shift {
  width: 16.2381395%;
}

#osk-container>.osk-hide {
  width: 17.5581396%;
}

#osk-container>.osk-space {
  clear: left;
  width: 100%;
  margin: 0;
}

#osk-container>.osk-last-item {
  margin-right: 0 !important;
}

#osk-container .osk-on {
  display: none;
}

#osk-container>.osk-uppercase {
  text-transform: uppercase;
}

#osk-container>.osk-disabled {
  cursor: default;
}

#osk-container>.osk-disabled:hover {
  position: static;
}

#osk-container>li:hover {
  position: relative;
  top: 1px;
}

#osk-container {
  width: 47%;
  min-width: 500px;
  max-width: 1200px;
  background: #eee;
  border-radius: 5%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

#osk-container>li {
  background: #ddd;
  border-radius: 10%;
  -moz-transition: 0.5s;
}

#osk-container>.osk-dragger {
  background: transparent;
  color: #aaa;
}

#osk-container>.osk-disabled {
  background: #e5e5e5;
  color: #ccc;
}

.osk-focused {
  background: #fafafa !important;
}

.key__orange,
#osk-container .osk-hide {
  background-color: #faa100;
  color: #fafafa;
}

#osk_mask {
  position: absolute;
  bottom: 10px;
  right: 10px;
  opacity: 0.5;
  z-index: 997;
}

#osk-container {
  bottom: 10px;
  font-size: 16px;
  z-index: 997;
  width: 49%;
}

#osk-container .osk-hide {
  border-radius: 5px;
}

.accessories-wrapper .switcher-wrapper .switcher ul {
  padding: 0 0px 0 40px;
}

.accessories-wrapper .switcher-wrapper .prev,
.accessories-wrapper .switcher-wrapper .next {
  top: 50px;
}

.accessories-wrapper .switcher-wrapper .pagination {
  background: #ececec;
  border: 1px solid #c7c6c6;
}

.accessories-wrapper .switcher-wrapper .pagination-fading-left,
.accessories-wrapper .switcher-wrapper .pagination-fading-right {
  z-index: 48;
  height: 100%;
  width: 53px;
  position: absolute;
  text-indent: -9999px;
  overflow: hidden;
  top: 0;
  left: 0;
  bottom: 0;
}

.accessories-wrapper .switcher-wrapper .pagination-fading-left.hidden,
.accessories-wrapper .switcher-wrapper .pagination-fading-right.hidden {
  display: none;
}

.accessories-wrapper .switcher-wrapper .pagination-fading-left {
  left: 0;
  right: auto;
  background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, #fff 43%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1);
}

.accessories-wrapper .switcher-wrapper .pagination-fading-right {
  left: auto;
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 43%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1);
}

.style-alt .text-default,
.style-alt a.link.link-default {
  color: #434649 !important;
}

.style-alt .text-primary,
.style-alt a.link.link-primary {
  color: #fa8600 !important;
}

.style-alt .text-secondary {
  color: #249c54 !important;
}

.style-alt .text-secondary-blue {
  color: #6f94b6 !important;
}

.style-alt .text-success {
  color: #97bc3c !important;
}

.style-alt .text-info,
.style-alt a.link.link-info {
  color: #5bc0de !important;
}

.style-alt .text-warning {
  color: #f0ad4e !important;
}

.style-alt .text-danger {
  color: #d9534f !important;
}

.style-alt .font-italic {
  font-style: italic !important;
}

.style-alt .font-weight-bold,
.style-alt .panel .panel-body.panel-body-details>.row>dl>dt {
  font-weight: bold !important;
}

.style-alt .text-align-right {
  text-align: right !important;
}

.style-alt .text-align-left {
  text-align: left !important;
}

.style-alt .text-align-center,
.style-alt .checkout-success-thank-you {
  text-align: center !important;
}

.style-alt .font-size-small {
  font-size: 11px !important;
}

.style-alt .font-size-base {
  font-size: 12px !important;
}

.style-alt .font-size-medium {
  font-size: 13px !important;
}

.style-alt .font-size-large {
  font-size: 18px !important;
}

.style-alt .font-size-xlarge {
  font-size: 29px !important;
}

.style-alt .font-weight-normal {
  font-weight: normal !important;
}

.style-alt .text-decoration-underline,
.style-alt a.link {
  text-decoration: underline !important;
}

.style-alt h1 {
  border: none;
}

.style-alt .text-overflow-ellipsis {
  width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.style-alt .ord-rw-tot.text-success {
  font-size: 22px;
  line-height: 22px;
}

.style-alt .ord-rw-tot.text-success .c-val {
  white-space: nowrap;
}

.style-alt .container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.style-alt .container:before,
.style-alt .container:after {
  display: table;
  content: " ";
}

.style-alt .container:after {
  clear: both;
}

@media (min-width: 768px) {
  .style-alt .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .style-alt .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .style-alt .container {
    width: 1170px;
  }
}

.style-alt .container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.style-alt .container-fluid:before,
.style-alt .container-fluid:after {
  display: table;
  content: " ";
}

.style-alt .container-fluid:after {
  clear: both;
}

.style-alt .row {
  margin-right: -15px;
  margin-left: -15px;
}

.style-alt .row:before,
.style-alt .row:after {
  display: table;
  content: " ";
}

.style-alt .row:after {
  clear: both;
}

.style-alt .row-no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.style-alt .row-no-gutters [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.style-alt .col-xs-1,
.style-alt .col-sm-1,
.style-alt .col-md-1,
.style-alt .col-lg-1,
.style-alt .col-xs-2,
.style-alt .col-sm-2,
.style-alt .col-md-2,
.style-alt .col-lg-2,
.style-alt .col-xs-3,
.style-alt .col-sm-3,
.style-alt .col-md-3,
.style-alt .col-lg-3,
.style-alt .col-xs-4,
.style-alt .col-sm-4,
.style-alt .col-md-4,
.style-alt .col-lg-4,
.style-alt .col-xs-5,
.style-alt .col-sm-5,
.style-alt .col-md-5,
.style-alt .col-lg-5,
.style-alt .col-xs-6,
.style-alt .col-sm-6,
.style-alt .col-md-6,
.style-alt .col-lg-6,
.style-alt .col-xs-7,
.style-alt .col-sm-7,
.style-alt .col-md-7,
.style-alt .col-lg-7,
.style-alt .col-xs-8,
.style-alt .col-sm-8,
.style-alt .col-md-8,
.style-alt .col-lg-8,
.style-alt .col-xs-9,
.style-alt .col-sm-9,
.style-alt .col-md-9,
.style-alt .col-lg-9,
.style-alt .col-xs-10,
.style-alt .col-sm-10,
.style-alt .col-md-10,
.style-alt .col-lg-10,
.style-alt .col-xs-11,
.style-alt .col-sm-11,
.style-alt .col-md-11,
.style-alt .col-lg-11,
.style-alt .col-xs-12,
.style-alt .col-sm-12,
.style-alt .col-md-12,
.style-alt .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.style-alt .col-xs-1,
.style-alt .col-xs-2,
.style-alt .col-xs-3,
.style-alt .col-xs-4,
.style-alt .col-xs-5,
.style-alt .col-xs-6,
.style-alt .col-xs-7,
.style-alt .col-xs-8,
.style-alt .col-xs-9,
.style-alt .col-xs-10,
.style-alt .col-xs-11,
.style-alt .col-xs-12 {
  float: left;
}

.style-alt .col-xs-1 {
  width: 8.33333%;
}

.style-alt .col-xs-2 {
  width: 16.66667%;
}

.style-alt .col-xs-3 {
  width: 25%;
}

.style-alt .col-xs-4 {
  width: 33.33333%;
}

.style-alt .col-xs-5 {
  width: 41.66667%;
}

.style-alt .col-xs-6 {
  width: 50%;
}

.style-alt .col-xs-7 {
  width: 58.33333%;
}

.style-alt .col-xs-8 {
  width: 66.66667%;
}

.style-alt .col-xs-9 {
  width: 75%;
}

.style-alt .col-xs-10 {
  width: 83.33333%;
}

.style-alt .col-xs-11 {
  width: 91.66667%;
}

.style-alt .col-xs-12 {
  width: 100%;
}

.style-alt .col-xs-pull-0 {
  right: auto;
}

.style-alt .col-xs-pull-1 {
  right: 8.33333%;
}

.style-alt .col-xs-pull-2 {
  right: 16.66667%;
}

.style-alt .col-xs-pull-3 {
  right: 25%;
}

.style-alt .col-xs-pull-4 {
  right: 33.33333%;
}

.style-alt .col-xs-pull-5 {
  right: 41.66667%;
}

.style-alt .col-xs-pull-6 {
  right: 50%;
}

.style-alt .col-xs-pull-7 {
  right: 58.33333%;
}

.style-alt .col-xs-pull-8 {
  right: 66.66667%;
}

.style-alt .col-xs-pull-9 {
  right: 75%;
}

.style-alt .col-xs-pull-10 {
  right: 83.33333%;
}

.style-alt .col-xs-pull-11 {
  right: 91.66667%;
}

.style-alt .col-xs-pull-12 {
  right: 100%;
}

.style-alt .col-xs-push-0 {
  left: auto;
}

.style-alt .col-xs-push-1 {
  left: 8.33333%;
}

.style-alt .col-xs-push-2 {
  left: 16.66667%;
}

.style-alt .col-xs-push-3 {
  left: 25%;
}

.style-alt .col-xs-push-4 {
  left: 33.33333%;
}

.style-alt .col-xs-push-5 {
  left: 41.66667%;
}

.style-alt .col-xs-push-6 {
  left: 50%;
}

.style-alt .col-xs-push-7 {
  left: 58.33333%;
}

.style-alt .col-xs-push-8 {
  left: 66.66667%;
}

.style-alt .col-xs-push-9 {
  left: 75%;
}

.style-alt .col-xs-push-10 {
  left: 83.33333%;
}

.style-alt .col-xs-push-11 {
  left: 91.66667%;
}

.style-alt .col-xs-push-12 {
  left: 100%;
}

.style-alt .col-xs-offset-0 {
  margin-left: 0%;
}

.style-alt .col-xs-offset-1 {
  margin-left: 8.33333%;
}

.style-alt .col-xs-offset-2 {
  margin-left: 16.66667%;
}

.style-alt .col-xs-offset-3 {
  margin-left: 25%;
}

.style-alt .col-xs-offset-4 {
  margin-left: 33.33333%;
}

.style-alt .col-xs-offset-5 {
  margin-left: 41.66667%;
}

.style-alt .col-xs-offset-6 {
  margin-left: 50%;
}

.style-alt .col-xs-offset-7 {
  margin-left: 58.33333%;
}

.style-alt .col-xs-offset-8 {
  margin-left: 66.66667%;
}

.style-alt .col-xs-offset-9 {
  margin-left: 75%;
}

.style-alt .col-xs-offset-10 {
  margin-left: 83.33333%;
}

.style-alt .col-xs-offset-11 {
  margin-left: 91.66667%;
}

.style-alt .col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 768px) {

  .style-alt .col-sm-1,
  .style-alt .col-sm-2,
  .style-alt .col-sm-3,
  .style-alt .col-sm-4,
  .style-alt .col-sm-5,
  .style-alt .col-sm-6,
  .style-alt .col-sm-7,
  .style-alt .col-sm-8,
  .style-alt .col-sm-9,
  .style-alt .col-sm-10,
  .style-alt .col-sm-11,
  .style-alt .col-sm-12 {
    float: left;
  }

  .style-alt .col-sm-1 {
    width: 8.33333%;
  }

  .style-alt .col-sm-2 {
    width: 16.66667%;
  }

  .style-alt .col-sm-3 {
    width: 25%;
  }

  .style-alt .col-sm-4 {
    width: 33.33333%;
  }

  .style-alt .col-sm-5 {
    width: 41.66667%;
  }

  .style-alt .col-sm-6 {
    width: 50%;
  }

  .style-alt .col-sm-7 {
    width: 58.33333%;
  }

  .style-alt .col-sm-8 {
    width: 66.66667%;
  }

  .style-alt .col-sm-9 {
    width: 75%;
  }

  .style-alt .col-sm-10 {
    width: 83.33333%;
  }

  .style-alt .col-sm-11 {
    width: 91.66667%;
  }

  .style-alt .col-sm-12 {
    width: 100%;
  }

  .style-alt .col-sm-pull-0 {
    right: auto;
  }

  .style-alt .col-sm-pull-1 {
    right: 8.33333%;
  }

  .style-alt .col-sm-pull-2 {
    right: 16.66667%;
  }

  .style-alt .col-sm-pull-3 {
    right: 25%;
  }

  .style-alt .col-sm-pull-4 {
    right: 33.33333%;
  }

  .style-alt .col-sm-pull-5 {
    right: 41.66667%;
  }

  .style-alt .col-sm-pull-6 {
    right: 50%;
  }

  .style-alt .col-sm-pull-7 {
    right: 58.33333%;
  }

  .style-alt .col-sm-pull-8 {
    right: 66.66667%;
  }

  .style-alt .col-sm-pull-9 {
    right: 75%;
  }

  .style-alt .col-sm-pull-10 {
    right: 83.33333%;
  }

  .style-alt .col-sm-pull-11 {
    right: 91.66667%;
  }

  .style-alt .col-sm-pull-12 {
    right: 100%;
  }

  .style-alt .col-sm-push-0 {
    left: auto;
  }

  .style-alt .col-sm-push-1 {
    left: 8.33333%;
  }

  .style-alt .col-sm-push-2 {
    left: 16.66667%;
  }

  .style-alt .col-sm-push-3 {
    left: 25%;
  }

  .style-alt .col-sm-push-4 {
    left: 33.33333%;
  }

  .style-alt .col-sm-push-5 {
    left: 41.66667%;
  }

  .style-alt .col-sm-push-6 {
    left: 50%;
  }

  .style-alt .col-sm-push-7 {
    left: 58.33333%;
  }

  .style-alt .col-sm-push-8 {
    left: 66.66667%;
  }

  .style-alt .col-sm-push-9 {
    left: 75%;
  }

  .style-alt .col-sm-push-10 {
    left: 83.33333%;
  }

  .style-alt .col-sm-push-11 {
    left: 91.66667%;
  }

  .style-alt .col-sm-push-12 {
    left: 100%;
  }

  .style-alt .col-sm-offset-0 {
    margin-left: 0%;
  }

  .style-alt .col-sm-offset-1 {
    margin-left: 8.33333%;
  }

  .style-alt .col-sm-offset-2 {
    margin-left: 16.66667%;
  }

  .style-alt .col-sm-offset-3 {
    margin-left: 25%;
  }

  .style-alt .col-sm-offset-4 {
    margin-left: 33.33333%;
  }

  .style-alt .col-sm-offset-5 {
    margin-left: 41.66667%;
  }

  .style-alt .col-sm-offset-6 {
    margin-left: 50%;
  }

  .style-alt .col-sm-offset-7 {
    margin-left: 58.33333%;
  }

  .style-alt .col-sm-offset-8 {
    margin-left: 66.66667%;
  }

  .style-alt .col-sm-offset-9 {
    margin-left: 75%;
  }

  .style-alt .col-sm-offset-10 {
    margin-left: 83.33333%;
  }

  .style-alt .col-sm-offset-11 {
    margin-left: 91.66667%;
  }

  .style-alt .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 992px) {

  .style-alt .col-md-1,
  .style-alt .col-md-2,
  .style-alt .col-md-3,
  .style-alt .col-md-4,
  .style-alt .col-md-5,
  .style-alt .col-md-6,
  .style-alt .col-md-7,
  .style-alt .col-md-8,
  .style-alt .col-md-9,
  .style-alt .col-md-10,
  .style-alt .col-md-11,
  .style-alt .col-md-12 {
    float: left;
  }

  .style-alt .col-md-1 {
    width: 8.33333%;
  }

  .style-alt .col-md-2 {
    width: 16.66667%;
  }

  .style-alt .col-md-3 {
    width: 25%;
  }

  .style-alt .col-md-4 {
    width: 33.33333%;
  }

  .style-alt .col-md-5 {
    width: 41.66667%;
  }

  .style-alt .col-md-6 {
    width: 50%;
  }

  .style-alt .col-md-7 {
    width: 58.33333%;
  }

  .style-alt .col-md-8 {
    width: 66.66667%;
  }

  .style-alt .col-md-9 {
    width: 75%;
  }

  .style-alt .col-md-10 {
    width: 83.33333%;
  }

  .style-alt .col-md-11 {
    width: 91.66667%;
  }

  .style-alt .col-md-12 {
    width: 100%;
  }

  .style-alt .col-md-pull-0 {
    right: auto;
  }

  .style-alt .col-md-pull-1 {
    right: 8.33333%;
  }

  .style-alt .col-md-pull-2 {
    right: 16.66667%;
  }

  .style-alt .col-md-pull-3 {
    right: 25%;
  }

  .style-alt .col-md-pull-4 {
    right: 33.33333%;
  }

  .style-alt .col-md-pull-5 {
    right: 41.66667%;
  }

  .style-alt .col-md-pull-6 {
    right: 50%;
  }

  .style-alt .col-md-pull-7 {
    right: 58.33333%;
  }

  .style-alt .col-md-pull-8 {
    right: 66.66667%;
  }

  .style-alt .col-md-pull-9 {
    right: 75%;
  }

  .style-alt .col-md-pull-10 {
    right: 83.33333%;
  }

  .style-alt .col-md-pull-11 {
    right: 91.66667%;
  }

  .style-alt .col-md-pull-12 {
    right: 100%;
  }

  .style-alt .col-md-push-0 {
    left: auto;
  }

  .style-alt .col-md-push-1 {
    left: 8.33333%;
  }

  .style-alt .col-md-push-2 {
    left: 16.66667%;
  }

  .style-alt .col-md-push-3 {
    left: 25%;
  }

  .style-alt .col-md-push-4 {
    left: 33.33333%;
  }

  .style-alt .col-md-push-5 {
    left: 41.66667%;
  }

  .style-alt .col-md-push-6 {
    left: 50%;
  }

  .style-alt .col-md-push-7 {
    left: 58.33333%;
  }

  .style-alt .col-md-push-8 {
    left: 66.66667%;
  }

  .style-alt .col-md-push-9 {
    left: 75%;
  }

  .style-alt .col-md-push-10 {
    left: 83.33333%;
  }

  .style-alt .col-md-push-11 {
    left: 91.66667%;
  }

  .style-alt .col-md-push-12 {
    left: 100%;
  }

  .style-alt .col-md-offset-0 {
    margin-left: 0%;
  }

  .style-alt .col-md-offset-1 {
    margin-left: 8.33333%;
  }

  .style-alt .col-md-offset-2 {
    margin-left: 16.66667%;
  }

  .style-alt .col-md-offset-3 {
    margin-left: 25%;
  }

  .style-alt .col-md-offset-4 {
    margin-left: 33.33333%;
  }

  .style-alt .col-md-offset-5 {
    margin-left: 41.66667%;
  }

  .style-alt .col-md-offset-6 {
    margin-left: 50%;
  }

  .style-alt .col-md-offset-7 {
    margin-left: 58.33333%;
  }

  .style-alt .col-md-offset-8 {
    margin-left: 66.66667%;
  }

  .style-alt .col-md-offset-9 {
    margin-left: 75%;
  }

  .style-alt .col-md-offset-10 {
    margin-left: 83.33333%;
  }

  .style-alt .col-md-offset-11 {
    margin-left: 91.66667%;
  }

  .style-alt .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 1200px) {

  .style-alt .col-lg-1,
  .style-alt .col-lg-2,
  .style-alt .col-lg-3,
  .style-alt .col-lg-4,
  .style-alt .col-lg-5,
  .style-alt .col-lg-6,
  .style-alt .col-lg-7,
  .style-alt .col-lg-8,
  .style-alt .col-lg-9,
  .style-alt .col-lg-10,
  .style-alt .col-lg-11,
  .style-alt .col-lg-12 {
    float: left;
  }

  .style-alt .col-lg-1 {
    width: 8.33333%;
  }

  .style-alt .col-lg-2 {
    width: 16.66667%;
  }

  .style-alt .col-lg-3 {
    width: 25%;
  }

  .style-alt .col-lg-4 {
    width: 33.33333%;
  }

  .style-alt .col-lg-5 {
    width: 41.66667%;
  }

  .style-alt .col-lg-6 {
    width: 50%;
  }

  .style-alt .col-lg-7 {
    width: 58.33333%;
  }

  .style-alt .col-lg-8 {
    width: 66.66667%;
  }

  .style-alt .col-lg-9 {
    width: 75%;
  }

  .style-alt .col-lg-10 {
    width: 83.33333%;
  }

  .style-alt .col-lg-11 {
    width: 91.66667%;
  }

  .style-alt .col-lg-12 {
    width: 100%;
  }

  .style-alt .col-lg-pull-0 {
    right: auto;
  }

  .style-alt .col-lg-pull-1 {
    right: 8.33333%;
  }

  .style-alt .col-lg-pull-2 {
    right: 16.66667%;
  }

  .style-alt .col-lg-pull-3 {
    right: 25%;
  }

  .style-alt .col-lg-pull-4 {
    right: 33.33333%;
  }

  .style-alt .col-lg-pull-5 {
    right: 41.66667%;
  }

  .style-alt .col-lg-pull-6 {
    right: 50%;
  }

  .style-alt .col-lg-pull-7 {
    right: 58.33333%;
  }

  .style-alt .col-lg-pull-8 {
    right: 66.66667%;
  }

  .style-alt .col-lg-pull-9 {
    right: 75%;
  }

  .style-alt .col-lg-pull-10 {
    right: 83.33333%;
  }

  .style-alt .col-lg-pull-11 {
    right: 91.66667%;
  }

  .style-alt .col-lg-pull-12 {
    right: 100%;
  }

  .style-alt .col-lg-push-0 {
    left: auto;
  }

  .style-alt .col-lg-push-1 {
    left: 8.33333%;
  }

  .style-alt .col-lg-push-2 {
    left: 16.66667%;
  }

  .style-alt .col-lg-push-3 {
    left: 25%;
  }

  .style-alt .col-lg-push-4 {
    left: 33.33333%;
  }

  .style-alt .col-lg-push-5 {
    left: 41.66667%;
  }

  .style-alt .col-lg-push-6 {
    left: 50%;
  }

  .style-alt .col-lg-push-7 {
    left: 58.33333%;
  }

  .style-alt .col-lg-push-8 {
    left: 66.66667%;
  }

  .style-alt .col-lg-push-9 {
    left: 75%;
  }

  .style-alt .col-lg-push-10 {
    left: 83.33333%;
  }

  .style-alt .col-lg-push-11 {
    left: 91.66667%;
  }

  .style-alt .col-lg-push-12 {
    left: 100%;
  }

  .style-alt .col-lg-offset-0 {
    margin-left: 0%;
  }

  .style-alt .col-lg-offset-1 {
    margin-left: 8.33333%;
  }

  .style-alt .col-lg-offset-2 {
    margin-left: 16.66667%;
  }

  .style-alt .col-lg-offset-3 {
    margin-left: 25%;
  }

  .style-alt .col-lg-offset-4 {
    margin-left: 33.33333%;
  }

  .style-alt .col-lg-offset-5 {
    margin-left: 41.66667%;
  }

  .style-alt .col-lg-offset-6 {
    margin-left: 50%;
  }

  .style-alt .col-lg-offset-7 {
    margin-left: 58.33333%;
  }

  .style-alt .col-lg-offset-8 {
    margin-left: 66.66667%;
  }

  .style-alt .col-lg-offset-9 {
    margin-left: 75%;
  }

  .style-alt .col-lg-offset-10 {
    margin-left: 83.33333%;
  }

  .style-alt .col-lg-offset-11 {
    margin-left: 91.66667%;
  }

  .style-alt .col-lg-offset-12 {
    margin-left: 100%;
  }
}

.style-alt .gutter-12.row {
  margin-right: -6px;
  margin-left: -6px;
}

.style-alt .gutter-12>[class^="col-"],
.style-alt .gutter-12>[class^=" col-"] {
  padding-right: 6px;
  padding-left: 6px;
}

.style-alt .gutter-20.row {
  margin-right: -10px;
  margin-left: -10px;
}

.style-alt .gutter-20>[class^="col-"],
.style-alt .gutter-20>[class^=" col-"] {
  padding-right: 10px;
  padding-left: 10px;
}

.style-alt .row.border-top-inset,
.style-alt .panel .panel-body.panel-body-details>.row {
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 1px 0 0 #fff inset;
}

.style-alt .row.border-top-inset:first-of-type,
.style-alt .panel .panel-body.panel-body-details>.row:first-of-type {
  box-shadow: none;
  border-top: none;
}

.style-alt .row.row-eq-height {
  display: flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.style-alt .row.row-eq-height>div[class*="col-"],
.style-alt .row.row-eq-height>div[class*="col-"]>.panel,
.style-alt .row.row-eq-height>div[class*="col-"]>.panel>.panel-body {
  display: inherit;
  flex-direction: column;
}

.style-alt .row.row-eq-height>div[class*="col-"]>.panel,
.style-alt .row.row-eq-height>div[class*="col-"]>.panel>.panel-body {
  flex-grow: 1;
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
}

.style-alt .row.address-book {
  margin-bottom: 15px;
}

.style-alt table {
  background-color: #f6f6f6;
}

.style-alt table col[class*="col-"] {
  position: static;
  display: table-column;
  float: none;
}

.style-alt table td[class*="col-"],
.style-alt table th[class*="col-"] {
  position: static;
  display: table-cell;
  float: none;
}

.style-alt caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777;
  text-align: left;
}

.style-alt th {
  text-align: left;
}

.style-alt .table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 17px;
}

.style-alt .table>thead>tr>th,
.style-alt .table>thead>tr>td,
.style-alt .table>tbody>tr>th,
.style-alt .table>tbody>tr>td,
.style-alt .table>tfoot>tr>th,
.style-alt .table>tfoot>tr>td {
  padding: 8px;
  line-height: 1.42857;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.style-alt .table>thead>tr>th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}

.style-alt .table>caption+thead>tr:first-child>th,
.style-alt .table>caption+thead>tr:first-child>td,
.style-alt .table>colgroup+thead>tr:first-child>th,
.style-alt .table>colgroup+thead>tr:first-child>td,
.style-alt .table>thead:first-child>tr:first-child>th,
.style-alt .table>thead:first-child>tr:first-child>td {
  border-top: 0;
}

.style-alt .table>tbody+tbody {
  border-top: 2px solid #ddd;
}

.style-alt .table .table {
  background-color: #fff;
}

.style-alt .table-condensed>thead>tr>th,
.style-alt .table-condensed>thead>tr>td,
.style-alt .table-condensed>tbody>tr>th,
.style-alt .table-condensed>tbody>tr>td,
.style-alt .table-condensed>tfoot>tr>th,
.style-alt .table-condensed>tfoot>tr>td {
  padding: 5px;
}

.style-alt .table-bordered {
  border: 1px solid #ddd;
}

.style-alt .table-bordered>thead>tr>th,
.style-alt .table-bordered>thead>tr>td,
.style-alt .table-bordered>tbody>tr>th,
.style-alt .table-bordered>tbody>tr>td,
.style-alt .table-bordered>tfoot>tr>th,
.style-alt .table-bordered>tfoot>tr>td {
  border: 1px solid #ddd;
}

.style-alt .table-bordered>thead>tr>th,
.style-alt .table-bordered>thead>tr>td {
  border-bottom-width: 2px;
}

.style-alt .table-striped>tbody>tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.style-alt .table-hover>tbody>tr:hover {
  background-color: #f5f5f5;
}

.style-alt .table>thead>tr>td.active,
.style-alt .table>thead>tr>th.active,
.style-alt .table>thead>tr.active>td,
.style-alt .table>thead>tr.active>th,
.style-alt .table>tbody>tr>td.active,
.style-alt .table>tbody>tr>th.active,
.style-alt .table>tbody>tr.active>td,
.style-alt .table>tbody>tr.active>th,
.style-alt .table>tfoot>tr>td.active,
.style-alt .table>tfoot>tr>th.active,
.style-alt .table>tfoot>tr.active>td,
.style-alt .table>tfoot>tr.active>th {
  background-color: #f5f5f5;
}

.style-alt .table-hover>tbody>tr>td.active:hover,
.style-alt .table-hover>tbody>tr>th.active:hover,
.style-alt .table-hover>tbody>tr.active:hover>td,
.style-alt .table-hover>tbody>tr:hover>.active,
.style-alt .table-hover>tbody>tr.active:hover>th {
  background-color: #e8e8e8;
}

.style-alt .table>thead>tr>td.success,
.style-alt .table>thead>tr>th.success,
.style-alt .table>thead>tr.success>td,
.style-alt .table>thead>tr.success>th,
.style-alt .table>tbody>tr>td.success,
.style-alt .table>tbody>tr>th.success,
.style-alt .table>tbody>tr.success>td,
.style-alt .table>tbody>tr.success>th,
.style-alt .table>tfoot>tr>td.success,
.style-alt .table>tfoot>tr>th.success,
.style-alt .table>tfoot>tr.success>td,
.style-alt .table>tfoot>tr.success>th {
  background-color: #dff0d8;
}

.style-alt .table-hover>tbody>tr>td.success:hover,
.style-alt .table-hover>tbody>tr>th.success:hover,
.style-alt .table-hover>tbody>tr.success:hover>td,
.style-alt .table-hover>tbody>tr:hover>.success,
.style-alt .table-hover>tbody>tr.success:hover>th {
  background-color: #d0e9c6;
}

.style-alt .table>thead>tr>td.info,
.style-alt .table>thead>tr>th.info,
.style-alt .table>thead>tr.info>td,
.style-alt .table>thead>tr.info>th,
.style-alt .table>tbody>tr>td.info,
.style-alt .table>tbody>tr>th.info,
.style-alt .table>tbody>tr.info>td,
.style-alt .table>tbody>tr.info>th,
.style-alt .table>tfoot>tr>td.info,
.style-alt .table>tfoot>tr>th.info,
.style-alt .table>tfoot>tr.info>td,
.style-alt .table>tfoot>tr.info>th {
  background-color: #d9edf7;
}

.style-alt .table-hover>tbody>tr>td.info:hover,
.style-alt .table-hover>tbody>tr>th.info:hover,
.style-alt .table-hover>tbody>tr.info:hover>td,
.style-alt .table-hover>tbody>tr:hover>.info,
.style-alt .table-hover>tbody>tr.info:hover>th {
  background-color: #c4e3f3;
}

.style-alt .table>thead>tr>td.warning,
.style-alt .table>thead>tr>th.warning,
.style-alt .table>thead>tr.warning>td,
.style-alt .table>thead>tr.warning>th,
.style-alt .table>tbody>tr>td.warning,
.style-alt .table>tbody>tr>th.warning,
.style-alt .table>tbody>tr.warning>td,
.style-alt .table>tbody>tr.warning>th,
.style-alt .table>tfoot>tr>td.warning,
.style-alt .table>tfoot>tr>th.warning,
.style-alt .table>tfoot>tr.warning>td,
.style-alt .table>tfoot>tr.warning>th {
  background-color: #fcf8e3;
}

.style-alt .table-hover>tbody>tr>td.warning:hover,
.style-alt .table-hover>tbody>tr>th.warning:hover,
.style-alt .table-hover>tbody>tr.warning:hover>td,
.style-alt .table-hover>tbody>tr:hover>.warning,
.style-alt .table-hover>tbody>tr.warning:hover>th {
  background-color: #faf2cc;
}

.style-alt .table>thead>tr>td.danger,
.style-alt .table>thead>tr>th.danger,
.style-alt .table>thead>tr.danger>td,
.style-alt .table>thead>tr.danger>th,
.style-alt .table>tbody>tr>td.danger,
.style-alt .table>tbody>tr>th.danger,
.style-alt .table>tbody>tr.danger>td,
.style-alt .table>tbody>tr.danger>th,
.style-alt .table>tfoot>tr>td.danger,
.style-alt .table>tfoot>tr>th.danger,
.style-alt .table>tfoot>tr.danger>td,
.style-alt .table>tfoot>tr.danger>th {
  background-color: #f2dede;
}

.style-alt .table-hover>tbody>tr>td.danger:hover,
.style-alt .table-hover>tbody>tr>th.danger:hover,
.style-alt .table-hover>tbody>tr.danger:hover>td,
.style-alt .table-hover>tbody>tr:hover>.danger,
.style-alt .table-hover>tbody>tr.danger:hover>th {
  background-color: #ebcccc;
}

.style-alt .table-responsive {
  min-height: 0.01%;
  overflow-x: auto;
}

@media screen and (max-width: 767px) {
  .style-alt .table-responsive {
    width: 100%;
    margin-bottom: 12.75px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
  }

  .style-alt .table-responsive>.table {
    margin-bottom: 0;
  }

  .style-alt .table-responsive>.table>thead>tr>th,
  .style-alt .table-responsive>.table>thead>tr>td,
  .style-alt .table-responsive>.table>tbody>tr>th,
  .style-alt .table-responsive>.table>tbody>tr>td,
  .style-alt .table-responsive>.table>tfoot>tr>th,
  .style-alt .table-responsive>.table>tfoot>tr>td {
    white-space: nowrap;
  }

  .style-alt .table-responsive>.table-bordered {
    border: 0;
  }

  .style-alt .table-responsive>.table-bordered>thead>tr>th:first-child,
  .style-alt .table-responsive>.table-bordered>thead>tr>td:first-child,
  .style-alt .table-responsive>.table-bordered>tbody>tr>th:first-child,
  .style-alt .table-responsive>.table-bordered>tbody>tr>td:first-child,
  .style-alt .table-responsive>.table-bordered>tfoot>tr>th:first-child,
  .style-alt .table-responsive>.table-bordered>tfoot>tr>td:first-child {
    border-left: 0;
  }

  .style-alt .table-responsive>.table-bordered>thead>tr>th:last-child,
  .style-alt .table-responsive>.table-bordered>thead>tr>td:last-child,
  .style-alt .table-responsive>.table-bordered>tbody>tr>th:last-child,
  .style-alt .table-responsive>.table-bordered>tbody>tr>td:last-child,
  .style-alt .table-responsive>.table-bordered>tfoot>tr>th:last-child,
  .style-alt .table-responsive>.table-bordered>tfoot>tr>td:last-child {
    border-right: 0;
  }

  .style-alt .table-responsive>.table-bordered>tbody>tr:last-child>th,
  .style-alt .table-responsive>.table-bordered>tbody>tr:last-child>td,
  .style-alt .table-responsive>.table-bordered>tfoot>tr:last-child>th,
  .style-alt .table-responsive>.table-bordered>tfoot>tr:last-child>td {
    border-bottom: 0;
  }
}

.style-alt table.table.table-g4m-default,
.style-alt table.table.table-g4m-basket {
  border-collapse: separate;
  width: 100%;
  border-spacing: 0;
  border-radius: 2px;
}

.style-alt table.table.table-g4m-default.right-header>thead>tr>th:last-child,
.style-alt table.table.right-header.table-g4m-basket>thead>tr>th:last-child,
.style-alt table.table.table-g4m-default.right-header>tbody>tr>td:last-child,
.style-alt table.table.right-header.table-g4m-basket>tbody>tr>td:last-child {
  text-align: right;
  padding-right: 6%;
}

.style-alt table.table.table-g4m-default>thead>tr>th,
.style-alt table.table.table-g4m-basket>thead>tr>th,
.style-alt table.table.table-g4m-default>tfoot>tr>th,
.style-alt table.table.table-g4m-basket>tfoot>tr>th {
  background: #ededed;
  color: #595c5e;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.167em;
  text-align: left;
  margin: 0 0 13px;
  padding: 10px 18px;
  vertical-align: middle;
  border-bottom: 0;
}

.style-alt table.table.table-g4m-default>thead>tr:first-child>th:first-child,
.style-alt table.table.table-g4m-basket>thead>tr:first-child>th:first-child,
.style-alt table.table.table-g4m-default>tfoot>tr:first-child>th:first-child,
.style-alt table.table.table-g4m-basket>tfoot>tr:first-child>th:first-child {
  border-top-left-radius: 3px;
}

.style-alt table.table.table-g4m-default>thead>tr:first-child>th:last-child,
.style-alt table.table.table-g4m-basket>thead>tr:first-child>th:last-child,
.style-alt table.table.table-g4m-default>tfoot>tr:first-child>th:last-child,
.style-alt table.table.table-g4m-basket>tfoot>tr:first-child>th:last-child {
  border-top-right-radius: 3px;
}

.style-alt table.table.table-g4m-default>tbody>tr:first-child td,
.style-alt table.table.table-g4m-basket>tbody>tr:first-child td {
  border-top: none;
  box-shadow: none;
}

.style-alt table.table.table-g4m-default>tbody>tr>td,
.style-alt table.table.table-g4m-basket>tbody>tr>td {
  padding: 10px 8px;
  border-top: 0;
  text-align: left;
}

.style-alt table.table.table-g4m-default>tbody>tr>td.no-border,
.style-alt table.table.table-g4m-basket>tbody>tr>td.no-border {
  border: none;
  box-shadow: none;
}

@media screen and (max-width: 768px) {

  .style-alt table.table.table-g4m-default>tbody>tr>td.no-border-mobile,
  .style-alt table.table.table-g4m-basket>tbody>tr>td.no-border-mobile {
    border: none;
    box-shadow: none;
  }
}

.style-alt table.table.table-g4m-default>tbody>tr>td.img-responsive-cell,
.style-alt table.table.table-g4m-basket>tbody>tr>td.img-responsive-cell {
  max-width: 65px;
  min-width: 65px;
}

.style-alt table.table.table-g4m-default>tbody>tr>td.img-responsive-cell img,
.style-alt table.table.table-g4m-basket>tbody>tr>td.img-responsive-cell img {
  min-width: 38px;
}

.style-alt table.table.table-g4m-default>tbody>tr>td:first-child,
.style-alt table.table.table-g4m-basket>tbody>tr>td:first-child {
  padding-left: 20px;
}

.style-alt table.table.table-g4m-default>tbody>tr>td:last-child,
.style-alt table.table.table-g4m-basket>tbody>tr>td:last-child {
  padding-right: 20px;
}

.style-alt table.table.table-g4m-default>tbody>tr.no-border td,
.style-alt table.table.table-g4m-basket>tbody>tr.no-border td {
  border: none;
  box-shadow: none;
}

.style-alt table.table.table-g4m-default>tbody>tr.row-info>td,
.style-alt table.table.table-g4m-basket>tbody>tr.row-info>td {
  padding: 10px 8px;
  vertical-align: middle;
}

.style-alt table.table.table-g4m-default>tbody>tr.row-info>td:first-child,
.style-alt table.table.table-g4m-basket>tbody>tr.row-info>td:first-child {
  padding-left: 20px;
}

.style-alt .table.table-social-sharing>tbody>tr>td {
  border-bottom: none !important;
}

.style-alt .table.table-social-sharing>tbody>tr>td tr td,
.style-alt .table.table-social-sharing>tbody>tr>td tr th {
  background-color: #f6f6f6;
  vertical-align: middle !important;
  box-shadow: none !important;
}

.style-alt .table.table-social-sharing>tbody>tr>td tr td img.img-thumbnail,
.style-alt .table.table-social-sharing>tbody>tr>td tr th img.img-thumbnail {
  max-width: none;
  width: 60px;
}

.style-alt .table.table-social-sharing>tbody>tr>td:first-of-type {
  padding: 10px 3px;
}

.style-alt .table.table-social-sharing>tbody>tr>td:last-of-type>.row>div[class*="col-"] {
  margin: 2px 0;
  text-align: center;
}

.style-alt table .share-footer div.share-text-container {
  float: none !important;
  float: left !important;
}

.style-alt table .share-footer div.share-icons-container {
  float: none !important;
  float: right !important;
}

.style-alt .total-sub-title {
  font-size: 0.55em;
  color: #434649;
  font-weight: bold;
  margin: 0;
  clear: both;
  display: block;
}

.style-alt table.table.table-g4m-basket {
  margin-bottom: 0;
}

.style-alt table.table.table-g4m-basket>tbody>tr>td {
  padding: 10px 8px 3px 8px;
}

.style-alt table.table.table-g4m-basket>tbody>tr>td.cell-pad-right-desktop {
  padding-right: 20px;
}

.style-alt table.table.table-g4m-basket>tbody>tr>td span.strikethrough {
  text-decoration: line-through;
  width: 100%;
  display: inline-block;
}

.style-alt table.table.table-g4m-basket>tbody>tr>td.quantity-cell>span {
  display: inline-block;
  float: right;
}

.style-alt table.table.table-g4m-basket>tbody>tr>td.img-responsive-cell {
  padding: 10px;
  width: 88px;
  max-width: 88px;
  min-width: 88px;
}

.style-alt table.table.table-g4m-basket>tbody>tr>td:first-of-type {
  padding-left: 10px;
}

.style-alt table.table.table-g4m-basket>tbody>tr>td:last-of-type {
  padding-right: 10px;
  width: 32px;
}

.style-alt table.table.table-g4m-basket>tbody>tr>td:last-of-type.remove-line-cell {
  padding: 8px 16px 12px 16px;
}

.style-alt table.table.table-g4m-basket>tbody>tr>td a.remove-basket-line {
  cursor: pointer;
}

.style-alt table.table.table-g4m-basket>thead>tr>th {
  padding: 10px 8px;
}

.style-alt table.table.table-g4m-basket>thead>tr>th:first-of-type {
  padding-left: 10px;
}

.style-alt table.table.table-g4m-basket>thead>tr>th:last-of-type {
  padding-right: 10px;
}

.style-alt table.table.table-g4m-basket>thead>tr>th.item-price-each,
.style-alt table.table.table-g4m-basket>thead>tr>th.item-price-total,
.style-alt table.table.table-g4m-basket>thead>tr>th.item-qty {
  width: 100px;
  word-break: break-all;
  word-break: break-word;
}

.style-alt table.table.table-g4m-basket>thead>tr>th:not(:first-child) {
  text-align: center;
  vertical-align: middle;
}

.style-alt table.table.table-g4m-basket>tbody>tr:not(.row-collapse) {
  height: 33px;
}

.style-alt table.table.table-g4m-basket>tbody>tr.row-collapse>td {
  border: none;
  box-shadow: none;
  padding-top: 0;
}

.style-alt table.table.table-g4m-basket>tbody>tr.row-collapse>td:first-of-type {
  padding-left: 8px;
}

.style-alt table.highlighted-list {
  border-spacing: 0;
}

.style-alt table.highlighted-list tbody>tr.selected>td {
  background: #e9efd7;
  border-top: 1px solid #faa82a;
  border-bottom: 1px solid #faa82a;
}

.style-alt table.highlighted-list tbody>tr.selected>td.service-category-desc {
  border-left: 1px solid #faa82a;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.style-alt table.highlighted-list tbody>tr.selected>td.svc-desc-mobile-toggle {
  border-left: 1px solid #faa82a;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.style-alt table.highlighted-list tbody>tr.selected>td:last-child {
  border-right: 1px solid #faa82a;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.style-alt table.highlighted-list tbody>tr.selected td:last-of-type {
  width: 11px;
}

.style-alt table.highlighted-list tbody>tr.selected td:last-of-type:before {
  content: "";
}

.style-alt table.highlighted-list tbody>tr>td {
  padding: 9px 5px;
}

.style-alt table.highlighted-list tbody>tr>td:first-of-type {
  padding-left: 9px;
}

.style-alt table.highlighted-list tbody>tr>td:last-of-type {
  padding-right: 5px;
  padding-left: 18px;
}

.style-alt table.table.table-g4m-blank {
  background-color: transparent;
}

.style-alt table.table.table-g4m-blank td {
  border: none;
}

.style-alt td.svc-desc-mobile-toggle {
  cursor: pointer;
  min-width: 20px;
}

.style-alt td.svc-desc-mobile-toggle span {
  display: block;
  width: 0;
  height: 0;
  top: 4px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid #a5a5a5;
  position: relative;
}

.style-alt td.svc-desc-mobile-toggle span:after {
  content: "";
  position: absolute;
  top: -11px;
  left: -6px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid #f6f6f6;
}

.style-alt table.highlighted-list tbody>tr.select-service-category td.svc-desc-mobile-toggle {
  display: none;
}

.style-alt table.highlighted-list tbody>tr.select-service-category:not(.selected):hover td {
  background-color: rgba(233, 239, 215, 0.5);
}

.style-alt table.highlighted-list tbody>tr.select-service-category:not(.selected):hover td.service-category-desc {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.style-alt table.highlighted-list tbody>tr.select-service-category:not(.selected):hover td:last-child {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.style-alt table.highlighted-list tbody>tr.select-service-category.s2s-service-category td {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.style-alt tr.svc-desc-mobile {
  display: none;
}

@media screen and (max-width: 991px) {
  .style-alt tr.svc-desc-mobile {
    background-color: #e8e8e8;
    display: table-row;
  }

  .style-alt tr.svc-desc-mobile td {
    padding: 0 !important;
  }

  .style-alt tr.svc-desc-mobile td>div {
    padding-left: 20px;
  }

  .style-alt tr.svc-desc-mobile td>div span {
    display: block;
    padding: 10px;
  }
}

.style-alt table.service-category-list tbody tr td.service-category-desc {
  width: auto;
}

.style-alt table.service-category-list tbody tr td.service-category-radio {
  width: 10px;
}

.style-alt table.service-category-list tbody tr td.service-category-price {
  width: 100px;
}

.style-alt fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.style-alt legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 17px;
  font-size: 18px;
  line-height: inherit;
  color: #434649;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}

.style-alt label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

.style-alt input[type="search"] {
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.style-alt input[type="radio"],
.style-alt input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
}

.style-alt input[type="radio"][disabled],
.style-alt input[type="radio"].disabled,
fieldset[disabled] .style-alt input[type="radio"],
.style-alt input[type="checkbox"][disabled],
.style-alt input[type="checkbox"].disabled,
fieldset[disabled] .style-alt input[type="checkbox"] {
  cursor: not-allowed;
}

.style-alt input[type="file"] {
  display: block;
}

.style-alt input[type="range"] {
  display: block;
  width: 100%;
}

.style-alt select[multiple],
.style-alt select[size] {
  height: auto;
}

.style-alt input[type="file"]:focus,
.style-alt input[type="radio"]:focus,
.style-alt input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.style-alt output {
  display: block;
  padding-top: 7px;
  font-size: 12px;
  line-height: 1.42857;
  color: #595c5e;
}

.style-alt .form-control {
  display: block;
  width: 100%;
  height: 31px;
  padding: 6px 12px;
  font-size: 12px;
  line-height: 1.42857;
  color: #595c5e;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
}

.style-alt .form-control:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.style-alt .form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.style-alt .form-control:-ms-input-placeholder {
  color: #999;
}

.style-alt .form-control::-webkit-input-placeholder {
  color: #999;
}

.style-alt .form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.style-alt .form-control[disabled],
.style-alt .form-control[readonly],
fieldset[disabled] .style-alt .form-control {
  background-color: #e6e6e6;
  opacity: 1;
}

.style-alt .form-control[disabled],
fieldset[disabled] .style-alt .form-control {
  cursor: not-allowed;
}

.style-alt textarea.form-control {
  height: auto;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {

  .style-alt input[type="date"].form-control,
  .style-alt input[type="time"].form-control,
  .style-alt input[type="datetime-local"].form-control,
  .style-alt input[type="month"].form-control {
    line-height: 31px;
  }

  .style-alt input[type="date"].input-sm,
  .input-group-sm .style-alt input[type="date"],
  .style-alt input[type="time"].input-sm,
  .input-group-sm .style-alt input[type="time"],
  .style-alt input[type="datetime-local"].input-sm,
  .input-group-sm .style-alt input[type="datetime-local"],
  .style-alt input[type="month"].input-sm,
  .input-group-sm .style-alt input[type="month"] {
    line-height: 28px;
  }

  .style-alt input[type="date"].input-lg,
  .input-group-lg .style-alt input[type="date"],
  .style-alt input[type="time"].input-lg,
  .input-group-lg .style-alt input[type="time"],
  .style-alt input[type="datetime-local"].input-lg,
  .input-group-lg .style-alt input[type="datetime-local"],
  .style-alt input[type="month"].input-lg,
  .input-group-lg .style-alt input[type="month"] {
    line-height: 46px;
  }
}

.style-alt .form-group {
  margin-bottom: 15px;
}

.style-alt .radio,
.style-alt .checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.style-alt .radio.disabled label,
fieldset[disabled] .style-alt .radio label,
.style-alt .checkbox.disabled label,
fieldset[disabled] .style-alt .checkbox label {
  cursor: not-allowed;
}

.style-alt .radio label,
.style-alt .checkbox label {
  min-height: 17px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
}

.style-alt .radio input[type="radio"],
.style-alt .radio-inline input[type="radio"],
.style-alt .checkbox input[type="checkbox"],
.style-alt .checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-top: 4px \9;
  margin-left: -20px;
}

.style-alt .radio+.radio,
.style-alt .checkbox+.checkbox {
  margin-top: -5px;
}

.style-alt .radio-inline,
.style-alt .checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;
}

.style-alt .radio-inline.disabled,
fieldset[disabled] .style-alt .radio-inline,
.style-alt .checkbox-inline.disabled,
fieldset[disabled] .style-alt .checkbox-inline {
  cursor: not-allowed;
}

.style-alt .radio-inline+.radio-inline,
.style-alt .checkbox-inline+.checkbox-inline {
  margin-top: 0;
  margin-left: 10px;
}

.style-alt .form-control-static {
  min-height: 29px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
}

.style-alt .form-control-static.input-lg,
.style-alt .form-control-static.input-sm {
  padding-right: 0;
  padding-left: 0;
}

.style-alt .input-sm {
  height: 28px;
  padding: 5px 10px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 3px;
}

.style-alt select.input-sm {
  height: 28px;
  line-height: 28px;
}

.style-alt textarea.input-sm,
.style-alt select[multiple].input-sm {
  height: auto;
}

.style-alt .form-group-sm .form-control {
  height: 28px;
  padding: 5px 10px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 3px;
}

.style-alt .form-group-sm select.form-control {
  height: 28px;
  line-height: 28px;
}

.style-alt .form-group-sm textarea.form-control,
.style-alt .form-group-sm select[multiple].form-control {
  height: auto;
}

.style-alt .form-group-sm .form-control-static {
  height: 28px;
  min-height: 28px;
  padding: 6px 10px;
  font-size: 11px;
  line-height: 1.5;
}

.style-alt .input-lg {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  border-radius: 6px;
}

.style-alt select.input-lg {
  height: 46px;
  line-height: 46px;
}

.style-alt textarea.input-lg,
.style-alt select[multiple].input-lg {
  height: auto;
}

.style-alt .form-group-lg .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  border-radius: 6px;
}

.style-alt .form-group-lg select.form-control {
  height: 46px;
  line-height: 46px;
}

.style-alt .form-group-lg textarea.form-control,
.style-alt .form-group-lg select[multiple].form-control {
  height: auto;
}

.style-alt .form-group-lg .form-control-static {
  height: 46px;
  min-height: 35px;
  padding: 11px 16px;
  font-size: 18px;
  line-height: 1.33333;
}

.style-alt .has-feedback {
  position: relative;
}

.style-alt .has-feedback .form-control {
  padding-right: 38.75px;
}

.style-alt .form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 31px;
  height: 31px;
  line-height: 31px;
  text-align: center;
  pointer-events: none;
}

.style-alt .input-lg+.form-control-feedback,
.style-alt .input-group-lg+.form-control-feedback,
.style-alt .form-group-lg .form-control+.form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px;
}

.style-alt .input-sm+.form-control-feedback,
.style-alt .input-group-sm+.form-control-feedback,
.style-alt .form-group-sm .form-control+.form-control-feedback {
  width: 28px;
  height: 28px;
  line-height: 28px;
}

.style-alt .has-success .help-block,
.style-alt .has-success .control-label,
.style-alt .has-success .radio,
.style-alt .has-success .checkbox,
.style-alt .has-success .radio-inline,
.style-alt .has-success .checkbox-inline,
.style-alt .has-success.radio label,
.style-alt .has-success.checkbox label,
.style-alt .has-success.radio-inline label,
.style-alt .has-success.checkbox-inline label {
  color: #3c763d;
}

.style-alt .has-success .form-control {
  border-color: #3c763d;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.style-alt .has-success .form-control:focus {
  border-color: #2b542c;
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 6px #67b168;
}

.style-alt .has-success .input-group-addon {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #3c763d;
}

.style-alt .has-success .form-control-feedback {
  color: #3c763d;
}

.style-alt .has-warning .help-block,
.style-alt .has-warning .control-label,
.style-alt .has-warning .radio,
.style-alt .has-warning .checkbox,
.style-alt .has-warning .radio-inline,
.style-alt .has-warning .checkbox-inline,
.style-alt .has-warning.radio label,
.style-alt .has-warning.checkbox label,
.style-alt .has-warning.radio-inline label,
.style-alt .has-warning.checkbox-inline label {
  color: #8a6d3b;
}

.style-alt .has-warning .form-control {
  border-color: #8a6d3b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.style-alt .has-warning .form-control:focus {
  border-color: #66512c;
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 6px #c0a16b;
}

.style-alt .has-warning .input-group-addon {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #8a6d3b;
}

.style-alt .has-warning .form-control-feedback {
  color: #8a6d3b;
}

.style-alt .has-error .help-block,
.style-alt .has-error .control-label,
.style-alt .has-error .radio,
.style-alt .has-error .checkbox,
.style-alt .has-error .radio-inline,
.style-alt .has-error .checkbox-inline,
.style-alt .has-error.radio label,
.style-alt .has-error.checkbox label,
.style-alt .has-error.radio-inline label,
.style-alt .has-error.checkbox-inline label {
  color: #a94442;
}

.style-alt .has-error .form-control {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.style-alt .has-error .form-control:focus {
  border-color: #843534;
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 6px #ce8483;
}

.style-alt .has-error .input-group-addon {
  color: #a94442;
  background-color: #f2dede;
  border-color: #a94442;
}

.style-alt .has-error .form-control-feedback {
  color: #a94442;
}

.style-alt .has-feedback label~.form-control-feedback {
  top: 22px;
}

.style-alt .has-feedback label.sr-only~.form-control-feedback {
  top: 0;
}

.style-alt .help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #81868b;
}

@media (min-width: 768px) {
  .style-alt .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }

  .style-alt .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .style-alt .form-inline .form-control-static {
    display: inline-block;
  }

  .style-alt .form-inline .input-group {
    display: inline-table;
    vertical-align: middle;
  }

  .style-alt .form-inline .input-group .input-group-addon,
  .style-alt .form-inline .input-group .input-group-btn,
  .style-alt .form-inline .input-group .form-control {
    width: auto;
  }

  .style-alt .form-inline .input-group>.form-control {
    width: 100%;
  }

  .style-alt .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }

  .style-alt .form-inline .radio,
  .style-alt .form-inline .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }

  .style-alt .form-inline .radio label,
  .style-alt .form-inline .checkbox label {
    padding-left: 0;
  }

  .style-alt .form-inline .radio input[type="radio"],
  .style-alt .form-inline .checkbox input[type="checkbox"] {
    position: relative;
    margin-left: 0;
  }

  .style-alt .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

.style-alt .form-horizontal .radio,
.style-alt .form-horizontal .checkbox,
.style-alt .form-horizontal .radio-inline,
.style-alt .form-horizontal .checkbox-inline {
  padding-top: 7px;
  margin-top: 0;
  margin-bottom: 0;
}

.style-alt .form-horizontal .radio,
.style-alt .form-horizontal .checkbox {
  min-height: 24px;
}

.style-alt .form-horizontal .form-group {
  margin-right: -15px;
  margin-left: -15px;
}

.style-alt .form-horizontal .form-group:before,
.style-alt .form-horizontal .form-group:after {
  display: table;
  content: " ";
}

.style-alt .form-horizontal .form-group:after {
  clear: both;
}

@media (min-width: 768px) {
  .style-alt .form-horizontal .control-label {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: right;
  }
}

.style-alt .form-horizontal .has-feedback .form-control-feedback {
  right: 15px;
}

@media (min-width: 768px) {
  .style-alt .form-horizontal .form-group-lg .control-label {
    padding-top: 11px;
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .style-alt .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 11px;
  }
}

.style-alt label {
  font-weight: normal;
  display: inline;
}

.style-alt .form input[type="text"],
.style-alt .form select,
.style-alt .form textarea {
  height: 26px;
}

.style-alt .form select[disabled] {
  background-color: #e6e6e6;
}

.style-alt .form input[type="text"],
.style-alt .form select,
.style-alt .form dd {
  width: 50%;
  vertical-align: top;
}

.style-alt .form input[type="text"]>input[type="text"],
.style-alt .form input[type="text"]>input[type="email"],
.style-alt .form input[type="text"]>input[type="url"],
.style-alt .form input[type="text"]>input[type="number"],
.style-alt .form input[type="text"]>input[type="password"],
.style-alt .form input[type="text"]>input[type="tel"],
.style-alt .form input[type="text"]>textarea,
.style-alt .form input[type="text"]>select,
.style-alt .form select>input[type="text"],
.style-alt .form select>input[type="email"],
.style-alt .form select>input[type="url"],
.style-alt .form select>input[type="number"],
.style-alt .form select>input[type="password"],
.style-alt .form select>input[type="tel"],
.style-alt .form select>textarea,
.style-alt .form select>select,
.style-alt .form dd>input[type="text"],
.style-alt .form dd>input[type="email"],
.style-alt .form dd>input[type="url"],
.style-alt .form dd>input[type="number"],
.style-alt .form dd>input[type="password"],
.style-alt .form dd>input[type="tel"],
.style-alt .form dd>textarea,
.style-alt .form dd>select {
  width: 100%;
  max-width: 100%;
}

.style-alt .form input[type="text"]>i.more-info-icon,
.style-alt .form select>i.more-info-icon,
.style-alt .form dd>i.more-info-icon {
  right: -18px;
  top: 7px;
  position: absolute;
}

.style-alt output {
  display: inline;
}

.style-alt input[type="radio"],
.style-alt input[type="checkbox"] {
  margin: 0;
}

.style-alt .btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 12px;
  line-height: 1.42857;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.style-alt .btn:focus,
.style-alt .btn.focus,
.style-alt .btn:active:focus,
.style-alt .btn:active.focus,
.style-alt .btn.active:focus,
.style-alt .btn.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.style-alt .btn:hover,
.style-alt .btn:focus,
.style-alt .btn.focus {
  color: #434649;
  text-decoration: none;
}

.style-alt .btn:active,
.style-alt .btn.active {
  background-image: none;
  outline: 0;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.style-alt .btn.disabled,
.style-alt .btn[disabled],
fieldset[disabled] .style-alt .btn {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  box-shadow: none;
}

.style-alt a.btn.disabled,
fieldset[disabled] .style-alt a.btn {
  pointer-events: none;
}

.style-alt .btn-default {
  color: #434649;
  background-color: #dedede;
  border-color: #dedede;
}

.style-alt .btn-default:focus,
.style-alt .btn-default.focus {
  color: #434649;
  background-color: #c5c5c5;
  border-color: #9e9e9e;
}

.style-alt .btn-default:hover {
  color: #434649;
  background-color: #c5c5c5;
  border-color: #bfbfbf;
}

.style-alt .btn-default:active,
.style-alt .btn-default.active,
.open>.style-alt .btn-default.dropdown-toggle {
  color: #434649;
  background-color: #c5c5c5;
  background-image: none;
  border-color: #bfbfbf;
}

.style-alt .btn-default:active:hover,
.style-alt .btn-default:active:focus,
.style-alt .btn-default:active.focus,
.style-alt .btn-default.active:hover,
.style-alt .btn-default.active:focus,
.style-alt .btn-default.active.focus,
.open>.style-alt .btn-default.dropdown-toggle:hover,
.open>.style-alt .btn-default.dropdown-toggle:focus,
.open>.style-alt .btn-default.dropdown-toggle.focus {
  color: #434649;
  background-color: #b3b3b3;
  border-color: #9e9e9e;
}

.style-alt .btn-default.disabled:hover,
.style-alt .btn-default.disabled:focus,
.style-alt .btn-default.disabled.focus,
.style-alt .btn-default[disabled]:hover,
.style-alt .btn-default[disabled]:focus,
.style-alt .btn-default[disabled].focus,
fieldset[disabled] .style-alt .btn-default:hover,
fieldset[disabled] .style-alt .btn-default:focus,
fieldset[disabled] .style-alt .btn-default.focus {
  background-color: #dedede;
  border-color: #dedede;
}

.style-alt .btn-default .badge {
  color: #dedede;
  background-color: #434649;
}

.style-alt .btn-primary {
  color: #fff;
  background-color: #f56a03;
  border-color: #f56a03;
}

.style-alt .btn-primary:focus,
.style-alt .btn-primary.focus {
  color: #fff;
  background-color: #c35402;
  border-color: #773401;
}

.style-alt .btn-primary:hover {
  color: #fff;
  background-color: #c35402;
  border-color: #b95002;
}

.style-alt .btn-primary:active,
.style-alt .btn-primary.active,
.open>.style-alt .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #c35402;
  background-image: none;
  border-color: #b95002;
}

.style-alt .btn-primary:active:hover,
.style-alt .btn-primary:active:focus,
.style-alt .btn-primary:active.focus,
.style-alt .btn-primary.active:hover,
.style-alt .btn-primary.active:focus,
.style-alt .btn-primary.active.focus,
.open>.style-alt .btn-primary.dropdown-toggle:hover,
.open>.style-alt .btn-primary.dropdown-toggle:focus,
.open>.style-alt .btn-primary.dropdown-toggle.focus {
  color: #fff;
  background-color: #9f4502;
  border-color: #773401;
}

.style-alt .btn-primary.disabled:hover,
.style-alt .btn-primary.disabled:focus,
.style-alt .btn-primary.disabled.focus,
.style-alt .btn-primary[disabled]:hover,
.style-alt .btn-primary[disabled]:focus,
.style-alt .btn-primary[disabled].focus,
fieldset[disabled] .style-alt .btn-primary:hover,
fieldset[disabled] .style-alt .btn-primary:focus,
fieldset[disabled] .style-alt .btn-primary.focus {
  background-color: #f56a03;
  border-color: #f56a03;
}

.style-alt .btn-primary .badge {
  color: #f56a03;
  background-color: #fff;
}

.style-alt .btn-success {
  color: #fff;
  background-color: #89bd4d;
  border-color: #89bd4d;
}

.style-alt .btn-success:focus,
.style-alt .btn-success.focus {
  color: #fff;
  background-color: #6f9d3a;
  border-color: #486525;
}

.style-alt .btn-success:hover {
  color: #fff;
  background-color: #6f9d3a;
  border-color: #6a9537;
}

.style-alt .btn-success:active,
.style-alt .btn-success.active,
.open>.style-alt .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #6f9d3a;
  background-image: none;
  border-color: #6a9537;
}

.style-alt .btn-success:active:hover,
.style-alt .btn-success:active:focus,
.style-alt .btn-success:active.focus,
.style-alt .btn-success.active:hover,
.style-alt .btn-success.active:focus,
.style-alt .btn-success.active.focus,
.open>.style-alt .btn-success.dropdown-toggle:hover,
.open>.style-alt .btn-success.dropdown-toggle:focus,
.open>.style-alt .btn-success.dropdown-toggle.focus {
  color: #fff;
  background-color: #5d8330;
  border-color: #486525;
}

.style-alt .btn-success.disabled:hover,
.style-alt .btn-success.disabled:focus,
.style-alt .btn-success.disabled.focus,
.style-alt .btn-success[disabled]:hover,
.style-alt .btn-success[disabled]:focus,
.style-alt .btn-success[disabled].focus,
fieldset[disabled] .style-alt .btn-success:hover,
fieldset[disabled] .style-alt .btn-success:focus,
fieldset[disabled] .style-alt .btn-success.focus {
  background-color: #89bd4d;
  border-color: #89bd4d;
}

.style-alt .btn-success .badge {
  color: #89bd4d;
  background-color: #fff;
}

.style-alt .btn-info {
  color: #fff;
  background-color: #3e76a2;
  border-color: #3e76a2;
}

.style-alt .btn-info:focus,
.style-alt .btn-info.focus {
  color: #fff;
  background-color: #305b7d;
  border-color: #1b3346;
}

.style-alt .btn-info:hover {
  color: #fff;
  background-color: #305b7d;
  border-color: #2d5676;
}

.style-alt .btn-info:active,
.style-alt .btn-info.active,
.open>.style-alt .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #305b7d;
  background-image: none;
  border-color: #2d5676;
}

.style-alt .btn-info:active:hover,
.style-alt .btn-info:active:focus,
.style-alt .btn-info:active.focus,
.style-alt .btn-info.active:hover,
.style-alt .btn-info.active:focus,
.style-alt .btn-info.active.focus,
.open>.style-alt .btn-info.dropdown-toggle:hover,
.open>.style-alt .btn-info.dropdown-toggle:focus,
.open>.style-alt .btn-info.dropdown-toggle.focus {
  color: #fff;
  background-color: #264863;
  border-color: #1b3346;
}

.style-alt .btn-info.disabled:hover,
.style-alt .btn-info.disabled:focus,
.style-alt .btn-info.disabled.focus,
.style-alt .btn-info[disabled]:hover,
.style-alt .btn-info[disabled]:focus,
.style-alt .btn-info[disabled].focus,
fieldset[disabled] .style-alt .btn-info:hover,
fieldset[disabled] .style-alt .btn-info:focus,
fieldset[disabled] .style-alt .btn-info.focus {
  background-color: #3e76a2;
  border-color: #3e76a2;
}

.style-alt .btn-info .badge {
  color: #3e76a2;
  background-color: #fff;
}

.style-alt .btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}

.style-alt .btn-warning:focus,
.style-alt .btn-warning.focus {
  color: #fff;
  background-color: #ec971f;
  border-color: #985f0d;
}

.style-alt .btn-warning:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}

.style-alt .btn-warning:active,
.style-alt .btn-warning.active,
.open>.style-alt .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ec971f;
  background-image: none;
  border-color: #d58512;
}

.style-alt .btn-warning:active:hover,
.style-alt .btn-warning:active:focus,
.style-alt .btn-warning:active.focus,
.style-alt .btn-warning.active:hover,
.style-alt .btn-warning.active:focus,
.style-alt .btn-warning.active.focus,
.open>.style-alt .btn-warning.dropdown-toggle:hover,
.open>.style-alt .btn-warning.dropdown-toggle:focus,
.open>.style-alt .btn-warning.dropdown-toggle.focus {
  color: #fff;
  background-color: #d58512;
  border-color: #985f0d;
}

.style-alt .btn-warning.disabled:hover,
.style-alt .btn-warning.disabled:focus,
.style-alt .btn-warning.disabled.focus,
.style-alt .btn-warning[disabled]:hover,
.style-alt .btn-warning[disabled]:focus,
.style-alt .btn-warning[disabled].focus,
fieldset[disabled] .style-alt .btn-warning:hover,
fieldset[disabled] .style-alt .btn-warning:focus,
fieldset[disabled] .style-alt .btn-warning.focus {
  background-color: #f0ad4e;
  border-color: #eea236;
}

.style-alt .btn-warning .badge {
  color: #f0ad4e;
  background-color: #fff;
}

.style-alt .btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}

.style-alt .btn-danger:focus,
.style-alt .btn-danger.focus {
  color: #fff;
  background-color: #c9302c;
  border-color: #761c19;
}

.style-alt .btn-danger:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}

.style-alt .btn-danger:active,
.style-alt .btn-danger.active,
.open>.style-alt .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c9302c;
  background-image: none;
  border-color: #ac2925;
}

.style-alt .btn-danger:active:hover,
.style-alt .btn-danger:active:focus,
.style-alt .btn-danger:active.focus,
.style-alt .btn-danger.active:hover,
.style-alt .btn-danger.active:focus,
.style-alt .btn-danger.active.focus,
.open>.style-alt .btn-danger.dropdown-toggle:hover,
.open>.style-alt .btn-danger.dropdown-toggle:focus,
.open>.style-alt .btn-danger.dropdown-toggle.focus {
  color: #fff;
  background-color: #ac2925;
  border-color: #761c19;
}

.style-alt .btn-danger.disabled:hover,
.style-alt .btn-danger.disabled:focus,
.style-alt .btn-danger.disabled.focus,
.style-alt .btn-danger[disabled]:hover,
.style-alt .btn-danger[disabled]:focus,
.style-alt .btn-danger[disabled].focus,
fieldset[disabled] .style-alt .btn-danger:hover,
fieldset[disabled] .style-alt .btn-danger:focus,
fieldset[disabled] .style-alt .btn-danger.focus {
  background-color: #d9534f;
  border-color: #d43f3a;
}

.style-alt .btn-danger .badge {
  color: #d9534f;
  background-color: #fff;
}

.style-alt .btn-link {
  font-weight: 400;
  color: #fa8600;
  border-radius: 0;
}

.style-alt .btn-link,
.style-alt .btn-link:active,
.style-alt .btn-link.active,
.style-alt .btn-link[disabled],
fieldset[disabled] .style-alt .btn-link {
  background-color: transparent;
  box-shadow: none;
}

.style-alt .btn-link,
.style-alt .btn-link:hover,
.style-alt .btn-link:focus,
.style-alt .btn-link:active {
  border-color: transparent;
}

.style-alt .btn-link:hover,
.style-alt .btn-link:focus {
  color: #fa8600;
  text-decoration: underline;
  background-color: transparent;
}

.style-alt .btn-link[disabled]:hover,
.style-alt .btn-link[disabled]:focus,
fieldset[disabled] .style-alt .btn-link:hover,
fieldset[disabled] .style-alt .btn-link:focus {
  color: #777;
  text-decoration: none;
}

.style-alt .btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  border-radius: 6px;
}

.style-alt .btn-sm {
  padding: 5px 10px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 3px;
}

.style-alt .btn-xs {
  padding: 1px 5px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 3px;
}

.style-alt .btn-block {
  display: block;
  width: 100%;
}

.style-alt .btn-block+.btn-block {
  margin-top: 5px;
}

.style-alt input[type="submit"].btn-block,
.style-alt input[type="reset"].btn-block,
.style-alt input[type="button"].btn-block {
  width: 100%;
}

.style-alt .btn {
  padding: 7px 12px;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 rgba(235, 136, 0, 0.2);
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.style-alt .btn.btn-large {
  padding: 8px 20px;
}

.style-alt .btn.btn-medium {
  font-size: 1.2rem;
}

.style-alt .btn.btn-small,
.style-alt .btn.btn-x-small {
  padding: 6px 12px;
  font-size: 9px;
}

.style-alt .btn.btn-x-small {
  min-width: 55px;
}

.style-alt .btn.btn-default {
  color: #333;
  border-color: #d5d5d5;
  background-color: #d5d5d5;
  transition: background-color 0.2s ease-out;
}

.style-alt .btn.btn-default:hover,
.style-alt .btn.btn-default.active {
  border-color: #cbcbcb;
  background-color: #cbcbcb;
}

.style-alt .btn.btn-primary {
  color: #fff;
  border-color: #fa8600;
  background-color: #fa8600;
  transition: background-color 0.2s ease-out;
}

.style-alt .btn.btn-primary:hover,
.style-alt .btn.btn-primary.active {
  border-color: #e67b00;
  background-color: #e67b00;
}

.style-alt .btn.btn-primary.btn-small,
.style-alt .btn.btn-primary.btn-x-small {
  padding: 6px 10px;
}

.style-alt .btn.btn-secondary {
  color: #fff;
  border-color: #168dbf;
  background-color: #168dbf;
  transition: background-color 0.2s ease-out;
}

.style-alt .btn.btn-secondary:hover,
.style-alt .btn.btn-secondary.active {
  border-color: #147fad;
  background-color: #147fad;
}

.style-alt .btn.btn-success {
  color: #fff;
  border-color: #249c54;
  background-color: #249c54;
  transition: background-color 0.2s ease-out;
}

.style-alt .btn.btn-success:hover,
.style-alt .btn.btn-success.active {
  border-color: #208b4b;
  background-color: #208b4b;
}

.style-alt .btn.btn-info {
  color: #fff;
  border-color: #168dbf;
  background-color: #168dbf;
  transition: background-color 0.2s ease-out;
}

.style-alt .btn.btn-info:hover,
.style-alt .btn.btn-info.active {
  border-color: #147fad;
  background-color: #147fad;
}

.style-alt .btn[disabled] {
  color: #fff;
  border-color: #ededed;
  background-color: #ededed;
  transition: background-color 0.2s ease-out;
}

.style-alt .btn[disabled]:hover,
.style-alt .btn[disabled].active {
  border-color: #ededed;
  background-color: #ededed;
}

.style-alt .button-group {
  margin-top: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.style-alt .button-group button,
.style-alt .button-group a.btn {
  width: 100%;
  margin-bottom: 5px;
}

.style-alt .button-group button:last-of-type,
.style-alt .button-group a.btn:last-of-type {
  margin-bottom: 0;
}

.style-alt .icon,
.style-alt table.highlighted-list tbody>tr.selected td:last-of-type:before,
.style-alt .js-validate>.js-validate-marker {
  display: inline-block;
  width: 11px;
  height: 9px;
}

.style-alt .icon-tick,
.style-alt .js-validate.valid>.js-validate-marker {
  background: url(/dist/images/icon/tick.png) transparent center center no-repeat;
}

.style-alt .icon-tick-dark-green,
.style-alt table.highlighted-list tbody>tr.selected td:last-of-type:before,
.style-alt .split-basket-type-choice .split-option-btn input[type="radio"]+label span:first-child:after {
  background: url(/dist/images/icon/tick_darkgreen.png) transparent center center no-repeat;
}

.style-alt .icon-red-cross,
.style-alt .js-validate.invalid>.js-validate-marker {
  background: url(/dist/images/icon/red-cross.png) transparent center center no-repeat;
}

.style-alt .icon-loader,
.style-alt .js-validate.loading>.js-validate-marker {
  height: 16px;
  width: 16px;
  background: url(/dist/images/icon/loader.gif) transparent center center no-repeat;
}

.style-alt .panel {
  margin-bottom: 17px;
  background-color: #f6f6f6;
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.style-alt .panel-body {
  padding: 15px;
}

.style-alt .panel-body:before,
.style-alt .panel-body:after {
  display: table;
  content: " ";
}

.style-alt .panel-body:after {
  clear: both;
}

.style-alt .panel-heading {
  padding: 8px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.style-alt .panel-heading>.dropdown .dropdown-toggle {
  color: inherit;
}

.style-alt .panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  color: inherit;
}

.style-alt .panel-title>a,
.style-alt .panel-title>small,
.style-alt .panel-title>.small,
.style-alt .panel-title>small>a,
.style-alt .panel-title>.small>a {
  color: inherit;
}

.style-alt .panel-footer {
  padding: 8px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.style-alt .panel>.list-group,
.style-alt .panel>.panel-collapse>.list-group {
  margin-bottom: 0;
}

.style-alt .panel>.list-group .list-group-item,
.style-alt .panel>.panel-collapse>.list-group .list-group-item {
  border-width: 1px 0;
  border-radius: 0;
}

.style-alt .panel>.list-group:first-child .list-group-item:first-child,
.style-alt .panel>.panel-collapse>.list-group:first-child .list-group-item:first-child {
  border-top: 0;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.style-alt .panel>.list-group:last-child .list-group-item:last-child,
.style-alt .panel>.panel-collapse>.list-group:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.style-alt .panel>.panel-heading+.panel-collapse>.list-group .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.style-alt .panel-heading+.list-group .list-group-item:first-child {
  border-top-width: 0;
}

.style-alt .list-group+.panel-footer {
  border-top-width: 0;
}

.style-alt .panel>.table,
.style-alt .panel>.table-responsive>.table,
.style-alt .panel>.panel-collapse>.table {
  margin-bottom: 0;
}

.style-alt .panel>.table caption,
.style-alt .panel>.table-responsive>.table caption,
.style-alt .panel>.panel-collapse>.table caption {
  padding-right: 15px;
  padding-left: 15px;
}

.style-alt .panel>.table:first-child,
.style-alt .panel>.table-responsive:first-child>.table:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.style-alt .panel>.table:first-child>thead:first-child>tr:first-child,
.style-alt .panel>.table:first-child>tbody:first-child>tr:first-child,
.style-alt .panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child,
.style-alt .panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.style-alt .panel>.table:first-child>thead:first-child>tr:first-child td:first-child,
.style-alt .panel>.table:first-child>thead:first-child>tr:first-child th:first-child,
.style-alt .panel>.table:first-child>tbody:first-child>tr:first-child td:first-child,
.style-alt .panel>.table:first-child>tbody:first-child>tr:first-child th:first-child,
.style-alt .panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child td:first-child,
.style-alt .panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child th:first-child,
.style-alt .panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child td:first-child,
.style-alt .panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child th:first-child {
  border-top-left-radius: 2px;
}

.style-alt .panel>.table:first-child>thead:first-child>tr:first-child td:last-child,
.style-alt .panel>.table:first-child>thead:first-child>tr:first-child th:last-child,
.style-alt .panel>.table:first-child>tbody:first-child>tr:first-child td:last-child,
.style-alt .panel>.table:first-child>tbody:first-child>tr:first-child th:last-child,
.style-alt .panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child td:last-child,
.style-alt .panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child th:last-child,
.style-alt .panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child td:last-child,
.style-alt .panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child th:last-child {
  border-top-right-radius: 2px;
}

.style-alt .panel>.table:last-child,
.style-alt .panel>.table-responsive:last-child>.table:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.style-alt .panel>.table:last-child>tbody:last-child>tr:last-child,
.style-alt .panel>.table:last-child>tfoot:last-child>tr:last-child,
.style-alt .panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child,
.style-alt .panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.style-alt .panel>.table:last-child>tbody:last-child>tr:last-child td:first-child,
.style-alt .panel>.table:last-child>tbody:last-child>tr:last-child th:first-child,
.style-alt .panel>.table:last-child>tfoot:last-child>tr:last-child td:first-child,
.style-alt .panel>.table:last-child>tfoot:last-child>tr:last-child th:first-child,
.style-alt .panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child td:first-child,
.style-alt .panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child th:first-child,
.style-alt .panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child td:first-child,
.style-alt .panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child th:first-child {
  border-bottom-left-radius: 2px;
}

.style-alt .panel>.table:last-child>tbody:last-child>tr:last-child td:last-child,
.style-alt .panel>.table:last-child>tbody:last-child>tr:last-child th:last-child,
.style-alt .panel>.table:last-child>tfoot:last-child>tr:last-child td:last-child,
.style-alt .panel>.table:last-child>tfoot:last-child>tr:last-child th:last-child,
.style-alt .panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child td:last-child,
.style-alt .panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child th:last-child,
.style-alt .panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child td:last-child,
.style-alt .panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child th:last-child {
  border-bottom-right-radius: 2px;
}

.style-alt .panel>.panel-body+.table,
.style-alt .panel>.panel-body+.table-responsive,
.style-alt .panel>.table+.panel-body,
.style-alt .panel>.table-responsive+.panel-body {
  border-top: 1px solid #ddd;
}

.style-alt .panel>.table>tbody:first-child>tr:first-child th,
.style-alt .panel>.table>tbody:first-child>tr:first-child td {
  border-top: 0;
}

.style-alt .panel>.table-bordered,
.style-alt .panel>.table-responsive>.table-bordered {
  border: 0;
}

.style-alt .panel>.table-bordered>thead>tr>th:first-child,
.style-alt .panel>.table-bordered>thead>tr>td:first-child,
.style-alt .panel>.table-bordered>tbody>tr>th:first-child,
.style-alt .panel>.table-bordered>tbody>tr>td:first-child,
.style-alt .panel>.table-bordered>tfoot>tr>th:first-child,
.style-alt .panel>.table-bordered>tfoot>tr>td:first-child,
.style-alt .panel>.table-responsive>.table-bordered>thead>tr>th:first-child,
.style-alt .panel>.table-responsive>.table-bordered>thead>tr>td:first-child,
.style-alt .panel>.table-responsive>.table-bordered>tbody>tr>th:first-child,
.style-alt .panel>.table-responsive>.table-bordered>tbody>tr>td:first-child,
.style-alt .panel>.table-responsive>.table-bordered>tfoot>tr>th:first-child,
.style-alt .panel>.table-responsive>.table-bordered>tfoot>tr>td:first-child {
  border-left: 0;
}

.style-alt .panel>.table-bordered>thead>tr>th:last-child,
.style-alt .panel>.table-bordered>thead>tr>td:last-child,
.style-alt .panel>.table-bordered>tbody>tr>th:last-child,
.style-alt .panel>.table-bordered>tbody>tr>td:last-child,
.style-alt .panel>.table-bordered>tfoot>tr>th:last-child,
.style-alt .panel>.table-bordered>tfoot>tr>td:last-child,
.style-alt .panel>.table-responsive>.table-bordered>thead>tr>th:last-child,
.style-alt .panel>.table-responsive>.table-bordered>thead>tr>td:last-child,
.style-alt .panel>.table-responsive>.table-bordered>tbody>tr>th:last-child,
.style-alt .panel>.table-responsive>.table-bordered>tbody>tr>td:last-child,
.style-alt .panel>.table-responsive>.table-bordered>tfoot>tr>th:last-child,
.style-alt .panel>.table-responsive>.table-bordered>tfoot>tr>td:last-child {
  border-right: 0;
}

.style-alt .panel>.table-bordered>thead>tr:first-child>td,
.style-alt .panel>.table-bordered>thead>tr:first-child>th,
.style-alt .panel>.table-bordered>tbody>tr:first-child>td,
.style-alt .panel>.table-bordered>tbody>tr:first-child>th,
.style-alt .panel>.table-responsive>.table-bordered>thead>tr:first-child>td,
.style-alt .panel>.table-responsive>.table-bordered>thead>tr:first-child>th,
.style-alt .panel>.table-responsive>.table-bordered>tbody>tr:first-child>td,
.style-alt .panel>.table-responsive>.table-bordered>tbody>tr:first-child>th {
  border-bottom: 0;
}

.style-alt .panel>.table-bordered>tbody>tr:last-child>td,
.style-alt .panel>.table-bordered>tbody>tr:last-child>th,
.style-alt .panel>.table-bordered>tfoot>tr:last-child>td,
.style-alt .panel>.table-bordered>tfoot>tr:last-child>th,
.style-alt .panel>.table-responsive>.table-bordered>tbody>tr:last-child>td,
.style-alt .panel>.table-responsive>.table-bordered>tbody>tr:last-child>th,
.style-alt .panel>.table-responsive>.table-bordered>tfoot>tr:last-child>td,
.style-alt .panel>.table-responsive>.table-bordered>tfoot>tr:last-child>th {
  border-bottom: 0;
}

.style-alt .panel>.table-responsive {
  margin-bottom: 0;
  border: 0;
}

.style-alt .panel-group {
  margin-bottom: 17px;
}

.style-alt .panel-group .panel {
  margin-bottom: 0;
  border-radius: 3px;
}

.style-alt .panel-group .panel+.panel {
  margin-top: 5px;
}

.style-alt .panel-group .panel-heading {
  border-bottom: 0;
}

.style-alt .panel-group .panel-heading+.panel-collapse>.panel-body,
.style-alt .panel-group .panel-heading+.panel-collapse>.list-group {
  border-top: 1px solid #ddd;
}

.style-alt .panel-group .panel-footer {
  border-top: 0;
}

.style-alt .panel-group .panel-footer+.panel-collapse .panel-body {
  border-bottom: 1px solid #ddd;
}

.style-alt .panel-default,
.style-alt .panel-g4m-default {
  border-color: #ddd;
}

.style-alt .panel-default>.panel-heading,
.style-alt .panel-g4m-default>.panel-heading {
  color: #595c5e;
  background-color: #ededed;
  border-color: #ddd;
}

.style-alt .panel-default>.panel-heading+.panel-collapse>.panel-body,
.style-alt .panel-g4m-default>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #ddd;
}

.style-alt .panel-default>.panel-heading .badge,
.style-alt .panel-g4m-default>.panel-heading .badge {
  color: #ededed;
  background-color: #595c5e;
}

.style-alt .panel-default>.panel-footer+.panel-collapse>.panel-body,
.style-alt .panel-g4m-default>.panel-footer+.panel-collapse>.panel-body {
  border-bottom-color: #ddd;
}

.style-alt .panel-primary {
  border-color: #fa8600;
}

.style-alt .panel-primary>.panel-heading {
  color: #fff;
  background-color: #fa8600;
  border-color: #fa8600;
}

.style-alt .panel-primary>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #fa8600;
}

.style-alt .panel-primary>.panel-heading .badge {
  color: #fa8600;
  background-color: #fff;
}

.style-alt .panel-primary>.panel-footer+.panel-collapse>.panel-body {
  border-bottom-color: #fa8600;
}

.style-alt .panel-success {
  border-color: #d6e9c6;
}

.style-alt .panel-success>.panel-heading {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.style-alt .panel-success>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #d6e9c6;
}

.style-alt .panel-success>.panel-heading .badge {
  color: #dff0d8;
  background-color: #3c763d;
}

.style-alt .panel-success>.panel-footer+.panel-collapse>.panel-body {
  border-bottom-color: #d6e9c6;
}

.style-alt .panel-info {
  border-color: #bce8f1;
}

.style-alt .panel-info>.panel-heading {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.style-alt .panel-info>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #bce8f1;
}

.style-alt .panel-info>.panel-heading .badge {
  color: #d9edf7;
  background-color: #31708f;
}

.style-alt .panel-info>.panel-footer+.panel-collapse>.panel-body {
  border-bottom-color: #bce8f1;
}

.style-alt .panel-warning {
  border-color: #faebcc;
}

.style-alt .panel-warning>.panel-heading {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.style-alt .panel-warning>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #faebcc;
}

.style-alt .panel-warning>.panel-heading .badge {
  color: #fcf8e3;
  background-color: #8a6d3b;
}

.style-alt .panel-warning>.panel-footer+.panel-collapse>.panel-body {
  border-bottom-color: #faebcc;
}

.style-alt .panel-danger {
  border-color: #ebccd1;
}

.style-alt .panel-danger>.panel-heading {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.style-alt .panel-danger>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #ebccd1;
}

.style-alt .panel-danger>.panel-heading .badge {
  color: #f2dede;
  background-color: #a94442;
}

.style-alt .panel-danger>.panel-footer+.panel-collapse>.panel-body {
  border-bottom-color: #ebccd1;
}

.style-alt .panel-g4m-default {
  border: 0;
  margin-bottom: 10px;
}

.style-alt .panel-g4m-default>.panel-heading {
  background: #ededed;
  font-weight: bold;
  font-size: 14px;
  border-bottom: 0;
}

.style-alt .panel-g4m-default>.panel-heading>.row>[class^="col-"] {
  margin-top: -8px;
  margin-bottom: -8px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-right: 1px solid #ddd;
}

.style-alt .panel-g4m-default>.panel-heading>.row>[class^="col-"]:last-child {
  border-right: none;
}

.style-alt .panel-g4m-default>.row>[class^="col-"]:first-child {
  padding-left: 0;
}

.style-alt .panel-g4m-default>.row>[class^="col-"]:last-child {
  padding-right: 0;
}

.style-alt button.dismissible {
  background: transparent;
  border: none;
  color: #a3a4a6;
  cursor: pointer;
  display: inline-block;
  float: right;
  font-size: 22px;
  outline: none;
  min-width: 15px;
  padding: 0;
  position: relative;
}

.style-alt button.dismissible:focus {
  outline: none;
}

.style-alt .panel-g4m-blank {
  border: none;
  border-radius: 0px;
  box-shadow: none;
  background: none;
}

.style-alt .panel-g4m-blank>.panel-heading {
  font-weight: bold;
  font-size: 14px;
}

.style-alt .panel-g4m-blank.bottom-border {
  border-bottom: 1px solid #ddd !important;
}

.style-alt .panel-body h4 {
  font-size: 14px;
  margin: 0;
}

.style-alt .panel-dismissible .close.dismissible {
  position: absolute;
  top: 15px;
  right: 15px;
}

.style-alt .panel .panel-body {
  line-height: 17px;
  position: relative;
}

.style-alt .panel .panel-body.body-padding-large {
  padding: 20px;
}

.style-alt .panel .panel-body.panel-body-form,
.style-alt .panel .panel-body.panel-body-form-modal {
  padding: 25px 45px 30px 30px;
}

.style-alt .panel .panel-body.panel-body-form .row #pymt-slct,
.style-alt .panel .panel-body.panel-body-form-modal .row #pymt-slct {
  margin-left: 0;
}

.style-alt .panel .panel-body.panel-body-form .row dt,
.style-alt .panel .panel-body.panel-body-form-modal .row dt {
  padding: 7px 1% 4px 0% !important;
  float: left !important;
  text-align: left !important;
  width: 35% !important;
  max-width: 120px;
  clear: left;
}

.style-alt .panel .panel-body.panel-body-form .row dd,
.style-alt .panel .panel-body.panel-body-form-modal .row dd {
  padding: 4px 1% 4px 0 !important;
  float: left !important;
  width: 65% !important;
  margin-left: 0 !important;
  position: relative;
}

.style-alt .panel .panel-body.panel-body-form-modal {
  padding: 0;
}

.style-alt .panel .panel-body.panel-body-details {
  padding: 0;
}

.style-alt .panel .panel-body.panel-body-details>.row {
  margin-left: 0;
  margin-right: 0;
  padding: 10px 20px;
}

.style-alt .panel .panel-body.panel-body-details>.row:first-of-type {
  padding: 20px 20px 10px;
}

.style-alt .panel .panel-body.panel-body-details>.row:last-of-type {
  padding: 10px 20px 20px;
}

.style-alt .panel .panel-body.panel-body-details>.row>dl {
  margin: 0;
}

.style-alt .panel .panel-body.panel-body-details>.row>dl>dt {
  float: left;
}

.style-alt .panel .panel-body.panel-body-details>.row>dl>dd {
  display: inline-block;
  margin-left: 5px;
  float: left;
  max-width: 50%;
}

.style-alt .panel.no-padding>.panel-heading,
.style-alt .panel.no-padding>.panel-body {
  padding: 0;
}

.style-alt .panel>.panel-body.padding-20 {
  padding: 20px;
}

.style-alt .modal.g4m-modal .panel.panel-g4m-default {
  background-color: #ffffff;
}

.style-alt .overlay {
  position: relative;
}

.style-alt .overlay:after {
  content: "";
  clear: both;
  width: 100%;
  height: 100%;
  z-index: 1045;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(120, 120, 120, 0.6);
}

.style-alt .overlay.overlay-loading:after {
  background-image: url(/dist/images/loading-animation.gif);
  background-position: center;
  background-repeat: no-repeat;
}

.style-alt #finance-klarna ul.product li label {
  float: none !important;
}

.style-alt #finance-klarna ul.product li output {
  float: right !important;
}

.style-alt .checkout-footer-billing-terms-desktop {
  display: inline-block;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .style-alt .checkout-footer-billing-terms-desktop {
    display: none;
  }
}

.style-alt .checkout-footer-billing-terms-mobile {
  display: block;
  padding: 0px 10px 10px;
  width: 100%;
  display: none;
}

.style-alt table.table.table-g4m-default>tbody>tr>td.order-detail-delivery-address-cell,
.style-alt table.table.table-g4m-basket>tbody>tr>td.order-detail-delivery-address-cell {
  font-size: 13px;
  padding-bottom: 0;
  padding-top: 0;
  padding-top: 15px;
}

.style-alt .modal-open {
  overflow: hidden;
}

.style-alt .modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.style-alt .modal.fade .modal-dialog {
  transform: translate(0, -25%);
  transition: transform 0.3s ease-out;
}

.style-alt .modal.in .modal-dialog {
  transform: translate(0, 0);
}

.style-alt .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.style-alt .modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.style-alt .modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  outline: 0;
}

.style-alt .modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: rgba(120, 120, 120, 0.6);
}

.style-alt .modal-backdrop.fade {
  filter: alpha(opacity=0);
  opacity: 0;
}

.style-alt .modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: 0.5;
}

.style-alt .modal-header {
  padding: 0 0 8px;
  border-bottom: 1px solid #e5e5e5;
}

.style-alt .modal-header:before,
.style-alt .modal-header:after {
  display: table;
  content: " ";
}

.style-alt .modal-header:after {
  clear: both;
}

.style-alt .modal-header .close {
  margin-top: -2px;
}

.style-alt .modal-title {
  margin: 0;
  line-height: 1.2;
}

.style-alt .modal-body {
  position: relative;
  padding: 0;
}

.style-alt .modal-footer {
  padding: 0;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

.style-alt .modal-footer:before,
.style-alt .modal-footer:after {
  display: table;
  content: " ";
}

.style-alt .modal-footer:after {
  clear: both;
}

.style-alt .modal-footer .btn+.btn {
  margin-bottom: 0;
  margin-left: 5px;
}

.style-alt .modal-footer .btn-group .btn+.btn {
  margin-left: -1px;
}

.style-alt .modal-footer .btn-block+.btn-block {
  margin-left: 0;
}

.style-alt .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 768px) {
  .style-alt .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }

  .style-alt .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }

  .style-alt .modal-sm {
    width: 300px;
  }
}

@media (min-width: 992px) {
  .style-alt .modal-lg {
    width: 900px;
  }
}

.style-alt .modal {
  -webkit-overflow-scrolling: auto !important;
  overflow-y: auto !important;
}

.style-alt .modal.g4m-modal {
  background-color: rgba(254, 254, 254, 0.2);
}

.style-alt .modal.g4m-modal .modal-dialog {
  left: 0;
  margin-top: 110px;
  padding: 0;
}

.style-alt .modal.g4m-modal .modal-content {
  background-color: #fff;
  padding: 24px;
  border-radius: 1px;
}

.style-alt .modal.g4m-modal .modal-content .modal-header {
  display: flex;
  align-items: center;
  border-bottom: 0;
  background-color: inherit;
}

.style-alt .modal.g4m-modal .modal-content .modal-header .close {
  margin-top: 0;
  min-width: 18px;
  padding: 0;
  height: 18px;
  width: 18px;
  background-image: url(/dist/images/svgs/modal-close.svg);
  background-color: #fff;
  background-repeat: no-repeat;
}

.style-alt .modal.g4m-modal .modal-content .modal-header .modal-title {
  flex-grow: 1;
  font-weight: bold;
  font-size: 16px;
  display: inherit;
}

.style-alt .modal.g4m-modal .modal-content .modal-body {
  padding-bottom: 15px;
}

.style-alt .modal.g4m-modal .modal-content .modal-body .form-padding-large {
  padding-right: 28px;
}

.style-alt .modal.g4m-modal .modal-content .modal-body .header {
  font-weight: bold;
}

.style-alt .modal.g4m-modal .modal-content .modal-body table {
  min-width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

.style-alt .modal.g4m-modal .modal-content .modal-body th {
  text-align: center;
  vertical-align: top;
}

.style-alt .modal.g4m-modal .modal-content .modal-body td {
  text-align: center;
  vertical-align: middle;
  padding: 0.5em 0;
  background-color: transparent;
}

.style-alt .modal.g4m-modal .modal-content .modal-body thead,
.style-alt .modal.g4m-modal .modal-content .modal-body tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}

.style-alt .modal.g4m-modal .modal-content .modal-body tbody tr:nth-child(odd) {
  background-color: #fafafa;
}

.style-alt .modal.g4m-modal .modal-content .modal-footer {
  padding: 15px 0 0;
}

.style-alt .modal.g4m-modal .btn[disabled] {
  color: #fff;
  border-color: #d5d5d5;
  background-color: #d5d5d5;
  transition: background-color 0.2s ease-out;
}

.style-alt .modal.g4m-modal .btn[disabled]:hover,
.style-alt .modal.g4m-modal .btn[disabled].active {
  border-color: #d5d5d5;
  background-color: #d5d5d5;
}

.style-alt #password-reset-modal .row.password-reset-row {
  margin-top: 20px;
  position: relative;
}

.style-alt #password-reset-modal .row.password-reset-row .col-xs-12:last-of-type {
  padding-top: 18px;
  padding-top: 6px;
}

.style-alt #password-reset-modal .row.password-reset-row .password-reset-cta {
  bottom: 0;
  position: absolute;
  right: 0;
}

.style-alt #password-reset-modal .row.password-reset-row .form-cta {
  margin-top: 1px;
}

.style-alt #password-reset-modal input#password-reset-ref-input {
  width: 100%;
  max-width: 100%;
}

.style-alt .tooltip {
  position: absolute;
  z-index: 1070;
  display: block;

  font-style: normal;
  font-weight: 400;
  line-height: 1.42857;
  line-break: auto;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  font-size: 11px;
  filter: alpha(opacity=0);
  opacity: 0;
}

.style-alt .tooltip.in {
  filter: alpha(opacity=90);
  opacity: 0.9;
}

.style-alt .tooltip.top {
  padding: 5px 0;
  margin-top: -3px;
}

.style-alt .tooltip.right {
  padding: 0 5px;
  margin-left: 3px;
}

.style-alt .tooltip.bottom {
  padding: 5px 0;
  margin-top: 3px;
}

.style-alt .tooltip.left {
  padding: 0 5px;
  margin-left: -3px;
}

.style-alt .tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.style-alt .tooltip.top-left .tooltip-arrow {
  right: 5px;
  bottom: 0;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.style-alt .tooltip.top-right .tooltip-arrow {
  bottom: 0;
  left: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.style-alt .tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}

.style-alt .tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}

.style-alt .tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

.style-alt .tooltip.bottom-left .tooltip-arrow {
  top: 0;
  right: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

.style-alt .tooltip.bottom-right .tooltip-arrow {
  top: 0;
  left: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

.style-alt .tooltip-inner {
  max-width: 600px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 3px;
}

.style-alt .tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.style-alt .tooltip ul {
  list-style: none;
  margin: 0;
  padding: 5px;
}

.style-alt .tooltip .tooltip-arrow {
  padding: 0 !important;
}

.style-alt .tooltip {
  padding: 5px 0 !important;
}

.style-alt .tooltip>.tooltip-inner {
  padding: 3px 8px !important;
}

.style-alt .tooltip>.tooltip-inner p {
  padding: 0;
}

.style-alt .popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  max-width: 300px;
  padding: 1px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857;
  line-break: auto;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  font-size: 12px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.style-alt .popover.top {
  margin-top: -10px;
}

.style-alt .popover.right {
  margin-left: 10px;
}

.style-alt .popover.bottom {
  margin-top: 10px;
}

.style-alt .popover.left {
  margin-left: -10px;
}

.style-alt .popover>.arrow,
.style-alt .tooltip-target .popover>.arrow-left,
.tooltip-target .style-alt .popover>.arrow-left,
.style-alt .tooltip-target-desktop .popover>.arrow-left,
.tooltip-target-desktop .style-alt .popover>.arrow-left,
.style-alt .tooltip-target .popover>.arrow-right,
.tooltip-target .style-alt .popover>.arrow-right,
.style-alt .tooltip-target-desktop .popover>.arrow-right,
.tooltip-target-desktop .style-alt .popover>.arrow-right {
  border-width: 11px;
}

.style-alt .popover>.arrow,
.style-alt .tooltip-target .popover>.arrow-left,
.tooltip-target .style-alt .popover>.arrow-left,
.style-alt .tooltip-target-desktop .popover>.arrow-left,
.tooltip-target-desktop .style-alt .popover>.arrow-left,
.style-alt .tooltip-target .popover>.arrow-right,
.tooltip-target .style-alt .popover>.arrow-right,
.style-alt .tooltip-target-desktop .popover>.arrow-right,
.tooltip-target-desktop .style-alt .popover>.arrow-right,
.style-alt .popover>.arrow:after,
.style-alt .tooltip-target .popover>.arrow-left:after,
.tooltip-target .style-alt .popover>.arrow-left:after,
.style-alt .tooltip-target-desktop .popover>.arrow-left:after,
.tooltip-target-desktop .style-alt .popover>.arrow-left:after,
.style-alt .tooltip-target .popover>.arrow-right:after,
.tooltip-target .style-alt .popover>.arrow-right:after,
.style-alt .tooltip-target-desktop .popover>.arrow-right:after,
.tooltip-target-desktop .style-alt .popover>.arrow-right:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.style-alt .popover>.arrow:after,
.style-alt .tooltip-target .popover>.arrow-left:after,
.tooltip-target .style-alt .popover>.arrow-left:after,
.style-alt .tooltip-target-desktop .popover>.arrow-left:after,
.tooltip-target-desktop .style-alt .popover>.arrow-left:after,
.style-alt .tooltip-target .popover>.arrow-right:after,
.tooltip-target .style-alt .popover>.arrow-right:after,
.style-alt .tooltip-target-desktop .popover>.arrow-right:after,
.tooltip-target-desktop .style-alt .popover>.arrow-right:after {
  content: "";
  border-width: 10px;
}

.style-alt .popover.top>.arrow,
.style-alt .tooltip-target .popover.top>.arrow-left,
.tooltip-target .style-alt .popover.top>.arrow-left,
.style-alt .tooltip-target-desktop .popover.top>.arrow-left,
.tooltip-target-desktop .style-alt .popover.top>.arrow-left,
.style-alt .tooltip-target .popover.top>.arrow-right,
.tooltip-target .style-alt .popover.top>.arrow-right,
.style-alt .tooltip-target-desktop .popover.top>.arrow-right,
.tooltip-target-desktop .style-alt .popover.top>.arrow-right {
  bottom: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-color: #999;
  border-top-color: rgba(0, 0, 0, 0.25);
  border-bottom-width: 0;
}

.style-alt .popover.top>.arrow:after,
.style-alt .tooltip-target .popover.top>.arrow-left:after,
.tooltip-target .style-alt .popover.top>.arrow-left:after,
.style-alt .tooltip-target-desktop .popover.top>.arrow-left:after,
.tooltip-target-desktop .style-alt .popover.top>.arrow-left:after,
.style-alt .tooltip-target .popover.top>.arrow-right:after,
.tooltip-target .style-alt .popover.top>.arrow-right:after,
.style-alt .tooltip-target-desktop .popover.top>.arrow-right:after,
.tooltip-target-desktop .style-alt .popover.top>.arrow-right:after {
  bottom: 1px;
  margin-left: -10px;
  content: " ";
  border-top-color: #fff;
  border-bottom-width: 0;
}

.style-alt .popover.right>.arrow,
.style-alt .tooltip-target .popover.right>.arrow-left,
.tooltip-target .style-alt .popover.right>.arrow-left,
.style-alt .tooltip-target-desktop .popover.right>.arrow-left,
.tooltip-target-desktop .style-alt .popover.right>.arrow-left,
.style-alt .tooltip-target .popover.right>.arrow-right,
.tooltip-target .style-alt .popover.right>.arrow-right,
.style-alt .tooltip-target-desktop .popover.right>.arrow-right,
.tooltip-target-desktop .style-alt .popover.right>.arrow-right {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-right-color: #999;
  border-right-color: rgba(0, 0, 0, 0.25);
  border-left-width: 0;
}

.style-alt .popover.right>.arrow:after,
.style-alt .tooltip-target .popover.right>.arrow-left:after,
.tooltip-target .style-alt .popover.right>.arrow-left:after,
.style-alt .tooltip-target-desktop .popover.right>.arrow-left:after,
.tooltip-target-desktop .style-alt .popover.right>.arrow-left:after,
.style-alt .tooltip-target .popover.right>.arrow-right:after,
.tooltip-target .style-alt .popover.right>.arrow-right:after,
.style-alt .tooltip-target-desktop .popover.right>.arrow-right:after,
.tooltip-target-desktop .style-alt .popover.right>.arrow-right:after {
  bottom: -10px;
  left: 1px;
  content: " ";
  border-right-color: #fff;
  border-left-width: 0;
}

.style-alt .popover.bottom>.arrow,
.style-alt .tooltip-target .popover.bottom>.arrow-left,
.tooltip-target .style-alt .popover.bottom>.arrow-left,
.style-alt .tooltip-target-desktop .popover.bottom>.arrow-left,
.tooltip-target-desktop .style-alt .popover.bottom>.arrow-left,
.style-alt .tooltip-target .popover.bottom>.arrow-right,
.tooltip-target .style-alt .popover.bottom>.arrow-right,
.style-alt .tooltip-target-desktop .popover.bottom>.arrow-right,
.tooltip-target-desktop .style-alt .popover.bottom>.arrow-right {
  top: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.style-alt .popover.bottom>.arrow:after,
.style-alt .tooltip-target .popover.bottom>.arrow-left:after,
.tooltip-target .style-alt .popover.bottom>.arrow-left:after,
.style-alt .tooltip-target-desktop .popover.bottom>.arrow-left:after,
.tooltip-target-desktop .style-alt .popover.bottom>.arrow-left:after,
.style-alt .tooltip-target .popover.bottom>.arrow-right:after,
.tooltip-target .style-alt .popover.bottom>.arrow-right:after,
.style-alt .tooltip-target-desktop .popover.bottom>.arrow-right:after,
.tooltip-target-desktop .style-alt .popover.bottom>.arrow-right:after {
  top: 1px;
  margin-left: -10px;
  content: " ";
  border-top-width: 0;
  border-bottom-color: #fff;
}

.style-alt .popover.left>.arrow,
.style-alt .tooltip-target .popover.left>.arrow-left,
.tooltip-target .style-alt .popover.left>.arrow-left,
.style-alt .tooltip-target-desktop .popover.left>.arrow-left,
.tooltip-target-desktop .style-alt .popover.left>.arrow-left,
.style-alt .tooltip-target .popover.left>.arrow-right,
.tooltip-target .style-alt .popover.left>.arrow-right,
.style-alt .tooltip-target-desktop .popover.left>.arrow-right,
.tooltip-target-desktop .style-alt .popover.left>.arrow-right {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.style-alt .popover.left>.arrow:after,
.style-alt .tooltip-target .popover.left>.arrow-left:after,
.tooltip-target .style-alt .popover.left>.arrow-left:after,
.style-alt .tooltip-target-desktop .popover.left>.arrow-left:after,
.tooltip-target-desktop .style-alt .popover.left>.arrow-left:after,
.style-alt .tooltip-target .popover.left>.arrow-right:after,
.tooltip-target .style-alt .popover.left>.arrow-right:after,
.style-alt .tooltip-target-desktop .popover.left>.arrow-right:after,
.tooltip-target-desktop .style-alt .popover.left>.arrow-right:after {
  right: 1px;
  bottom: -10px;
  content: " ";
  border-right-width: 0;
  border-left-color: #fff;
}

.style-alt .popover-title {
  padding: 8px 14px;
  margin: 0;
  font-size: 12px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}

.style-alt .popover-content {
  padding: 9px 14px;
}

.style-alt .popover>.popover-title {
  padding: 6px 10px 5px;
  background: #ededed;
  background: linear-gradient(to bottom, #ededed 0%, #e9e9e9 100%) repeat scroll 0 0 transparent;
}

.style-alt .popover>.popover-content {
  padding: 7px 10px;
}

.style-alt .carousel-container {
  width: 100%;
  margin: 0 auto;
}

.style-alt .carousel-container>.carousel-content {
  text-align: center;
  position: relative;
  margin: 0 auto 12px;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 13px;
  overflow: hidden;
  width: 100%;
}

.style-alt .carousel-container>.carousel-content:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
}

.style-alt .carousel-content>.carousel-list {
  width: 9999px;
  position: relative;
  z-index: 5;
  list-style: none;
  margin: 0;
  padding: 0;
}

.style-alt .carousel-content>.carousel-list>li {
  float: left;
  width: 130px;
  margin: 0 7px 2px 7px;
  border: 1px solid #eaeaea;
  border-bottom: 2px solid #dcdcdc;
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 10px 5px 11px;
  border-radius: 3pxpx;
  box-sizing: border-box;
}

.style-alt .carousel-content>.carousel-list>li:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 28px;
  z-index: -1;
  background: #fff url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJod…EiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+");
  background: linear-gradient(to bottom, #fff 0%, #f4f4f4 100%);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.style-alt .carousel-content>.carousel-list>li>.prd-img {
  display: block;
  margin: 0 0 7px;
  height: 80px;
}

.style-alt .carousel-content>.carousel-list>li>.prd-img>img {
  vertical-align: middle;
  width: auto;
  height: auto;
  max-width: 100%;
}

.style-alt .carousel-content>.carousel-list>li>h3 {
  font-size: 0.917em;
  line-height: 1.182em;
  margin: 0 0 3px;
  height: 40px;
  max-height: 40px;
  overflow: hidden;
  color: #434649;
}

.style-alt .carousel-content>.carousel-list>li>h3>a {
  color: inherit;
}

.style-alt .carousel-content>.pagination {
  content: "";
  display: block;
  position: absolute;
  top: 50px;
  bottom: 0;
  width: 20px;
  background: #ececec;
  border: 1px solid #c7c6c6;
  height: 64px;
  z-index: 50;
  text-indent: -9999px;
  overflow: hidden;
  cursor: pointer;
}

.style-alt .carousel-content>.pagination.prev:before,
.style-alt .carousel-content>.pagination.prev:after,
.style-alt .carousel-content>.pagination.next:before,
.style-alt .carousel-content>.pagination.next:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40%;
  border: solid transparent;
  border-width: 8px 8px 8px 0;
  border-right-color: #888888;
  border-left-color: #888888;
  margin-top: -1px;
}

.style-alt .carousel-content>.pagination.prev {
  left: 0;
  right: auto;
}

.style-alt .carousel-content>.pagination.prev:before,
.style-alt .carousel-content>.pagination.prev:after {
  right: auto;
  left: 5px;
}

.style-alt .carousel-content>.pagination.prev:after {
  border-right-color: #f2f2f2;
  margin-left: 3px;
}

.style-alt .carousel-content>.pagination.next {
  right: 0;
  left: auto;
}

.style-alt .carousel-content>.pagination.next:before,
.style-alt .carousel-content>.pagination.next:after {
  border-width: 8px 0 8px 8px;
  left: auto;
  right: 6px;
}

.style-alt .carousel-content>.pagination.next:after {
  border-left-color: #f2f2f2;
  margin-right: 3px;
}

.style-alt .carousel-content>.pagination-fading-left,
.style-alt .carousel-content>.pagination-fading-right {
  z-index: 49;
  height: 100%;
  width: 53px;
  position: absolute;
  text-indent: -9999px;
  overflow: hidden;
  top: 0;
  bottom: 0;
}

.style-alt .carousel-content>.pagination-fading-left {
  left: 0;
  right: auto;
  background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, #fff 43%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1);
}

.style-alt .carousel-content>.pagination-fading-right {
  left: auto;
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 43%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1);
}

.style-alt .expandable {
  border: none;
  padding: 0;
}

.style-alt .expandable .expand-select {
  cursor: pointer;
}

.style-alt .expandable .expand-select.expand-icon {
  position: relative;
}

.style-alt .expandable .expand-select.expand-icon:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  z-index: 2;
  top: 40%;
  right: 10px;
}

.style-alt .expandable .expand-select.expand-icon-inline {
  position: relative;
}

.style-alt .expandable .expand-select.expand-icon-inline:after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  z-index: 2;
  position: relative;
  top: 0;
  margin-left: 5px;
}

.style-alt .expandable.expand-open .expand-select.expand-icon:before,
.style-alt .expandable.expand-open .expand-select.expand-icon-inline:after {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 10px solid #898b8c;
}

.style-alt .expandable.expand-close .expand-select.expand-icon:before,
.style-alt .expandable.expand-close .expand-select.expand-icon-inline:after {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 10px solid #898b8c;
}

.style-alt .js-validate>.js-validate-marker {
  content: "";
  position: absolute;
  top: 12px;
  right: -12px;
  margin-left: 5px;
  z-index: 2;
}

.style-alt .js-validate.invalid>input[type="text"],
.style-alt .js-validate.invalid>input[type="email"],
.style-alt .js-validate.invalid>input[type="url"],
.style-alt .js-validate.invalid>input[type="number"],
.style-alt .js-validate.invalid>input[type="password"],
.style-alt .js-validate.invalid>input[type="tel"],
.style-alt .js-validate.invalid>textarea,
.style-alt .js-validate.invalid>select {
  border: 1px solid #ed5621;
}

.style-alt .js-validate.valid>i.more-info-icon,
.style-alt .js-validate.invalid>i.more-info-icon {
  right: -32px;
}

.style-alt .js-validate.loading>.js-validate-marker {
  top: 8px;
  margin-left: 7px;
}

.style-alt .ui-datepicker-g4m-basket .ui-datepicker-header {
  margin-right: 1px;
}

.style-alt .ui-datepicker-g4m-basket .ui-datepicker-title {
  min-height: 30px;
  background: #168dbf;
  color: #fff;
}

.style-alt .ui-datepicker-g4m-basket .ui-datepicker-calendar .ui-datepicker-unselectable {
  background: #ededed;
}

.style-alt .ui-datepicker-g4m-basket .ui-datepicker-calendar .ui-datepicker-unselectable div {
  position: absolute;
  width: auto;
  height: auto;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  border: 1px solid #bbb;
}

.style-alt .ui-datepicker-g4m-basket .ui-datepicker-calendar .ui-datepicker-current-day {
  color: #333;
  background: #e9efd7;
}

.style-alt .ui-datepicker-g4m-basket .ui-datepicker-calendar .ui-datepicker-current-day a {
  color: inherit;
  line-height: 26px;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  width: auto;
  border-color: #fa8600;
}

.style-alt .ui-datepicker-g4m-basket .ui-datepicker-calendar .ui-datepicker-current-day a.ui-state-hover :not(.ui-state-do-not-apply-hover) {
  color: #fff;
}

.style-alt .ui-datepicker-g4m-basket .ui-datepicker-calendar .ui-state-hover {
  color: #666;
  background: none;
  cursor: default;
  text-decoration: none;
}

.style-alt .ui-datepicker-g4m-basket .ui-datepicker-calendar .ui-state-hover:not(.ui-state-do-not-apply-hover) {
  text-decoration: underline;
  cursor: pointer;
  color: #fff;
  background: #fa8600;
}

.style-alt .ui-datepicker-g4m-basket .font-normal {
  font-weight: normal;
}

.style-alt .clearfix:before,
.style-alt .site-header .secure-checkout-wrapper:before,
.site-header .style-alt .secure-checkout-wrapper:before,
.style-alt .clearfix:after,
.style-alt .site-header .secure-checkout-wrapper:after,
.site-header .style-alt .secure-checkout-wrapper:after,
.style-alt .purchase-info .info-row .info-row-stock:after,
.purchase-info .info-row .style-alt .info-row-stock:after {
  display: table;
  content: " ";
}

.style-alt .clearfix:after,
.style-alt .site-header .secure-checkout-wrapper:after,
.site-header .style-alt .secure-checkout-wrapper:after,
.style-alt .purchase-info .info-row .info-row-stock:after,
.purchase-info .info-row .style-alt .info-row-stock:after {
  clear: both;
}

.style-alt .center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.style-alt .pull-right {
  float: right !important;
}

.style-alt .pull-left {
  float: left !important;
}

.style-alt .hide {
  display: none !important;
}

.style-alt .show {
  display: block !important;
}

.style-alt .invisible {
  visibility: hidden;
}

.style-alt .text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.style-alt .hidden {
  display: none !important;
}

.style-alt .affix {
  position: fixed;
}

.style-alt .horizontal-radio label {
  display: inline-block;
  margin-right: 10px;
  margin-top: 5px;
  margin-left: 2px;
}

.style-alt .horizontal-radio label:last-of-type {
  margin-left: 0;
  margin-right: 0;
}

.style-alt .horizontal-radio label>input[type="radio"] {
  margin: 0 4px 0 0;
}

.style-alt .img-thumbnail {
  border-radius: 0px;
}

.style-alt .no-border-right {
  border-right: none !important;
}

.style-alt .no-border-left {
  border-left: none !important;
}

.style-alt .no-border-top {
  border-top: none !important;
  box-shadow: none !important;
}

.style-alt * {
  box-sizing: border-box;
}

.style-alt *:before,
.style-alt *:after {
  box-sizing: border-box;
}

.style-alt .hide-all-of-type-but-first:not(:first-of-type) {
  display: none;
}

.style-alt .no-margin {
  margin: 0 !important;
}

.style-alt .no-margin-top {
  margin-top: 0 !important;
}

.style-alt .no-margin-left {
  margin-left: 0 !important;
}

.style-alt .no-margin-right {
  margin-right: 0 !important;
}

.style-alt .no-margin-bottom {
  margin-bottom: 0 !important;
}

.style-alt .margin-top-large {
  margin-top: 15px !important;
}

.style-alt .margin-left-large {
  margin-left: 15px !important;
}

.style-alt .margin-right-large {
  margin-right: 15px !important;
}

.style-alt .margin-bottom-large,
.style-alt .g4m-order-details-container {
  margin-bottom: 15px !important;
}

.style-alt .margin-top-small {
  margin-top: 5px !important;
}

.style-alt .margin-left-small {
  margin-left: 5px !important;
}

.style-alt .margin-right-small {
  margin-right: 5px !important;
}

.style-alt .margin-bottom-small,
.style-alt .checkout-success-thank-you {
  margin-bottom: 5px !important;
}

.style-alt .no-padding {
  padding: 0 !important;
}

.style-alt .no-padding-top {
  padding-top: 0 !important;
}

.style-alt .no-padding-left {
  padding-left: 0 !important;
}

.style-alt .no-padding-right {
  padding-right: 0 !important;
}

.style-alt .no-padding-bottom {
  padding-bottom: 0 !important;
}

.style-alt .padding-top-extra-large {
  padding-top: 20px !important;
}

.style-alt .padding-top-large {
  padding-top: 15px !important;
}

.style-alt .padding-left-large {
  padding-left: 15px !important;
}

.style-alt .padding-right-large {
  padding-right: 15px !important;
}

.style-alt .padding-bottom-large {
  padding-bottom: 15px !important;
}

.style-alt .padding-top-small {
  padding-top: 5px !important;
}

.style-alt .padding-left-small {
  padding-left: 5px !important;
}

.style-alt .padding-right-small {
  padding-right: 5px !important;
}

.style-alt .padding-bottom-small {
  padding-bottom: 5px !important;
}

@media screen and (max-width: 768px) {
  .style-alt .no-padding-top-mobile {
    padding-top: 0 !important;
  }
}

.style-alt .checkout-success-thank-you {
  line-height: 22px;
}

.style-alt .pseudo-clear,
.style-alt .form:after,
.style-alt .panel-g4m-blank:after,
.style-alt .panel .panel-body.panel-body-form .row dl:after,
.style-alt .panel .panel-body.panel-body-form-modal .row dl:after,
.style-alt .panel .panel-body.panel-body-form .row dl div:after,
.style-alt .panel .panel-body.panel-body-form-modal .row dl div:after,
.style-alt .panel .panel-body.panel-body-form .row dl div:before,
.style-alt .panel .panel-body.panel-body-form-modal .row dl div:before,
.style-alt .carousel-content>.carousel-list:after {
  content: "";
  display: block;
  clear: both;
}

.style-alt .cursor-pointer {
  cursor: pointer;
}

@-ms-viewport {
  width: device-width;
}

.style-alt .visible-xs {
  display: none !important;
}

.style-alt .visible-sm {
  display: none !important;
}

.style-alt .visible-md {
  display: none !important;
}

.style-alt .visible-lg {
  display: none !important;
}

.style-alt .visible-xs-block,
.style-alt .visible-xs-inline,
.style-alt .visible-xs-inline-block,
.style-alt .visible-sm-block,
.style-alt .visible-sm-inline,
.style-alt .visible-sm-inline-block,
.style-alt .visible-md-block,
.style-alt .visible-md-inline,
.style-alt .visible-md-inline-block,
.style-alt .visible-lg-block,
.style-alt .visible-lg-inline,
.style-alt .visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .style-alt .visible-xs {
    display: block !important;
  }

  .style-alt table.visible-xs {
    display: table !important;
  }

  .style-alt tr.visible-xs {
    display: table-row !important;
  }

  .style-alt th.visible-xs,
  .style-alt td.visible-xs {
    display: table-cell !important;
  }
}

@media (max-width: 767px) {
  .style-alt .visible-xs-block {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .style-alt .visible-xs-inline {
    display: inline !important;
  }
}

@media (max-width: 767px) {
  .style-alt .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .style-alt .visible-sm {
    display: block !important;
  }

  .style-alt table.visible-sm {
    display: table !important;
  }

  .style-alt tr.visible-sm {
    display: table-row !important;
  }

  .style-alt th.visible-sm,
  .style-alt td.visible-sm {
    display: table-cell !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .style-alt .visible-sm-block {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .style-alt .visible-sm-inline {
    display: inline !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .style-alt .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .style-alt .visible-md {
    display: block !important;
  }

  .style-alt table.visible-md {
    display: table !important;
  }

  .style-alt tr.visible-md {
    display: table-row !important;
  }

  .style-alt th.visible-md,
  .style-alt td.visible-md {
    display: table-cell !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .style-alt .visible-md-block {
    display: block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .style-alt .visible-md-inline {
    display: inline !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .style-alt .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1200px) {
  .style-alt .visible-lg {
    display: block !important;
  }

  .style-alt table.visible-lg {
    display: table !important;
  }

  .style-alt tr.visible-lg {
    display: table-row !important;
  }

  .style-alt th.visible-lg,
  .style-alt td.visible-lg {
    display: table-cell !important;
  }
}

@media (min-width: 1200px) {
  .style-alt .visible-lg-block {
    display: block !important;
  }
}

@media (min-width: 1200px) {
  .style-alt .visible-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 1200px) {
  .style-alt .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .style-alt .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .style-alt .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .style-alt .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .style-alt .hidden-lg {
    display: none !important;
  }
}

.style-alt .visible-print {
  display: none !important;
}

@media print {
  .style-alt .visible-print {
    display: block !important;
  }

  .style-alt table.visible-print {
    display: table !important;
  }

  .style-alt tr.visible-print {
    display: table-row !important;
  }

  .style-alt th.visible-print,
  .style-alt td.visible-print {
    display: table-cell !important;
  }
}

.style-alt .visible-print-block {
  display: none !important;
}

@media print {
  .style-alt .visible-print-block {
    display: block !important;
  }
}

.style-alt .visible-print-inline {
  display: none !important;
}

@media print {
  .style-alt .visible-print-inline {
    display: inline !important;
  }
}

.style-alt .visible-print-inline-block {
  display: none !important;
}

@media print {
  .style-alt .visible-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .style-alt .hidden-print {
    display: none !important;
  }
}

.style-alt .fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}

.style-alt .fade.in {
  opacity: 1;
}

.style-alt .collapse {
  display: none;
}

.style-alt .collapse.in {
  display: block;
}

.style-alt tr.collapse.in {
  display: table-row;
}

.style-alt tbody.collapse.in {
  display: table-row-group;
}

.style-alt .collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-property: height, visibility;
  transition-duration: 0.35s;
  transition-timing-function: ease;
}

.style-alt #payment_method_8 .form-inline div {
  padding: 4px 5px 4px 0;
}

.style-alt #payment_method_8 .form-inline div.tooltip-target,
.style-alt #payment_method_8 .form-inline div.tooltip-target-desktop {
  padding: 0;
}

.style-alt #new_card_details select {
  width: 100% !important;
}

.style-alt .promo-code {
  padding-left: 0;
}

.style-alt .promo-code>.promo-code-opener {
  line-height: 27px;
}

.style-alt .promo-code>.promo-code-opener:before {
  right: -8px;
}

.style-alt .promo-code .promo-code-opener,
.style-alt .promo-code .promo-code-content {
  padding: 0 8px 0 0;
}

.style-alt .breadcrumb-container {
  float: left;
}

.style-alt .button-wrapper {
  padding: 0 0 15px;
}

.style-alt .top-button-wrapper {
  min-height: 40px;
}

.style-alt .disabled-pymt-opt span {
  display: inline-block;
}

.style-alt .basket-icon:before {
  margin-top: 5px;
}

.style-alt #basket-dispatch-info {
  padding-right: 13px;
  text-align: left;
  text-align: right;
}

.style-alt #address_override_form.panel {
  margin: 0;
}

.style-alt #address_override_form.panel .address-override-title {
  margin-bottom: 7px;
}

@media screen and (min-width: 1200px) {
  .style-alt #address_override_form.panel .address-override-title {
    text-align: right;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1200px) {
  .style-alt #address_override_form.panel .address-override-row {
    float: right;
  }
}

.style-alt #address_override_form.panel .address-override-row .address-override-error {
  color: #ff0000;
  text-align: right;
  padding-right: 80px;
}

.style-alt #address_override_form.panel dt,
.style-alt #address_override_form.panel dd {
  width: auto;
  line-height: 30px;
}

@media screen and (max-width: 991px) {

  .style-alt #address_override_form.panel dt,
  .style-alt #address_override_form.panel dd {
    width: 100% !important;
    max-width: 100%;
  }
}

@media screen and (min-width: 1200px) {

  .style-alt #address_override_form.panel dt,
  .style-alt #address_override_form.panel dd {
    display: inline-block;
  }
}

.style-alt #address_override_form.panel dt {
  margin-right: 7px;
}

@media screen and (min-width: 992px) {
  .style-alt #address_override_form.panel dt label {
    text-align: right !important;
  }
}

@media screen and (min-width: 1200px) {
  .style-alt #address_override_form.panel dt label {
    padding-left: 5px;
  }
}

.style-alt #address_override_form.panel .address-override-input-wrap {
  margin-bottom: 10px;
}

.style-alt #address_override_form.panel .address-override-input-wrap input,
.style-alt #address_override_form.panel .address-override-input-wrap select {
  height: 30px;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  box-shadow: none;
  padding: 4px 10px;
}

.style-alt #address_override_form.panel .address-override-input-wrap select {
  background-color: #fff;
}

@media screen and (min-width: 992px) {
  .style-alt #address_override_form.panel .address-override-input-wrap {
    display: inline-block;
  }
}

.style-alt #address_override_form.panel .address-override-input-wrap.address-override-country,
.style-alt #address_override_form.panel .address-override-input-wrap.address-override-postcode {
  width: 50%;
  float: left;
}

@media screen and (min-width: 992px) {

  .style-alt #address_override_form.panel .address-override-input-wrap.address-override-country,
  .style-alt #address_override_form.panel .address-override-input-wrap.address-override-postcode {
    width: auto;
    float: none;
  }
}

.style-alt #address_override_form.panel .address-override-input-wrap.address-override-country {
  padding-right: 3px;
}

@media screen and (max-width: 1199px) {
  .style-alt #address_override_form.panel .address-override-input-wrap.address-override-country {
    width: 50%;
  }
}

.style-alt #address_override_form.panel .address-override-input-wrap.address-override-country+.address-override-postcode {
  padding-left: 3px;
}

@media screen and (max-width: 1199px) {
  .style-alt #address_override_form.panel .address-override-input-wrap.address-override-country+.address-override-postcode {
    width: 24%;
  }
}

.style-alt #address_override_form.panel .address-override-input-wrap.address-override-country+.address-override-postcode>dd>input.invalid {
  border: 1px solid #ed5621;
}

@media screen and (min-width: 992px) {
  .style-alt #address_override_form.panel .address-override-input-wrap.address-override-button {
    margin-left: 5px;
  }
}

@media screen and (max-width: 1199px) {
  .style-alt #address_override_form.panel .address-override-input-wrap.address-override-button {
    width: 24%;
  }
}

.style-alt #address_override_form.panel .address-override-input-wrap.address-override-button .btn {
  padding: 6px 12px;
  font-size: 11px;
  width: 100%;
}

@media screen and (min-width: 1200px) {
  .style-alt #address_override_form.panel .address-override-input-wrap.address-override-button .btn {
    vertical-align: top;
  }
}

.style-alt .pickup-point-error,
.style-alt .pickup-point-info {
  color: #ff0000;
  font-size: 14px;
  margin: 1% 1% 1% 0;
}

.style-alt .pickup-point-error:empty,
.style-alt .pickup-point-info:empty {
  display: none;
}

.style-alt .pickup-point-selector-container .pickup-point {
  margin-bottom: 0;
  border-bottom: none;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 1px 0 0 #fff inset;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.style-alt .pickup-point {
  margin-bottom: 0;
  border-bottom: none;
}

.style-alt .pickup-point .pickup-point-title {
  color: #595c5e;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.167em;
  text-align: left;
  padding: 10px 10px;
}

.style-alt .pickup-point h3.header {
  margin: 0;
}

.style-alt .pickup-point .pickup-point-content {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 0;
}

.style-alt .pickup-point .pickup-point-content .pickup-point-selector {
  padding-left: 0px;
  padding-right: 0px;
}

.style-alt .pickup-point .pickup-point-content #pickup-point-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(/dist/images/loading-animation.gif) center center no-repeat rgba(19, 19, 19, 0.7);
  z-index: 2;
}

.style-alt .pickup-point .pickup-point-map {
  background-color: #f0ede5;
  height: 250px;
  box-sizing: border-box;
}

.style-alt .pickup-point .pickup-point-map>div {
  transition: opacity 0.3s;
  opacity: 0;
}

.style-alt .pickup-point .pickup-point-map #pickup-point-travel-mode {
  position: absolute;
  top: 5px;
  right: 5px;
  background: #fff;
  padding: 7px;
  z-index: 1;
  font-size: 11px;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.298039) 0 1px 4px -1px;
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
}

.style-alt .pickup-point .pickup-point-map #pickup-point-travel-mode label {
  display: block;
}

.style-alt .pickup-point .pickup-point-map.map-loaded>div {
  opacity: 1;
}

.style-alt .pickup-point .pickup-point-map button.gm-control-active {
  min-width: 0;
}

.style-alt .pickup-point-selector {
  box-sizing: border-box;
}

.style-alt .pickup-point-selector-el:not(.input-small) {
  height: 35px;
  max-width: 450px;
  margin-bottom: 15px;
}

.style-alt .pickup-point-table-selector>td {
  background-color: #e9efd7;
  opacity: 0.8;
  transition: opacity 0.2s;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: #f6f6f6;
  padding: 6px 10px 6px 30px !important;
}

.style-alt table.highlighted-list tbody>tr.s2s-service-category.selected+.pickup-point-table-selector>td {
  opacity: 1;
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: #faa82a;
}

.style-alt .pickup-point-selector-el label {
  margin-right: 3px;
  display: inline-block;
  margin-bottom: 10px;
}

.style-alt .pickup-point-selector-el .pickup-point-search-postcode {
  width: 80px;
  height: 30px;
  padding: 4px 10px;
  margin-right: 3px;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
}

.style-alt .pickup-point-selector-el .pickup-point-search-postcode.input-invalid {
  border: 1px solid #ed5621;
}

.style-alt .pickup-point-selector-el .btn {
  padding: 2%;
  min-width: 60px;
  font-size: 14px;
  margin: 0;
}

@media screen and (max-width: 1024px) {
  .style-alt .pickup-point-selector-el .btn {
    font-size: 11px;
  }
}

.style-alt .pickup-point-selector-el.input-small label:after {
  content: ":";
}

.style-alt .pickup-point-selector-el.input-small .btn {
  padding: 6px 12px;
  font-size: 11px;
}

.style-alt .pickup-point-list {
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  height: 250px;
  margin-right: 10px;
}

.style-alt .pickup-point-list::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
}

.style-alt .pickup-point-list::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.style-alt .pickup-point-list ul {
  list-style-type: none;
  padding-left: 0;
}

.style-alt .pickup-point-list ul li {
  margin-bottom: 5px;
  cursor: pointer;
  padding-top: 5px;
  width: 98%;
  box-sizing: border-box;
  padding-left: 3%;
}

.style-alt .pickup-point-list ul li:hover {
  background-color: rgba(233, 239, 215, 0.5);
  border-radius: 3px;
}

.style-alt .pickup-point-list ul li.selected-point {
  background-color: #e9efd7;
  background-position: 95% 23px;
  background-image: url(/dist/images/icon/tick_darkgreen.png);
  background-repeat: no-repeat;
  border: 1px solid #faa82a;
  border-radius: 3px;
}

.style-alt .pickup-point-list ul li input[type="radio"] {
  float: left;
  margin-top: 19px;
  width: 5%;
}

.style-alt .pickup-point-list ul li label {
  cursor: pointer;
}

.style-alt .pickup-point-list ul li .pickup-point-address {
  box-sizing: border-box;
  float: left;
  padding-left: 3%;
  padding-right: 5%;
  max-width: 90%;
}

.style-alt .pickup-point-more-info-content h2 {
  margin-bottom: 10px;
}

.style-alt .pickup-point-more-info-content address {
  float: left;
  margin-right: 15px;
}

.style-alt .pickup-point-more-info-content .more-info-address {
  margin-top: 10px;
}

.style-alt .pickup-point-more-info-content .more-info-map,
.style-alt .pickup-point-more-info-content .more-info-map iframe {
  width: 100%;
  height: 400px;
}

.style-alt .pickup-point-more-info-content .more-info-map {
  position: relative;
}

.style-alt .pickup-point-more-info-content .more-info-map .loading-map {
  position: absolute;
  top: 48%;
  left: 45%;
}

.style-alt .pickup-point-more-info-content ul {
  float: right;
  list-style: none;
}

.style-alt .pickup-point-more-info-content ul span {
  width: 100px;
  display: inline-block;
}

.style-alt table.table-g4m-basket span.update-basket-split-quantity {
  display: inline-block;
  margin-right: 2px;
}

.style-alt table.table-g4m-basket select.update-basket-line-qty {
  background-color: #fff;
  text-align: center;
  width: 45px;
  vertical-align: initial;
}

.style-alt .g4m-order-details-container {
  border-radius: 3px;
}

.style-alt .g4m-order-details-container>table {
  margin-bottom: 0;
}

.style-alt .split-basket-type-choice {
  margin-bottom: 15px;
}

.style-alt .split-basket-type-choice>.row {
  margin-left: -7px;
  margin-right: -7px;
  display: flex;
  flex-wrap: wrap;
}

.style-alt .split-basket-type-choice>.row>div[class^="col-"] {
  padding-left: 7px;
  padding-right: 7px;
}

.style-alt .split-basket-type-choice>.row:before,
.style-alt .split-basket-type-choice>.row:after {
  content: normal;
  display: none;
}

.style-alt .split-basket-type-choice .split-option-btn {
  position: relative;
  flex-direction: column;
}

.style-alt .split-basket-type-choice .split-option-btn input {
  position: absolute;
  top: 50%;
  left: 17px;
  margin-top: -7px;
  z-index: 2;
}

.style-alt .split-basket-type-choice .split-option-btn label {
  height: 100%;
  z-index: 1;
  cursor: pointer;
  border-radius: 3px;
  background-color: #f6f6f6;
  padding: 11px 20px 11px 30px;
  display: block;
  position: relative;
  margin-bottom: 0;
  border: 1px solid #ebebeb;
  transition:
    border 0.3s ease,
    background-color 0.2s ease;
}

.style-alt .split-basket-type-choice .split-option-btn label:hover {
  background-color: rgba(233, 239, 215, 0.5);
}

.style-alt .split-basket-type-choice .split-option-btn label span:first-child {
  position: relative;
  font-weight: bold;
  display: inline-block;
  font-size: 13px;
}

.style-alt .split-basket-type-choice .split-option-btn label span:last-child {
  display: block;
  font-size: 12px;
  line-height: 1.2;
}

.style-alt .split-basket-type-choice .split-option-btn input[type="radio"] {
  transition: color 0.2s ease;
}

.style-alt .split-basket-type-choice .split-option-btn input[type="radio"]+label span:first-child:after {
  width: 11px;
  content: "";
  height: 9px;
  position: absolute;
  right: -15px;
  top: 5px;
  opacity: 0;
  transform: scale(0);
  transition: transform 0.2s ease;
}

.style-alt .split-basket-type-choice .split-option-btn input[type="radio"]:checked+label {
  border: 1px solid #faa82a;
  background-color: #e9efd7;
}

.style-alt .split-basket-type-choice .split-option-btn input[type="radio"]:checked+label span:first-child:after {
  opacity: 1;
  transform: scale(1);
}

.style-alt #basket-form .basket-header-text {
  font-size: 24px;
  margin-bottom: 0;
  padding: 4px 0px;
}

.style-alt #basket-form .mobile-basket-header.icon-indent {
  padding-left: 40px;
}

.style-alt #basket-form .basket-icon:before {
  width: 39px;
  height: 37px;
  margin-left: -51px;
  margin-top: -2px;
  background-position: 0px -182px;
}

.style-alt #basket-form #basket-container>.panel-g4m-blank:first-of-type {
  margin-bottom: 13px;
}

.style-alt #address-book-container .address-book-panels .row:before,
.style-alt #address-book-container .address-book-panels .row:after {
  display: initial;
  content: "";
}

.panel {
  margin-bottom: 10px;
}

.panel.alert-success {
  background-color: #dff0d8;
  color: #434649;
  border-color: #d6e9c6;
}

.panel.alert-success>.panel-heading {
  color: #434649;
  border-color: #d6e9c6;
  background: #dff0d8;
  background: linear-gradient(to bottom, #dff0d8 0%, #d6e9c6 100%) repeat scroll 0 0 transparent;
}

.panel.alert-success .panel-body p:last-of-type {
  margin-bottom: 0;
}

.panel.alert-success .panel-body ul.alert-list {
  margin-bottom: 12px;
  padding-left: 20px;
}

.panel.alert-info {
  background-color: #d9edf7;
  color: #434649;
  border-color: #bce8f1;
}

.panel.alert-info>.panel-heading {
  color: #434649;
  border-color: #bce8f1;
  background: #d9edf7;
  background: linear-gradient(to bottom, #d9edf7 0%, #bce8f1 100%) repeat scroll 0 0 transparent;
}

.panel.alert-info .panel-body p:last-of-type {
  margin-bottom: 0;
}

.panel.alert-info .panel-body ul.alert-list {
  margin-bottom: 12px;
  padding-left: 20px;
}

.panel.alert-warning {
  background-color: #fcf8e3;
  color: #434649;
  border-color: #faebcc;
}

.panel.alert-warning>.panel-heading {
  color: #434649;
  border-color: #faebcc;
  background: #fcf8e3;
  background: linear-gradient(to bottom, #fcf8e3 0%, #faebcc 100%) repeat scroll 0 0 transparent;
}

.panel.alert-warning .panel-body p:last-of-type {
  margin-bottom: 0;
}

.panel.alert-warning .panel-body ul.alert-list {
  margin-bottom: 12px;
  padding-left: 20px;
}

.panel.alert-danger {
  background-color: #f2dede;
  color: #434649;
  border-color: #ebccd1;
}

.panel.alert-danger>.panel-heading {
  color: #434649;
  border-color: #ebccd1;
  background: #f2dede;
  background: linear-gradient(to bottom, #f2dede 0%, #ebccd1 100%) repeat scroll 0 0 transparent;
}

.panel.alert-danger .panel-body p:last-of-type {
  margin-bottom: 0;
}

.panel.alert-danger .panel-body ul.alert-list {
  margin-bottom: 12px;
  padding-left: 20px;
}

.panel.alert-emphasised-warning {
  background-color: #fce9b0;
  color: #5c4300;
  border-color: #fce9b0;
}

.panel.alert-emphasised-warning>.panel-heading {
  color: #5c4300;
  border-color: #fce9b0;
  background: #fce9b0;
  background: linear-gradient(to bottom, #fce9b0 0%, #fce9b0 100%) repeat scroll 0 0 transparent;
}

.panel.alert-emphasised-warning .panel-body p:last-of-type {
  margin-bottom: 0;
}

.panel.alert-emphasised-warning .panel-body ul.alert-list {
  margin-bottom: 12px;
  padding-left: 20px;
}

.panel.alert-emphasised-warning .close {
  color: #826601;
}

.panel-dismissible {
  position: relative;
}

.panel-dismissible .panel-body {
  padding-right: 45px;
}

.panel-dismissible .alert-close-icon {
  overflow: hidden;
  width: 45px;
  height: 45px;
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.panel-dismissible .alert-close-icon>button.dismissible {
  width: inherit;
  height: inherit;
  text-align: center;
  margin: 0;
}

.panel-dismissible button.dismissible {
  font-weight: normal;
}

.widget--about,
.widget--category-boxes,
.widget--header,
.widget--merchandising-banner,
.widget--product-listing,
.widget--text-links,
.widget--title {
  margin-bottom: 30px;
}

.widget--about:last-child,
.widget--category-boxes:last-child,
.widget--header:last-child,
.widget--merchandising-banner:last-child,
.widget--product-listing:last-child,
.widget--text-links:last-child,
.widget--title:last-child {
  margin-bottom: 0;
}

.widget--header {
  margin-bottom: 25px;
}

.widget--image-text-links {
  margin-bottom: 10px;
}

.widget--image-text-links:last-child {
  margin-bottom: 0;
}

.plp-page .widget--category-boxes .category-boxes {
  margin-top: 0;
  margin-bottom: 0;
}

.widget--image-text-links .blue-title {
  margin-top: 0;
}

.widget--image-text-links .image-text-links {
  margin-bottom: 0;
}

.widget--merchandising-banner {
  margin-top: 0;
  padding-bottom: 0;
}

.widget--title p:last-child {
  margin-bottom: 0;
}

.my-account-page {
  font-size: 1.4rem;
}

.my-account-page .h1-title {
  padding: 7px 0 6px;
}

.my-account-page .h1-title h2 {
  font-size: 2.4rem;
}

.g4m-button-group {
  margin-bottom: 8px;
}

.g4m-button-group .button {
  margin-bottom: 4px;
}

.g4m-order {
  padding: 20px;
  margin-bottom: 10px;
  border: 1px solid #babbbc;
  border-radius: 3px;
  font-size: 1.4rem;
  line-height: 1.5;
}

.g4m-order .title-split-order {
  font-size: 1.6rem;
}

.g4m-order-line {
  border-top: 1px solid #ededed;
  margin-top: 20px;
  padding-top: 20px;
  display: -ms-grid;
  display: grid;
  gap: 20px;
  -ms-grid-columns: 3fr 20px 3fr 20px 2fr;
  grid-template-columns: 3fr 3fr 2fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-template-areas: "ol-order-details ol-address ol-actions" "ol-products ol-products ol-actions";
}

.g4m-order-line:first-child {
  border-top: none;
  margin-top: 0;
  padding-top: 0px;
}

@media screen and (max-width: 1024px) {
  .g4m-order-line {
    gap: 0 20px;
    -ms-grid-columns: 2fr 20px 1fr;
    grid-template-columns: 2fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    grid-template-areas: "ol-order-details ol-actions" "ol-address ol-actions" "ol-products ol-products";
  }
}

@media screen and (max-width: 600px) {
  .g4m-order-line {
    gap: 0 20px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    grid-template-areas: "ol-order-details" "ol-address" "ol-products" "ol-actions";
  }
}

.g4m-order-line__order-details {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: ol-order-details;
}

@media screen and (min-width: 1024px) {
  .g4m-order-line__order-details {
    padding-right: 20px;
    border-right: 1px solid #ededed;
  }
}

.g4m-order-line__delivery {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  grid-area: ol-address;
}

.g4m-order-line__actions {
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  -ms-grid-column: 5;
  grid-area: ol-actions;
}

@media screen and (max-width: 600px) {
  .g4m-order-line__actions {
    margin-top: 20px;
  }
}

.g4m-order-line__actions .abutton {
  display: block;
  margin-bottom: 5px;
}

.g4m-order-line__actions .cancelbtn {
  width: 100%;
}

.g4m-order-line__products {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-area: ol-products;
}

@media screen and (max-width: 1024px) {
  .g4m-order-line__order-details {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }

  .g4m-order-line__delivery {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
  }

  .g4m-order-line__actions {
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    -ms-grid-column: 3;
  }

  .g4m-order-line__products {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
  }
}

@media screen and (max-width: 600px) {
  .g4m-order-line__order-details {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }

  .g4m-order-line__delivery {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
  }

  .g4m-order-line__actions {
    -ms-grid-row: 7;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
  }

  .g4m-order-line__products {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }
}

@media screen and (max-width: 1024px) {
  .g4m-order-line__products {
    margin-top: 20px;
  }
}

.g4m-order-line__products h5 {
  margin-bottom: 0.6em;
}

.g4m-order-details {
  list-style: none;
  line-height: 1.3;
}

.g4m-order-details li {
  margin-bottom: 0.6em;
}

@media screen and (min-width: 360px) {
  .g4m-order-details li {
    display: flex;
    justify-content: space-between;
  }
}

.g4m-order-details__label {
  display: inline-block;
}

.g4m-order-details__details {
  display: inline-block;
  text-align: right;
  font-weight: bold;
}

.g4m-product-list {
  list-style: none;
  line-height: 1.3;
}

.g4m-product-list li {
  margin-bottom: 0.6em;
}

.g4m-product-list li:last-child {
  margin-bottom: 0;
}

.g4m-product-list .warranty-certificate-link {
  position: relative;
  padding-left: 16px;
}

.g4m-product-list .warranty-certificate-link img {
  position: absolute;
  top: 0;
  left: 4px;
}

.confirm-modal .modal-content {
  padding-top: 15px;
}

.confirm-modal .modal-dialog {
  width: 400px;
}

.confirm-modal .modal-button {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 5px;
  width: 80%;
  display: block;
}

.confirm-modal .modal-text {
  margin-left: 8%;
  margin-right: 8%;
  width: 84%;
  display: block;
}

.confirm-modal .modal-head {
  font-weight: bold;
  font-size: larger;
  margin-left: 8%;
  margin-right: 8%;
  margin-bottom: 0;
  width: 84%;
  display: block;
}

.confirm-modal .whitebtn {
  margin: auto;
  background-color: #ffffff;
  border-color: #ffffff;
  color: #000000;
  font-weight: normal;
}

#klarna_finance_payment #klarna_container {
  display: none;
  min-height: 325px;
}

#klarna_finance_payment #klarna_title {
  color: #595d5e;
  text-align: center;
  display: none;
}

#klarna_finance_payment #klarna_msg {
  color: #595d5e;
  display: none;
}

#klarna_finance_payment #klarna_loading {
  display: inline-block;
  vertical-align: middle;
  line-height: 100px;
  width: 100%;
  text-align: center;
}

#klarna_finance_payment #klarna_box {
  opacity: 0;
  max-height: 100px;
  overflow: hidden;
  text-align: center;
  transition:
    max-height 0.4s ease,
    opacity 0.4s ease;
}

#klarna_finance_payment #klarna_box.klarna-loaded {
  opacity: 1;
  max-height: 1500px;
}

#klarna_finance_payment #klarna_footer {
  color: #595d5e;
  padding-top: 10px;
  display: none;
}

.klarna-finance-currency-icon {
  color: #2597c4;
}

.klarna-one-content {
  position: relative;
  z-index: 0;
}

.klarna-one-content h5 {
  margin-bottom: 0;
  margin-top: 0;
}

.klarna-one-content .split-finance-content {
  margin-top: 0 !important;
}

.klarna-one-content .split-finance-content-end {
  margin-bottom: 15px !important;
}

.sidebar-box.payment-methods img[src$="21.png"],
.sidebar-box.payment-methods img[src$="21-da.png"],
.sidebar-box.payment-methods img[src$="21-en.png"],
.sidebar-box.payment-methods img[src$="21-fi.png"],
.sidebar-box.payment-methods img[src$="21-no.png"],
.sidebar-box.payment-methods img[src$="21-sv.png"] {
  transform: translateX(15px);
}

.sidebar-box.payment-methods img[src$="27.png"] {
  transform: translateX(15px);
}

.sidebar-box.payment-methods img[src$="27-com.png"] {
  transform: translateX(-4px);
}

.sidebar-box.payment-methods img[src$="27-da.png"] {
  transform: translateX(10px);
}

.sidebar-box.payment-methods img[src$="27-en.png"] {
  transform: translateX(15px);
}

.sidebar-box.payment-methods img[src$="27-fi.png"] {
  transform: translateX(0);
}

.sidebar-box.payment-methods img[src$="27-no.png"] {
  transform: translateX(10px);
}

.sidebar-box.payment-methods img[src$="27-sv.png"] {
  transform: translateX(7px);
}

.cookie-banner {
  background-color: rgba(51, 51, 51, 0.95);
  bottom: 0;
  box-shadow: #888 0 0 10px 0;
  display: flex;
  height: auto;
  justify-content: center;
  padding: 20px;
  position: fixed;
  width: 100%;
  z-index: 1030;
}

@media screen and (min-width: 768px) {
  .cookie-banner {
    padding: 30px 0;
  }
}

.cookie-banner__cont {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1366px;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .cookie-banner__cont {
    align-items: center;
    flex-direction: row;
  }
}

.cookie-banner__blurb {
  font-size: 1.2rem;
}

@media screen and (min-width: 768px) {
  .cookie-banner__blurb {
    padding: 0;
    margin: 0 0 0 18px;
  }
}

.cookie-banner__header {
  font-size: 1.5rem;
  margin-top: 9px;
}

.cookie-banner__cta .g4m-btn {
  width: 100%;
  margin: 5px 0;
  white-space: nowrap;
  text-transform: uppercase;
}

@media screen and (min-width: 768px) {
  .cookie-banner__cta {
    padding-left: 20px;
    margin-right: 18px;
  }

  .cookie-banner__cta .g4m-btn {
    font-size: 1.2rem;
    padding-left: 24px;
    padding-right: 24px;
    display: block;
    margin: 0 0 10px;
  }
}

#cookie-consent .g4m-modal-body {
  overflow-y: auto;
}

#cookie-consent .g4m-modal-body .cookie-consent-modal p {
  margin-top: 0;
}

@media screen and (min-width: 768px) {
  #cookie-consent .g4m-modal-body {
    background: #fff;
  }
}

@media screen and (min-width: 768px) {
  #cookie-consent .g4m-modal-dialog {
    width: 580px;
  }
}

#cookie-consent .g4m-modal-header {
  text-transform: capitalize;
  padding-bottom: 8px;
}

#cookie-consent .g4m-modal-header .g4m-modal-title {
  font-size: 1.35em;
}

#cookie-consent .cookie-consent-modal {
  font-size: 1.4rem;
}

@media screen and (min-width: 768px) {
  #cookie-consent .cookie-consent-modal__sections {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }
}

#cookie-consent .cookie-consent-modal__section {
  background: #fff;
  padding: 20px;
  margin: 9px 0;
  border: 1px solid #ededed;
}

@media screen and (min-width: 768px) {
  #cookie-consent .cookie-consent-modal__section {
    width: calc(50% - 10px);
  }
}

#cookie-consent .cookie-consent-modal__section input[type="checkbox"] {
  width: 16px;
  height: 16px;
  margin: 0;
}

#cookie-consent .cookie-consent-modal__section p {
  margin-left: 26px;
  margin-top: 10px;
}

#cookie-consent .cookie-consent-modal__input {
  display: flex;
  align-items: center;
}

#cookie-consent .cookie-consent-modal__input label {
  margin-left: 10px;
  margin-bottom: 0;
  font-size: 1.6rem;
}

#cookie-consent .cookie-consent-modal__granular {
  display: flex;
  flex-wrap: wrap;
}

#cookie-consent .cookie-consent-modal__granular .panel {
  border: none;
  box-shadow: none;
}

#cookie-consent .cookie-consent-modal__granular .g4m-cookie-content-accordion {
  width: 100%;
  max-width: calc(100% - (58px + 14 * 2px));
  margin-left: 16px;
}

#cookie-consent .cookie-consent-modal__granular .g4m-cookie-content-accordion.g4m-panel-outlined {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  margin-bottom: 1.7em;
}

@media screen and (max-width: 768px) {
  #cookie-consent .cookie-consent-modal .g4m-cookie-content-accordion .panel-title {
    min-height: 48px;
  }

  #cookie-consent .cookie-consent-modal .g4m-cookie-content-accordion .panel-heading a[role="button"] {
    font-size: 12px;
  }

  #cookie-consent .cookie-consent-modal .g4m-cookie-content-accordion .panel-body {
    padding: 4px 15px 15px;
  }
}

#cookie-consent .cookie-consent-modal .g4m-cookie-content-accordion a {
  display: inline-block;
  width: 100%;
  padding: 8px 15px;
}

#cookie-consent .cookie-consent-modal .g4m-cookie-content-accordion a:hover {
  text-decoration: none;
}

#cookie-consent .cookie-consent-modal .g4m-cookie-content-accordion .panel-title {
  font-size: 1.5rem;
  text-align: center;
}

#cookie-consent .cookie-consent-modal .g4m-cookie-content-accordion .panel-heading {
  padding: 0;
}

#cookie-consent .cookie-consent-modal .g4m-cookie-content-accordion .panel-heading a[role="button"] {
  display: inline-block;
  width: 100%;
  padding: 16px 35px 10px 15px;
  text-decoration: none;
  color: #45494b;
}

#cookie-consent .cookie-consent-modal .g4m-cookie-content-accordion .panel-heading a[role="button"]:after {
  content: "";
  display: block;
  height: 10px;
  width: 10px;
  position: absolute;
  right: 24px;
  margin-top: -18px;
  transform: rotate(135deg);
  border-top: 2px solid #babbbc;
  border-right: 2px solid #babbbc;
  pointer-events: none;
  transition: all 0.4s ease;
}

#cookie-consent .cookie-consent-modal .g4m-cookie-content-accordion .panel-heading a[role="button"][aria-expanded="true"]:after {
  transform: rotate(-45deg);
}

@media screen and (min-width: 768px) {
  #cookie-consent .cookie-consent-modal__ctas {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

#cookie-consent .cookie-consent-modal__ctas .g4m-btn {
  width: 100%;
  min-height: 40px;
  margin: 5px 0;
  white-space: normal;
  text-transform: uppercase;
}

@media screen and (min-width: 768px) {
  #cookie-consent .cookie-consent-modal__ctas .g4m-btn {
    width: 100%;
  }
}

.body-class--mobile #cookie-consent .cookie-consent-modal .panel-heading a[role="button"] {
  padding: 16px 24px 10px 4px;
}

.body-class--mobile #cookie-consent .cookie-consent-modal .panel-heading a[role="button"]:after {
  right: 5%;
  top: 34px;
}

.single-column-responsive-layout {
  max-width: 1366px;
  margin: 0 auto;
  padding: 0;
}

.single-column-responsive-layout .sidebar-box {
  float: left;
}

@media screen and (min-width: 1024px) {
  .single-column-responsive-layout .container-fluid {
    padding: 0 18px;
  }
}

.product-card-legacy {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  font-size: 1.4rem;
  line-height: 1.3;
  background-color: #fff;
  color: inherit;
  border: 1px solid #d5d5d5;
  border-radius: 3px;
  transition: border 150ms ease-in-out;
}

.product-card-legacy:hover {
  border: 1px solid #fa8600;
}

.product-card-legacy:hover {
  text-decoration: none;
}

.product-card-legacy .title,
.product-card-legacy .description,
.product-card-legacy .image-wrapper,
.product-card-legacy .availability {
  flex: 1 1 auto;
}

.product-card-legacy .promo-sash {
  display: block;
  width: 55px;
  height: 55px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.product-card-legacy .title,
.product-card-legacy .description {
  text-align: center;
  padding: 0 20px;
}

.product-card-legacy .title {
  order: 2;
  flex-shrink: 0;
  margin: 0;
  overflow: hidden;
  height: 40px;
  font-size: 1.4rem;
}

.fnc-bx-vid {
  text-decoration: unset !important;
  cursor: pointer;
}

.product-card-legacy .description {
  order: 3;
  height: 8px;
  width: 95%;
  overflow: hidden;
  font-size: 1.4rem;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;

  * {
    width: 100% !important;
    display: block;
    word-break: break-word;
    white-space: normal;
    margin: 5px 0;
  }
}

.product-card-legacy .image-wrapper {
  order: 1;
  flex: 0 0 auto;
  padding: 15px 0;

  img {
    height: 160px;
    object-fit: contain;
  }
}

.product-card-legacy .image {
  display: block;
  margin: 0 auto;
  height: 100%;
}

.product-card-legacy .image {
  transition: transform 150ms ease-in-out;
  transform: scale(1);
}

.product-card-legacy:hover .image {
  transform: scale(1.05);
}

.product-card-legacy .availability {
  order: 4;
  display: flex;
  justify-content: stretch;
  align-items: flex-end;
  min-height: 30px;
  margin: 5px 0 15px;
}

.product-card-legacy .availability .scaled-price-label {
  position: absolute;
  height: 38px;
  color: darkred;
  font-weight: bold;
  margin-left: 16px;
}

.product-card-legacy .price,
.product-card-legacy .stock-message {
  line-height: 1;
  flex: 1 1 auto;
}

.product-card-legacy .price {
  flex: none;
  position: relative;
  bottom: -1px;
  font-weight: bold;
  text-align: right;
  color: #d00000;
  font-size: 2.5rem;
  padding-left: 15px;
  padding-right: 20px;
}

.product-card-legacy .stock-message {
  font-weight: bold;
  line-height: 1.2;
  padding-right: 20px;
  font-size: 1.4rem;
  text-align: right;
}

.product-card-legacy .stock-message.in-stock {
  color: #249c54;
}

.product-card-legacy .stock-message.not-available.cannot-order {
  font-size: 1.1rem;
}

.product-card-legacy .stock-message.not-available {
  font-weight: normal;
  color: #888888;
  text-align: center;
  font-size: 0.96rem;
  padding-right: 20px;
}

.product-card-legacy .price.not-available {
  display: none;
}

.category-description .box-title {
  font-size: 2.2rem;
  margin: 0 0 20px;
}

.category-description .box-description,
.category-description .box-description p {
  line-height: 1.5;
}

.widget>.title,
.widget>h2,
.sidebar .filter-box h2 {
  font-size: 1.3rem;
}

.contact-us {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  background-color: #fff;
  height: 300px;
}

.body-class--mobile .contact-us.hide-mobile {
  display: none;
}

.sidebar .contact-us {
  margin-bottom: 12px;
}

.contact-us>.title {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  margin: 0;
  background-color: #e5e5e5;
}

.contact-us>.title>* {
  color: #434649;
}

.contact-us .contact-item {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 0;
  padding: 10px;
}

.contact-us .contact-item:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
}

.contact-us .contact-item>* {
  color: #434649;
  text-align: center;
}

.contact-us .phone {
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
  }
}

.contact-us .phone-icon {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-image: url("../assets/img/icon-phone-vertical.svg");
  background-size: 100% 100%;
  vertical-align: middle;
}

.contact-us .phone-number {
  line-height: 25px;
}

.contact-us .email {
  text-decoration: underline;
}

.contact-us .video-our-story a {
  display: block;
  padding: 9px 0 0;
  text-align: center;
  color: #434649;
}

.contact-us .video-our-story span {
  line-height: 2.8rem;
}

.body-class--has-adaptive .hide-mobile {
  display: inherit !important;
}

@media screen and (max-width: 768px) {
  .body-class--has-adaptive .show-mobile {
    display: none !important;
  }
}

.body-class--desktop .style-alt .hidden-xs,
.body-class--tablet .style-alt .hidden-xs {
  display: block !important;
}

.body-class--desktop .style-alt th.hidden-xs,
.body-class--tablet .style-alt th.hidden-xs {
  display: table-cell !important;
}

.body-class--desktop .style-alt .visible-xs,
.body-class--tablet .style-alt .visible-xs {
  display: none !important;
}

.body-class--desktop .col-xs-12.col-sm-1,
.body-class--tablet .col-xs-12.col-sm-1 {
  width: 8.33333%;
}

.body-class--desktop .col-xs-12.col-sm-2,
.body-class--tablet .col-xs-12.col-sm-2 {
  width: 16.66667%;
}

.body-class--desktop .col-xs-12.col-sm-3,
.body-class--tablet .col-xs-12.col-sm-3 {
  width: 25%;
}

.body-class--desktop .col-xs-12.col-sm-4,
.body-class--tablet .col-xs-12.col-sm-4 {
  width: 33.33333%;
}

.body-class--desktop .col-xs-12.col-sm-5,
.body-class--tablet .col-xs-12.col-sm-5 {
  width: 41.66667%;
}

.body-class--desktop .col-xs-12.col-sm-6,
.body-class--tablet .col-xs-12.col-sm-6 {
  width: 49.9%;
}

.body-class--desktop .col-xs-12.col-sm-7,
.body-class--tablet .col-xs-12.col-sm-7 {
  width: 58.33333%;
}

.body-class--desktop .col-xs-12.col-sm-8,
.body-class--tablet .col-xs-12.col-sm-8 {
  width: 66.66667%;
}

.body-class--desktop .col-xs-12.col-sm-8,
.body-class--tablet .col-xs-12.col-sm-8 {
  width: 75%;
}

.body-class--desktop .col-xs-12.col-sm-10,
.body-class--tablet .col-xs-12.col-sm-10 {
  width: 83.33333%;
}

.body-class--desktop .col-xs-12.col-sm-11,
.body-class--tablet .col-xs-12.col-sm-11 {
  width: 91.66667%;
}

nav#inv-breadcrumb .breadcrumb-item {
  font-size: 1.2rem;
}

.zoom-hover .page-overlay {
  background-color: #222;
  opacity: 0.33;
  visibility: visible;
  z-index: 20;
}

.filter-wrap .filter-box {
  width: auto;
}

.filter-wrap .filter-box .go,
.filter-wrap .filter-box input {
  margin: 0 2px 0 0;
}

.filter-wrap .filter-box #max-input-label,
.filter-wrap .filter-box #min-input-label {
  font-weight: 700;
}

.instant-search-title {
  border: none;
  line-height: 1.3em;
}

.single-column-responsive-layout .search-content * {
  box-sizing: unset;
}

.single-column-responsive-layout .search-content {
  margin-left: 3px;
}

.single-column-responsive-layout .sidebar {
  margin: 0;
  font-size: 1.2rem;
  display: -ms-inline-grid;
  display: inline-grid;
}

.single-column-responsive-layout .sidebar-left {
  margin-right: 3px;
}

.single-column-responsive-layout .content {
  margin-right: 0;
}

.single-column-responsive-layout .content .section-title {
  clear: none;
}

.single-column-responsive-layout .content .h1-title {
  font-size: 3rem;
  line-height: 1.3em;
  padding: 7px 0 6px 0;
  margin: 0 0 12px;
}

.single-column-responsive-layout .content .results-block .section-title {
  text-align: left;
  font-size: 1.4rem;
  line-height: 1.215em;
  padding: 7px 15px;
  margin: 0 0 11px;
  font-weight: bold;
}

.single-column-responsive-layout .content .holder select,
.single-column-responsive-layout .content .instock,
.single-column-responsive-layout .content .nostock,
.single-column-responsive-layout .content .show-large,
.single-column-responsive-layout .content .inv-del-est {
  font-size: 1.2rem;
}

.single-column-responsive-layout .content .add-to-basket-button {
  margin: 0;
}

@media screen and (max-width: 1336px) {
  .single-column-responsive-layout .content .h1-title {
    font-size: 2.4rem;
    line-height: 1.3em;
  }
}

.single-column-responsive-layout .pdp-page {
  font-size: 16px;
}

.single-column-responsive-layout .pdp-page * {
  font-size: 100%;
}

.single-column-responsive-layout .pdp-page .search-results {
  display: flow-root;
  position: relative;
}

.single-column-responsive-layout .pdp-page .row.row-top-border>*::before {
  content: "";
  display: block;
  border-top: 2px solid #f4f4f4;
}

.single-column-responsive-layout .pdp-page .add-to-basket-button {
  width: 100%;
  margin: 1em 0;
  max-width: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.single-column-responsive-layout .pdp-page .add-to-basket-button .button-label {
  font-size: 1.8rem;
}

.single-column-responsive-layout .pdp-page .add-to-basket-button .button-icon {
  width: 3rem;
  height: 3rem;
  display: inline-block;
  vertical-align: middle;
  min-width: 3rem;
}

.single-column-responsive-layout .pdp-page .pdp-product-block:before {
  content: "  ";
}

.single-column-responsive-layout .pdp-page .pdp-product-block:after {
  clear: both;
  content: "";
  display: block;
}

.single-column-responsive-layout .pdp-page .pdp-breadcrumb {
  color: #83888b;
  margin: 0 0 11px;
}

.single-column-responsive-layout .pdp-page .pdp-breadcrumb #inv-breadcrumb {
  font-size: 0.75em;
}

.single-column-responsive-layout .pdp-page .pdp-title h1 {
  padding-top: 0;
  color: #434649;
  font-size: 3rem;
  line-height: 1.1em;
  margin: 0 0 8px;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box {
  text-align: center;
  width: 100%;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .image-box-wrapper {
  margin: 0 auto;
  max-width: 600px;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .image-box-inner {
  margin: 0 auto;
  max-width: 500px;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .gmask {
  height: 100%;
  margin: 0;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .gmask ul {
  margin: 8px auto;
  position: relative;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .gmask ul li {
  max-width: 500px;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .gmask ul li.active {
  z-index: 20;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .gmask ul li.image {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  right: 0;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .gmask ul li.image img.main-image {
  max-width: 100%;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .gmask ul li.image .zoomPad {
  max-width: 500px;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .gmask ul li.image .zoomPad .zoomWindow {
  overflow: hidden;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .gmask ul li.video {
  align-items: center;
  display: none;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .gmask ul li.video.active {
  display: flex;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .gmask ul li.video .wistia_responsive_padding {
  width: 100%;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .gmask ul li.video .wistia_responsive_padding .wistia_embed {
  height: 100%;
  width: 100%;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .gmask .video .video-overlay {
  display: none !important;
  max-height: 500px;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper {
  max-width: 600px;
  float: left;
  margin: 0;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper .switcher {
  border: 0;
  margin: 0 auto 10px;
  padding: 0;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper .switcher ul {
  padding: 0 29px 0 31px;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper .switcher ul li {
  border: 1px solid #f4f4f4;
  height: 75px;
  width: 75px;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper .switcher ul li.active {
  border: 1px solid #f08000;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper .switcher ul li a {
  margin: 0;
  width: auto;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper .switcher ul li a img {
  max-height: 73px;
  max-width: 73px;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper .switcher ul li a.video .video-thumbnail {
  height: 73px;
  margin: 0;
  padding: 13px;
  width: 73px;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper .switcher ul li .video-tagline {
  display: none;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper .switcher .pagination {
  background-color: #fff;
  border: none;
  display: flex;
  height: 75px;
  width: 31px;
  z-index: 15;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper .switcher .pagination.hidden {
  visibility: hidden;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper .switcher .pagination.chevron::before,
.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper .switcher .pagination.chevron::after {
  content: none;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper .switcher .pagination svg {
  display: block;
  width: 31px;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper .switcher .pagination-inactive {
  background-color: #fff;
  display: flex;
  height: 75px;
  position: absolute;
  width: 31px;
  z-index: 15;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper .switcher .pagination-inactive.chevron-left {
  left: 0;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper .switcher .pagination-inactive.chevron-right {
  right: 0;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper .switcher .pagination-inactive svg {
  display: block;
  width: 31px;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper.simple-carousel {
  width: 562px !important;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper.simple-carousel .switcher .chevron,
.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper.simple-carousel .switcher .chevron-right,
.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper.simple-carousel .switcher .chevron-left {
  height: 62px;
}

@media screen and (max-width: 1366px) {
  .single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper.simple-carousel .switcher .pagination-inactive.hidden~ul .selected {
    margin-left: 13px;
  }
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper.simple-carousel .switcher .follow-link {
  border: 1px solid #ededed;
  box-sizing: border-box;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper.simple-carousel .switcher .follow-link:first-child {
  margin-left: 3px;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper.simple-carousel .switcher .follow-link:hover {
  border-color: #99999a;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper.simple-carousel .switcher .follow-link.selected,
.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper.simple-carousel .switcher .follow-link.selected:hover {
  border-color: #f08000;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper.simple-carousel .switcher .follow-link {
  height: 62px;
  width: 62px;
  max-height: 62px;
  max-width: 62px;
}

.single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper.simple-carousel .switcher .follow-link img {
  height: 60px;
  width: 60px;
  max-height: 60px;
  max-width: 60px;
}

@media screen and (max-width: 1256px) {
  .single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper.simple-carousel {
    width: 488px !important;
  }
}

@media screen and (max-width: 1038px) {
  .single-column-responsive-layout .pdp-page .pdp-images.image-box .switcher-wrapper.simple-carousel {
    width: 419px !important;
  }
}

.single-column-responsive-layout .pdp-page .short-description .prd-ref {
  margin-top: 1em;
  font-weight: bold;
}

.single-column-responsive-layout .pdp-page .full-description {
  font-size: 1.4rem;
  margin-bottom: 30px;
}

.single-column-responsive-layout .pdp-page .full-description h2.section-title {
  display: inline-block;
  text-align: left;
  font-size: 2.2rem;
  line-height: 1.5em;
  margin: 0.5em 0 0;
  padding: 0;
}

.single-column-responsive-layout .pdp-page .full-description #trustpilot-service-reviews {
  margin-top: 20px;
  font-size: 1.4rem;
}

.single-column-responsive-layout .pdp-page .full-description #trustpilot-service-reviews ul.trustpilot-header li {
  background-color: #f4f4f4;
  border-bottom: 1px solid #eee;
  font-size: 1.4rem;
}

.single-column-responsive-layout .pdp-page .full-description #trustpilot-service-reviews ul.trustpilot-footer li {
  background-color: #5f5f5f;
}

.single-column-responsive-layout .pdp-page .full-description #trustpilot-service-reviews .trustpilot-review-list-container.loading {
  background-color: #f4f4f4;
}

.single-column-responsive-layout .pdp-page .full-description #trustpilot-service-reviews ul li h2 {
  font-size: 1.4rem;
  margin: 0;
}

.single-column-responsive-layout .pdp-page .full-description a {
  font-weight: bold;
  color: #09c;
}

.single-column-responsive-layout .pdp-page .full-description p {
  margin-top: 0.5em;
}

.single-column-responsive-layout .pdp-page .full-description h2 {
  font-size: 1.8rem;
  margin-bottom: 0;
}

.single-column-responsive-layout .pdp-page .full-description h3,
.single-column-responsive-layout .pdp-page .full-description h4 {
  font-size: 1.5rem;
  margin-bottom: 0;
}

.single-column-responsive-layout .pdp-page .plug-adapter-warning {
  background-color: #f4f4f4;
  border: 1px solid #eee;
  border-radius: 0.3em;
  font-size: 0.875em;
  padding: 1.2em 1.7em;
}

.single-column-responsive-layout .pdp-page .plug-adapter-warning h3 {
  font-size: 1.5rem;
  margin: 0;
}

.single-column-responsive-layout .pdp-page .plug-adapter-warning p {
  font-size: 1.4rem;
  margin: 0.287em 0 0;
}

.single-column-responsive-layout .pdp-page .modal-dialog {
  position: initial;
}

.single-column-responsive-layout .pdp-page .product-carousel .switcher-wrapper .switcher {
  z-index: 16;
}

.single-column-responsive-layout .pdp-page .product-carousel .switcher-wrapper .switcher .accessories-list {
  font-size: 1.1rem;
}

.single-column-responsive-layout .pdp-page .product-carousel .switcher-wrapper .switcher .accessories-list .carousel-product-title {
  max-height: 38px;
}

.single-column-responsive-layout .pdp-page .product-carousel .switcher-wrapper .switcher .accessories-list li {
  width: 137px;
  border: 2px solid #f4f4f4;
  margin: 0 8px 2px 9px;
}

.single-column-responsive-layout .pdp-page .product-carousel .switcher-wrapper .switcher .accessories-list .holder .price {
  color: #d00000;
  font-size: 1.4rem;
  font-weight: bold;
}

.single-column-responsive-layout .pdp-page .product-carousel .switcher-wrapper .switcher .accessories-list .holder input {
  margin-left: 5px;
}

.single-column-responsive-layout .pdp-page .product-carousel .switcher-wrapper .switcher .next:after {
  border-left-color: #fff;
}

.single-column-responsive-layout .pdp-page .product-carousel .switcher-wrapper .switcher .prev:after {
  border-right-color: #fff;
}

.single-column-responsive-layout .pdp-page .product-carousel .switcher-wrapper .switcher.switcher-items-1 {
  max-width: 222px;
}

.single-column-responsive-layout .pdp-page .product-carousel .switcher-wrapper .switcher.switcher-items-2 {
  max-width: 374px;
}

.single-column-responsive-layout .pdp-page .product-carousel .switcher-wrapper .switcher.switcher-items-3 {
  max-width: 526px;
}

.single-column-responsive-layout .pdp-page .product-carousel .switcher-wrapper .switcher.switcher-items-4 {
  max-width: 678px;
}

.single-column-responsive-layout .pdp-page .product-carousel .switcher-wrapper .switcher.switcher-items-5 {
  max-width: 830px;
}

.single-column-responsive-layout .pdp-page .product-carousel .switcher-wrapper .switcher.switcher-items-6 {
  max-width: 982px;
}

.single-column-responsive-layout .pdp-page .product-carousel .switcher-wrapper .switcher.switcher-items-7 {
  max-width: 1134px;
}

.single-column-responsive-layout .pdp-page .product-carousel .switcher-wrapper .switcher.switcher-items-8 {
  max-width: 1286px;
}

.single-column-responsive-layout .pdp-page .product-carousel .switcher-wrapper .switcher.switcher-items-9 {
  max-width: 1438px;
}

.single-column-responsive-layout .pdp-page .product-carousel .btn-holder {
  display: table;
  float: right;
  padding-bottom: 15px;
}

.single-column-responsive-layout .pdp-page .product-carousel .btn-holder .add-to-basket-text {
  padding-right: 30px;
  font-size: 1.4rem;
  text-align: right;
  margin: 0 0 0 auto;
  display: table-cell;
  vertical-align: middle;
}

@media screen and (max-width: 974px) {
  .single-column-responsive-layout .pdp-page .product-carousel .btn-holder .add-to-basket-text {
    text-align: left;
  }
}

.single-column-responsive-layout .pdp-page .product-carousel .btn-holder .add-to-basket-button {
  margin: 4px 0 0 0;
  min-width: 320px;
  width: auto;
  flex-shrink: 0;
}

.single-column-responsive-layout .pdp-page .product-carousel .btn-holder .button-label {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 2.5em;
  margin-left: 3em;
}

.single-column-responsive-layout .pdp-page .product-carousel .btn-holder svg {
  position: relative;
  left: 3em;
}

.single-column-responsive-layout .pdp-page .section-title {
  margin: 0.3em 0;
  font-size: 2.2rem;
  text-align: center;
}

.single-column-responsive-layout .pdp-page .pagination {
  background: none;
  border: none;
}

.single-column-responsive-layout .pdp-page .section-title {
  margin: 0.3em 0;
  font-size: 2.2rem;
  text-align: center;
}

.single-column-responsive-layout .pdp-page .short-description .quick-info {
  border-top: 2px solid #f4f4f4;
  padding-top: 15px;
}

.single-column-responsive-layout [class|="checkbox-light"] {
  height: 16px;
  width: 16px;
}

.single-column-responsive-layout [class|="checkbox-light"][class$="-wip"] {
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-appearance: none;
}

.single-column-responsive-layout [class|="checkbox-light"][class$="-wip"]:focus {
  outline: none;
}

.single-column-responsive-layout [class|="checkbox-light"][class$="-wip"]:checked:after {
  content: "\2714";
  font-size: 14px;
  position: relative;
  top: -3px;
  left: 2px;
  color: #009f51;
}

body.zoom-hover .nav-holder {
  z-index: 15;
}

.cbom-pricing-top {
  width: 100%;
}

.cbom-pricing-bottom {
  width: 100%;
  padding: 10px 0 10px 50px;
}

.single-column-responsive-layout .cbom-pricing-bottom+.add-to-basket-button {
  margin-left: 50px;
}

.purchase-info {
  box-sizing: border-box;
}

.purchase-info .info-row {
  display: flex;
  max-width: 475px;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  color: #434649;
}

.purchase-info .info-row .info-row-pricing {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-weight: bold;
  line-height: 2em;
  margin-bottom: 2px;
}

.purchase-info .info-row .info-row-price {
  display: block;
  color: #d00000;
  font-size: 3rem;
  line-height: 1.1em;
}

.purchase-info .info-row .info-row-stock .info-row-stock-msg {
  float: left;
  font-weight: bold;
  line-height: 1.5em;
}

.purchase-info .info-row .info-row-stock .nostock {
  font-size: 0.9375em;
  margin: 0;
  padding: 0;
}

.purchase-info .info-row .info-row-stock .instock {
  font-size: 1.8rem;
}

.purchase-info .info-row .info-row-stock .instock:not(.third-party) {
  color: #009f51;
}

.purchase-info .info-row .info-row-stock .more-info-icon {
  margin: 6px;
}

.purchase-info .info-row .info-row-delivery-msg {
  width: 100%;
  margin: 2px 0;
  color: #434649;
  font-size: 1.5rem;
}

.purchase-info .info-row .info-row-delivery-msg .free {
  color: inherit;
}

.purchase-info .info-row .info-row-delivery-msg .more-info-icon {
  margin-top: 1px;
  margin-bottom: 4px;
}

.purchase-info .info-row .info-row-delivery-msg .arrow-right {
  top: 19px;
}

.purchase-info .info-row .info-row-delivery-msg .delivery-advice-title {
  font-size: 1.5rem;
}

.purchase-info .info-row .info-row-vat {
  display: block;
  text-align: right;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.1em;
  color: #83888b;
}

.purchase-info .info-row .info-row-special-msg {
  font-weight: bold;
  font-size: 0.9375em;
  text-align: right;
}

.purchase-info .info-row.without-vat .info-row-pricing {
  width: 50%;
  order: -2;
}

.purchase-info .info-row.without-vat .info-row-special-msg {
  order: -1;
}

.quick-info-container {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.quick-info {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: 15px;
  border-bottom: 2px solid #f4f4f4;
  margin-bottom: 15px;
}

.quick-info .color-blue {
  color: #09c;
}

.quick-info:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.quick-info.question .quick-info-header {
  font-weight: normal;
}

.quick-info .quick-info-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 31px;
  height: 31px;
  border-radius: 50%;
  background: #f4f4f4;
  padding: 8px;
}

.quick-info .quick-info-icon svg {
  height: 100%;
  width: 100%;
}

.quick-info.short-description .quick-info-header {
  margin-bottom: 10px;
}

.quick-info.short-description .quick-info-content {
  margin-top: 0px;
}

.quick-info.short-description .quick-info-content p {
  margin: 0;
}

.quick-info.short-description .quick-info-content br~br {
  display: none;
}

.quick-info span {
  font-size: 1.5rem;
}

.quick-info .quick-info-header {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 20px;
  width: calc(100% - 31px - 12px - 2px);
  margin-left: 12px;
  margin-top: calc((31px / 2) - (20px / 2));
  min-height: calc(31px - (((31px / 2) - (20px / 2))));
}

.g4m-mobile-pdp .quick-info .quick-info-header {
  width: 100%;
  margin: 0;
}

.quick-info .quick-info-header.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.quick-info .quick-info-header h2,
.quick-info .quick-info-header h3 {
  margin: 0;
}

.quick-info .quick-info-header .more-info-icon {
  margin-bottom: 6px;
  margin-left: 2px;
}

.quick-info .quick-info-header .manufacturer-logo {
  align-self: flex-start;
  transform: translateY(-15px);
  height: calc((31px / 2) - (20px / 2) + 20px);
  margin-bottom: 5px;
  margin-left: 10px;
}

.quick-info .quick-info-header .warranty-block,
.quick-info .quick-info-header .tooltip-container,
.quick-info .quick-info-header .tooltip-source {
  margin: 0;
}

.quick-info .quick-info-header .tooltip-target,
.quick-info .quick-info-header .tooltip-target-desktop {
  margin-left: -45px;
}

.quick-info .quick-info-content {
  width: 100%;
  padding-left: calc(31px + 12px);
  font-size: 1.4rem;
}

.g4m-mobile-pdp .quick-info .quick-info-content {
  padding-left: 30px;
}

.quick-info .quick-info-content>*:first-child {
  margin-top: 10px;
}

.quick-info .quick-info-content .list {
  margin-bottom: inherit;
  padding-left: 15px;
}

.quick-info .quick-info-content .list li {
  padding-bottom: 2px;
}

.quick-info .quick-info-content .extended-warranty-link {
  font-size: 1.5rem;
}

.quick-info .quick-info-content .inventory-detail-alt-lang-link {
  margin-bottom: 5px;
}

.quick-info .quick-info-content a {
  font-weight: bold;
  color: #09c;
}

.quick-info .quick-info-content p {
  margin-top: 10px;
}

.quick-info .tooltip-source {
  font-size: 1.5rem;
}

.quick-info.desktop-no-border-bottom {
  border-bottom: none;
}

.quick-info.variations-condition {
  font-size: 1.5rem;
}

.quick-info.variations-condition .quick-info-content {
  width: auto;
  padding-left: 12px;
}

@media screen and (max-width: 768px) {
  .quick-info.variations-condition .quick-info-content {
    padding-left: 0;
  }
}

.quick-info.variations-condition .quick-info-icon {
  padding: 8px 7px 8px 8px;
}

@media screen and (max-width: 768px) {
  .quick-info.variations-condition .quick-info-icon {
    background: none;
  }
}

.quick-info.variations-condition .variants-text a:hover {
  text-decoration: none;
}

.quick-info.variations-condition .variants-text .variants-link {
  font-weight: bold;
  text-decoration: underline;
}

.quick-info.variations-condition .variants-text .variants-price {
  font-weight: normal;
  color: #09c;
}

.quick-info.variations-condition .variants-text:first-of-type {
  margin-top: 5px;
}

.quick-info .warranty-checkbox {
  display: flex;
}

.quick-info .warranty-checkbox .more-info-icon {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
}

@media screen and (max-width: 768px) {
  .quick-info .warranty-checkbox .more-info-icon {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .quick-info .hide-mobile {
    display: none;
  }
}

.quick-info.variations-colour .display-flex {
  display: flex;
}

.quick-info.variations-colour .display-flex h3 {
  display: flex;
}

.quick-info.variations-colour .display-flex h3 span {
  margin-left: 5px;
}

.quick-info.variations-colour .quick-info-icon svg {
  height: auto;
  width: auto;
}

.quick-info.variations-colour .quick-info-header {
  width: auto;
  margin-bottom: 15px;
}

.quick-info.variations-colour .quick-info-header .selected-colour {
  font-weight: 400;
  color: #83888b;
}

@media screen and (max-width: 768px) {
  .quick-info.variations-colour .quick-info-header h3 {
    font-size: 1.5rem;
  }
}

.quick-info.quick-info-row {
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}

.quick-info.quick-info-row .quick-info-icon {
  width: unset;
}

.quick-info.quick-info-row .quick-info-header {
  width: unset;
  flex-shrink: 1;
}

.quick-info.quick-info-row .quick-info-content {
  width: unset;
  flex-shrink: 0;
}

.quick-info.quick-info-row .quick-info-content>*:first-child {
  margin-top: 0;
}

.moneyBackGuaranteeOptional .tooltip-target,
.moneyBackGuaranteeOptional .tooltip-target-desktop {
  top: -8px;
}

.moneyBackGuaranteeOptional .extended-warranty-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.moneyBackGuaranteeOptional .extended-warranty-wrapper .tooltip-container {
  margin-left: 10px;
}

.body-class--mobile .moneyBackGuaranteeOptional h4 {
  font-size: 1.7rem;
}

.moneyBackGuaranteeOptional .extended-warranty-link {
  color: #09c;
  display: inline-block;
  font-weight: bold;
  line-height: 1.5;
}

.moneyBackGuaranteeOptional .extended-warranty-link:hover {
  text-decoration: underline;
}

.moneyBackGuaranteeOptional .extended-warranty-link .warranty-price {
  color: #d00000;
}

.moneyBackGuaranteeOptional .smallText {
  display: block;
  margin-bottom: 2px;
  line-height: 10px;
  color: #83888b;
}

.quick-info .moneyBackGuaranteeOptional .smallText,
.moneyBackGuaranteeOptional .smallText span {
  font-size: 1.3rem;
}

.moneyBackGuaranteeOptional .more-info-icon {
  margin-bottom: 6px;
  margin-left: 2px;
}

.moneyBackGuaranteeOptional .arrow-right {
  top: 35px;
}

.moneyBackGuaranteeOptional .text-container {
  line-height: 10px;
}

.moneyBackGuaranteeOptional .text-container .more-info-icon {
  margin-top: 2px;
}

.moneyBackGuaranteeOptional .text-container .smallText {
  display: block;
  margin-top: 2px;
}

.quick-info-extended-warranty .moneyBackGuaranteeOptional {
  width: 100%;
}

.quick-info-extended-warranty .moneyBackGuaranteeOptional .checkbox {
  margin: 5px 0;
}

.quick-info-extended-warranty .moneyBackGuaranteeOptional .cr {
  left: 2px;
  top: 1px;
  width: 22px;
  height: 22px;
  transform: none;
}

.quick-info-extended-warranty .moneyBackGuaranteeOptional .cr svg {
  width: 15px;
  height: 15px;
}

.quick-info-extended-warranty .quick-info-header,
.quick-info-finance .quick-info-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (min-width: 1024px) {

  .quick-info-extended-warranty .quick-info-header,
  .quick-info-finance .quick-info-header {
    padding-right: 30px;
  }
}

.quick-info-extended-warranty .quick-info-header::-moz-selection,
.quick-info-extended-warranty .quick-info-header *::-moz-selection,
.quick-info-finance .quick-info-header::-moz-selection,
.quick-info-finance .quick-info-header *::-moz-selection {
  background-color: white;
}

.quick-info-extended-warranty .quick-info-header::selection,
.quick-info-extended-warranty .quick-info-header *::selection,
.quick-info-finance .quick-info-header::selection,
.quick-info-finance .quick-info-header *::selection {
  background-color: white;
}

.quick-info-extended-warranty .finance-title-apr,
.quick-info-finance .finance-title-apr {
  color: #83888b;
  font-size: 1.3rem;
  font-weight: normal;
  line-height: inherit;
  display: block;
}

@media screen and (min-width: 1024px) {

  .quick-info-extended-warranty .finance-title-apr,
  .quick-info-finance .finance-title-apr {
    display: inline;
  }
}

.quick-info-extended-warranty .expand-link,
.quick-info-finance .expand-link {
  color: #83888b;
  font-size: 1.3rem;
  font-weight: normal;
  display: block;
}

.g4m-mobile-pdp .quick-info-extended-warranty .expand-link,
.g4m-mobile-pdp .quick-info-finance .expand-link {
  margin-right: 15px;
}

.g4m-mobile-pdp .quick-info-extended-warranty .expand-link::after,
.g4m-mobile-pdp .quick-info-finance .expand-link::after {
  width: 10px;
  height: 10px;
  border-color: #babbbc;
  transition: all 0.4s ease;
}

.g4m-mobile-pdp .quick-info-extended-warranty .expand-link.expanded::after,
.g4m-mobile-pdp .quick-info-finance .expand-link.expanded::after {
  margin-top: -4px;
  transform: translateY(2px) rotateZ(-45deg);
}

.quick-info-extended-warranty .expand-link::after,
.quick-info-finance .expand-link::after {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  margin-left: 0.5em;
  margin-right: 1px;
  vertical-align: middle;
  transform: translateY(-5px) rotateZ(135deg);
}

.quick-info-extended-warranty .when-expanded,
.quick-info-extended-warranty .when-collapsed,
.quick-info-finance .when-expanded,
.quick-info-finance .when-collapsed {
  display: none;
}

@media screen and (min-width: 1024px) {

  .quick-info-extended-warranty .when-expanded,
  .quick-info-finance .when-expanded {
    display: none;
  }

  .quick-info-extended-warranty .when-collapsed,
  .quick-info-finance .when-collapsed {
    display: inline;
    font-size: 1.3rem;
  }

  .quick-info-extended-warranty .expanded .when-expanded,
  .quick-info-finance .expanded .when-expanded {
    display: inline;
  }

  .quick-info-extended-warranty .expanded .when-collapsed,
  .quick-info-finance .expanded .when-collapsed {
    display: none;
  }
}

.quick-info-extended-warranty .expanded .expand-link::after,
.quick-info-finance .expanded .expand-link::after {
  transform: translateY(2px) rotateZ(-45deg);
}

.quick-info-extended-warranty .quick-info-content,
.quick-info-finance .quick-info-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;
}

.quick-info-extended-warranty .quick-info-content.expanding,
.quick-info-extended-warranty .quick-info-content.expanded,
.quick-info-finance .quick-info-content.expanding,
.quick-info-finance .quick-info-content.expanded {
  max-height: 2000px;
}

.quick-info-extended-warranty .quick-info-content.expanded,
.quick-info-finance .quick-info-content.expanded {
  overflow: visible;
}

.quick-info-extended-warranty .quick-info-content .heading,
.quick-info-finance .quick-info-content .heading {
  display: none;
}

.quick-info-extended-warranty .quick-info-content .details li,
.quick-info-finance .quick-info-content .details li {
  min-height: 2em;
  line-height: 2em;
  border-bottom: 1px solid #f4f4f4;
}

@media screen and (min-width: 1024px) {

  .quick-info-extended-warranty .quick-info-content .details li,
  .quick-info-finance .quick-info-content .details li {
    padding-right: 30px;
  }
}

.quick-info-extended-warranty .quick-info-content .details li.show-figures-for,
.quick-info-finance .quick-info-content .details li.show-figures-for {
  border-top: 1px solid #f4f4f4;
  border-bottom: none;
  padding-top: 6px;
}

.quick-info-extended-warranty .quick-info-content .details li.option,
.quick-info-finance .quick-info-content .details li.option {
  padding-bottom: 6px;
}

.quick-info-extended-warranty .quick-info-content .details label,
.quick-info-finance .quick-info-content .details label {
  margin-bottom: 0;
  padding-right: 2px;
}

.quick-info-extended-warranty .quick-info-content .details output,
.quick-info-finance .quick-info-content .details output {
  line-height: 2em;
  padding-top: 0;
}

.quick-info-extended-warranty .quick-info-content .details select,
.quick-info-finance .quick-info-content .details select {
  width: 120px;
  height: 2em;
  line-height: 1;
  padding: 0 25px 0 5px;
  margin-bottom: 0;
  background-color: #f4f4f4;
}

@media screen and (min-width: 1024px) {

  .quick-info-extended-warranty .quick-info-content .details select,
  .quick-info-finance .quick-info-content .details select {
    width: auto;
    min-width: 180px;
  }
}

.quick-info-extended-warranty .quick-info-content .font-size-small,
.quick-info-finance .quick-info-content .font-size-small {
  color: #83888b;
  font-size: 0.9285em !important;
  margin: 4px 0;
}

.quick-info .quick-info-content span.more-info {
  color: #1c9ace;
  font-size: 1.3rem;
  font-weight: bold;
}

.quick-info .quick-info-content span.more-info:hover {
  text-decoration: underline;
}

.asset-box {
  width: 75px;
  height: 75px;
  background: #f4f4f4;
  border: 1px solid #e3e3e3;
  border-radius: 2px;
  position: relative;
  float: left;
}

.asset-box.audio .mejs-container {
  width: 100% !important;
  height: 100% !important;
}

.asset-box.audio .mejs-container .mejs-inner {
  width: 100%;
  height: 100%;
}

.asset-box.audio .mejs-container .mejs-mediaelement {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.asset-box.audio .mejs-container .mejs-controls {
  padding: 0;
}

.asset-box.audio .mejs-container .mejs-controls .mejs-duration-container,
.asset-box.audio .mejs-container .mejs-controls .mejs-currenttime-container {
  display: none;
}

.asset-box.audio .mejs-container .mejs-controls .mejs-button {
  float: left;
}

.asset-box.audio .mejs-container .mejs-controls .mejs-button.mejs-volume-button {
  position: absolute;
  top: 15px;
  left: 0;
  width: 100%;
}

.asset-box.audio .mejs-container .mejs-controls .mejs-button.mejs-volume-button button {
  display: block;
  height: 28px;
  width: 36px;
  margin: 0 auto;
  padding: 0;
  border: none;
  background-image: url("/dist/images/svgs/speaker.svg");
  background-repeat: no-repeat;
  background-size: 29px;
  background-position: left;
}

.asset-box.audio .mejs-container .mejs-controls .mejs-button.mejs-volume-button.mejs-unmute button {
  background-image: url("/dist/images/svgs/speaker-no-audio.svg");
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: left;
}

.asset-box.audio .mejs-container .mejs-controls .mejs-button button {
  cursor: pointer;
  border: none;
  position: relative;
  min-width: 0;
  height: auto;
}

.asset-box.audio .mejs-container .mejs-controls .mejs-button button:focus {
  outline: 0;
}

.asset-box.audio .mejs-container .mejs-controls .mejs-button.mejs-playpause-button {
  position: relative;
  margin-top: 52px;
  margin-left: 4px;
  left: 0;
  float: left;
  background: none;
}

.asset-box.audio .mejs-container .mejs-controls .mejs-button.mejs-playpause-button button {
  background: none;
  margin-left: 2px;
  margin-right: 8px;
  padding: 5px;
}

.asset-box.audio .mejs-container .mejs-controls .mejs-button.mejs-playpause-button button:before,
.asset-box.audio .mejs-container .mejs-controls .mejs-button.mejs-playpause-button button:after {
  content: "";
  top: 50%;
  left: 50%;
  position: absolute;
}

.asset-box.audio .mejs-container .mejs-controls .mejs-button.mejs-playpause-button.mejs-play button:before {
  border: solid transparent;
  border-width: 5px 0 5px 6px;
  border-left-color: #63656a;
  margin-top: -5px;
  margin-left: -5px;
}

.asset-box.audio .mejs-container .mejs-controls .mejs-button.mejs-playpause-button.mejs-pause button:before,
.asset-box.audio .mejs-container .mejs-controls .mejs-button.mejs-playpause-button.mejs-pause button:after {
  border: none;
  height: 10px;
  width: 3px;
  background: #63656a;
  margin: -5px 0 0 -5px;
}

.asset-box.audio .mejs-container .mejs-controls .mejs-button.mejs-playpause-button.mejs-pause button:after {
  margin-left: 0;
}

.asset-box.audio .mejs-container .mejs-controls .mejs-clear {
  clear: both;
}

.asset-box.audio .mejs-container .mejs-controls .mejs-horizontal-volume-slider {
  display: none;
}

.asset-box.audio .mejs-container .mejs-controls .mejs-time-rail {
  position: relative;
  float: left;
  right: 8px;
  width: 30px;
  padding: 7px 0;
  margin-top: 47px;
  border-left: none;
  box-shadow: none;
}

.asset-box.audio .mejs-container .mejs-controls .mejs-time-rail span {
  position: absolute;
  width: 42px;
  height: 4px;
  left: 0;
  border-radius: 1px;
  cursor: pointer;
}

.asset-box.audio .mejs-container .mejs-controls .mejs-time-rail .mejs-time-buffering {
  width: 100%;
  background-image: linear-gradient(-45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
  background-size: 15px 15px;
  -webkit-animation: buffering-stripes 2s linear infinite;
  animation: buffering-stripes 2s linear infinite;
}

.asset-box.audio .mejs-container .mejs-controls .mejs-time-rail .mejs-time-loaded {
  background: #d5d5d5;
  width: 0;
}

.asset-box.audio .mejs-container .mejs-controls .mejs-time-rail .mejs-time-total {
  background: #ededed;
}

.asset-box.audio .mejs-container .mejs-controls .mejs-time-rail .mejs-time-current {
  width: 0;
  background: #09c;
}

.asset-box.audio .mejs-container .mejs-controls .mejs-time-rail .mejs-time-current:after {
  content: "";
  width: 9px;
  height: 9px;
  display: block;
  background-color: #fff;
  border: 1px solid #d5d5d5;
  border-radius: 50%;
  position: absolute;
  right: -4px;
  bottom: -4px;
}

.asset-box.audio .mejs-container .mejs-controls .mejs-time-rail .mejs-time-handle {
  display: none;
}

.asset-box.audio .mejs-container .mejs-controls .mejs-time-rail .mejs-time-float {
  position: absolute;
  display: none;
  background: #eee;
  width: 36px;
  height: 20px;
  border: solid 1px #333;
  top: -28px;
  margin-left: -18px;
  text-align: center;
  color: #111;
  z-index: 10;
  font-size: 11px;
  line-height: 15px;
}

.asset-box.audio .mejs-container .mejs-controls .mejs-time-rail .mejs-time-float-current {
  margin: 2px;
  width: 30px;
  display: block;
  text-align: center;
  left: 0;
  height: auto;
}

.asset-box.audio .mejs-container .mejs-controls .mejs-time-rail .mejs-time-float-current:after,
.asset-box.audio .mejs-container .mejs-controls .mejs-time-rail .mejs-time-float-current:before {
  position: absolute;
  width: 0;
  height: 0;
  border: solid transparent;
  border-width: 5px 5px 0;
  border-radius: 0;
  top: 100%;
  left: 50%;
  border-top-color: #333;
  margin-left: -5px;
  content: "";
}

.asset-box.pdf a {
  color: #63656a;
  font-size: 11px;
  width: 100%;
  height: 100%;
}

.asset-box.pdf a .asset-box-icon {
  width: 100%;
  margin-top: 6px;
  text-align: center;
  float: left;
  display: inline-block;
}

.asset-box.pdf a .asset-box-icon svg {
  height: 36px;
}

.asset-box.pdf a .asset-box-content {
  float: left;
  display: inline-block;
  width: 100%;
  padding: 0 5px;
  text-align: center;
  font-size: 11px;
}

.g4m-pdp-assets-outer {
  margin: 0 auto;
  max-width: 600px;
}

.g4m-pdp-assets-wrapper {
  width: 100%;
  height: 75px;
  position: relative;
  float: left;
  margin: 0 auto 20px;
}

.g4m-pdp-assets-wrapper .g4m-pdp-assets {
  margin: 0;
  float: left;
  display: block;
  position: relative;
  height: 75px;
  padding: 0 30px;
  opacity: 0;
}

.g4m-pdp-assets-wrapper .g4m-pdp-assets .scroller-mask {
  height: 100%;
  overflow: hidden;
  position: relative;
  float: left;
  margin: 0;
  padding: 0;
}

.g4m-pdp-assets-wrapper .g4m-pdp-assets .scroller-mask .scroller-content {
  position: absolute;
}

.g4m-pdp-assets-wrapper .g4m-pdp-assets .scroller-mask .asset-box:not(:last-of-type) {
  margin-right: 10px;
}

.g4m-pdp-assets-wrapper .g4m-pdp-assets .assets-scroller {
  cursor: pointer;
  display: flex;
  height: 75px;
  position: absolute;
  top: 0;
  width: 31px;
}

.g4m-pdp-assets-wrapper .g4m-pdp-assets .assets-scroller svg {
  display: block;
  width: 31px;
}

.g4m-pdp-assets-wrapper .g4m-pdp-assets .assets-scroller.active svg path {
  fill: #636569;
}

.g4m-pdp-assets-wrapper .g4m-pdp-assets .assets-scroller.chevron-left {
  left: 0;
}

.g4m-pdp-assets-wrapper .g4m-pdp-assets .assets-scroller.chevron-right {
  right: 0;
}

@keyframes buffering-stripes {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 30px 0;
  }
}

.purchase-info .info-row.custom-cable-info-row {
  max-width: none;
}

.purchase-info .info-row.custom-cable-info-row a.button {
  max-width: 475px;
  margin-right: 43px;
}

.purchase-info .info-row.custom-cable-info-row .info-row-delivery-msg {
  display: none;
}

.cable-builder {
  padding: 20px 0 0 0;
  position: relative;
  border-bottom: 0;
}

.cable-builder .quick-info-header {
  display: table;
  padding-right: 63px;
}

.cable-builder .quick-info-header b {
  display: table-cell;
}

.cable-builder .quick-info-header .reset-link-holder {
  display: table-cell;
  text-align: right;
  font-weight: normal;
  text-decoration: underline;
}

.cable-builder form {
  width: 100%;
}

.cable-builder ol {
  list-style: none;
  counter-reset: item;
  width: 100%;
}

.cable-builder ol li.builder-step {
  position: relative;
  counter-increment: item;
  width: 100%;
  border-bottom: 1px #f4f4f4 solid;
  min-height: 58px;
  transition: all 400ms ease-out;
}

.cable-builder ol li.builder-step .accordion-handler {
  width: 100%;
  min-height: 58px;
  display: table;
}

.cable-builder ol li.builder-step .accordion-handler .step-arrow {
  display: table-cell;
  position: absolute;
  cursor: pointer;
  width: 25px;
  height: 25px;
  right: 15px;
  top: 20px;
}

.cable-builder ol li.builder-step .accordion-handler .step-arrow .pointer {
  transform: rotate(45deg);
  border-bottom: 2px gray solid;
  transition: all 400ms ease-in;
  display: block;
  border-right: 2px gray solid;
  width: 14px;
  height: 14px;
}

.cable-builder ol li.builder-step .accordion-handler .counter {
  font-size: 13pt;
}

.cable-builder ol li.builder-step .accordion-handler .bullet {
  display: inline-block;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  padding-top: 1px;
  text-align: center;
  margin: 20px 16px 20px 20px;
  vertical-align: sub;
}

.cable-builder ol li.builder-step .accordion-handler .counter:before {
  content: counter(item);
}

.cable-builder ol li.builder-step .accordion-handler .step-header {
  transition: all 400ms ease;
  font-weight: bold;
  position: absolute;
  left: 75px;
  top: 23px;
}

.cable-builder ol li.builder-step .expander {
  width: 100%;
  margin: 15px;
  margin-left: 0;
  border-top: 1px #eeeeee solid;
  padding: 15px;
  overflow: hidden;
  max-height: 0;
  transition: max-height 400ms ease-in-out;
  transition-delay: 0ms;
}

.cable-builder ol li.builder-step .expander input#cable_quantity_confirm_btn {
  border: 0;
  background: #1c9ace;
  color: white;
  height: 40px;
  width: 120px;
  text-transform: uppercase;
}

.cable-builder ol li.builder-step:hover {
  cursor: pointer;
}

.cable-builder ol li.builder-step:hover .accordion-handler {
  background-color: #f4f4f4;
}

.cable-builder ol li.step-1 .expander,
.cable-builder ol li.step-2 .expander,
.cable-builder ol li.step-3 .expander {
  padding: 3px;
}

.cable-builder ol li.choose .expander,
.cable-builder ol li.chosen .expander {
  padding: 0;
  margin: 0;
  border: 0;
}

.cable-builder ol li.choose .open,
.cable-builder ol li.chosen .open {
  display: none;
}

.cable-builder ol li.choose.selected .accordion-handler,
.cable-builder ol li.chosen.selected .accordion-handler {
  background-color: #f4f4f4;
}

.cable-builder ol li.choose.selected .step-arrow .pointer,
.cable-builder ol li.chosen.selected .step-arrow .pointer {
  transform: rotate(225deg);
}

.cable-builder ol li.choose.selected .expander,
.cable-builder ol li.chosen.selected .expander {
  max-height: 260px;
  transition-delay: 400ms;
}

.cable-builder ol li.choose.selected .open,
.cable-builder ol li.chosen.selected .open {
  display: block;
}

.cable-builder ol li.selected.builder-step {
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.16);
  cursor: default;
}

.cable-builder ol li .info {
  display: none;
}

.cable-builder ol li.chosen .accordion-handler .info {
  display: table-cell;
  vertical-align: middle;
  padding-top: 18px;
  padding-right: 50px;
  width: 100%;
}

.cable-builder ol li.chosen .accordion-handler .bullet {
  border-color: #2d9cdb;
}

.cable-builder ol li.chosen .accordion-handler .bullet .counter:before {
  content: "";
}

.cable-builder ol li.chosen .accordion-handler .bullet .counter {
  display: block;
  transform: rotate(-45deg);
  border: 0;
  border-bottom: 2px #168dbf solid;
  border-left: 2px #168dbf solid;
  width: 18px;
  height: 7px;
  margin-top: 7px;
  margin-left: 6px;
}

.cable-builder ol li.chosen .accordion-handler .step-header {
  font-size: 13px;
  color: #666666;
  top: 3px;
}

.cable-builder ul.cc-infolist {
  overflow-y: auto;
  list-style: none;
  padding: 0;
  margin: 3px 3px 3px 3px;
  max-height: 253px;
}

.cable-builder ul.cc-infolist li {
  padding-left: 48px;
  cursor: pointer;
}

.cable-builder ul.cc-infolist li:hover {
  background: #b8dfee;
}

.cable-builder ul.cc-infolist li.connector-selected {
  background: #76c1e0;
  color: white;
}

.cable-builder ul.cc-infolist li.connector-selected .cc-inforow-price {
  color: #76c1e0;
}

.cable-builder .cc-inforow {
  padding: 10px;
  display: inline-table;
  width: 100%;
}

.cable-builder .cc-inforow .cc-inforow-img {
  vertical-align: middle;
  display: table-cell;
  width: 43px;
}

.cable-builder .cc-inforow .cc-inforow-img .img-holder {
  overflow: hidden;
  border-radius: 3px;
  border: 1px #828282 solid;
  width: 36px;
  height: 36px;
  display: block;
}

.cable-builder .cc-inforow .cc-inforow-img .img-holder img {
  max-width: 36px;
  max-height: 36px;
}

.cable-builder .cc-inforow .cc-inforow-item {
  vertical-align: middle;
  max-width: 227px;
  display: table-cell;
  height: 22px;
  font-weight: normal;
}

.cable-builder .cc-inforow .cc-inforow-price {
  font-weight: 800;
  font-size: 18px;
  text-align: right;
  width: 100px;
  padding-right: 50px;
  display: table-cell;
  vertical-align: middle;
}

.cable-builder .cc-inforow .single {
  display: none;
}

.cable-builder .cc-inforow .multiple {
  display: inline;
}

.cable-builder .cc-inforow-item.has-one .single {
  display: inline;
}

.cable-builder .cc-inforow-item.has-one .multiple {
  display: none;
}

.cable-builder ol li.step-4 #unit-price-display {
  display: block;
  position: absolute;
  width: 100%;
}

.cable-builder ol li.step-4 #unit-price-display.unit-price-hide {
  display: none;
}

.cable-builder ol li.step-4 .expander .curtain {
  text-align: right;
}

.cable-builder ol li.selected.step-4 #quantity_selector.open .quantity-list {
  display: block;
}

.cable-builder #cable_bundle_summary {
  display: none;
  width: 100%;
}

.cable-builder #cable_bundle_summary b {
  width: 70%;
  font-size: 18pt;
  padding-left: 20px;
}

.cable-builder #cable_bundle_summary .final-price-holder {
  width: 35%;
  text-align: right;
  color: #ca0000;
  font-weight: bold;
  font-size: 20pt;
  margin-right: 62px;
  display: inline-block;
}

.cable-builder #cable_bundle_summary.open {
  display: inline-flex;
  margin-top: 12px;
}

.custom-cables-icon {
  transform: rotate(-45deg);
  padding: 0;
  margin: 0;
  width: 40px;
  display: inline-flex;
}

.custom-cables-icon .plastic,
.custom-cables-icon .copper {
  padding: 0;
  margin: 0;
}

.custom-cables-icon .plastic {
  border: 1px #168dbf solid;
  border-left: none;
  width: 65%;
  height: 7px;
}

.custom-cables-icon .copper {
  background-color: #168dbf;
  width: 35%;
  height: 3px;
  display: inline-block;
  margin-top: 2px;
}

a.add-to-basket-button[disabled="true"] {
  cursor: pointer;
  pointer-events: none;
  background-color: #f8dec0;
  color: white;
  border: 0;
}

.dropdownv2 {
  list-style: none;
  overflow-y: auto;
  max-height: 260px;
  padding: 0;
  margin: 3px;
}

.dropdownv2 li {
  cursor: pointer;
  min-height: 45px;
}

.dropdownv2 li .price {
  display: -ms-inline-grid;
  display: inline-grid;
  width: 70%;
  text-align: right;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #434649;
  padding-right: 60px;
}

.dropdownv2 li:hover {
  background-color: #b8dfee;
}

.dropdownv2 li.selected {
  background-color: #76c1e0;
}

.dropdownv2 li.selected span {
  color: white;
  font-weight: bold;
}

.dropdownv2 li.selected .price {
  color: #76c1e0;
}

.dropdownv2 li.selected:hover {
  background-color: #76c1e0;
}

#custom-cables-quantity-selector .quantity-amount {
  display: inline-flex;
  width: 30%;
  padding: 12px 0 13px 71px;
}

#custom-cables-length-selector .length-amount {
  display: inline-flex;
  width: 30%;
  padding: 12px 0 13px 71px;
}

.cables-desktop-vertical-adjustment {
  display: flex;
  flex-direction: column;
}

.cables-desktop-vertical-adjustment .cable-builder {
  order: -1;
}

.cables-desktop-vertical-adjustment .cable-builder .builder-steps {
  padding: 0;
}

.g4m-divider,
.g4m-divider--line {
  text-align: center;
  font-weight: 700;
  font-size: 1.8rem;
  margin: 3px 0;
}

.g4m-divider span,
.g4m-divider--line span {
  margin: 0 auto;
  display: inline-block;
}

.g4m-divider--line {
  overflow: hidden;
  display: flex;
  font-size: 1.4rem;
}

.g4m-divider--line span {
  position: relative;
}

.g4m-divider--line span:before,
.g4m-divider--line span:after {
  content: "";
  display: block;
  width: 100vw;
  height: 1px;
  background-color: #babbbc;
  position: absolute;
  top: 50%;
}

.g4m-divider--line span:before {
  right: calc(100% + 1em);
}

.g4m-divider--line span:after {
  left: calc(100% + 1em);
}

.g4m-expandable-option {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
}

.g4m-expandable-option h4 {
  font-size: inherit;
  margin-bottom: 5px;
}

.g4m-expandable-option .option-content p {
  height: 1.5em;
  overflow: hidden;
  position: relative;
  margin: 0 10px 5px 0;
}

.g4m-expandable-option .option-content p::after {
  content: "...";
  position: absolute;
  width: 3em;
  height: 1.5em;
  top: 0;
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 50%);
  text-align: right;
  padding-right: 5px;
}

.g4m-expandable-option .option-content input:checked~p {
  height: auto;
}

.g4m-expandable-option .option-content input:checked~p::after {
  display: none;
}

.g4m-expandable-option input {
  display: none;
}

.g4m-expandable-option input~label .readmore,
.g4m-expandable-option input~label .readless {
  color: #09c;
  cursor: pointer;
  padding: 10px;
  position: relative;
  left: -10px;
}

.g4m-expandable-option input~label .readmore:hover,
.g4m-expandable-option input~label .readless:hover {
  text-decoration: underline;
}

.g4m-expandable-option input~label .readless {
  display: none;
}

.g4m-expandable-option input:checked~label .readmore {
  display: none;
}

.g4m-expandable-option input:checked~label .readless {
  display: inherit;
}

.cancellations-page {
  max-width: 920px;
  margin: 0 auto;
  font-size: 1.4rem;
  color: #434649;
}

.cancellations-page .h1-title {
  padding: 7px 0 6px;
}

.cancellations-page .h1-title h2 {
  font-size: 2.4rem;
}

.cancellations-input {
  border-color: #d5d5d5;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  font-size: 16px;
  padding: 11px;
  transition: box-shadow 0.2s;
}

.cancellations-input::-webkit-input-placeholder {
  color: #babbbc;
}

.cancellations-input::-moz-placeholder {
  color: #babbbc;
}

.cancellations-input:-ms-input-placeholder {
  color: #babbbc;
}

.cancellations-input::-ms-input-placeholder {
  color: #babbbc;
}

.cancellations-input::placeholder {
  color: #babbbc;
}

.cancellations-input-label {
  font-weight: bold !important;
}

.cancellation-button-wrap {
  padding: 10px 0 10px 0;
  overflow: auto;
  text-align: center;
  height: auto;
}

.cancellation-button {
  font-size: 18px;
}

.cancellation-error-help {
  margin-top: 40px;
}

.cancellations-title {
  font-size: 20px;
  line-height: 1.2;
}

.cancellation-success-info {
  margin-top: 20px;
}

.info-icon {
  float: left;
  background-image: url(/dist/images/svgs/icon-lightbulb-blue-muted.svg);
  background-repeat: no-repeat;
  width: 70px;
  background-position-x: 20px;
  height: 200px;
}

.cancel-header {
  width: 100%;
  margin-bottom: 10px;
  background-color: #ffffff;
}

.cancellations-title {
  font-weight: bold;
  text-align: left;
  flex: 2 1 auto;
  padding-right: 10px;
  display: block;
  font-size: 20px;
}

.cancellations-result-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.cancellations-error-icon {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='70' height='70' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='35' cy='35' r='35' fill='%23D00000'%3E%3C/circle%3E%3Cg clip-path='url(%23returns-icon-error_svg__clip0)'%3E%3Cpath d='M29.853 14.412h10.294V39.85H29.853V14.41zm0 30.97h10.294v10.206H29.853V45.381z' fill='%23fff'%3E%3C/path%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='returns-icon-error_svg__clip0'%3E%3Cpath fill='%23fff' transform='translate(14.412 14.412)' d='M0 0h41.176v41.176H0z'%3E%3C/path%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  width: 100px;
  height: 120px;
  background-repeat: no-repeat;
  background-position-y: 25px;
}

.cancellations-success-icon {
  background-image: url(/dist/images/svgs/returns-icon-success.svg);
  width: 100px;
  height: 120px;
  background-repeat: no-repeat;
  background-position-y: 25px;
}

.category-box {
  position: relative;
  z-index: 1;
  font-size: 1.4rem;
  line-height: 1.25;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-clip: padding-box;
  border-radius: 3px;
  line-height: 1.358;
}

.category-box .text-block {
  width: 100%;
  height: 100%;
  padding: 7% 3%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  line-height: 34px;
}

.category-box .text-block a {
  color: #434649;
  position: relative;
  z-index: 10;
}

.category-box .category-detailed a {
  line-height: 2;
}

.category-box .category-box-title {
  margin: 0 0 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 2.4rem;
  line-height: 1.1;
}

.category-box .category-detailed {
  padding: 0;
  margin: 0;
  list-style: none;
}

.category-box .link-mask {
  overflow: hidden;
  text-indent: -9999px;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.category-box {
  font-size: 1.4rem;
}

.category-box .text-block {
  padding: 21px 10px 5px;
}

@media screen and (min-width: 1336px) {
  .category-box .text-block {
    padding: 16px 22px 5px;
  }

  .category-box .category-box-title {
    font-size: 3rem;
    line-height: 1;
  }
}

.category-links-group a {
  color: #45494b;
}

.category-box-accordion {
  display: flex;
  flex-wrap: wrap;
}

.category-box-accordion a {
  cursor: pointer;
  color: inherit;
}

@media screen and (max-width: 299px) {
  .category-box-accordion__category-box {
    width: calc(99.99% - 0px);
    margin-bottom: 20px;
  }

  .category-box-accordion__category-box:not(:nth-child(1n)) {
    margin-right: 10px;
  }

  .category-box-accordion__category-box:nth-last-child(-n + 1):nth-child(1n + 1),
  .category-box-accordion__category-box:nth-last-child(-n + 1):nth-child(1n + 1)~* {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 300px) and (max-width: 449px) {
  .category-box-accordion__category-box {
    width: calc(49.99% - 5px);
    margin-bottom: 20px;
  }

  .category-box-accordion__category-box:not(:nth-child(2n)) {
    margin-right: 10px;
  }

  .category-box-accordion__category-box:nth-last-child(-n + 2):nth-child(2n + 1),
  .category-box-accordion__category-box:nth-last-child(-n + 2):nth-child(2n + 1)~* {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 450px) and (max-width: 649px) {
  .category-box-accordion__category-box {
    width: calc(33.32333% - 6.66667px);
    margin-bottom: 20px;
  }

  .category-box-accordion__category-box:not(:nth-child(3n)) {
    margin-right: 10px;
  }

  .category-box-accordion__category-box:nth-last-child(-n + 3):nth-child(3n + 1),
  .category-box-accordion__category-box:nth-last-child(-n + 3):nth-child(3n + 1)~* {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 650px) {
  .category-box-accordion__category-box {
    width: calc(24.99% - 7.5px);
    margin-bottom: 20px;
  }

  .category-box-accordion__category-box:not(:nth-child(4n)) {
    margin-right: 10px;
  }

  .category-box-accordion__category-box:nth-last-child(-n + 4):nth-child(4n + 1),
  .category-box-accordion__category-box:nth-last-child(-n + 4):nth-child(4n + 1)~* {
    margin-bottom: 0;
  }
}

.category-box-accordion__link {
  display: block;
  margin-bottom: 5px;
}

.category-box-accordion__link:last-child {
  margin-bottom: 0;
}

.product-card-legacy {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  font-size: 1.4rem;
  line-height: 1.3;
  background-color: #fff;
  color: inherit;
  border: 1px solid #d5d5d5;
  border-radius: 3px;
  transition: border 150ms ease-in-out;
}

.product-card-legacy:hover {
  border: 1px solid #fa8600;
}

.product-card-legacy:hover {
  text-decoration: none;
}

.product-card-legacy .title,
.product-card-legacy .description,
.product-card-legacy .image-wrapper,
.product-card-legacy .availability {
  flex: 1 1 auto;
}

.product-card-legacy .promo-sash {
  display: block;
  width: 55px;
  height: 55px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.product-card-legacy .title,
.product-card-legacy .description {
  text-align: center;
  padding: 0 20px;
}

.product-card-legacy .title {
  order: 2;
  flex-shrink: 0;
  margin: 0;
  overflow: hidden;
  height: 25px;
  font-size: 1.4rem;
}

.product-card-legacy .description {
  order: 3;
  min-height: 18px;
  overflow: hidden;
  font-size: 1.4rem;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.product-card-legacy .image-wrapper {
  order: 1;
  flex: 0 0 auto;
  padding: 15px 0;
}

.product-card-legacy .image {
  display: block;
  margin: 0 auto;
  height: 100%;
}

.product-card-legacy .image {
  transition: transform 150ms ease-in-out;
  transform: scale(1);
}

.product-card-legacy:hover .image {
  transform: scale(1.05);
}

.product-card-legacy .availability {
  order: 4;
  display: flex;
  justify-content: stretch;
  align-items: flex-end;
  min-height: 30px;
  margin: 5px 0 15px;
}

.product-card-legacy .availability .scaled-price-label {
  position: absolute;
  height: 38px;
  color: darkred;
  font-weight: bold;
  margin-left: 16px;
}

.product-card-legacy .price,
.product-card-legacy .stock-message {
  line-height: 1;
  flex: 1 1 auto;
}

.product-card-legacy .price {
  flex: none;
  position: relative;
  bottom: -1px;
  font-weight: bold;
  text-align: right;
  color: #d00000;
  font-size: 2.5rem;
  padding-left: 15px;
  padding-right: 20px;
}

.product-card-legacy .stock-message {
  font-weight: bold;
  line-height: 1.2;
  padding-right: 20px;
  font-size: 1.4rem;
  text-align: right;
}

.product-card-legacy .stock-message.in-stock {
  color: #249c54;
}

.product-card-legacy .stock-message.not-available.cannot-order {
  font-size: 1.1rem;
}

.product-card-legacy .stock-message.not-available {
  font-weight: normal;
  color: #888888;
  text-align: center;
  font-size: 0.96rem;
  padding-right: 20px;
}

.product-card-legacy .price.not-available {
  display: none;
}

.plp-page {
  line-height: 1.5;
}

.h1-title h1 {
  font-size: 3rem;
}

.header-title-PLP {
  border-top: none;
}

.header-copy-PLP {
  line-height: 1.5;
  font-size: 1.4rem;
  border-bottom: none;
}

.listing-centred {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.listing-centred .listing-item {
  display: inline-block;
  margin: 0 5px 10px;
}

a.text-link {
  display: block;
  font-size: 13.5px;
  padding: 0.4em 1.6em 0.3em;
  border-radius: 20px;
  background-color: #e5e5e5;
  color: #434649;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);
  transition: background-color 0.3s;
}

a.text-link:hover,
a.text-link:focus {
  text-decoration: none;
  outline: none;
  background-color: #dbdbdb;
}

.rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: #e9e9e9;
  height: 4px;
  border-radius: 6px;
}

.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: #abe2fb;
}

.rc-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: -webkit-grab;
  margin-top: -5px;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px #96dbfa;
  background-color: #fff;
  touch-action: pan-x;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #57c5f7;
  box-shadow: 0 0 0 5px #96dbfa;
}

.rc-slider-handle:focus {
  outline: none;
}

.rc-slider-handle-click-focused:focus {
  border-color: #96dbfa;
  box-shadow: unset;
}

.rc-slider-handle:hover {
  border-color: #57c5f7;
}

.rc-slider-handle:active {
  border-color: #57c5f7;
  box-shadow: 0 0 5px #57c5f7;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px;
}

.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
}

.rc-slider-mark-text-active {
  color: #666;
}

.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}

.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}

.rc-slider-dot-active {
  border-color: #96dbfa;
}

.rc-slider-dot-reverse {
  margin-right: -4px;
}

.rc-slider-disabled {
  background-color: #e9e9e9;
}

.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}

.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  border-color: #ccc;
  box-shadow: none;
  background-color: #fff;
  cursor: not-allowed;
}

.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important;
}

.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;
}

.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px;
}

.rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px;
}

.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  touch-action: pan-y;
}

.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%;
}

.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px;
}

.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px;
}

.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px;
}

.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px;
}

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  -webkit-animation-name: rcSliderTooltipZoomDownIn;
  animation-name: rcSliderTooltipZoomDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  -webkit-animation-name: rcSliderTooltipZoomDownOut;
  animation-name: rcSliderTooltipZoomDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  transform: scale(0, 0);
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

@-webkit-keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }

  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
}

@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }

  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
}

@-webkit-keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }

  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
}

@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }

  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
}

.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-tooltip-hidden {
  display: none;
}

.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0;
}

.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}

.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c;
}

.react-g4m-button,
.react-g4m-button--block,
.react-g4m-button--cta-primary,
.react-g4m-button--cta-secondary,
.react-g4m-button--cta-tertiary,
.react-g4m-button--cta-expand,
.react-g4m-button--cta-flat,
.react-g4m-button--cta-chevron-right,
.react-g4m-button--cta-chevron-up,
.react-g4m-button--cta-chevron-down,
.react-g4m-button--show-more-products-btn,
.react-g4m-button--cta-loading {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 22px;
  text-align: center;
  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
  min-height: 40px;
  min-width: 70px;
  padding: 9px 15px;
  border: 0;
  border-radius: 3px;
  margin: 0;
  transition: none;
  outline: none;
  cursor: pointer;
}

.react-g4m-button:hover,
.react-g4m-button--block:hover,
.react-g4m-button--cta-primary:hover,
.react-g4m-button--cta-secondary:hover,
.react-g4m-button--cta-tertiary:hover,
.react-g4m-button--cta-expand:hover,
.react-g4m-button--cta-flat:hover,
.react-g4m-button--cta-chevron-right:hover,
.react-g4m-button--cta-chevron-up:hover,
.react-g4m-button--cta-chevron-down:hover,
.react-g4m-button--show-more-products-btn:hover,
.react-g4m-button--cta-loading:hover {
  text-decoration: none;
}

.react-g4m-button:disabled,
.react-g4m-button--block:disabled,
.react-g4m-button--cta-primary:disabled,
.react-g4m-button--cta-secondary:disabled,
.react-g4m-button--cta-tertiary:disabled,
.react-g4m-button--cta-expand:disabled,
.react-g4m-button--cta-flat:disabled,
.react-g4m-button--cta-chevron-right:disabled,
.react-g4m-button--cta-chevron-up:disabled,
.react-g4m-button--cta-chevron-down:disabled,
.react-g4m-button--show-more-products-btn:disabled,
.react-g4m-button--cta-loading:disabled,
.react-g4m-button.react-g4m-button--disabled,
.react-g4m-button--disabled.react-g4m-button--block,
.react-g4m-button--disabled.react-g4m-button--cta-primary,
.react-g4m-button--disabled.react-g4m-button--cta-secondary,
.react-g4m-button--disabled.react-g4m-button--cta-tertiary,
.react-g4m-button--disabled.react-g4m-button--cta-expand,
.react-g4m-button--disabled.react-g4m-button--cta-flat,
.react-g4m-button--disabled.react-g4m-button--cta-chevron-right,
.react-g4m-button--disabled.react-g4m-button--cta-chevron-up,
.react-g4m-button--disabled.react-g4m-button--cta-chevron-down,
.react-g4m-button--disabled.react-g4m-button--show-more-products-btn,
.react-g4m-button--disabled.react-g4m-button--cta-loading {
  opacity: 0.65;
  cursor: default;
}

.react-g4m-button--block {
  display: block;
  width: 100%;
}

.react-g4m-button--cta-primary {
  color: #fff;
  background-color: #fa8600;
  text-transform: uppercase;
}

.react-g4m-button--cta-primary:hover {
  background-color: #d17000;
}

.react-g4m-button--cta-primary:disabled:hover,
.react-g4m-button--cta-primary.react-g4m-button--disabled:hover {
  background-color: #fa8600;
}

.react-g4m-button--cta-secondary {
  color: #fff;
  background-color: #168dbf;
}

.react-g4m-button--cta-secondary:hover {
  background-color: #12729a;
}

.react-g4m-button--cta-secondary:disabled:hover,
.react-g4m-button--cta-secondary.react-g4m-button--disabled:hover {
  background-color: #168dbf;
}

.react-g4m-button--cta-tertiary {
  color: #333;
  background-color: #ededed;
}

.react-g4m-button--cta-tertiary:hover {
  background-color: #d9d9d9;
}

.react-g4m-button--cta-tertiary:disabled:hover,
.react-g4m-button--cta-tertiary.react-g4m-button--disabled:hover {
  background-color: #ededed;
}

.react-g4m-button--cta-expand {
  display: block;
  min-height: auto;
  margin: 0 auto;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: normal;
  font-size: 1.4rem;
  color: #83888b;
  background-color: rgba(0, 0, 0, 0);
}

.react-g4m-button--cta-expand:hover {
  background-color: rgba(0, 0, 0, 0);
}

.react-g4m-button--cta-expand:disabled:hover,
.react-g4m-button--cta-expand.react-g4m-button--disabled:hover {
  background-color: rgba(0, 0, 0, 0);
}

.react-g4m-button--cta-flat {
  color: #168dbf;
  background-color: transparent;
  transition: background-color 50ms ease-out;
}

.react-g4m-button--cta-flat:hover {
  color: #168dbf;
  background-color: rgba(22, 141, 191, 0.15);
}

.react-g4m-button--cta-chevron-right {
  position: relative;
  padding-right: 35px;
}

.react-g4m-button--cta-chevron-right::after {
  content: "";
  position: absolute;
  right: 15px;
  top: 50%;
  display: block;
  width: 8px;
  height: 8px;
  border-top: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: translateY(-50%) rotateZ(45deg);
  pointer-events: none;
}

.react-g4m-button--cta-chevron-up {
  position: relative;
  padding-right: 35px;
}

.react-g4m-button--cta-chevron-up::after {
  content: "";
  position: absolute;
  right: 15px;
  top: 50%;
  display: block;
  width: 8px;
  height: 8px;
  border-top: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: translateY(-30%) rotateZ(-45deg);
  pointer-events: none;
}

.react-g4m-button--cta-chevron-up[class*="cta-expand"] {
  padding-right: 30px;
}

.react-g4m-button--cta-chevron-down {
  position: relative;
  padding-right: 35px;
}

.react-g4m-button--cta-chevron-down::after {
  content: "";
  position: absolute;
  right: 15px;
  top: 50%;
  display: block;
  width: 8px;
  height: 8px;
  border-top: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: translateY(-70%) rotateZ(135deg);
  pointer-events: none;
}

.react-g4m-button--cta-chevron-down[class*="cta-expand"] {
  padding-right: 30px;
}

.react-g4m-button--show-more-products-btn {
  display: block;
  margin: 30px auto;
}

@-webkit-keyframes rotateIcon {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
}

@keyframes rotateIcon {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
}

.react-g4m-button--cta-loading__loading-icon {
  display: inline-block;
  width: 22px;
  height: 22px;
  margin: 0 0.5em -6px 0;
  background: url(/dist/images/svgs/icon-loader-white.svg) center/22px no-repeat;
  -webkit-animation: rotateIcon 2s linear infinite;
  animation: rotateIcon 2s linear infinite;
}

.g4m-button--open {
  display: block;
  position: relative;
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
  background-color: #e5e5e5;
  color: #333;
  border: 1px solid #ddd;
}

.g4m-button--open,
.g4m-button--open select {
  border-radius: 3px;
  width: 100%;
  height: 40px;
}

.g4m-button--open {
  outline: none;
}

.g4m-button--open:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 16px;
  top: 50%;
  box-shadow: inset -2px 2px 0 #636569;
  pointer-events: none;
  transform: translateY(-50%) rotateZ(45deg);
}

.g4m-button--open.filter--applied {
  background-color: #1c9ace;
  color: #fff;
}

.g4m-button--open.filter--applied:after {
  box-shadow: inset -2px 2px 0 white;
}

.g4m-button--apply-filters {
  display: block;
  position: relative;
  white-space: nowrap;
  font-size: 16px;
  background-color: #168dbf;
  color: #fff;
}

.g4m-button--apply-filters,
.g4m-button--apply-filters select {
  border-radius: 3px;
  width: 100%;
  height: 40px;
}

.g4m-button--apply-filters {
  outline: none;
}

.g4m-button--primary {
  display: block;
  position: relative;
  white-space: nowrap;
  border: none;
  font-size: 16px;
  background-color: #fa8600;
  color: #fff;
}

.g4m-button--primary,
.g4m-button--primary select {
  border-radius: 3px;
  width: 100%;
  height: 40px;
}

.g4m-button--primary {
  outline: none;
}

.g4m-button--secondary {
  display: block;
  position: relative;
  white-space: nowrap;
  border: none;
  font-size: 16px;
  background-color: #168dbf;
  color: #fff;
}

.g4m-button--secondary,
.g4m-button--secondary select {
  border-radius: 3px;
  width: 100%;
  height: 40px;
}

.g4m-button--secondary {
  outline: none;
}

.g4m-button--flat,
.g4m-button--flat-icon {
  display: block;
  position: relative;
  white-space: nowrap;
  border: none;
  background-color: transparent;
  color: #83888b;
  height: 100%;
  padding: 0 14px;
  text-align: right;
}

.g4m-button--flat,
.g4m-button--flat-icon,
.g4m-button--flat select,
.g4m-button--flat-icon select {
  border-radius: 3px;
  width: 100%;
  height: 40px;
}

.g4m-button--flat,
.g4m-button--flat-icon {
  outline: none;
}

.g4m-button--flat-icon {
  border: none;
  font-size: 0;
  text-indent: -9999px;
}

.g4m-button--flat-icon .g4m-svg {
  display: block;
}

.g4m-button--outlined {
  background-color: transparent;
  color: #63656a;
  padding: 8px 10px;
  border-radius: 20px;
  border: 1px solid #63656a;
}

.g4m-button--outlined:hover {
  background-color: #63656a;
  color: #fff;
}

.r-g4m-accordion {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  margin-bottom: 10px;
}

.r-g4m-accordion:last-child {
  margin-bottom: 0;
}

.r-g4m-accordion__heading {
  position: relative;
  padding: 0;
  border: none;
  clear: both;
}

.r-g4m-accordion__heading a[role="button"] {
  display: inline-block;
  width: 100%;
  padding: 10px 35px 10px 35px;
  text-decoration: none;
  color: #45494b;
  font-size: 1.5rem;
  text-align: center;
}

.r-g4m-accordion__heading a[role="button"]:after {
  content: "";
  display: block;
  height: 10px;
  width: 10px;
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -8px;
  transform: rotate(135deg);
  border-top: 2px solid #babbbc;
  border-right: 2px solid #babbbc;
  pointer-events: none;
  transition: all 0.4s ease;
}

.r-g4m-accordion__heading a[role="button"][aria-expanded="true"]:after {
  margin-top: -4px;
  transform: rotate(-45deg);
}

.r-g4m-accordion__title {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit;
}

.r-g4m-accordion__body {
  padding: 15px;
}

.r-g4m-accordion__body h3:first-child {
  margin-top: 0;
}

.r-g4m-accordion__body p {
  margin-bottom: 1em;
}

.r-g4m-accordion__body p:last-child {
  margin-bottom: 0;
}

.r-g4m-info-box,
.r-g4m-info-box--default,
.r-g4m-info-box--help,
.r-g4m-info-box--warning,
.r-g4m-info-box--help-minimal,
.r-g4m-info-box--warning-minimal {
  position: relative;
  border-radius: 3px;
  background-repeat: no-repeat;
}

.r-g4m-info-box--default {
  padding: 20px;
  margin-bottom: 20px;
  color: #073f57;
  background-color: #edfaff;
}

.r-g4m-info-box--default a {
  color: #073f57;
}

.r-g4m-info-box--help {
  min-height: 60px;
  padding: 20px 0;
  margin-bottom: 10px;
  background-position: 9px 9px;
  background-size: 32px;
  color: #073f57;
  background-color: #edfaff;
  background-image: url(/dist/images/svgs/icon-lightbulb-blue-muted.svg);
}

.r-g4m-info-box--help .r-g4m-info-box__content {
  padding: 0 20px 0 50px;
}

.r-g4m-info-box--help .r-g4m-info-box__content:last-child {
  margin-bottom: 0;
}

.r-g4m-info-box--help+.react-g4m-button--block {
  margin-top: 20px;
}

.r-g4m-info-box--help a {
  color: #073f57;
}

.r-g4m-info-box--warning {
  min-height: 60px;
  padding: 20px 0;
  margin-bottom: 10px;
  background-position: 9px 9px;
  background-size: 32px;
  color: #713807;
  background-color: #fdecdd;
  background-image: url(/dist/images/svgs/icon-warning-orange-muted.svg);
}

.r-g4m-info-box--warning .r-g4m-info-box__content {
  padding: 0 20px 0 50px;
}

.r-g4m-info-box--warning .r-g4m-info-box__content:last-child {
  margin-bottom: 0;
}

.r-g4m-info-box--warning+.react-g4m-button--block {
  margin-top: 20px;
}

.r-g4m-info-box--warning a {
  color: #713807;
}

.r-g4m-info-box--help-minimal {
  min-height: 30px;
  margin: 0 0 10px;
  background-position: 0 0;
  background-size: 20px;
  background-image: url(/dist/images/svgs/icon-lightbulb-blue-muted.svg);
}

.r-g4m-info-box--help-minimal .r-g4m-info-box__content {
  padding: 0 0 0 30px;
}

.r-g4m-info-box--help-minimal .r-g4m-info-box__content:last-child {
  margin-bottom: 0;
}

.r-g4m-info-box--warning-minimal {
  min-height: 30px;
  margin: 0 0 10px;
  background-position: 0 0;
  background-size: 20px;
  background-image: url(/dist/images/svgs/icon-warning-orange-muted.svg);
}

.r-g4m-info-box--warning-minimal .r-g4m-info-box__content {
  padding: 0 0 0 30px;
}

.r-g4m-info-box--warning-minimal .r-g4m-info-box__content:last-child {
  margin-bottom: 0;
}

.r-g4m-info-box.expanded,
.expanded.r-g4m-info-box--default,
.expanded.r-g4m-info-box--help,
.expanded.r-g4m-info-box--warning,
.expanded.r-g4m-info-box--help-minimal,
.expanded.r-g4m-info-box--warning-minimal,
.r-g4m-info-box.collapsed,
.collapsed.r-g4m-info-box--default,
.collapsed.r-g4m-info-box--help,
.collapsed.r-g4m-info-box--warning,
.collapsed.r-g4m-info-box--help-minimal,
.collapsed.r-g4m-info-box--warning-minimal {
  transition: all 0.3s ease;
  position: absolute;
}

.r-g4m-info-box.expanded .react-g4m-button--cta-flat,
.expanded.r-g4m-info-box--default .react-g4m-button--cta-flat,
.expanded.r-g4m-info-box--help .react-g4m-button--cta-flat,
.expanded.r-g4m-info-box--warning .react-g4m-button--cta-flat,
.expanded.r-g4m-info-box--help-minimal .react-g4m-button--cta-flat,
.expanded.r-g4m-info-box--warning-minimal .react-g4m-button--cta-flat,
.r-g4m-info-box.collapsed .react-g4m-button--cta-flat,
.collapsed.r-g4m-info-box--default .react-g4m-button--cta-flat,
.collapsed.r-g4m-info-box--help .react-g4m-button--cta-flat,
.collapsed.r-g4m-info-box--warning .react-g4m-button--cta-flat,
.collapsed.r-g4m-info-box--help-minimal .react-g4m-button--cta-flat,
.collapsed.r-g4m-info-box--warning-minimal .react-g4m-button--cta-flat {
  display: block;
  margin: 10px auto 0;
  padding: 4px 15px;
  min-height: 30px;
}

.r-g4m-info-box.collapsed,
.collapsed.r-g4m-info-box--default,
.collapsed.r-g4m-info-box--help,
.collapsed.r-g4m-info-box--warning,
.collapsed.r-g4m-info-box--help-minimal,
.collapsed.r-g4m-info-box--warning-minimal {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}

.r-g4m-info-box.expanded,
.expanded.r-g4m-info-box--default,
.expanded.r-g4m-info-box--help,
.expanded.r-g4m-info-box--warning,
.expanded.r-g4m-info-box--help-minimal,
.expanded.r-g4m-info-box--warning-minimal {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.r-g4m-info-box .r-g4m-info-box__content h4,
.r-g4m-info-box--default .r-g4m-info-box__content h4,
.r-g4m-info-box--help .r-g4m-info-box__content h4,
.r-g4m-info-box--warning .r-g4m-info-box__content h4,
.r-g4m-info-box--help-minimal .r-g4m-info-box__content h4,
.r-g4m-info-box--warning-minimal .r-g4m-info-box__content h4 {
  margin-top: 1.4em;
  margin-bottom: 0.5em;
}

.r-g4m-info-box .r-g4m-info-box__content>*,
.r-g4m-info-box--default .r-g4m-info-box__content>*,
.r-g4m-info-box--help .r-g4m-info-box__content>*,
.r-g4m-info-box--warning .r-g4m-info-box__content>*,
.r-g4m-info-box--help-minimal .r-g4m-info-box__content>*,
.r-g4m-info-box--warning-minimal .r-g4m-info-box__content>* {
  line-height: 1.2;
  margin: 0.7em 0;
}

.r-g4m-info-box .r-g4m-info-box__content>*:first-child,
.r-g4m-info-box--default .r-g4m-info-box__content>*:first-child,
.r-g4m-info-box--help .r-g4m-info-box__content>*:first-child,
.r-g4m-info-box--warning .r-g4m-info-box__content>*:first-child,
.r-g4m-info-box--help-minimal .r-g4m-info-box__content>*:first-child,
.r-g4m-info-box--warning-minimal .r-g4m-info-box__content>*:first-child {
  margin-top: 0;
}

.r-g4m-info-box .r-g4m-info-box__content>*:last-child,
.r-g4m-info-box--default .r-g4m-info-box__content>*:last-child,
.r-g4m-info-box--help .r-g4m-info-box__content>*:last-child,
.r-g4m-info-box--warning .r-g4m-info-box__content>*:last-child,
.r-g4m-info-box--help-minimal .r-g4m-info-box__content>*:last-child,
.r-g4m-info-box--warning-minimal .r-g4m-info-box__content>*:last-child {
  margin-bottom: 0;
}

.r-g4m-info-box .r-g4m-info-box__content ul,
.r-g4m-info-box--default .r-g4m-info-box__content ul,
.r-g4m-info-box--help .r-g4m-info-box__content ul,
.r-g4m-info-box--warning .r-g4m-info-box__content ul,
.r-g4m-info-box--help-minimal .r-g4m-info-box__content ul,
.r-g4m-info-box--warning-minimal .r-g4m-info-box__content ul,
.r-g4m-info-box .r-g4m-info-box__content ol,
.r-g4m-info-box--default .r-g4m-info-box__content ol,
.r-g4m-info-box--help .r-g4m-info-box__content ol,
.r-g4m-info-box--warning .r-g4m-info-box__content ol,
.r-g4m-info-box--help-minimal .r-g4m-info-box__content ol,
.r-g4m-info-box--warning-minimal .r-g4m-info-box__content ol {
  padding-left: 20px;
}

.r-g4m-info-box .r-g4m-info-box__content a,
.r-g4m-info-box--default .r-g4m-info-box__content a,
.r-g4m-info-box--help .r-g4m-info-box__content a,
.r-g4m-info-box--warning .r-g4m-info-box__content a,
.r-g4m-info-box--help-minimal .r-g4m-info-box__content a,
.r-g4m-info-box--warning-minimal .r-g4m-info-box__content a {
  color: #09c;
}

.r-g4m-info-box .r-g4m-info-box__content a:hover,
.r-g4m-info-box--default .r-g4m-info-box__content a:hover,
.r-g4m-info-box--help .r-g4m-info-box__content a:hover,
.r-g4m-info-box--warning .r-g4m-info-box__content a:hover,
.r-g4m-info-box--help-minimal .r-g4m-info-box__content a:hover,
.r-g4m-info-box--warning-minimal .r-g4m-info-box__content a:hover {
  color: #4078a4;
}

.r-g4m-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.r-g4m-input:last-of-type {
  margin-bottom: 20px;
}

.r-g4m-input .r-g4m-input__label {
  color: #63656a;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  margin-bottom: 4px;
}

.r-g4m-input .r-g4m-input__input {
  background-position: center right 11px;
  background-repeat: no-repeat;
  background-size: 16px;
  border-color: #d5d5d5;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  color: #333;
  font-size: 16px;
  font-weight: 400;
  height: 40px;
  line-height: 1;
  margin: 0;
  padding: 11px;
  width: 100%;
  box-shadow: 0 0 0 transparent;
  transition: box-shadow 0.2s;
}

.r-g4m-input .r-g4m-input__input[disabled],
.r-g4m-input .r-g4m-input__input[disabled]:hover {
  color: #434649;
  border: 1px solid #f6f6f6;
  background-color: #f6f6f6;
}

.r-g4m-input .r-g4m-input__input:hover,
.r-g4m-input .r-g4m-input__input:focus {
  border-color: #83888b;
}

.r-g4m-input .r-g4m-input__input:focus {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.3);
  outline: none;
}

.r-g4m-input .r-g4m-input__input::-webkit-input-placeholder {
  color: #babbbc;
}

.r-g4m-input .r-g4m-input__input::-moz-placeholder {
  color: #babbbc;
}

.r-g4m-input .r-g4m-input__input:-ms-input-placeholder {
  color: #babbbc;
}

.r-g4m-input .r-g4m-input__input::-ms-input-placeholder {
  color: #babbbc;
}

.r-g4m-input .r-g4m-input__input::placeholder {
  color: #babbbc;
}

.r-g4m-input .r-g4m-input__err-msg {
  color: #d00000;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.15;
  visibility: hidden;
  overflow: hidden;
  height: 0;
  min-height: 0;
  transition: min-height 0.2s ease;
}

.r-g4m-input.r-g4m-input--is-valid .r-g4m-input__input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-size: 16px 16px;
}

.r-g4m-input.r-g4m-input--is-invalid .r-g4m-input__input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-size: 16px 16px;
}

.r-g4m-input.r-g4m-input--is-invalid .r-g4m-input__err-msg {
  visibility: visible;
  min-height: 14px;
  margin-top: 5px;
}

.r-g4m-input.r-g4m-input--is-valid .r-g4m-input__input,
.r-g4m-input.r-g4m-input--is-invalid .r-g4m-input__input {
  padding-right: 38px;
}

.r-g4m-loading-spinner {
  font-size: 2.4rem;
  line-height: 1;
  text-align: center;
}

.r-g4m-loading-spinner__loading-icon {
  width: 24px;
  height: 24px;
  background: url(/dist/images/svgs/icon-loader.svg) center/24px no-repeat;
  display: inline-block;
  position: relative;
  top: 14px;
  left: 12px;
  transition: opacity 0.4s ease;
  -webkit-animation: react-returns-app-loading 1.6s infinite linear;
  animation: react-returns-app-loading 1.6s infinite linear;
  transform-origin: 50% 50%;
}

.r-g4m-radio-option,
.r-g4m-radio-option--selected {
  position: relative;
  font-size: 1.6rem;
  padding: 20px;
  padding-left: 40px;
  color: #434649;
}

.r-g4m-radio-option__header {
  position: relative;
}

.r-g4m-radio-option__header input {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
}

.r-g4m-radio-option__header label {
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 0;
  cursor: pointer;
}

.r-g4m-radio-option__header [type="radio"]+label:before {
  content: "";
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: -22px;
  border: 1px solid #83888b;
}

.r-g4m-radio-option__header [type="radio"]:checked+label:before {
  border: 5px solid #1c9ace;
}

.r-g4m-radio-option__content {
  display: none;
  padding-top: 15px;
}

.r-g4m-radio-option__content .r-returns-form-fieldset {
  margin-bottom: 10px;
}

.r-g4m-radio-option--selected {
  background-color: #edfaff;
}

.r-g4m-radio-option--selected .r-g4m-radio-option__content {
  display: block;
}

.g4m-select--mobile-sort-by {
  display: block;
  position: relative;
  white-space: nowrap;
}

.g4m-select--mobile-sort-by,
.g4m-select--mobile-sort-by select {
  border-radius: 3px;
  width: 100%;
  height: 40px;
}

.g4m-select--mobile-sort-by select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  box-shadow: none;
  padding-right: 30px;
}

.g4m-select--mobile-sort-by select::-ms-expand {
  display: none;
}

.g4m-select--mobile-sort-by select optgroup.ios-fix {
  display: none;
}

.g4m-select--mobile-sort-by select.g4m-select-inline select {
  padding-right: 10px;
}

.g4m-select--mobile-sort-by:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 16px;
  top: 50%;
  box-shadow: inset -2px 2px 0 #636569;
  pointer-events: none;
  transform: translateY(-50%) rotateZ(135deg);
}

.g4m-select--mobile-sort-by .select-text {
  display: block;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-weight: bold;
  text-align: center;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: none;
}

.g4m-select--mobile-sort-by .select-text+select {
  color: transparent;
}

.g4m-select--mobile-sort-by .select-text+select::-ms-value {
  font-size: 0;
  background: #e5e5e5;
}

.g4m-select--mobile-sort-by select {
  outline: none;
  text-align: center;
  -moz-text-align-last: center;
  text-align-last: center;
  font-size: 16px;
  background-color: #e5e5e5;
  border: 1px solid #ddd;
  padding-left: 10px;
}

.g4m-select--mobile-sort-by option {
  background-color: #fff;
  color: #333;
}

.g4m-select--mobile-sort-by.sort--applied select {
  background-color: #1c9ace;
  border-color: #1c9ace;
}

.g4m-select--mobile-sort-by.sort--applied .select-text {
  color: #fff;
}

.g4m-select--mobile-sort-by.sort--applied:after {
  box-shadow: inset -2px 2px 0 white;
}

.r-g4m-select-custom__button {
  position: relative;
  padding-right: 40px;
  z-index: 6;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.is-open.is-open .r-g4m-select-custom__button {
  background-color: #1c9ace;
  border-color: #1c9ace;
  color: #fff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.is-open.is-open .r-g4m-select-custom__button:hover {
  border-color: #1c9ace;
}

.is-open.is-open .r-g4m-select-custom__button:after {
  box-shadow: inset -2px 2px 0 white;
  transform: translateY(-50%) rotateZ(-45deg);
}

.r-g4m-select-custom__dropdown {
  position: absolute;
  width: 100%;
  top: 100%;
  z-index: 7;
  background-color: #fff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}

.r-g4m-select-native__text {
  display: block;
  width: 100%;
  height: 100%;
  padding: 11px 30px;
  line-height: normal;
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid;
  position: absolute;
  top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: none;
}

.r-g4m-select-native select,
.r-g4m-select-native--single-filter select,
.r-g4m-select-native--single-select select,
.r-g4m-select-native--multi-filter select,
.r-g4m-select-native--multi-select select {
  height: 100%;
  width: 100%;
  outline: none;
  color: transparent;
  border: none;
  text-align: center;
  -moz-text-align-last: center;
  text-align-last: center;
  padding-left: 10px;
}

.r-g4m-select-native option,
.r-g4m-select-native--single-filter option,
.r-g4m-select-native--single-select option,
.r-g4m-select-native--multi-filter option,
.r-g4m-select-native--multi-select option {
  background-color: #fff;
  color: #333;
}

.r-g4m-select-custom--single-filter .r-g4m-select-custom__button {
  display: block;
  position: relative;
  white-space: nowrap;
  background-color: #e5e5e5;
  color: #333;
  border: 1px solid #ddd;
  font-size: 1.4rem;
  font-weight: bold;
}

.r-g4m-select-custom--single-filter .r-g4m-select-custom__button,
.r-g4m-select-custom--single-filter .r-g4m-select-custom__button select {
  border-radius: 3px;
  width: 100%;
  height: 40px;
}

.r-g4m-select-custom--single-filter .r-g4m-select-custom__button {
  outline: none;
}

.r-g4m-select-custom--single-filter .r-g4m-select-custom__button:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 16px;
  top: 50%;
  box-shadow: inset -2px 2px 0 #636569;
  pointer-events: none;
  transform: translateY(-50%) rotateZ(135deg);
}

.r-g4m-select-custom--single-filter .r-g4m-select-custom__button:hover {
  background-color: #1c9ace;
  color: #fff;
  border: 1px solid #1c9ace;
}

.r-g4m-select-custom--single-filter .r-g4m-select-custom__button:hover:after {
  box-shadow: inset -2px 2px 0 white;
}

.r-g4m-select-custom--single-filter.is-open .r-g4m-select-custom__button {
  color: red;
}

.r-g4m-select-custom--single-filter.is-open .r-g4m-select-custom__button,
.r-g4m-select-custom--single-filter.has-been-applied .r-g4m-select-custom__button {
  background-color: #1c9ace;
  border-color: #1c9ace;
  color: #fff;
}

.r-g4m-select-custom--single-filter.is-open .r-g4m-select-custom__button:after,
.r-g4m-select-custom--single-filter.has-been-applied .r-g4m-select-custom__button:after {
  box-shadow: inset -2px 2px 0 white;
}

.r-g4m-select-custom--single-select .r-g4m-select-custom__button {
  display: block;
  position: relative;
  white-space: nowrap;
  background-color: #fff;
  color: #babbbc;
  border: 1px solid #d5d5d5;
  font-size: 1.6rem;
  font-style: italic;
  font-weight: normal;
}

.r-g4m-select-custom--single-select .r-g4m-select-custom__button,
.r-g4m-select-custom--single-select .r-g4m-select-custom__button select {
  border-radius: 3px;
  width: 100%;
  height: 40px;
}

.r-g4m-select-custom--single-select .r-g4m-select-custom__button {
  outline: none;
}

.r-g4m-select-custom--single-select .r-g4m-select-custom__button:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 16px;
  top: 50%;
  box-shadow: inset -2px 2px 0 #babbbc;
  pointer-events: none;
  transform: translateY(-50%) rotateZ(135deg);
}

.r-g4m-select-custom--single-select .r-g4m-select-custom__button:hover {
  border-color: #83888b;
}

.r-g4m-select-custom--single-select.has-been-applied .r-g4m-select-custom__button {
  font-style: normal;
  color: #434649;
}

.r-g4m-select-custom--single-select.is-open .r-g4m-select-custom__button {
  color: #fff;
}

.r-g4m-select-native--single-filter {
  display: block;
  position: relative;
  white-space: nowrap;
  position: relative;
  height: 40px;
}

.r-g4m-select-native--single-filter,
.r-g4m-select-native--single-filter select {
  border-radius: 3px;
  width: 100%;
  height: 40px;
}

.r-g4m-select-native--single-filter select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  box-shadow: none;
  padding-right: 30px;
}

.r-g4m-select-native--single-filter select::-ms-expand {
  display: none;
}

.r-g4m-select-native--single-filter select optgroup.ios-fix {
  display: none;
}

.r-g4m-select-native--single-filter select.g4m-select-inline select {
  padding-right: 10px;
}

.r-g4m-select-native--single-filter:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 16px;
  top: 50%;
  box-shadow: inset -2px 2px 0 #636569;
  pointer-events: none;
  transform: translateY(-50%) rotateZ(135deg);
}

.r-g4m-select-native--single-filter .r-g4m-select-native__text {
  background-color: #e5e5e5;
  color: #333;
  border-color: #ddd;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
}

.r-g4m-select-native--single-filter select {
  font-size: 1.6rem;
}

.r-g4m-select-native--single-filter select::-ms-value {
  font-size: 0;
  background: #e5e5e5;
}

.r-g4m-select-native--single-filter.is-selected .r-g4m-select-native__text {
  background-color: #1c9ace;
  color: #fff;
  border-color: #1c9ace;
}

.r-g4m-select-native--single-filter.is-selected:after {
  box-shadow: inset -2px 2px 0 white;
}

.r-g4m-select-native--single-select {
  display: block;
  position: relative;
  white-space: nowrap;
}

.r-g4m-select-native--single-select,
.r-g4m-select-native--single-select select {
  border-radius: 3px;
  width: 100%;
  height: 40px;
}

.r-g4m-select-native--single-select select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  box-shadow: none;
  padding-right: 30px;
}

.r-g4m-select-native--single-select select::-ms-expand {
  display: none;
}

.r-g4m-select-native--single-select select optgroup.ios-fix {
  display: none;
}

.r-g4m-select-native--single-select select.g4m-select-inline select {
  padding-right: 10px;
}

.r-g4m-select-native--single-select:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 16px;
  top: 50%;
  box-shadow: inset -2px 2px 0 #babbbc;
  pointer-events: none;
  transform: translateY(-50%) rotateZ(135deg);
}

.r-g4m-select-native--single-select .r-g4m-select-native__text {
  box-sizing: border-box;
  padding: 11px 30px 11px 11px;
  background-color: #fff;
  border-color: #d5d5d5;
  color: #babbbc;
  font-size: 1.6rem;
  font-style: italic;
}

.r-g4m-select-native--single-select select {
  transition: box-shadow 0.2s;
}

.r-g4m-select-native--single-select select:focus {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.3);
}

.r-g4m-select-native--single-select select:hover~.r-g4m-select-native__text,
.r-g4m-select-native--single-select select:focus~.r-g4m-select-native__text {
  border-color: #83888b;
}

.r-g4m-select-native--single-select.is-selected .r-g4m-select-native__text {
  font-style: normal;
  color: #434649;
}

.r-g4m-select-custom,
.r-g4m-select-custom--single-filter,
.r-g4m-select-custom--single-select,
.r-g4m-select-custom--multi-filter,
.r-g4m-select-custom--multi-select,
.r-g4m-select-custom--numeric-filter,
.r-g4m-select-custom--numeric-select {
  position: relative;
}

.r-g4m-select-custom--multi-filter .r-g4m-select-custom__button {
  display: block;
  position: relative;
  white-space: nowrap;
  background-color: #e5e5e5;
  color: #333;
  border: 1px solid #ddd;
  font-size: 1.4rem;
  font-weight: bold;
}

.r-g4m-select-custom--multi-filter .r-g4m-select-custom__button,
.r-g4m-select-custom--multi-filter .r-g4m-select-custom__button select {
  border-radius: 3px;
  width: 100%;
  height: 40px;
}

.r-g4m-select-custom--multi-filter .r-g4m-select-custom__button {
  outline: none;
}

.r-g4m-select-custom--multi-filter .r-g4m-select-custom__button:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 16px;
  top: 50%;
  box-shadow: inset -2px 2px 0 #636569;
  pointer-events: none;
  transform: translateY(-50%) rotateZ(135deg);
}

.r-g4m-select-custom--multi-filter .r-g4m-select-custom__button:hover {
  background-color: #1c9ace;
  color: #fff;
  border: 1px solid #1c9ace;
}

.r-g4m-select-custom--multi-filter .r-g4m-select-custom__button:hover:after {
  box-shadow: inset -2px 2px 0 white;
}

.r-g4m-select-custom--multi-filter.has-been-applied .r-g4m-select-custom__button {
  background-color: #1c9ace;
  color: #fff;
  border-color: #1c9ace;
}

.r-g4m-select-custom--multi-filter.has-been-applied .r-g4m-select-custom__button:after {
  box-shadow: inset -2px 2px 0 white;
}

.r-g4m-select-custom--multi-select .r-g4m-select-custom__button {
  display: block;
  position: relative;
  white-space: nowrap;
  background-color: #fff;
  color: #babbbc;
  border: 1px solid #d5d5d5;
  font-size: 1.6rem;
  font-style: italic;
  font-weight: normal;
}

.r-g4m-select-custom--multi-select .r-g4m-select-custom__button,
.r-g4m-select-custom--multi-select .r-g4m-select-custom__button select {
  border-radius: 3px;
  width: 100%;
  height: 40px;
}

.r-g4m-select-custom--multi-select .r-g4m-select-custom__button {
  outline: none;
}

.r-g4m-select-custom--multi-select .r-g4m-select-custom__button:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 16px;
  top: 50%;
  box-shadow: inset -2px 2px 0 #babbbc;
  pointer-events: none;
  transform: translateY(-50%) rotateZ(135deg);
}

.r-g4m-select-custom--multi-select .r-g4m-select-custom__button:hover {
  border-color: #83888b;
}

.r-g4m-select-custom--multi-select.has-been-applied .r-g4m-select-custom__button {
  font-style: normal;
  color: #434649;
}

.r-g4m-select-custom--multi-select.is-open .r-g4m-select-custom__button {
  color: #fff;
}

.r-g4m-select-native--multi-filter {
  display: block;
  position: relative;
  white-space: nowrap;
  position: relative;
  height: 40px;
}

.r-g4m-select-native--multi-filter,
.r-g4m-select-native--multi-filter select {
  border-radius: 3px;
  width: 100%;
  height: 40px;
}

.r-g4m-select-native--multi-filter select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  box-shadow: none;
  padding-right: 30px;
}

.r-g4m-select-native--multi-filter select::-ms-expand {
  display: none;
}

.r-g4m-select-native--multi-filter select optgroup.ios-fix {
  display: none;
}

.r-g4m-select-native--multi-filter select.g4m-select-inline select {
  padding-right: 10px;
}

.r-g4m-select-native--multi-filter:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 16px;
  top: 50%;
  box-shadow: inset -2px 2px 0 #636569;
  pointer-events: none;
  transform: translateY(-50%) rotateZ(135deg);
}

.r-g4m-select-native--multi-filter .r-g4m-select-native__text {
  background-color: #e5e5e5;
  color: #333;
  border-color: #ddd;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
}

.r-g4m-select-native--multi-filter select {
  font-size: 1.6rem;
}

.r-g4m-select-native--multi-filter select::-ms-value {
  font-size: 0;
  background: #e5e5e5;
}

.r-g4m-select-native--multi-filter.is-selected .r-g4m-select-native__text {
  background-color: #1c9ace;
  color: #fff;
  border-color: #1c9ace;
}

.r-g4m-select-native--multi-filter.is-selected:after {
  box-shadow: inset -2px 2px 0 white;
}

.r-g4m-select-native--multi-select {
  display: block;
  position: relative;
  white-space: nowrap;
}

.r-g4m-select-native--multi-select,
.r-g4m-select-native--multi-select select {
  border-radius: 3px;
  width: 100%;
  height: 40px;
}

.r-g4m-select-native--multi-select select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  box-shadow: none;
  padding-right: 30px;
}

.r-g4m-select-native--multi-select select::-ms-expand {
  display: none;
}

.r-g4m-select-native--multi-select select optgroup.ios-fix {
  display: none;
}

.r-g4m-select-native--multi-select select.g4m-select-inline select {
  padding-right: 10px;
}

.r-g4m-select-native--multi-select:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 16px;
  top: 50%;
  box-shadow: inset -2px 2px 0 #babbbc;
  pointer-events: none;
  transform: translateY(-50%) rotateZ(135deg);
}

.r-g4m-select-native--multi-select .r-g4m-select-native__text {
  background-color: #fff;
  border-color: #d5d5d5;
  color: #babbbc;
  font-size: 1.6rem;
  font-style: italic;
}

.r-g4m-select-native--multi-select select {
  transition: box-shadow 0.2s;
}

.r-g4m-select-native--multi-select select:focus {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.3);
}

.r-g4m-select-native--multi-select select:hover~.r-g4m-select-native__text,
.r-g4m-select-native--multi-select select:focus~.r-g4m-select-native__text {
  border-color: #83888b;
}

.r-g4m-select-native--multi-select.is-selected .r-g4m-select-native__text {
  font-style: normal;
  color: #434649;
}

.r-g4m-select-custom,
.r-g4m-select-custom--single-filter,
.r-g4m-select-custom--single-select,
.r-g4m-select-custom--multi-filter,
.r-g4m-select-custom--multi-select,
.r-g4m-select-custom--numeric-filter,
.r-g4m-select-custom--numeric-select {
  position: relative;
}

.r-g4m-select-custom--numeric-filter .r-g4m-select-custom__button {
  display: block;
  position: relative;
  white-space: nowrap;
  background-color: #e5e5e5;
  color: #333;
  border: 1px solid #ddd;
  font-size: 1.4rem;
  font-weight: bold;
}

.r-g4m-select-custom--numeric-filter .r-g4m-select-custom__button,
.r-g4m-select-custom--numeric-filter .r-g4m-select-custom__button select {
  border-radius: 3px;
  width: 100%;
  height: 40px;
}

.r-g4m-select-custom--numeric-filter .r-g4m-select-custom__button {
  outline: none;
}

.r-g4m-select-custom--numeric-filter .r-g4m-select-custom__button:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 16px;
  top: 50%;
  box-shadow: inset -2px 2px 0 #636569;
  pointer-events: none;
  transform: translateY(-50%) rotateZ(135deg);
}

.r-g4m-select-custom--numeric-filter .r-g4m-select-custom__button:hover {
  background-color: #1c9ace;
  color: #fff;
  border: 1px solid #1c9ace;
}

.r-g4m-select-custom--numeric-filter .r-g4m-select-custom__button:hover:after {
  box-shadow: inset -2px 2px 0 white;
}

.r-g4m-select-custom--numeric-filter .r-g4m-select-custom__dropdown {
  padding: 20px 10px 30px 10px;
}

.r-g4m-select-custom--numeric-filter .r-g4m-select-custom__dropdown .g4m-slider-range__slider {
  padding: 0 10px;
}

.r-g4m-select-custom--numeric-filter .r-g4m-select-custom__dropdown .dropdown__clear-btn {
  padding: 10px 0 0;
  text-align: center;
}

.r-g4m-select-custom--numeric-filter.is-open .r-g4m-select-custom__button,
.r-g4m-select-custom--numeric-filter.has-been-applied .r-g4m-select-custom__button {
  background-color: #1c9ace;
  border-color: #1c9ace;
  color: #fff;
}

.r-g4m-select-custom--numeric-filter.is-open .r-g4m-select-custom__button:after,
.r-g4m-select-custom--numeric-filter.has-been-applied .r-g4m-select-custom__button:after {
  box-shadow: inset -2px 2px 0 white;
}

.r-g4m-select-custom--numeric-select .r-g4m-select-custom__button {
  display: block;
  position: relative;
  white-space: nowrap;
  background-color: #fff;
  color: #babbbc;
  border: 1px solid #d5d5d5;
  font-size: 1.6rem;
  font-style: italic;
  font-weight: normal;
}

.r-g4m-select-custom--numeric-select .r-g4m-select-custom__button,
.r-g4m-select-custom--numeric-select .r-g4m-select-custom__button select {
  border-radius: 3px;
  width: 100%;
  height: 40px;
}

.r-g4m-select-custom--numeric-select .r-g4m-select-custom__button {
  outline: none;
}

.r-g4m-select-custom--numeric-select .r-g4m-select-custom__button:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 16px;
  top: 50%;
  box-shadow: inset -2px 2px 0 #babbbc;
  pointer-events: none;
  transform: translateY(-50%) rotateZ(135deg);
}

.r-g4m-select-custom--numeric-select .r-g4m-select-custom__button:hover {
  border-color: #83888b;
}

.r-g4m-select-custom--numeric-select.has-been-applied .r-g4m-select-custom__button {
  font-style: normal;
  color: #434649;
}

.r-g4m-select-custom--numeric-select.is-open .r-g4m-select-custom__button {
  color: #fff;
}

.g4m-button-select {
  position: relative;
}

.g4m-button-select__dropdown {
  position: absolute;
  width: 100%;
  top: 100%;
  z-index: 5;
  background-color: #fff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}

.dropdown__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;
  padding: 10px 20px;
  border-bottom: 1px solid #f4f4f4;
}

.dropdown__list {
  list-style: none;
  padding: 0;
  margin: 0;
  max-width: 100%;
  overflow-y: auto;
}

@media only screen and (max-height: 600px) {
  .dropdown__list {
    max-height: 240px;
  }
}

@media only screen and (min-height: 600px) {
  .dropdown__list {
    max-height: 288px;
  }
}

.dropdown__list li {
  height: 48px;
  border-bottom: 1px solid #f4f4f4;
}

.dropdown__list li:last-child {
  border: none;
}

.g4m-dropdown-button {
  height: 100%;
  width: 100%;
  padding: 17px 20px;
  border-radius: 0px;
  border: none;
  outline: none;
  text-align: left;
  font-weight: normal;
  background-color: #fff;
  color: #63656a;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.g4m-dropdown-button:hover {
  background: #edfaff;
}

.g4m-dropdown-button-label {
  display: flex;
  width: calc(100% - 28px);
}

.g4m-dropdown-button-label .label__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.g4m-dropdown-button-label .label__text:not(:last-child) {
  max-width: calc(100% - 33px);
}

.g4m-dropdown-button-label .label__counter {
  color: #83888b;
  margin-left: 0.2em;
}

.g4m-dropdown-button-radio,
.g4m-dropdown-button-checkbox {
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border: 1px solid #83888b;
  background-color: #fff;
  margin-right: 12px;
  position: relative;
}

.g4m-dropdown-button-radio svg,
.g4m-dropdown-button-checkbox svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.g4m-dropdown-button-radio {
  border-radius: 8px;
}

.g4m-dropdown-button-checkbox {
  border-radius: 3px;
}

.g4m-dropdown-button.selected {
  background-color: #edfaff;
  border-color: #edfaff;
}

.g4m-dropdown-button.selected .g4m-dropdown-button-radio {
  border: 5px solid #1c9ace;
}

.g4m-dropdown-button.selected .g4m-dropdown-button-checkbox {
  background-color: #1c9ace;
  border-color: #1c9ace;
}

.g4m-dropdown-button.selected .g4m-dropdown-button-label {
  color: #1c9ace;
}

.r-g4m-status-message,
.r-g4m-status-message--success,
.r-g4m-status-message--partial-success,
.r-g4m-status-message--error {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.r-g4m-status-message__icon {
  flex: 0 0 auto;
  width: 70px;
  height: 70px;
  margin: 0 0 30px 0;
  background-position: 0 0;
  background-size: 100%;
  margin: 0 30px 0 0;
}

.r-g4m-status-message--success .r-g4m-status-message__icon {
  background-image: url("/dist/images/svgs/returns-icon-success.svg");
}

.r-g4m-status-message--partial-success .r-g4m-status-message__icon {
  background-image: url("/dist/images/svgs/returns-icon-info-green.svg");
}

.r-g4m-status-message--error .r-g4m-status-message__icon {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='70' height='70' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='35' cy='35' r='35' fill='%23D00000'%3E%3C/circle%3E%3Cg clip-path='url(%23returns-icon-error_svg__clip0)'%3E%3Cpath d='M29.853 14.412h10.294V39.85H29.853V14.41zm0 30.97h10.294v10.206H29.853V45.381z' fill='%23fff'%3E%3C/path%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='returns-icon-error_svg__clip0'%3E%3Cpath fill='%23fff' transform='translate(14.412 14.412)' d='M0 0h41.176v41.176H0z'%3E%3C/path%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}

.r-g4m-status-message__text .r-returns-title {
  font-size: 2rem;
  margin: 0;
  line-height: 1.2;
  font-size: 2.4rem;
}

.r-g4m-status-message__text .r-returns-title__line {
  display: block;
  margin-bottom: 0.4em;
}

.r-g4m-status-message__text .r-returns-title__line:last-child {
  margin-bottom: 0;
}

.r-g4m-table,
.r-g4m-table--horizontal,
.r-g4m-table--vertical {
  width: 100%;
  border-collapse: collapse;
}

.style-alt .r-g4m-table,
.style-alt .r-g4m-table--horizontal,
.style-alt .r-g4m-table--vertical {
  color: inherit;
  background-color: transparent;
}

.r-g4m-table th,
.r-g4m-table--horizontal th,
.r-g4m-table--vertical th,
.r-g4m-table td,
.r-g4m-table--horizontal td,
.r-g4m-table--vertical td {
  padding: 5px;
}

.r-g4m-table th:first-child,
.r-g4m-table--horizontal th:first-child,
.r-g4m-table--vertical th:first-child,
.r-g4m-table td:first-child,
.r-g4m-table--horizontal td:first-child,
.r-g4m-table--vertical td:first-child {
  padding-left: 0;
}

.r-g4m-table th:last-child,
.r-g4m-table--horizontal th:last-child,
.r-g4m-table--vertical th:last-child,
.r-g4m-table td:last-child,
.r-g4m-table--horizontal td:last-child,
.r-g4m-table--vertical td:last-child {
  padding-right: 0;
}

.r-g4m-table tr:first-child th,
.r-g4m-table--horizontal tr:first-child th,
.r-g4m-table--vertical tr:first-child th,
.r-g4m-table tr:first-child td,
.r-g4m-table--horizontal tr:first-child td,
.r-g4m-table--vertical tr:first-child td {
  padding-top: 0;
}

.r-g4m-table tr:last-child th,
.r-g4m-table--horizontal tr:last-child th,
.r-g4m-table--vertical tr:last-child th,
.r-g4m-table tr:last-child td,
.r-g4m-table--horizontal tr:last-child td,
.r-g4m-table--vertical tr:last-child td {
  padding-bottom: 0;
}

.r-g4m-table--horizontal th {
  font-weight: normal;
  text-align: left;
}

.r-g4m-table--horizontal td {
  font-weight: bold;
  text-align: right;
}

.r-g4m-table--vertical th {
  font-weight: bold;
  text-align: left;
}

.r-g4m-table--vertical td {
  font-weight: normal;
}

.product-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  font-size: 1.4rem;
  line-height: 1.3;
  background-color: #fff;
  color: inherit;
  border: 1px solid #d5d5d5;
  border-radius: 3px;
  transition: border 150ms ease-in-out;
}

.product-card:hover {
  border: 1px solid #fa8600;
}

.product-card:hover {
  text-decoration: none;
}

.product-card-title,
.product-card-description,
.product-card-image-wrapper,
.product-card-availability {
  flex: 1 1 auto;
}

.product-card-promo-sash {
  display: block;
  width: 55px;
  height: 55px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.product-card-title,
.product-card-description {
  text-align: center;
  padding: 0 20px;
}

.product-card-title {
  order: 2;
  flex-shrink: 0;
  margin: 0;
  overflow: hidden;
  line-height: 1.2;
  height: 55px;
  font-size: 1.4rem;
}

.product-card-description {
  order: 3;
  min-height: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.product-card-image-wrapper {
  order: 1;
  flex: 0 0 auto;
  padding: 15px 0;
}

.product-card-image {
  display: block;
  margin: 0 auto;
  height: 100%;
  max-width: 215px;
  max-height: 215px;
  transition: transform 150ms ease-in-out;
  transform: scale(1);
}

.product-card:hover .product-card-image {
  transform: scale(1.05);
}

.product-card-availability {
  order: 4;
  display: flex;
  justify-content: stretch;
  align-items: flex-end;
  min-height: 30px;
  margin: 5px 0 15px;
}

.product-card-price,
.product-card-stock-message {
  line-height: 1;
  flex: 1 1 auto;
  text-align: right;
}

.product-card-price {
  flex: none;
  position: relative;
  bottom: -1px;
  font-weight: bold;
  color: #d00000;
  font-size: 2.5rem;
  padding-left: 20px;
}

.product-card-price.is-custom-cable {
  font-size: 2.3rem;
  padding-left: 20px;
}

.product-card-stock-message {
  font-weight: bold;
  line-height: 1.2;
  padding-left: 15px;
  padding-right: 20px;
}

.product-card-stock-message.in-stock {
  color: #249c54;
}

.product-card-stock-message.not-available.cannot-order {
  font-size: 1.1rem;
}

.product-card-stock-message.not-available {
  font-weight: normal;
  color: #888888;
  text-align: center;
  font-size: 1.2rem;
  padding-right: 20px;
}

.product-card-price.not-available {
  display: none;
}

.product-card-custom-cable-from-label,
.product-card-price small {
  font-size: 14px;
  font-weight: bold;
  margin-right: 4px;
}

.r-progress {
  width: 100%;
  margin-bottom: 30px;
  background-color: #fff;
}

.r-progress__steps {
  display: flex;
  justify-content: space-between;
  justify-content: stretch;
  align-items: stretch;
  min-height: 60px;
}

.r-progress__steps *::-ms-backdrop,
.r-progress__steps {
  height: 60px;
}

.r-progress__step,
.r-progress__step--previous,
.r-progress__step--active {
  font-size: medium;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  display: inline-block;
  min-width: auto;
  padding: 1px 7px 2px 1px;
  border: 1px solid #bababa;
  border-radius: 0;
  margin: 0;
  background-color: silver;
  vertical-align: baseline;
  cursor: default;
  padding: 0;
  border: 0;
  color: #babbbc;
  font-size: 2rem;
  line-height: 1;
  position: relative;
  display: flex;
  flex: 0 1 auto;
  justify-content: stretch;
  align-items: center;
  background-color: #fff;
  overflow: visible;
}

.r-progress__step:focus,
.r-progress__step--previous:focus,
.r-progress__step--active:focus,
.r-progress__step:hover,
.r-progress__step--previous:hover,
.r-progress__step--active:hover {
  background-color: #9c9c9c;
  cursor: inherit;
}

.r-progress__step:focus,
.r-progress__step--previous:focus,
.r-progress__step--active:focus,
.r-progress__step:hover,
.r-progress__step--previous:hover,
.r-progress__step--active:hover {
  outline: none;
  background-color: #fff;
}

@media screen and (max-width: 767px) {

  .r-progress__step:focus .r-progress__step-num,
  .r-progress__step--previous:focus .r-progress__step-num,
  .r-progress__step--active:focus .r-progress__step-num {
    text-decoration: underline;
  }
}

.r-progress__step:focus .r-progress__step-label,
.r-progress__step--previous:focus .r-progress__step-label,
.r-progress__step--active:focus .r-progress__step-label {
  text-decoration: underline;
}

.r-progress__step:first-child:after,
.r-progress__step--previous:first-child:after,
.r-progress__step--active:first-child:after {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.r-progress__step--previous {
  color: #a4d5e6;
  cursor: pointer;
}

.r-progress__step--previous:hover {
  color: #168dbf;
  cursor: pointer;
}

.r-progress__step--previous:after {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 0;
  display: block;
  height: 6px;
  width: 100%;
  min-width: 36px;
  background: #168dbf;
}

.r-progress__step--active {
  color: #434649;
  flex: 1 1 auto;
}

@media screen and (max-width: 767px) {
  .r-progress__step--active:focus .r-progress__step-num {
    text-decoration: none;
  }

  .r-progress__step--active:focus .r-progress__step-label {
    text-decoration: underline;
  }
}

.r-progress__step--active:focus .r-progress__step-label {
  text-decoration: underline;
}

.r-progress__step--active:after {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 0;
  display: block;
  height: 6px;
  width: 100%;
  min-width: 36px;
  background: #168dbf;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.r-progress__step--active .r-progress__step-label {
  display: block;
}

.r-progress__step--active .r-progress__step-num,
.r-progress__step--active .r-progress__step-label {
  font-size: 2.2rem;
}

@media screen and (max-width: 840px) {

  .r-progress__step--active .r-progress__step-num,
  .r-progress__step--active .r-progress__step-label {
    font-size: 2rem;
  }
}

.r-progress__step-num {
  font-weight: bold;
  text-align: center;
  flex: 0 0 auto;
  width: 36px;
  font-size: 2rem;
}

@media screen and (max-width: 840px) {
  .r-progress__step-num {
    font-size: 1.8rem;
  }
}

.r-progress__step-label {
  display: none;
  font-weight: 200;
  text-align: left;
  flex: 2 1 auto;
  padding-right: 10px;
  display: block;
  font-size: 2rem;
}

@media screen and (max-width: 840px) {
  .r-progress__step-label {
    font-size: 1.8rem;
  }
}

.r-progress__bar-background {
  width: 100%;
  height: 6px;
  border-radius: 3px;
  margin: 0;
  background-color: #ededed;
}

.g4m-slider-range__label {
  display: flex;
  justify-content: space-between;
  color: #63656a;
  font: inherit;
  font-weight: bold;
}

.g4m-slider-range__slider {
  cursor: pointer;
}

.g4m-slider-range__slider .rc-slider-track {
  background: #09c;
}

.g4m-slider-range__slider .rc-slider-handle {
  border: solid 2px #09c;
  background-color: #09c;
  cursor: pointer;
}

.g4m-slider-range__slider .rc-slider-handle-click-focused:focus {
  border-color: #09c;
}

.g4m-slider-range__slider .rc-slider-handle:active {
  border-color: #09c;
  box-shadow: 0 0 5px #09c;
}

.r-g4m-select-native__text {
  font-style: normal !important;
}

.r-g4m-select-custom__button {
  font-style: normal !important;
}

h3 {
  margin-top: 0;
  margin-bottom: 0;
}

h4 {
  margin-top: 0;
  margin-bottom: 0;
}

.locate-drop-off {
  max-width: 800px;
  margin: 0 auto;
  font-size: 1.4rem;
  color: #434649;
}

.locate-drop-off p {
  margin: 0 0 0 0;
  line-height: 1.5;
}

.locate-drop-off-finder {
  display: -ms-grid;
  display: grid;
  gap: 0 60px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto 0 auto 0 auto 0 auto 0 auto 0 auto;
  grid-template-rows: repeat(6, auto);
  grid-template-areas: "locate-header" "locate-description" "locate-country" "locate-postcode" "locate-search" "locate-results";
}

.locate-drop-off-finder *::-ms-backdrop,
.locate-drop-off-finder {
  gap: 0;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto 0 auto 0 auto 0 auto 0 auto 0 auto 0 auto;
  grid-template-rows: auto auto auto auto auto auto auto;
  grid-template-areas: "locate-header" "locate-description" "locate-form" "locate-country" "locate-postcode" "locate-search" "locate-results";
}

.locate-drop-off-finder__header {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: locate-header;
  text-align: center;
}

.locate-drop-off-finder__header .title {
  font-size: 30px;
  margin: 0 0 1em;
  line-height: 1.2;
}

.locate-drop-off-finder__description {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: locate-description;
  margin-left: 210px;
  margin-right: 210px;
  text-align: center;
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .body-class--mobile .locate-drop-off-finder__description {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.locate-drop-off-finder__description>p:last-child {
  margin-bottom: 20px;
}

.locate-drop-off-finder__form *::-ms-backdrop,
.locate-drop-off-finder__form {
  -ms-grid-row: 5;
  -ms-grid-column: 1;
  grid-area: locate-form;
}

.locate-drop-off-finder__country {
  -ms-grid-row: 7;
  -ms-grid-column: 1;
  -ms-grid-row: 5;
  -ms-grid-column: 1;
  grid-area: locate-country;
  margin-left: 240px;
  margin-right: 240px;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .body-class--mobile .locate-drop-off-finder__country {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.locate-drop-off-finder__postcode {
  -ms-grid-row: 9;
  -ms-grid-column: 1;
  -ms-grid-row: 7;
  -ms-grid-column: 1;
  grid-area: locate-postcode;
  margin-left: 240px;
  margin-right: 240px;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .body-class--mobile .locate-drop-off-finder__postcode {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.locate-drop-off-finder__search {
  -ms-grid-row: 11;
  -ms-grid-column: 1;
  -ms-grid-row: 9;
  -ms-grid-column: 1;
  grid-area: locate-search;
  margin-left: 240px;
  margin-right: 240px;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .body-class--mobile .locate-drop-off-finder__search {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.locate-drop-off-finder__submitbutton {
  color: white;
  background-color: #fa8600;
  transition: background-color 50ms ease-out;
  height: 40px;
  vertical-align: center;
  margin-top: 6px;
}

.locate-drop-off-finder__submitbutton:hover {
  color: white;
  background-color: rgba(250, 134, 0, 0.65);
}

.locate-drop-off-finder__results {
  -ms-grid-row: 13;
  -ms-grid-column: 1;
  -ms-grid-row: 11;
  -ms-grid-column: 1;
  grid-area: locate-results;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .body-class--mobile .locate-drop-off-finder__results {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.locate-drop-off-finder__locations {
  overflow-y: scroll;
  overflow-x: auto;
  height: 400px;
  float: left;
  margin-right: 20px;
  width: 33%;
}

.locate-drop-off-finder__map {
  position: relative;
  width: 70%;
  height: 400px;
  margin-left: 33.5%;
}

.locate-drop-off-finder__label {
  height: 12px;
  font-weight: 700 !important;
}

.locate-drop-off-finder__loading {
  font-size: 2.4rem;
  line-height: 1;
  text-align: center;
  padding: 150px 0;
}

.locate-drop-off-finder__loading-icon {
  width: 24px;
  height: 24px;
  background: url(/dist/images/svgs/icon-loader.svg) center/24px no-repeat;
  display: inline-block;
  position: relative;
  top: 14px;
  left: 12px;
  transition: opacity 0.4s ease;
  -webkit-animation: locate-drop-off-loading 1.6s infinite linear;
  animation: locate-drop-off-loading 1.6s infinite linear;
  transform-origin: 50% 50%;
}

.locate-drop-off-finder__divider {
  border-top: 2px solid grey;
}

.locate-drop-off-finder__result {
  margin-right: 5px;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .body-class--mobile .locate-drop-off-finder__result {
    margin-right: 0;
  }
}

.locate-drop-off-finder__error {
  color: red;
  font-size: 16px;
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
}

@-webkit-keyframes locate-drop-off-loading {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes locate-drop-off-loading {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.drop-off-location {
  border-style: solid;
  border-color: grey;
  display: -ms-grid;
  display: grid;
  gap: 0 0;
  -ms-grid-columns: 15% 0 85%;
  grid-template-columns: 15% 85%;
  -ms-grid-rows: auto 0 auto 0 auto 0 auto 0 auto;
  grid-template-rows: repeat(5, auto);
  grid-template-areas: "drop-select drop-name" "drop-select drop-map" "drop-select drop-address" "drop-select drop-postcodecity" "drop-select drop-openhours";
}

@media screen and (max-width: 767px) {
  .body-class--mobile .drop-off-location {
    -ms-grid-columns: 7% (1fr) 2;
    grid-template-columns: 7% repeat(2, 1fr);
    -ms-grid-rows: (auto) 5;
    grid-template-rows: repeat(5, auto);
    grid-template-areas: "drop-select drop-name drop-openhourstitle" "drop-select drop-address drop-openhours" "drop-select drop-postcodecity drop-openhours" "drop-select drop-mapcontent drop-mapcontent" "drop-select drop-map drop-map";
  }
}

.drop-off-location__name {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  grid-area: drop-name;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .body-class--mobile .drop-off-location__name {
    margin-top: 5px;
  }
}

.drop-off-location__name .title {
  font-size: 3rem;
  margin: 0 0 1em;
  line-height: 1.2;
}

@media screen and (max-width: 767px) {
  .body-class--mobile .drop-off-location__name .title {
    font-size: 2.5rem;
  }
}

.drop-off-location__map {
  -ms-grid-row: 3;
  -ms-grid-column: 3;
  grid-area: drop-map;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .body-class--mobile .drop-off-location__map {
    margin-bottom: 5px;
  }
}

.drop-off-location__address {
  -ms-grid-row: 5;
  -ms-grid-column: 3;
  grid-area: drop-address;
}

@media screen and (max-width: 767px) {
  .body-class--mobile .drop-off-location__address {
    margin-top: 5px;
  }
}

.drop-off-location__postcodecity {
  -ms-grid-row: 7;
  -ms-grid-column: 3;
  grid-area: drop-postcodecity;
  margin-bottom: 10px;
  display: flex;
}

@media screen and (max-width: 767px) {
  .body-class--mobile .drop-off-location__postcodecity {
    margin-bottom: 5px;
  }
}

.drop-off-location__openhourstitle {
  grid-area: drop-openhourstitle;
  text-align: right;
  margin-top: 5px;
  margin-right: 20px;
  margin-left: 5px;
}

.drop-off-location__openhours {
  -ms-grid-row: 9;
  -ms-grid-column: 3;
  grid-area: drop-openhours;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .body-class--mobile .drop-off-location__openhours {
    margin: 5px 20px 5px 5px;
    text-align: right;
  }
}

.drop-off-location__select {
  -ms-grid-row: 1;
  -ms-grid-row-span: 9;
  -ms-grid-column: 1;
  grid-area: drop-select;
  display: block;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .body-class--mobile .drop-off-location__select {
    position: absolute;
    margin-left: 7px;
    margin-top: 20px;
  }
}

.drop-off-location__header {
  font-weight: bold;
}

.drop-off-location__selected {
  background-color: rgba(22, 141, 191, 0.1);
}

.drop-off-location__mapcontent {
  grid-area: drop-mapcontent;
  position: relative;
  width: auto;
  margin-right: 20px;
  margin-bottom: 10px;
  height: 300px;
}

@media screen and (max-width: 767px) {
  .body-class--mobile .drop-off-location>.drop-off-location__name {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
  }

  .body-class--mobile .drop-off-location>.drop-off-location__map {
    -ms-grid-row: 5;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
  }

  .body-class--mobile .drop-off-location>.drop-off-location__address {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
  }

  .body-class--mobile .drop-off-location>.drop-off-location__postcodecity {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
  }

  .drop-off-location__openhourstitle {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }

  .body-class--mobile .drop-off-location>.drop-off-location__openhours {
    -ms-grid-row: 2;
    -ms-grid-row-span: 2;
    -ms-grid-column: 3;
  }

  .body-class--mobile .drop-off-location>.drop-off-location__select {
    -ms-grid-row: 1;
    -ms-grid-row-span: 5;
    -ms-grid-column: 1;
  }

  .drop-off-location__mapcontent {
    -ms-grid-row: 4;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
  }
}

.g4m-drawer {
  width: 80vw;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 5;
  transition: right 0.4s ease;
}

.g4m-drawer.in {
  right: 0;
}

.g4m-drawer.out {
  right: -80vw;
}

.g4m-drawer-content {
  position: relative;
  height: 100%;
}

.g4m-button-close-drawer {
  width: 44px;
  height: 44px;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  outline: none;
  border: none;
  background-color: transparent;
}

.error-boundary__reload {
  color: #168dbf;
  font-size: inherit;
  font-weight: normal;
  line-height: inherit;
  text-align: inherit;
  text-decoration: none;
  display: inline;
  min-width: auto;
  padding: 0;
  border: 0;
  margin: 0;
  outline: none;
  background: transparent;
  transition: none;
}

.error-boundary__reload:hover {
  text-decoration: underline;
  background: transparent;
}

.g4m-grid-filter-controls-mobile {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 9px;
}

.g4m-grid-filter-controls-mobile .g4m-grid-item {
  box-sizing: border-box;
  width: calc(49.99% - 4.5px);
  margin-bottom: 9px;
}

.g4m-grid-filter-controls-mobile .g4m-grid-item:not(:nth-child(2n)) {
  margin-right: 9px;
}

.g4m-grid-filter-controls-mobile .g4m-grid-item:nth-last-child(-n + 2):nth-child(2n + 1),
.g4m-grid-filter-controls-mobile .g4m-grid-item:nth-last-child(-n + 2):nth-child(2n + 1)~* {
  margin-bottom: 0;
}

.g4m-grid-filter-controls-mobile .g4m-grid-item:not(:nth-child(-n + 0)) {
  margin-bottom: 0;
}

.g4m-grid-filter-controls-mobile .g4m-grid-item:not(:nth-child(-n + 2)) {
  display: none;
}

.g4m-grid-filter-controls-desktop {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.g4m-grid-filter-controls-desktop .g4m-grid-item {
  box-sizing: border-box;
}

@media screen and (max-width: 1290px) {
  .g4m-grid-filter-controls-desktop.all-filters .g4m-grid-item {
    width: calc(33.32333% - 10px);
    margin-bottom: 15px;
  }

  .g4m-grid-filter-controls-desktop.all-filters .g4m-grid-item:not(:nth-child(3n)) {
    margin-right: 15px;
  }

  .g4m-grid-filter-controls-desktop.all-filters .g4m-grid-item:nth-last-child(-n + 3):nth-child(3n + 1),
  .g4m-grid-filter-controls-desktop.all-filters .g4m-grid-item:nth-last-child(-n + 3):nth-child(3n + 1)~* {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1291px) {
  .g4m-grid-filter-controls-desktop.all-filters .g4m-grid-item {
    width: calc(19.99% - 16px);
    margin-bottom: 20px;
  }

  .g4m-grid-filter-controls-desktop.all-filters .g4m-grid-item:not(:nth-child(5n)) {
    margin-right: 20px;
  }

  .g4m-grid-filter-controls-desktop.all-filters .g4m-grid-item:nth-last-child(-n + 5):nth-child(5n + 1),
  .g4m-grid-filter-controls-desktop.all-filters .g4m-grid-item:nth-last-child(-n + 5):nth-child(5n + 1)~* {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1290px) {
  .g4m-grid-filter-controls-desktop:not(.all-filters) .g4m-grid-item {
    width: calc(33.32333% - 10px);
    margin-bottom: 15px;
  }

  .g4m-grid-filter-controls-desktop:not(.all-filters) .g4m-grid-item:not(:nth-child(3n)) {
    margin-right: 15px;
  }

  .g4m-grid-filter-controls-desktop:not(.all-filters) .g4m-grid-item:nth-last-child(-n + 3):nth-child(3n + 1),
  .g4m-grid-filter-controls-desktop:not(.all-filters) .g4m-grid-item:nth-last-child(-n + 3):nth-child(3n + 1)~* {
    margin-bottom: 0;
  }

  .g4m-grid-filter-controls-desktop:not(.all-filters) .g4m-grid-item:not(:nth-child(-n + 3)) {
    margin-bottom: 0;
  }

  .g4m-grid-filter-controls-desktop:not(.all-filters) .g4m-grid-item:not(:nth-child(-n + 6)) {
    display: none;
  }
}

@media screen and (min-width: 1291px) {
  .g4m-grid-filter-controls-desktop:not(.all-filters) .g4m-grid-item {
    width: calc(19.99% - 16px);
    margin-bottom: 20px;
  }

  .g4m-grid-filter-controls-desktop:not(.all-filters) .g4m-grid-item:not(:nth-child(5n)) {
    margin-right: 20px;
  }

  .g4m-grid-filter-controls-desktop:not(.all-filters) .g4m-grid-item:nth-last-child(-n + 5):nth-child(5n + 1),
  .g4m-grid-filter-controls-desktop:not(.all-filters) .g4m-grid-item:nth-last-child(-n + 5):nth-child(5n + 1)~* {
    margin-bottom: 0;
  }

  .g4m-grid-filter-controls-desktop:not(.all-filters) .g4m-grid-item:not(:nth-child(-n + 5)) {
    margin-bottom: 0;
  }

  .g4m-grid-filter-controls-desktop:not(.all-filters) .g4m-grid-item:not(:nth-child(-n + 10)) {
    display: none;
  }
}

.filtering-tool,
.filtering-tool-item:not(:last-child) {
  margin-bottom: 20px;
}

@media screen and (min-width: 1024px) {
  .g4m-grid-filter-controls-desktop.g4m-grid-centered--desktop {
    justify-content: center;
  }

  .g4m-grid-filter-controls-desktop.g4m-grid-centered--desktop .g4m-grid-item:last-child {
    margin-right: 0;
  }
}

@media screen and (max-width: 1023px) {
  .g4m-grid-filter-controls-desktop.g4m-grid-centered--tablet {
    justify-content: center;
  }

  .g4m-grid-filter-controls-desktop.g4m-grid-centered--tablet .g4m-grid-item:last-child {
    margin-right: 0;
  }
}

.container-filters-controls__reveal-button {
  border-bottom: 1px solid #d5d5d5;
  padding-bottom: 20px;
}

.container-filters-controls__reveal-button.hide-from-tablet {
  display: none;
}

@media screen and (min-width: 1291px) {
  .container-filters-controls__reveal-button.hide-from-desktop {
    display: none;
  }
}

.g4m-filters-mobile {
  height: 100%;
  position: relative;
}

.g4m-button-filter,
.g4m-button-option {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.g4m-button-filter {
  padding: 10px 40px 10px 20px;
}

.g4m-button-filter:after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  box-sizing: content-box;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 23px;
  left: auto;
  transform: rotate(135deg);
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-bottom: none;
  border-right: none;
}

.g4m-button-option {
  padding: 10px 20px;
}

.g4m-button-filter.selected,
.g4m-button-option.selected {
  background-color: #edfaff;
  font-weight: bold;
}

.g4m-button-filter.selected .g4m-filter-counter,
.g4m-button-option.selected .g4m-filter-counter {
  color: #333;
}

.g4m-button-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  max-width: calc(100% - 28px - 33px);
}

.g4m-filter-counter {
  color: #83888b;
  margin-left: 0.2em;
}

.g4m-filter-icon-selected {
  margin-left: 1em;
}

.g4m-filter-icon-selected svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.g4m-filter-footer {
  width: 100%;
  height: 72px;
  padding: 15px;
  position: absolute;
  bottom: 0;
  background-color: #fff;
  border-top: 1px solid #f4f4f4;
}

.g4m-filter-footer .g4m-button--secondary {
  height: 100%;
}

.g4m-panel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 56px;
  background-color: #f4f4f4;
}

.g4m-panel-header-toggle {
  width: 60px;
}

.g4m-panel-header-toggle .g4m-button--flat,
.g4m-panel-header-toggle .g4m-button--flat-icon {
  text-align: right;
}

.g4m-panel-header-title {
  max-width: calc(100% - 2 * 60px);
  padding: 18px 0;
  font-size: 19px;
  font-weight: bold;
  line-height: 1.1;
  text-align: center;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.plp-page .g4m-panel,
.react-search-app .g4m-panel {
  width: 80vw;
  height: 100%;
  padding-bottom: 72px;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #fff;
  overflow: hidden;
  transition: right 0.4s ease;
}

.plp-page .g4m-panel.out,
.react-search-app .g4m-panel.out {
  right: -80vw;
}

.plp-page .g4m-panel.in,
.react-search-app .g4m-panel.in {
  right: 0;
}

.plp-page .g4m-panel.offset,
.react-search-app .g4m-panel.offset {
  right: 30vw;
}

.plp-page .g4m-panel-header,
.react-search-app .g4m-panel-header {
  width: 100%;
  align-self: flex-start;
}

.plp-page .g4m-panel-content,
.react-search-app .g4m-panel-content {
  width: 100%;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.g4m-filter-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  min-height: 100%;
}

.g4m-filter-item {
  height: 40px;
  border-bottom: 1px solid #f4f4f4;
}

.list-item-slider {
  margin: 20px 20px 30px 20px;
}

.list-item-slider .input-range__slider {
  width: 20px;
  height: 20px;
  margin-top: -12px;
  margin-left: -12px;
}

.list-item-slider .g4m-slider-range__slider {
  padding: 0 5px;
}

.list-item-slider .g4m-slider-range__label {
  margin-top: 14px;
}

.g4m-loading-container {
  display: none;
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2500;
  pointer-events: none;
}

.icon-loading {
  width: 24px;
  height: 24px;
  background: url(/dist/images/svgs/icon-loader.svg) center/24px no-repeat;
  opacity: 0.7;
}

.icon-loading.loading-big {
  width: 39px;
  height: 39px;
  background: url(/dist/images/svgs/icon-loader.svg) center/39px no-repeat;
}

.g4m-loading-overlay {
  position: relative;
  pointer-events: auto;
}

.g4m-loading-overlay.fullpage {
  position: fixed;
}

.g4m-loading-overlay:after,
.g4m-loading-overlay .icon-loading {
  opacity: 0;
  visibility: hidden;
}

.g4m-loading-overlay.in {
  display: block;
}

.g4m-loading-overlay.in:after,
.g4m-loading-overlay.in .icon-loading {
  opacity: 1;
  visibility: visible;
}

.g4m-loading-overlay.in:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  outline: 2px solid rgba(255, 255, 255, 0.9);
  background-color: rgba(255, 255, 255, 0.9);
  transition: opacity 0.2s ease;
}

.g4m-loading-overlay.in .icon-loading {
  display: block;
  font-size: 3rem;
  color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  transition: opacity 0.4s ease;
  -webkit-animation: cog-spin 1.6s infinite linear;
  animation: cog-spin 1.6s infinite linear;
  transform-origin: 50% 50%;
}

.g4m-loading-overlay.g4m-modal-body {
  padding: 0;
}

@-webkit-keyframes cog-spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes cog-spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.g4m-overlay--drawer {
  height: 100%;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 5;
  background-color: black;
}

.out.g4m-overlay--drawer {
  opacity: 0;
}

.in.g4m-overlay--drawer {
  opacity: 0.3;
}

.g4m-overlay--drawer.out {
  left: 0;
  transition: none;
  pointer-events: none;
}

.g4m-overlay--drawer.in {
  left: -80vw;
  transition: opacity 0.4s ease 0.4s;
}

.react-returns-app {
  max-width: 920px;
  margin: 0 auto;
  font-size: 1.4rem;
  color: #434649;
}

.react-returns-app__loading {
  font-size: 2.4rem;
  line-height: 1;
  text-align: center;
  padding: 150px 0;
}

.react-returns-app__loading-icon {
  width: 24px;
  height: 24px;
  background: url(/dist/images/svgs/icon-loader.svg) center/24px no-repeat;
  display: inline-block;
  position: relative;
  top: 14px;
  left: 12px;
  transition: opacity 0.4s ease;
  -webkit-animation: react-returns-app-loading 1.6s infinite linear;
  animation: react-returns-app-loading 1.6s infinite linear;
  transform-origin: 50% 50%;
}

.react-returns-app .react-returns-app__cont {
  margin: 0 10px;
}

.react-returns-app .react-returns-app__main {
  margin: 0;
}

.react-returns-app p {
  margin: 0 0 0.7em;
  line-height: 1.5;
}

@-webkit-keyframes react-returns-app-loading {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes react-returns-app-loading {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.r-returns-page-confirmation {
  display: -ms-grid;
  display: grid;
  -moz-column-gap: 30px;
  column-gap: 30px;
  -ms-grid-columns: 1fr 30px 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto auto auto;
  grid-template-rows: auto auto auto auto;
  grid-template-areas: "con-status-message con-status-message" "con-instructions con-details" "con-instructions con-action" "con-instructions .";
}

@media screen and (max-width: 767px) {
  .body-class--mobile .r-returns-page-confirmation {
    -moz-column-gap: 30px;
    column-gap: 30px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
    grid-template-areas: "con-status-message" "con-details" "con-instructions" "con-action";
  }
}

.r-returns-page-confirmation__status-message {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-area: con-status-message;
  padding: 30px 0;
  padding: 60px 0;
}

.r-returns-page-confirmation__instructions {
  -ms-grid-row: 2;
  -ms-grid-row-span: 3;
  -ms-grid-column: 1;
  grid-area: con-instructions;
}

.r-returns-page-confirmation__details {
  -ms-grid-row: 2;
  -ms-grid-column: 3;
  grid-area: con-details;
}

.r-returns-page-confirmation__action {
  -ms-grid-row: 3;
  -ms-grid-column: 3;
  grid-area: con-action;
}

@media screen and (max-width: 767px) {
  .body-class--mobile .r-returns-page-confirmation>.r-returns-page-confirmation__status-message {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }

  .body-class--mobile .r-returns-page-confirmation>.r-returns-page-confirmation__instructions {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
  }

  .body-class--mobile .r-returns-page-confirmation>.r-returns-page-confirmation__details {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
  }

  .body-class--mobile .r-returns-page-confirmation>.r-returns-page-confirmation__action {
    -ms-grid-row: 4;
    -ms-grid-column: 1;
  }
}

.alert {
  display: block;
  width: 100%;
  min-height: 25px;
  margin: 0 0 12px;
  background: #f6f6f6;
  border-radius: 3px;
  color: #83888b;
  font-size: 1.25em;
  line-height: 1.25em;
  border-bottom: 3px solid #e6e6e6;
}

.alert .alert-message {
  padding: 12px 10px 10px;
  height: 100%;
}

.alert.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.alert.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.alert.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.r-returns-page-find-my-order {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 30px 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
  -moz-column-gap: 30px;
  column-gap: 30px;
  grid-template-areas: "fmo-alert fmo-alert" "fmo-header fmo-help" "fmo-form fmo-help" "fmo-form .";
}

@media screen and (max-width: 767px) {
  .body-class--mobile .r-returns-page-find-my-order {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) {
  .body-class--mobile .r-returns-page-find-my-order {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto 0 auto;
    grid-template-rows: auto auto;
    gap: 0;
    grid-template-areas: "fmo-alert" "fmo-header" "fmo-form" "fmo-help";
  }
}

.r-returns-page-find-my-order__alert {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-area: fmo-alert;
  padding: 20px;
  margin-bottom: 20px;
}

.r-returns-page-find-my-order__alert h3 {
  text-transform: uppercase;
}

.r-returns-page-find-my-order__alert p {
  margin-bottom: 0;
}

.r-returns-page-find-my-order__header {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: fmo-header;
  margin-bottom: 20px;
}

.r-returns-page-find-my-order__header p:last-child {
  margin-bottom: 0;
}

.r-returns-page-find-my-order__form {
  -ms-grid-row: 3;
  -ms-grid-row-span: 2;
  -ms-grid-column: 1;
  grid-area: fmo-form;
}

@media screen and (max-width: 767px) {
  .r-returns-page-find-my-order__form {
    margin-bottom: 20px;
  }
}

.r-returns-page-find-my-order__help {
  -ms-grid-row: 2;
  -ms-grid-row-span: 2;
  -ms-grid-column: 3;
  grid-area: fmo-help;
}

@media screen and (max-width: 767px) {
  .body-class--mobile .r-returns-page-find-my-order>.r-returns-page-find-my-order__alert {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }

  .body-class--mobile .r-returns-page-find-my-order>.r-returns-page-find-my-order__header {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
  }

  .body-class--mobile .r-returns-page-find-my-order>.r-returns-page-find-my-order__form {
    -ms-grid-row: 5;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
  }

  .body-class--mobile .r-returns-page-find-my-order>.r-returns-page-find-my-order__help {
    -ms-grid-row: 7;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
  }
}

.r-returns-page-find-my-order__order-number-input input {
  text-transform: uppercase;
}

.r-returns-page-find-my-order__order-number-input input::-webkit-input-placeholder {
  text-transform: none;
}

.r-returns-page-find-my-order__order-number-input input::-moz-placeholder {
  text-transform: none;
}

.r-returns-page-find-my-order__order-number-input input:-ms-input-placeholder {
  text-transform: none;
}

.r-returns-page-find-my-order__order-number-input input::-ms-input-placeholder {
  text-transform: none;
}

.r-returns-page-find-my-order__order-number-input input::placeholder {
  text-transform: none;
}

.r-returns-page-error {
  display: -ms-grid;
  display: grid;
  gap: 0 30px;
  -ms-grid-columns: 1fr 30px 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto 0 auto 0 auto 0 auto;
  grid-template-rows: auto auto auto auto;
  grid-template-areas: "fmoe-error-message fmoe-error-message" "fmoe-order-data    fmoe-info" "fmoe-action        fmoe-info" ".                  fmoe-info";
}

@media screen and (max-width: 767px) {
  .body-class--mobile .r-returns-page-error {
    gap: 0;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto 0 auto 0 auto;
    grid-template-rows: auto auto auto;
    grid-template-areas: "fmoe-error-message" "fmoe-order-data" "fmoe-action";
  }
}

.r-returns-page-error__error {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-area: fmoe-error-message;
  padding-bottom: 30px;
}

.r-returns-page-error__error .r-returns-title {
  font-size: 1.6rem;
}

.r-returns-page-error__order-data-wrapper {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: fmoe-order-data;
}

.r-returns-page-error__order-data {
  margin-bottom: 10px;
}

.r-returns-page-error__order-data:last-child {
  margin-bottom: 0;
}

.r-returns-page-error__order-data input[disabled] {
  font-weight: bold;
}

.r-returns-page-error__order-data input[disabled]#orderReference {
  font-size: 1.8rem;
}

.r-returns-page-error__order-data input[disabled]#emailAddress {
  font-size: 1.4rem;
}

.r-returns-page-error__order-detail {
  margin-top: 20px;
}

.r-returns-page-error__info {
  -ms-grid-row: 3;
  -ms-grid-row-span: 5;
  -ms-grid-column: 3;
  display: none;
  grid-area: fmoe-info;
  display: block;
  margin-top: 18px;
}

.r-returns-page-error__action-message {
  margin-bottom: 20px;
}

.r-returns-page-error__action {
  -ms-grid-row: 5;
  -ms-grid-column: 1;
  grid-area: fmoe-action;
}

@media screen and (max-width: 767px) {
  .body-class--mobile .r-returns-page-error>.r-returns-page-error__error {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }

  .body-class--mobile .r-returns-page-error>.r-returns-page-error__order-data-wrapper {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
  }

  .body-class--mobile .r-returns-page-error>.r-returns-page-error__action {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
  }
}

.r-returns-page-select-products {
  display: -ms-grid;
  display: grid;
  gap: 20px 30px;
  -ms-grid-columns: 1fr 30px 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto 20px auto 20px auto;
  grid-template-rows: auto auto auto;
  grid-template-areas: "sp-product-list sp-order-data" "sp-product-list sp-actions" "sp-product-list .";
}

@media screen and (max-width: 767px) {
  .body-class--mobile .r-returns-page-select-products {
    gap: 0;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto 0 auto 0 auto;
    grid-template-rows: auto auto auto;
    grid-template-areas: "sp-order-data" "sp-product-list" "sp-actions";
  }
}

.r-returns-page-select-products__order-detail {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  grid-area: sp-order-data;
}

.r-returns-page-select-products__product-list {
  -ms-grid-row: 1;
  -ms-grid-row-span: 5;
  -ms-grid-column: 1;
  grid-area: sp-product-list;
}

.r-returns-page-select-products__product-list .r-returns-page-select-products__action {
  display: none;
  display: block;
}

.r-returns-page-select-products__product-list .title {
  font-size: 2rem;
  margin: 0 0 1em;
  line-height: 1.2;
}

.r-returns-page-select-products__product {
  height: 100px;
  background-color: #edfaff;
  margin-bottom: 10px;
}

.r-returns-page-select-products__product:nth-last-child(2) {
  margin-bottom: 0;
  margin-bottom: 20px;
}

.r-returns-page-select-products__actions {
  -ms-grid-row: 3;
  -ms-grid-column: 3;
  grid-area: sp-actions;
}

@media screen and (max-width: 767px) {
  .body-class--mobile .r-returns-page-select-products>.r-returns-page-select-products__order-detail {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }

  .body-class--mobile .r-returns-page-select-products>.r-returns-page-select-products__product-list {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
  }

  .body-class--mobile .r-returns-page-select-products>.r-returns-page-select-products__actions {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
  }
}

.r-returns-page-select-products__action {
  margin-bottom: 10px;
  margin-top: 20px;
}

.r-returns-page-select-products__actions .r-returns-page-select-products__action {
  margin-top: 0;
}

.r-returns-collection-option-label {
  display: flex;
  justify-content: space-between;
}

.r-returns-collection-option-label__price {
  text-align: right;
  min-width: 80px;
}

.r-returns-collection-option-actions .r-returns-form-fieldset {
  margin-bottom: 10px;
}

.r-returns-page-shipping-options {
  display: -ms-grid;
  display: grid;
  gap: 0 30px;
  -ms-grid-columns: 1fr 30px 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto 0 auto 0 auto 0 auto 0 auto;
  grid-template-rows: repeat(5, auto);
  grid-template-areas: "so-header so-header" "so-description so-description" "so-option-dol so-help-dol" "so-option-cfa so-help-cfa" "so-option-siy so-help-siy" "so-option-crr so-help-crr";
}

@media screen and (max-width: 767px) {
  .body-class--mobile .r-returns-page-shipping-options {
    gap: 0;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto 0 auto 0 auto 0 auto 0 auto 0 auto;
    grid-template-rows: repeat(6, auto);
    grid-template-areas: "so-header" "so-option-dol" "so-option-cfa" "so-option-siy" "so-option-crr" "so-description";
  }

  .body-class--mobile .r-returns-page-shipping-options [class^="r-returns-page-shipping-options__help"] {
    display: none;
  }
}

.r-returns-page-shipping-options__header {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-area: so-header;
}

.r-returns-page-shipping-options__header .title {
  font-size: 2rem;
  margin: 0 0 1em;
  line-height: 1.2;
}

.r-returns-page-shipping-options__description {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-area: so-description;
}

.r-returns-page-shipping-options__description>p:last-child {
  margin-bottom: 20px;
}

.r-returns-page-shipping-options__option-dol {
  -ms-grid-row: 5;
  -ms-grid-column: 1;
  grid-area: so-option-dol;
}

.r-returns-page-shipping-options__help-dol {
  -ms-grid-row: 5;
  -ms-grid-column: 3;
  grid-area: so-help-dol;
}

.r-returns-page-shipping-options__option-cfa {
  -ms-grid-row: 7;
  -ms-grid-column: 1;
  grid-area: so-option-cfa;
}

.r-returns-page-shipping-options__help-cfa {
  -ms-grid-row: 7;
  -ms-grid-column: 3;
  grid-area: so-help-cfa;
}

.r-returns-page-shipping-options__option-siy {
  -ms-grid-row: 9;
  -ms-grid-column: 1;
  grid-area: so-option-siy;
}

.r-returns-page-shipping-options__help-siy {
  -ms-grid-row: 9;
  -ms-grid-column: 3;
  grid-area: so-help-siy;
  position: relative;
}

.r-returns-page-shipping-options__option-crr {
  -ms-grid-row: 11;
  -ms-grid-column: 1;
  grid-area: so-option-crr;
}

.r-returns-page-shipping-options__help-crr {
  -ms-grid-row: 11;
  -ms-grid-column: 3;
  grid-area: so-help-crr;
}

@media screen and (max-width: 767px) {
  .body-class--mobile .r-returns-page-shipping-options>.r-returns-page-shipping-options__header {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }

  .body-class--mobile .r-returns-page-shipping-options>.r-returns-page-shipping-options__description {
    -ms-grid-row: 11;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }

  .body-class--mobile .r-returns-page-shipping-options>.r-returns-page-shipping-options__option-dol {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
  }

  .body-class--mobile .r-returns-page-shipping-options>.r-returns-page-shipping-options__option-cfa {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
  }

  .body-class--mobile .r-returns-page-shipping-options>.r-returns-page-shipping-options__option-siy {
    -ms-grid-row: 7;
    -ms-grid-column: 1;
  }

  .body-class--mobile .r-returns-page-shipping-options>.r-returns-page-shipping-options__option-crr {
    -ms-grid-row: 9;
    -ms-grid-column: 1;
  }
}

.r-returns-page-shipping-options .r-returns-shipping-card,
.r-returns-page-shipping-options .r-returns-shipping-card--collection {
  margin-bottom: 10px;
}

.r-returns-shipping-card,
.r-returns-shipping-card--collection {
  border: 1px solid #babbbc;
  border-radius: 3px;
  margin-bottom: 10px;
}

.r-returns-shipping-card .r-g4m-accordion,
.r-returns-shipping-card--collection .r-g4m-accordion {
  display: none;
}

@media screen and (max-width: 767px) {

  .body-class--mobile .r-returns-shipping-card .r-g4m-accordion,
  .body-class--mobile .r-returns-shipping-card--collection .r-g4m-accordion {
    display: block;
  }
}

.r-returns-shipping-card .r-g4m-accordion:last-child,
.r-returns-shipping-card--collection .r-g4m-accordion:last-child {
  margin-bottom: 0;
}

.r-returns-shipping-card__header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  font-size: 2rem;
  line-height: 1.2;
}

.r-returns-shipping-card__header h3 {
  margin: 0;
}

.r-returns-shipping-card__header strong {
  margin-left: 30px;
}

.r-returns-shipping-card__content {
  padding: 0 20px;
  margin-bottom: 10px;
}

.r-returns-shipping-card__content p:last-of-type {
  margin-bottom: 0;
}

.r-returns-shipping-card__address {
  padding: 0 20px 20px;
}

.r-returns-shipping-card__actions {
  padding: 0 20px 20px;
}

.r-returns-shipping-card__actions>* {
  margin-top: 10px;
}

.r-returns-shipping-card__actions>*:first-child {
  margin-top: 0;
}

.r-returns-shipping-card--collection .r-returns-shipping-card__actions {
  padding: 0;
}

.r-returns-shipping-card--collection .r-g4m-radio-option--selected:last-child {
  border-radius: 0 0 3px 3px;
}

.r-returns-page-summary {
  display: -ms-grid;
  display: grid;
  gap: 20px 30px;
  -ms-grid-columns: 1fr 30px 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto 20px auto 20px auto 20px auto 20px auto;
  grid-template-areas: "sum-title sum-title" "sum-order-data sum-actions" "sum-returns ." "sum-method ." "sum-action .";
}

@media screen and (max-width: 767px) {
  .body-class--mobile .r-returns-page-summary {
    gap: 20px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto 20px auto 20px auto 20px auto 20px auto;
    grid-template-areas: "sum-title" "sum-order-data" "sum-returns" "sum-method" "sum-actions";
  }
}

.r-returns-page-summary__title {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-area: sum-title;
  font-size: 3rem;
  line-height: 1.2;
  margin: 0;
}

.r-returns-page-summary__order-detail {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: sum-order-data;
}

.r-returns-page-summary__returns {
  -ms-grid-row: 5;
  -ms-grid-column: 1;
  grid-area: sum-returns;
}

.r-returns-page-summary__method {
  -ms-grid-row: 7;
  -ms-grid-column: 1;
  grid-area: sum-method;
}

.r-returns-page-summary__method>h3 {
  font-size: 2rem;
  line-height: 23px;
  margin-top: 0;
  margin-bottom: 10px;
}

.r-returns-page-summary__method>h4 {
  font-weight: normal;
  margin-bottom: 10px;
}

.r-returns-page-summary__actions {
  -ms-grid-row: 3;
  -ms-grid-column: 3;
  grid-area: sum-actions;
  color: #434649;
  font-size: 1.6rem;
}

.r-returns-page-summary__actions .r-returns-page-summary__proceed {
  margin-bottom: 20px;
}

.r-returns-page-summary__actions a {
  text-decoration: underline;
}

.r-returns-page-summary__action {
  -ms-grid-row: 9;
  -ms-grid-column: 1;
  grid-area: sum-action;
}

@media screen and (max-width: 767px) {
  .body-class--mobile .r-returns-page-summary>.r-returns-page-summary__title {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }

  .body-class--mobile .r-returns-page-summary>.r-returns-page-summary__order-detail {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
  }

  .body-class--mobile .r-returns-page-summary>.r-returns-page-summary__returns {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
  }

  .body-class--mobile .r-returns-page-summary>.r-returns-page-summary__method {
    -ms-grid-row: 7;
    -ms-grid-column: 1;
  }

  .body-class--mobile .r-returns-page-summary>.r-returns-page-summary__actions {
    -ms-grid-row: 9;
    -ms-grid-column: 1;
  }
}

@media screen and (max-width: 767px) {
  .r-returns-page-summary__action {
    display: none;
  }
}

.r-returns-page-summary__subtitle {
  font-size: 2.4rem;
  margin: 0 0 10px;
  line-height: 1.2;
}

.r-returns-page-summary__button-edit {
  font-size: medium;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  display: inline-block;
  min-width: auto;
  padding: 1px 7px 2px 1px;
  border: 1px solid #bababa;
  border-radius: 0;
  margin: 0;
  background-color: silver;
  vertical-align: baseline;
  cursor: default;
  padding: 0;
  border: 0;
  color: #168dbf;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.2;
  display: inline-block;
  margin-bottom: 10px;
  background-color: transparent;
}

.r-returns-page-summary__button-edit:focus,
.r-returns-page-summary__button-edit:hover {
  background-color: #9c9c9c;
  cursor: inherit;
}

.r-returns-page-summary__button-edit:focus,
.r-returns-page-summary__button-edit:hover {
  background-color: transparent;
  outline: none;
  text-decoration: underline;
  cursor: pointer;
}

.r-returns-page-summary__button-edit-icon {
  display: inline-block;
  width: 1.2em;
  height: 1.2em;
  background-image: url("/dist/images/svgs/icon-edit-blue.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  margin-left: 0.5em;
  vertical-align: bottom;
}

.r-returns-page-summary__address {
  font-size: 1.6rem;
  margin-bottom: 10px;
}

.r-returns-page-summary__date {
  display: flex;
  font-size: 1.6rem;
  justify-content: space-between;
  margin-bottom: 10px;
}

.r-g4m-address {
  display: flex;
  flex-direction: column;
  font-style: normal;
  margin: 0 0 10px;
}

.r-returns-address-select__tabs {
  display: flex;
  margin-bottom: 20px;
}

.r-returns-address-select__tabs>button {
  flex-grow: 1;
  margin-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  min-height: 32px;
  width: 100%;
  border: 1px solid transparent;
}

.r-returns-address-select__tabs>button:hover {
  border-color: #edfaff;
}

.r-returns-address-select__tabs>button.active {
  border-color: #168dbf;
  width: calc(100% - 2px);
}

.r-returns-address-select__tabs>button.active:hover {
  background: transparent;
}

.r-returns-address-select__tabs:last-child {
  margin-right: 0;
}

.r-returns-address-select .r-g4m-address {
  margin-bottom: 0;
}

.r-returns-address-select .r-g4m-address__title {
  margin: 0 0 8px;
}

.r-ext-returns-product-card__return-more {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
}

.r-returns-form-fieldset .r-returns-form__label {
  display: block;
  font-weight: 700;
  font-size: 1.4rem;
  color: #63656a;
  line-height: 1.2;
  margin: 0;
  margin-bottom: 4px;
}

.r-returns-form-fieldset .r-returns-form__textarea {
  width: 100%;
  height: 110px;
  padding: 15px;
  border: 1px solid #babbbc;
  border-radius: 3px;
  font-size: 1.6rem;
  box-shadow: 0 0 0 transparent;
  transition: box-shadow 0.2s;
}

.r-returns-form-fieldset .r-returns-form__textarea:hover {
  border-color: #83888b;
}

.r-returns-form-fieldset .r-returns-form__textarea:focus {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.3);
  outline: none;
}

.r-returns-form-fieldset .r-returns-form__textarea::-webkit-input-placeholder {
  color: #babbbc;
}

.r-returns-form-fieldset .r-returns-form__textarea::-moz-placeholder {
  color: #babbbc;
}

.r-returns-form-fieldset .r-returns-form__textarea:-ms-input-placeholder {
  color: #babbbc;
}

.r-returns-form-fieldset .r-returns-form__textarea::-ms-input-placeholder {
  color: #babbbc;
}

.r-returns-form-fieldset .r-returns-form__textarea::placeholder {
  color: #babbbc;
}

.r-returns-product-header {
  display: -ms-grid;
  display: grid;
  gap: 0 10px;
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr;
  -ms-grid-columns: auto 10px 1fr;
  grid-template-columns: auto 1fr;
  grid-template-areas: "ph-image ph-title";
}

.r-returns-product-header__image {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: ph-image;
  width: 60px;
  height: 60px;
}

.r-returns-product-header__title-container {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  grid-area: ph-title;
}

.r-returns-product-header__title {
  font-size: 1.6rem;
  margin: 0 0 10px;
  line-height: 1.2;
}

.r-returns-product-header__title:last-child {
  margin-bottom: 0;
}

.r-returns-product-form__row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 15px;
}

.r-returns-product-form__row:last-child {
  margin-bottom: 0;
}

.r-returns-product-form__row [class*="r-g4m-info-box--"] {
  margin-top: 15px;
}

.r-returns-product-form .r-returns-product-form__label {
  font-weight: bold;
  margin-bottom: 4px;
  display: inline-block;
}

.r-returns-product-form__quantity-drop-down .r-g4m-dropdown-native--selected,
.r-returns-product-form__quantity-drop-down .g4m-button-select.r-g4m-dropdown {
  display: block;
  width: 50%;
  min-width: 100px;
  max-width: 160px;
}

.r-returns-product-form__err-msg {
  display: block;
  color: #d00000;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.15;
  visibility: hidden;
  overflow: hidden;
  height: 0;
  min-height: 0;
  transition: min-height 0.2s ease;
}

.r-returns-form-fieldset--is-invalid .r-returns-product-form__err-msg,
.g4m-button--is-invalid .r-returns-product-form__err-msg {
  visibility: visible;
  min-height: 14px;
  margin-top: 5px;
}

.r-returns-product-form__row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 15px;
}

.r-returns-product-form__row:last-child {
  margin-bottom: 0;
}

.r-returns-product-form__row [class*="r-g4m-info-box--"] {
  margin-top: 15px;
}

.r-returns-product-form .r-returns-product-form__label {
  font-weight: bold;
  margin-bottom: 4px;
  display: inline-block;
}

.r-returns-product-form__quantity-drop-down .r-g4m-dropdown-native--selected,
.r-returns-product-form__quantity-drop-down .g4m-button-select.r-g4m-dropdown {
  display: block;
  width: 50%;
  min-width: 100px;
  max-width: 160px;
}

.r-returns-product-form__err-msg {
  display: block;
  color: #d00000;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.15;
  visibility: hidden;
  overflow: hidden;
  height: 0;
  min-height: 0;
  transition: min-height 0.2s ease;
}

.r-returns-form-fieldset--is-invalid .r-returns-product-form__err-msg,
.g4m-button--is-invalid .r-returns-product-form__err-msg {
  visibility: visible;
  min-height: 14px;
  margin-top: 5px;
}

.r-returns-product-card,
.r-returns-product-card--summary,
.r-returns-product-card--disabled {
  display: -ms-grid;
  display: grid;
  gap: 0 10px;
  -ms-grid-columns: minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  -ms-grid-rows: auto 0 auto 0 auto;
  grid-template-rows: auto auto auto;
  grid-template-areas: "rpc-header" "rpc-content" "rpc-actions";
  font-size: 1.4rem;
  padding: 20px;
  border: 1px solid #babbbc;
  border-radius: 3px;
  margin-bottom: 10px;
  background-color: #fff;
}

.r-returns-product-card--summary {
  gap: 0 10px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto 0 auto;
  grid-template-rows: auto auto;
  grid-template-areas: "rpc-header" "rpc-content";
}

.r-returns-product-card--disabled {
  gap: 0 10px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto 0 auto;
  grid-template-rows: auto auto;
  grid-template-areas: "rpc-header" "rpc-content";
}

.r-returns-product-card--disabled .r-returns-product-header__image {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.5;
}

.r-returns-product-card__actions {
  -ms-grid-row: 5;
  -ms-grid-column: 1;
  grid-area: rpc-actions;
}

.r-returns-product-card__header {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: rpc-header;
}

.r-returns-product-card__content {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: rpc-content;
  margin-top: 20px;
}

.r-returns-product-card__quantity-message-available {
  font-weight: bold;
}

.r-returns-product-card__return .r-returns-product-form {
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid #ededed;
}

.r-returns-product-card__return:last-child .r-returns-product-form {
  padding-bottom: 0;
}

.r-returns-product-card__hr {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / 3;
  height: 1px;
  border-top: 1px solid #babbbc;
  width: 100%;
}

.r-returns-product-card__product-select-form {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / 3;
  max-width: 100%;
}

.r-returns-product-card__divider {
  border: 0;
  height: 1px;
  background-color: #ededed;
  margin: 10px 0;
}

.r-returns-product-card__divider:first-child {
  margin-top: 0;
}

.r-returns-product-card__divider:last-child {
  margin-bottom: 0;
}

.r-returns-product-card__label {
  line-height: 1.2;
  margin-bottom: 4px;
}

.r-returns-product-card__label:first-child {
  margin-top: 0;
}

.r-returns-product-card__value {
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 10px;
}

.r-returns-product-card__value:last-child {
  margin-bottom: 0;
}

.g4m-grid-product-listing {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.g4m-grid-product-listing .g4m-grid-item {
  box-sizing: border-box;
  height: 370px;
}

@media screen and (max-width: 1023px) {
  .g4m-grid-product-listing .g4m-grid-item {
    width: calc(33.32333% - 10px);
    margin-bottom: 15px;
  }

  .g4m-grid-product-listing .g4m-grid-item:not(:nth-child(3n)) {
    margin-right: 15px;
  }

  .g4m-grid-product-listing .g4m-grid-item:nth-last-child(-n + 3):nth-child(3n + 1),
  .g4m-grid-product-listing .g4m-grid-item:nth-last-child(-n + 3):nth-child(3n + 1)~* {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1024px) {
  .g4m-grid-product-listing .g4m-grid-item {
    width: calc(24.99% - 15px);
    margin-bottom: 20px;
  }

  .g4m-grid-product-listing .g4m-grid-item:not(:nth-child(4n)) {
    margin-right: 20px;
  }

  .g4m-grid-product-listing .g4m-grid-item:nth-last-child(-n + 4):nth-child(4n + 1),
  .g4m-grid-product-listing .g4m-grid-item:nth-last-child(-n + 4):nth-child(4n + 1)~* {
    margin-bottom: 0;
  }
}

.react-product-listing-widget {
  margin-bottom: 20px;
}

.react-product-listing-widget__filters {
  margin-bottom: 20px;
}

.react-product-listing-widget__message,
.react-product-listing-widget__message--listing-count,
.react-product-listing-widget__message--listing-title {
  margin: 0 0 20px;
  font-size: 1.4rem;
}

.react-product-listing-widget__message--listing-count {
  text-align: center;
}

.react-product-listing-widget__message--listing-title {
  text-align: center;
  font-weight: bold;
  font-size: 1.3rem;
  font-size: 1.5rem;
}

.react-product-listing-widget__button-set {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 20px 0;
  margin: 40px 0;
}

.react-product-listing-widget__product-list {
  margin-bottom: 20px;
}

.button-set--previous {
  margin: 0 0 20px;
}

.button-set--next {
  margin: 20px 0 0;
}

.font-weight-bold,
.style-alt .panel .panel-body.panel-body-details>.row>dl>dt {
  font-weight: bold;
}

.category-description .box-title {
  font-size: 2.2rem;
  margin: 0 0 20px;
}

.category-description .box-description,
.category-description .box-description p {
  line-height: 1.5;
}

.button-text {
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  font-weight: normal;
  font-size: inherit;
  vertical-align: top;
  min-width: 0;
  line-height: inherit;
  display: block;
  color: #babbbc;
}

.button-text:after {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-top: 2px solid #babbbc;
  border-right: 2px solid #babbbc;
  margin-right: 10px;
}

.button-text.button-read-more:after {
  transform: rotate(135deg) translate(-6px, -3px);
}

.button-text.button-read-less:after {
  transform: rotate(-45deg) translate(3px, 6px);
}

.button-text:hover {
  background-color: transparent;
  color: #b0b1b2;
}

.description-image,
.description-image--figure {
  max-width: 100%;
}

.description-image--figure {
  display: inline-block;
  background-color: #f6f6f6;
  padding: 20px;
}

.description-layout--full .description-image--figure {
  width: 100%;
}

.description-image__image {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.description-image__caption {
  text-align: left;
  display: block;
  color: #63656a;
  margin: 1em 0 0;
}

.description-image__caption p {
  margin: 0 0 0.6em;
}

.description-image__caption p:last-child {
  margin-bottom: 0;
}

.specification-image {
  display: block;
  max-width: 100%;
  margin: 0 auto 20px;
}

.description-block {
  display: flex;
  flex-direction: column;
}

.body-class--desktop .description-grid--stacked .description-block {
  flex-direction: row;
}

@media screen and (min-width: 1024px) {

  .body-class--desktop .description-grid--2-col .description-block,
  .body-class--mobile .description-grid--2-col .description-block {
    flex-direction: row;
  }
}

@media screen and (min-width: 600px) {
  .body-class--mobile .description-grid--stacked .description-block {
    flex-direction: row;
  }
}

@media screen and (min-width: 1330px) {
  .body-class--mobile .description-grid--3-col .description-block {
    flex-direction: row;
  }
}

.description-block__image {
  height: 8em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.description-block__image .thumbnail {
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}

.body-class--desktop .description-grid--stacked .description-block__image {
  min-width: 12em;
  padding-right: 1em;
  padding-top: 0.4em;
}

@media screen and (min-width: 600px) {
  .body-class--mobile .description-grid--stacked .description-block__image {
    min-width: 12em;
    padding-right: 1em;
    padding-top: 0.4em;
  }
}

@media screen and (min-width: 1024px) {

  .body-class--desktop .description-grid--2-col .description-block__image,
  .body-class--mobile .description-grid--2-col .description-block__image {
    min-width: 12em;
    padding-right: 1em;
    padding-top: 0.4em;
  }
}

@media screen and (min-width: 1330px) {
  .body-class--mobile .description-grid--3-col .description-block__image {
    min-width: 12em;
    padding-right: 1em;
    padding-top: 0.4em;
  }
}

.description-block__text h3,
.single-column-responsive-layout .pdp-page .description-block__text h3 {
  font-size: 2rem;
  margin-bottom: 0.5em;
}

.description-block__text p:last-child {
  margin-bottom: 0;
}

.description-grid--3-col,
.description-grid--2-col,
.description-grid--stacked {
  display: flex;
  flex-wrap: wrap;
  clear: both;
}

@media screen and (min-width: 1024px) {
  .description-grid--3-col .description-grid__item {
    width: calc(33.32333% - 20px);
    margin-bottom: 30px;
  }

  .description-grid--3-col .description-grid__item:not(:nth-child(3n)) {
    margin-right: 30px;
  }

  .description-grid--3-col .description-grid__item:nth-last-child(-n + 3):nth-child(3n + 1),
  .description-grid--3-col .description-grid__item:nth-last-child(-n + 3):nth-child(3n + 1)~* {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1023px) {
  .body-class--desktop .description-grid--3-col .description-grid__item {
    width: calc(49.99% - 15px);
    margin-bottom: 30px;
  }

  .body-class--desktop .description-grid--3-col .description-grid__item:not(:nth-child(2n)) {
    margin-right: 30px;
  }

  .body-class--desktop .description-grid--3-col .description-grid__item:nth-last-child(-n + 2):nth-child(2n + 1),
  .body-class--desktop .description-grid--3-col .description-grid__item:nth-last-child(-n + 2):nth-child(2n + 1)~* {
    margin-bottom: 0;
  }
}

.body-class--desktop .description-grid--2-col .description-grid__item {
  width: calc(49.99% - 15px);
  margin-bottom: 30px;
}

.body-class--desktop .description-grid--2-col .description-grid__item:not(:nth-child(2n)) {
  margin-right: 30px;
}

.body-class--desktop .description-grid--2-col .description-grid__item:nth-last-child(-n + 2):nth-child(2n + 1),
.body-class--desktop .description-grid--2-col .description-grid__item:nth-last-child(-n + 2):nth-child(2n + 1)~* {
  margin-bottom: 0;
}

@media screen and (max-width: 600px) {
  .body-class--mobile .description-grid__item {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 600px) and (max-width: 1023px) {
  .body-class--mobile .description-grid--3-col .description-grid__item {
    width: calc(49.99% - 15px);
    margin-bottom: 30px;
  }

  .body-class--mobile .description-grid--3-col .description-grid__item:not(:nth-child(2n)) {
    margin-right: 30px;
  }

  .body-class--mobile .description-grid--3-col .description-grid__item:nth-last-child(-n + 2):nth-child(2n + 1),
  .body-class--mobile .description-grid--3-col .description-grid__item:nth-last-child(-n + 2):nth-child(2n + 1)~* {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 600px) {
  .body-class--mobile .description-grid--2-col .description-grid__item {
    width: calc(49.99% - 15px);
    margin-bottom: 30px;
  }

  .body-class--mobile .description-grid--2-col .description-grid__item:not(:nth-child(2n)) {
    margin-right: 30px;
  }

  .body-class--mobile .description-grid--2-col .description-grid__item:nth-last-child(-n + 2):nth-child(2n + 1),
  .body-class--mobile .description-grid--2-col .description-grid__item:nth-last-child(-n + 2):nth-child(2n + 1)~* {
    margin-bottom: 0;
  }
}

.body-class--desktop [class^="description-grid--"]+[class^="description-grid--"],
.body-class--desktop [class^="description-grid--"]+*:not([class^="description-grid--"]) {
  margin-top: 30px;
}

@media screen and (min-width: 600px) {

  .body-class--mobile [class^="description-grid--"]+[class^="description-grid--"],
  .body-class--mobile [class^="description-grid--"]+*:not([class^="description-grid--"]) {
    margin-top: 30px;
  }
}

.description-layout,
.description-layout--center,
.description-layout--full,
.description-layout--right,
.description-layout--left {
  text-align: center;
  margin-bottom: 20px;
}

.description-layout--center {
  margin: 20px 0;
}

.description-layout--full {
  margin: 20px 0;
}

@media screen and (min-width: 768px) {
  .description-layout--right {
    width: 40%;
    margin-left: 20px;
    float: right;
    clear: right;
  }
}

@media screen and (min-width: 768px) {
  .description-layout--left {
    width: 40%;
    margin-right: 20px;
    float: left;
    clear: left;
  }
}

.specification-table {
  background-color: #f6f6f6;
  border-bottom: 1px solid #dddddd;
}

.specification-table th,
.specification-table td {
  width: 50%;
}

@media screen and (max-width: 520px) {

  .body-class--mobile .specification-table th,
  .body-class--mobile .specification-table td {
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 520px) {
  .body-class--mobile .specification-table td {
    border-top: none;
    padding-top: 0;
  }
}

.specification-table-title {
  margin: 0 0 10px;
}

.awards {
  width: 100%;
  position: relative;
}

.awards .description-award,
.awards .description-award--default,
.awards .description-award--whathifi {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid;
  text-align: left;
}

.awards .description-award:last-child,
.awards .description-award--default:last-child,
.awards .description-award--whathifi:last-child {
  margin-bottom: 0;
}

.awards .description-award--default {
  border-color: #7b8073;
}

.awards .description-award--whathifi {
  border-color: #dd1e2f;
}

.awards .description-award--whathifi .award-title__text {
  color: #dd1e2f;
}

.awards .description-award p:last-child,
.awards .description-award--default p:last-child,
.awards .description-award--whathifi p:last-child {
  margin-bottom: 0;
}

.awards .award-title {
  overflow: hidden;
}

.single-column-responsive-layout .pdp-page .full-description .awards .award-title {
  margin-bottom: 1em;
}

.awards .award-title__logo {
  display: block;
  width: 50%;
  float: left;
  height: 40px;
}

.awards .award-title__logo img {
  display: block;
  max-width: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.awards .award-title__text {
  display: block;
  width: 50%;
  float: right;
  padding-left: 5px;
  text-align: right;
}

.awards .award-title__text img {
  display: inline-block;
  max-width: 100%;
}

.award-title__logo+.awards .award-title__text {
  width: 40%;
  float: right;
  text-align: right;
  font-weight: 400;
}

.award-thumbnail+.award-title .awards .award-title__text {
  float: left;
  font-weight: 800;
}

.awards .award-thumbnail {
  float: right;
  width: 70px;
  margin: 0 0 1em 1em;
}

.awards {
  float: right;
  width: 50%;
  max-width: 410px;
  margin-left: 25px;
  margin-bottom: 10px;
}

.awards .award .award-title {
  font-size: 1.1em;
}

.awards .award .award-title img {
  max-width: 50%;
}

.awards .award .award-title .award-title-right {
  max-width: 40%;
}

#awards-accordion .awards {
  float: none;
  width: 100%;
  max-width: 100%;
  margin: 0;
}

#awards-accordion .award-title {
  font-size: 1.1em;
  margin-bottom: 1em;
}

.description-note {
  display: flex;
  padding: 20px;
  background-color: #f6f6f6;
  color: #63656a;
}

.description-note__image {
  padding-right: 1em;
}

.description-note__image .icon,
.description-note__image .style-alt table.highlighted-list tbody>tr.selected td:last-of-type:before,
.style-alt table.highlighted-list tbody>tr.selected .description-note__image td:last-of-type:before,
.description-note__image .style-alt .js-validate>.js-validate-marker,
.style-alt .description-note__image .js-validate>.js-validate-marker {
  display: block;
  width: 5em;
  height: auto;
  max-height: 100%;
}

.description-note__text {
  text-align: left;
}

.description-note__text h3,
.single-column-responsive-layout .pdp-page .description-note__text h3 {
  font-size: 2rem;
  margin-bottom: 0.5em;
}

.description-note__text p {
  margin-bottom: 0.6em;
}

.description-note__text p:last-child {
  margin-bottom: 0;
}

.g4m-expandable-description {
  overflow: hidden;
}

.g4m-expandable-description .hidden-text {
  display: none;
}

.g4m-expandable-description .visible-text p:last-of-type {
  margin-bottom: 0;
  display: inline;
}

.g4m-expandable-description,
.g4m-expandable-description p {
  font-size: 1.4rem;
}

.g4m-expandable-description a {
  color: #1c9ace;
}

.g4m-expandable-description a:hover {
  text-decoration: underline;
}

.g4m-expandable-description .button-text {
  display: inline;
  color: #83888b;
}

.g4m-expandable-description .button-text:after {
  border-color: #636569;
}

.g4m-expandable-description .header-widget-image {
  float: right;
  margin-left: 20px;
}

section.sidebar-box {
  background-color: #fff;
  border: 1px solid #e5e5e5;
}

section.sidebar-box.contact,
section.sidebar-box#top-brands,
section.sidebar-box.mini-security,
section.sidebar-box.why-shop-here,
section.sidebar-box.mini-contact {
  padding: 0;
}

section.sidebar-box.contact>h2,
section.sidebar-box#top-brands>h2,
section.sidebar-box.mini-security>h2,
section.sidebar-box.why-shop-here>h2,
section.sidebar-box.mini-contact>h2 {
  margin: 0;
}

section.sidebar-box>h2 {
  color: #434649;
  background-color: #e5e5e5;
}

section.sidebar-box>h2 a {
  color: #434649;
  text-decoration: none;
}

section.sidebar-box address {
  text-align: center;
}

section.sidebar-box address>span,
section.sidebar-box address>span.row {
  display: block;
  padding: 10px;
  margin: 0 auto;
}

section.sidebar-box.why-shop-here ul,
section.sidebar-box.payment-methods ul,
section.sidebar-box.mini-security ul {
  padding: 0;
}

section.sidebar-box.why-shop-here ul li,
section.sidebar-box.payment-methods ul li,
section.sidebar-box.mini-security ul li {
  padding: 7px 10px;
}

section.sidebar-box.why-shop-here a {
  background: none;
}

section.sidebar-box#top-brands,
section.sidebar-box.contact {
  padding: 0;
  background-color: #fff;
}

section.sidebar-box#top-brands .brands,
section.sidebar-box.contact .brands {
  margin: 0;
}

section.sidebar-box#top-brands .btn-holder,
section.sidebar-box.contact .btn-holder {
  padding: 10px;
}

section.sidebar-box.contact .social-networks {
  padding: 7px 10px;
}

.content h1.section-title,
h1.section-title,
.content h2.section-title,
h2.section-title,
.content h3.section-title,
h3.section-title {
  font-weight: bold;
}

.content h1.section-title>a,
h1.section-title>a,
.content h2.section-title>a,
h2.section-title>a,
.content h3.section-title>a,
h3.section-title>a {
  font-weight: bold;
}

h1.section-title,
h2.section-title,
h3.section-title {
  background: none;
  color: #434649;
}

h1.section-title>a,
h2.section-title>a,
h3.section-title>a {
  color: #434649;
}

.results-block .toolbar {
  background-color: #e5e5e5;
}

.products-list .prd-prc {
  color: #d00000;
}

.price.no-rrp,
.grand-total-container.text-secondary,
.price.text-success {
  color: #d00000 !important;
}

.gallery .switcher a:hover,
.gallery .switcher .active a {
  background-color: #1c9ace;
}

.button:not(.orange) .button:not(.grey) {
  background-color: #1c9ace;
  border-color: #1c9ace;
}

.button:not(.orange) .button:not(.grey):hover {
  background-color: #4078a4;
  border-color: #4078a4;
}

.content h1,
.content h1.block-header,
.content .h1-title {
  border-bottom: none;
}

.content .grey-div {
  background: #fff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
}

.content .grey-div .header,
.content .grey-div h3.header,
.content .grey-div .section-title {
  color: #434649;
  background: #e5e5e5;
}

.content .generic-table {
  background: #fff;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
  border-collapse: initial;
  border-radius: 3px;
}

.content .generic-table thead {
  color: #434649;
  background: #e5e5e5;
}

.content .generic-table thead th {
  background: none;
  color: inherit;
  border-color: #fff;
}

.content .generic-table tbody td {
  border: none;
}

.products-list .holder .prd-inf.third-party .prd-inf-long,
.products-list .holder .prd-inf.not-available .prd-inf-long {
  color: #434649;
  font-weight: bold;
}

.result-list .third-party,
.result-list .not-available {
  font-weight: bold;
  color: #434649;
}

.sitemap li.section-title {
  background: #e5e5e5;
}

.sitemap li.section-title,
.sitemap li.section-title a {
  color: #434649;
}

@media only screen and (min-width: 768px) {
  .body-class--mobile .mm-menu.mm-opened {
    display: block !important;
  }

  .body-class--mobile .mm-menu.mm-opened .show-mobile {
    display: block !important;
  }

  .body-class--mobile .mm-menu.mm-opened .mmenu-clearance {
    color: #b8250f !important;
  }

  .body-class--mobile .mm-menu.mm-opened .mmenu-icons {
    width: 15px;
    height: 17px;
    background: url(/dist/images/mmenu-icons_v2.png);
    display: inline-block;
    vertical-align: middle;
    margin-right: 9px;
    margin-bottom: 1px;
    background-size: 64px 17px;
  }

  .body-class--mobile .mm-menu.mm-opened .mmenu-icons.login {
    background-position: 0 0;
  }

  .body-class--mobile .mm-menu.mm-opened .mmenu-icons.news {
    background-position: 17px 0;
  }

  .body-class--mobile .mm-menu.mm-opened .mmenu-icons.logout {
    background-position: 35px 0;
  }

  .body-class--mobile .mm-menu.mm-opened .mmenu-icons.help {
    background-position: 50px 0;
  }

  .body-class--mobile .mm-menu.mm-opened .full_bottom_border:after {
    left: 0 !important;
  }

  .body-class--mobile .mm-menu.mm-opened .mm-search input {
    font-size: 16px;
  }

  .body-class--mobile #header {
    max-width: none;
  }

  .body-class--mobile #header .header-box-mobile {
    display: flex !important;
  }

  .body-class--mobile #header .search-box {
    height: auto;
    padding: 0;
    position: relative;
  }

  .body-class--mobile #page {
    max-width: none !important;
  }

  .body-class--mobile #page .content {
    margin: 0;
  }

  .body-class--mobile .hide-mobile,
  .body-class--mobile .hide-tablet {
    display: none !important;
  }

  .body-class--mobile #footer .show-mobile {
    color: #43464b !important;
    display: block !important;
  }

  .body-class--mobile aside {
    display: none;
  }
}

.g4m-select {
  position: relative;
  white-space: nowrap;
}

.g4m-select select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  border-radius: 2px;
  width: 100%;
  height: 40px;
  padding-right: 40px;
}

.g4m-select select::-ms-expand {
  display: none;
}

.g4m-select select optgroup.ios-fix {
  display: none;
}

.g4m-select:after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  position: absolute;
  right: 10px;
  top: 50%;
  border-top: 2px solid rgba(128, 128, 128, 0.4);
  border-right: 2px solid rgba(128, 128, 128, 0.4);
  transform: translateY(-50%) rotateZ(135deg);
  pointer-events: none;
}

.g4m-select select {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  font-size: 16px;
}

.g4m-select-inline {
  position: relative;
  white-space: nowrap;
  display: inline-block;
}

.g4m-select-inline select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  border-radius: 2px;
  width: 100%;
  height: 40px;
  padding-right: 40px;
}

.g4m-select-inline select::-ms-expand {
  display: none;
}

.g4m-select-inline select optgroup.ios-fix {
  display: none;
}

.g4m-select-inline:after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  position: absolute;
  right: 10px;
  top: 50%;
  border-top: 2px solid rgba(128, 128, 128, 0.4);
  border-right: 2px solid rgba(128, 128, 128, 0.4);
  transform: translateY(-50%) rotateZ(135deg);
  pointer-events: none;
}

.g4m-select-inline select {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  font-size: 16px;
  padding-right: 10px;
}

.g4m-checkbox.checkbox-inline label {
  font-weight: normal;
}

.g4m-checkbox label,
.g4m-checkbox label.g4m-checkbox-inner {
  display: block;
  padding-left: 0;
  position: relative;
}

.g4m-checkbox label:after,
.g4m-checkbox label.g4m-checkbox-inner:after {
  content: "";
  display: table;
  clear: both;
}

.g4m-checkbox label .g4m-checkbox-label,
.g4m-checkbox label.g4m-checkbox-inner .g4m-checkbox-label {
  margin-left: 30px;
}

.g4m-checkbox label input[type="checkbox"],
.g4m-checkbox label.g4m-checkbox-inner input[type="checkbox"] {
  opacity: 0;
}

.g4m-checkbox label input[type="checkbox"]+.cr>.cr-icon,
.g4m-checkbox label input[type="checkbox"]+.cr svg,
.g4m-checkbox label.g4m-checkbox-inner input[type="checkbox"]+.cr>.cr-icon,
.g4m-checkbox label.g4m-checkbox-inner input[type="checkbox"]+.cr svg {
  opacity: 0;
}

.g4m-checkbox label input[type="checkbox"]:checked+.cr>.cr-icon,
.g4m-checkbox label input[type="checkbox"]:checked+.cr svg,
.g4m-checkbox label.g4m-checkbox-inner input[type="checkbox"]:checked+.cr>.cr-icon,
.g4m-checkbox label.g4m-checkbox-inner input[type="checkbox"]:checked+.cr svg {
  opacity: 1;
}

.g4m-checkbox label input[type="checkbox"]:focus+.cr,
.g4m-checkbox label.g4m-checkbox-inner input[type="checkbox"]:focus+.cr {
  border-color: #faa21b;
}

.g4m-checkbox label input[type="checkbox"]:disabled+.cr,
.g4m-checkbox label.g4m-checkbox-inner input[type="checkbox"]:disabled+.cr {
  opacity: 0.5;
}

.g4m-checkbox .cr {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 8px;
}

.g4m-checkbox .cr svg {
  width: 12.6px;
  height: 12.6px;
}

.g4m-checkbox .cr svg {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 768px) {
  .g4m-checkbox .warranty-checkbox .cr {
    width: 20px;
    height: 20px;
  }

  .g4m-checkbox .warranty-checkbox .cr svg {
    width: 16.2px;
    height: 16.2px;
  }

  .g4m-checkbox:not(.warranty-checkbox) .cr {
    width: 20px;
    height: 20px;
  }

  .g4m-checkbox:not(.warranty-checkbox) .cr svg {
    width: 12.6px;
    height: 12.6px;
  }
}

.g4m-checkbox:not(.warranty-checkbox) label.g4m-checkbox-inner {
  display: inline-block;
}

.g4m-checkbox:not(.warranty-checkbox) label.g4m-checkbox-inner .g4m-checkbox-label {
  margin-left: 28px;
}

.g4m-checkbox:not(.warranty-checkbox) label>span {
  line-height: 20px;
  float: left;
}

.g4m-checkbox-toggle {
  height: 4em;
}

.g4m-checkbox-toggle label {
  display: block;
  box-sizing: content-box;
  position: relative;
  font-size: 25px;
  padding: 0;
  overflow: hidden;
  border: 2px transparent solid;
  border-radius: 0.6em;
  width: 1.7em;
  height: 1em;
  margin-top: 0.4em;
  background-color: #e9e9e9;
  transition: all cubic-bezier(0.18, 0.89, 0.37, 1.01) 0.3s;
}

.g4m-checkbox-toggle label.hide-label .g4m-checkbox-toggle-label {
  display: none;
}

.g4m-checkbox-toggle label::before,
.g4m-checkbox-toggle label::after {
  display: block;
  line-height: 0.9em;
  transition: transform cubic-bezier(0.18, 0.89, 0.37, 1.01) 0.3s;
}

.g4m-checkbox-toggle label::after {
  content: "";
  display: block;
  flex-basis: 100%;
  width: 0.9em;
  height: 0.9em;
  background-color: #fff;
  text-indent: 1em;
  border-radius: 0.5em;
  transform: translate(0.05em, 0.05em);
}

.g4m-checkbox-toggle input {
  position: absolute;
  left: -9000px;
  opacity: 0;
}

.g4m-checkbox-toggle input:checked+label {
  background-color: #009f51;
}

.g4m-checkbox-toggle input:checked+label::after {
  transform: translate(0.75em, 0.05em);
}

.g4m-checkbox-toggle input:checked:disabled+label {
  background-color: rgba(0, 159, 81, 0.3);
  cursor: not-allowed;
}

.g4m-grid {
  display: flex;
  flex-wrap: wrap;
}

.g4m-grid-item {
  box-sizing: border-box;
}

.page-home .g4m-grid-category-boxes {
  display: flex;
  flex-wrap: wrap;
}

.page-home .g4m-grid-category-boxes .g4m-grid-item {
  height: 261px;
}

@media screen and (max-width: 1023px) {
  .page-home .g4m-grid-category-boxes .g4m-grid-item {
    width: calc(33.32333% - 10px);
    margin-bottom: 15px;
  }

  .page-home .g4m-grid-category-boxes .g4m-grid-item:not(:nth-child(3n)) {
    margin-right: 15px;
  }

  .page-home .g4m-grid-category-boxes .g4m-grid-item:nth-last-child(-n + 3):nth-child(3n + 1),
  .page-home .g4m-grid-category-boxes .g4m-grid-item:nth-last-child(-n + 3):nth-child(3n + 1)~* {
    margin-bottom: 0;
  }

  .page-home .g4m-grid-category-boxes .g4m-grid-item:not(:nth-child(-n + 6)) {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .page-home .g4m-grid-category-boxes .g4m-grid-item {
    width: calc(33.32333% - 13.33333px);
    margin-bottom: 20px;
  }

  .page-home .g4m-grid-category-boxes .g4m-grid-item:not(:nth-child(3n)) {
    margin-right: 20px;
  }

  .page-home .g4m-grid-category-boxes .g4m-grid-item:nth-last-child(-n + 3):nth-child(3n + 1),
  .page-home .g4m-grid-category-boxes .g4m-grid-item:nth-last-child(-n + 3):nth-child(3n + 1)~* {
    margin-bottom: 0;
  }

  .page-home .g4m-grid-category-boxes .g4m-grid-item:not(:nth-child(-n + 3)) {
    margin-bottom: 0;
  }

  .page-home .g4m-grid-category-boxes .g4m-grid-item:not(:nth-child(-n + 6)) {
    display: none;
  }
}

.plp-page .g4m-grid-category-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.plp-page .g4m-grid-category-boxes .g4m-grid-item {
  max-width: 320px;
  height: 200px;
}

@media screen and (max-width: 1023px) {
  .plp-page .g4m-grid-category-boxes .g4m-grid-item {
    width: calc(33.32333% - 13.33333px);
    margin-bottom: 20px;
  }

  .plp-page .g4m-grid-category-boxes .g4m-grid-item:not(:nth-child(3n)) {
    margin-right: 20px;
  }

  .plp-page .g4m-grid-category-boxes .g4m-grid-item:nth-last-child(-n + 3):nth-child(3n + 1),
  .plp-page .g4m-grid-category-boxes .g4m-grid-item:nth-last-child(-n + 3):nth-child(3n + 1)~* {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1024px) {
  .plp-page .g4m-grid-category-boxes .g4m-grid-item {
    width: calc(24.99% - 15px);
    margin-bottom: 20px;
  }

  .plp-page .g4m-grid-category-boxes .g4m-grid-item:not(:nth-child(4n)) {
    margin-right: 20px;
  }

  .plp-page .g4m-grid-category-boxes .g4m-grid-item:nth-last-child(-n + 4):nth-child(4n + 1),
  .plp-page .g4m-grid-category-boxes .g4m-grid-item:nth-last-child(-n + 4):nth-child(4n + 1)~* {
    margin-bottom: 0;
  }
}

.plp-page .g4m-grid-category-boxes .g4m-grid-item:last-child {
  margin-right: 0;
}

.collect-from-showroom-details {
  padding-top: 1em;
  border-top: 1px solid #ededed;
}

.collect-from-showroom-details.active {
  display: block;
}

.collect-from-showroom-details .collection-info,
.collect-from-showroom-details .collection-location {
  padding: 1em 0;
}

.collect-from-showroom-details .collection-location {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}

.collect-from-showroom-details .collection-location>div>.title {
  margin-bottom: 1em;
}

.collect-from-showroom-details .collection-location .address,
.collect-from-showroom-details .collection-location .opening-hours,
.collect-from-showroom-details .collection-location .contact-info {
  width: calc(50% * 0.33);
  padding-right: 0.5em;
}

.collect-from-showroom-details .collection-location .contact-info-email {
  margin-top: 1em;
}

.collect-from-showroom-details .collection-location .map-container {
  width: 50%;
}

.collect-from-showroom-details .collection-location .map-container .map-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: calc((9 / 16) * 100%);
}

.collect-from-showroom-details .collection-location .map-container .map {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button-with-text {
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: buy;
  }

  .apple-pay-button-with-text>* {
    display: none;
  }

  .apple-pay-button-black-with-text {
    -apple-pay-button-style: black;
  }

  .apple-pay-button-white-with-text {
    -apple-pay-button-style: white;
  }

  .apple-pay-button-white-with-line-with-text {
    -apple-pay-button-style: white-outline;
  }
}

@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button-with-text {
    --apple-pay-scale: 1;
    display: inline-flex;
    justify-content: center;
    font-size: 12px;
    border-radius: 5px;
    padding: 0px;
    box-sizing: border-box;
    min-width: 200px;
    min-height: 32px;
    max-height: 64px;
  }

  .apple-pay-button-black-with-text {
    background-color: black;
    color: white;
  }

  .apple-pay-button-white-with-text {
    background-color: white;
    color: black;
  }

  .apple-pay-button-white-with-line-with-text {
    background-color: white;
    color: black;
    border: 0.5px solid black;
  }

  .apple-pay-button-with-text.apple-pay-button-black-with-text>.apple-pay-logo {
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-color: black;
  }

  .apple-pay-button-with-text.apple-pay-button-white-with-text>.apple-pay-logo {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
  }

  .apple-pay-button-with-text.apple-pay-button-white-with-line-with-text>.apple-pay-logo {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
  }

  .apple-pay-button-with-text>.apple-pay-text {
    font-size: calc(1em * var(--apple-pay-scale));
    font-weight: 300;
    -ms-grid-row-align: center;
    align-self: center;
    margin-right: calc(2px * var(--apple-pay-scale));
  }

  .apple-pay-button-with-text>.apple-pay-logo {
    width: calc(35px * var(--scale));
    height: 100%;
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: 0 50%;
    margin-left: calc(2px * var(--apple-pay-scale));
    border: none;
  }
}

.apple-pay-button {
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: check-out;
}

.apple-pay-button-black {
  -apple-pay-button-style: black;
}

.apple-pay-button-white {
  -apple-pay-button-style: white;
}

.apple-pay-button-white-with-line {
  -apple-pay-button-style: white-outline;
}

.apple-pay-button-with-text {
  height: 35px;
}

.apple-pay-button {
  cursor: pointer;
}

.bottom-order-summary .apple-pay-button,
.radio-block .apple-pay-button {
  height: 41px;
  display: block;
}

.fright .apple-pay-button,
.button-wrapper .apple-pay-button {
  height: 40px;
  width: 176px;
}

.fright button[type="submit"],
.button-wrapper button[type="submit"] {
  height: 40px;
  margin: 0;
}

.body-class--mobile .apple-pay-button-block-basket .apple-pay-button {
  height: 41px;
  display: block;
}

.body-class--desktop .apple-pay-button-block-basket .apple-pay-button {
  height: 35px;
}

.pymt_apple_pay_image {
  width: 63px;
}

.payment-provider-logo.apple-pay {
  width: 68px;
  height: 44px;
  background-image: url(/dist/images/svgs/apple-pay-mark.svg);
  background-position: center;
  background-size: 100%;
}

@media screen and (min-width: 1200px) {
  .body-class--desktop .apple-pay-basket-header.apple-pay-visible .col-xs-12.col-sm-7 {
    width: 41.66667%;
  }

  .body-class--desktop .apple-pay-basket-header.apple-pay-visible .col-xs-12.col-sm-5 {
    width: 58.33333%;
  }
}

@media screen and (max-width: 1199px) {
  .wrap-for-apple-pay {
    display: block;
  }
}

@media screen and (min-width: 1200px) {
  .wrap-for-apple-pay {
    display: inline;
  }
}

@media screen and (max-width: 1199px) {
  .wrap-for-apple-pay {
    display: block;
  }
}

@media screen and (min-width: 1200px) {
  .wrap-for-apple-pay {
    display: inline;
  }
}

.hide-apple-pay .apple-pay-button,
.hide-apple-pay .g4m-divider,
.hide-apple-pay .g4m-divider--line {
  display: none;
}

@media screen and (max-width: 1199px) {

  .display-apple-pay .apple-pay-button,
  .display-apple-pay .g4m-divider,
  .display-apple-pay .g4m-divider--line {
    display: block;
  }
}

@media screen and (min-width: 1200px) {

  .display-apple-pay .apple-pay-button,
  .display-apple-pay .g4m-divider,
  .display-apple-pay .g4m-divider--line {
    display: inline-block;
  }
}

.apple-pay-button-block-basket {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 1199px) {
  .apple-pay-button-block-basket.display-apple-pay {
    flex-direction: column;
  }

  .apple-pay-button-block-basket.display-apple-pay .apple-pay-button,
  .apple-pay-button-block-basket.display-apple-pay .g4m-divider,
  .apple-pay-button-block-basket.display-apple-pay .g4m-divider--line,
  .apple-pay-button-block-basket.display-apple-pay .btn-primary {
    display: block;
    width: 100%;
  }

  .apple-pay-button-block-basket .g4m-divider,
  .apple-pay-button-block-basket .g4m-divider--line {
    margin: 3px 0;
  }
}

@media screen and (min-width: 1200px) {
  .apple-pay-button-block-basket {
    align-items: center;
  }

  .apple-pay-button-block-basket .btn-primary {
    display: inline-block;
  }

  .apple-pay-button-block-basket .g4m-divider,
  .apple-pay-button-block-basket .g4m-divider--line {
    margin: 0 12px;
  }

  .apple-pay-button-block-basket .apple-pay-button {
    width: 43%;
  }
}

.body-class--mobile .basket p.terms-and-conditions {
  margin-bottom: 9px;
}

.body-class--desktop .basket-container p.terms-and-conditions {
  margin: 9px 0 0;
}

.body-class--desktop .apple-pay-button-block-basket .apple-pay-button.disabled,
.body-class--desktop .apple-pay-button-block-basket .g4m-divider.disabled,
.body-class--desktop .apple-pay-button-block-basket .disabled.g4m-divider--line {
  opacity: 0.1;
}

@media (min-width: 767px) {

  .bottom-order-summary button[type="submit"],
  .bottom-order-summary .apple-pay-button {
    display: none !important;
  }
}

.hide-apple-pay.radio-block {
  display: none;
}

@media screen and (max-width: 1279px) {

  .payment-page .sidebar.right,
  .quotation-page .sidebar.right {
    display: none;
  }

  .payment-page .content,
  .quotation-page .content {
    margin-right: 0;
  }
}

@media screen and (min-width: 1280px) {

  .payment-page #sidebar-left .mini-security,
  .quotation-page #sidebar-left .mini-security {
    display: none;
  }
}

.main-holder:not(.payment-page):not(.quotation-page) #sidebar-left .mini-security {
  display: none;
}

.main-holder.quotation-mobile #sidebar-left .mini-security {
  display: none;
}

#hps_iframe {
  background-color: transparent !important;
}

.main-holder.payment-page .style-alt .panel .panel-body.panel-body-form,
.main-holder.payment-page .style-alt .panel .panel-body.panel-body-form-modal,
.main-holder.quotation-page .style-alt .panel .panel-body.panel-body-form,
.main-holder.quotation-page .style-alt .panel .panel-body.panel-body-form-modal {
  padding: 20px;
}

.main-holder.payment-page .payment_method_details,
.main-holder.quotation-page .payment_method_details {
  max-width: 432px;
}

.main-holder.payment-page #payment_method_33,
.main-holder.quotation-page #payment_method_33 {
  max-width: 376px;
}

.main-holder.payment-page .payment_method_details .terms-and-conditions,
.main-holder.quotation-page .payment_method_details .terms-and-conditions {
  padding: 0;
  margin: 12px 0 0;
}

.main-holder.payment-page #payment_method_33 .additional-payment-details,
.main-holder.quotation-page.quotation-desktop #payment_method_33 .additional-payment-details,
.main-holder.quotation-page.quotation-tablet #payment_method_33 .additional-payment-details {
  margin-top: -13px;
}

.main-holder.payment-page #payment_method_33 .checkbox.g4m-checkbox,
.main-holder.quotation-page.quotation-desktop #payment_method_33 .checkbox.g4m-checkbox,
.main-holder.quotation-page.quotation-tablet #payment_method_33 .checkbox.g4m-checkbox {
  margin-top: 0;
}

.main-holder.payment-page .payment_method_details,
.main-holder.quotation-page.quotation-desktop .payment_method_details,
.main-holder.quotation-page.quotation-tablet .payment_method_details {
  margin: 0 38px 0 auto;
}

.main-holder.payment-page .style-alt .col-xs-12#pymt-details,
.main-holder.quotation-page.quotation-desktop .style-alt .col-xs-12#pymt-details,
.main-holder.quotation-page.quotation-tablet .style-alt .col-xs-12#pymt-details {
  padding-left: 0;
}

#hps_iframe_wrapper {
  display: none;
  margin-left: -6px;
  margin-right: -6px;
  width: calc(100% + 12px);
}

[id^="#payment_method"] div>#hps_iframe_wrapper {
  padding: 0;
}

#hps_iframe_loading .loading-cog-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 249px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  margin-bottom: 20px;
}

#hps_iframe_loading .loading-cog {
  width: 40px;
  height: 40px;
  margin: 20px 0 0 40px;
  -webkit-animation-name: cog-spin;
  animation-name: cog-spin;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

p.loading-issue-message {
  padding: 0;
}

p.loading-issue-message a {
  font-weight: 800;
}

.jp-payment-iframe {
  width: 100%;
  background-color: white;
  min-height: 220px;
  padding: 10px;
}

.jp-payment-button {
  visibility: hidden;
}

.judo-modal-visible {
  z-index: 99999 !important;
}

.grid--product-key-features {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

@media screen and (min-width: 600px) {
  .grid--product-key-features {
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 600px) {
  .grid--product-key-features li {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 600px) {
  .grid--product-key-features li {
    width: calc(49.99% - 5px);
    margin-bottom: 10px;
  }

  .grid--product-key-features li:not(:nth-child(2n)) {
    margin-right: 10px;
  }

  .grid--product-key-features li:nth-last-child(-n + 2):nth-child(2n + 1),
  .grid--product-key-features li:nth-last-child(-n + 2):nth-child(2n + 1)~* {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1024px) {
  .grid--product-key-features li {
    width: calc(24.99% - 7.5px);
    margin-bottom: 10px;
  }

  .grid--product-key-features li:not(:nth-child(4n)) {
    margin-right: 10px;
  }

  .grid--product-key-features li:nth-last-child(-n + 4):nth-child(4n + 1),
  .grid--product-key-features li:nth-last-child(-n + 4):nth-child(4n + 1)~* {
    margin-bottom: 0;
  }
}

.product-key-feature {
  height: 5em;
  padding: 15px;
  border: 1px solid #d5d5d5;
  border-radius: 3px;
  display: flex;
}

.product-key-feature:hover,
.product-key-feature:focus {
  border: 1px solid #fa8600;
  text-decoration: none;
}

.product-key-feature:hover .product-key-feature__title,
.product-key-feature:focus .product-key-feature__title {
  color: #fa8600;
}

.product-key-feature__icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  order: -1;
}

.product-key-feature__icon img {
  max-height: 100%;
  max-width: 100%;
}

.product-key-feature__title {
  width: 100%;
  color: #63656a;
}

.product-key-feature:after {
  content: "";
  display: block;
  width: 12px;
  height: 8px;
  margin-top: 6px;
  border-top: 8px solid #63656a;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

.g4m-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.g4m-popup {
  width: 80%;
  max-width: 300px;
  padding: 20px;
  position: relative;
  font-size: 14px;
  background-color: #fff;
  -webkit-animation-name: slide-in;
  animation-name: slide-in;
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
}

@-webkit-keyframes slide-in {
  from {
    margin-top: -20px;
  }

  to {
    margin-top: 0;
  }
}

@keyframes slide-in {
  from {
    margin-top: -20px;
  }

  to {
    margin-top: 0;
  }
}

.g4m-popup .g4m-popup__icon {
  width: 160px;
  max-height: 160px;
  margin: 0.5em auto 2em;
}

.g4m-popup .g4m-popup__icon img {
  display: block;
  max-height: 100%;
  max-width: 100%;
  margin: 0 auto;
}

.g4m-popup .g4m-popup__title {
  font-size: 1.17em;
}

.g4m-popup .g4m-popup__description {
  margin: 0;
  font-size: 1em;
}

.g4m-popup .g4m-popup__close {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: none;
  min-width: 0;
  color: #a3a4a6;
  font-size: 22px;
  margin-top: 0;
  direction: ltr;
  text-indent: -9999px;
  padding: 0;
  height: 45px;
  width: 45px;
  background-image: url(/dist/images/svgs/modal-close.svg);
  background-size: 14px 14px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.custom-cable.component-list {
  margin: 0;
  padding: 0 0 0 30px;
  list-style: none;
}

.custom-cable.component-list li {
  margin: 0;
  padding: 0;
  text-transform: capitalize;
}

.custom-cable-basket-item-info-row .custom-cable-button-row {
  margin-left: 30px;
  padding: 11px 0px;
}

.custom-bundle-list.component-list {
  margin: 0;
  padding: 0 0 0 20px;
  list-style: none;
}

.custom-bundle-list.component-list li {
  margin: 0;
  padding: 0;
  text-transform: capitalize;
}

.panel-optional-details .panel-body {
  padding: 20px;
}

.panel-optional-details .field-row .button-row,
.panel-optional-details .info-row,
.panel-optional-details .button-row {
  margin-top: 1.5rem;
}

.panel-optional-details .button-row {
  display: flex;
  justify-content: flex-end;
}

.panel-optional-details .field-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.panel-optional-details .field-row:first-child {
  margin-top: 0;
}

.panel-optional-details .field-row>label,
.panel-optional-details .field-row>input {
  max-width: initial;
}

.panel-optional-details .field-row>label {
  line-height: 1.5em;
  width: 60%;
  // margin: calc(($row-height/2) - (1.5em / 2)) 0 0 0;
}

.panel-optional-details .field-row>input {
  box-sizing: border-box;
  position: relative;
  width: 40% !important;
  height: 26px;
  max-width: 300px;
  margin-left: 20px;
}

.panel-optional-details .field-row>input[type="checkbox"] {
  -ms-grid-row-align: center;
  align-self: center;
  width: auto !important;
  height: auto !important;
  margin-top: -2px;
  margin-left: 0;
  margin-right: 10px;
}

.panel-optional-details .field-row>input[type="checkbox"]+label {
  width: 100%;
  margin: 0;
}

.panel-optional-details .field-row.validation-error::after,
.panel-optional-details .field-row.validation-success::after {
  content: " ";
  position: absolute;
  right: 0;
  display: block;
  width: 20px;
  height: 26px;
  background-color: transparent;
  background-image: url("/dist/images/icon/red-cross.png");
  background-position: center center;
  background-repeat: no-repeat;
}

.panel-optional-details .field-row.validation-error>input {
  border: 1px solid #ed5621;
}

.panel-optional-details .field-row.validation-success::after {
  background-image: url("/dist/images/icon/tick.png");
}

.delivery-address-block {
  -webkit-flex: 1;
}

.click-drop-down-expandable-options .financing {
  width: 100%;
}

.click-drop-down-expandable-options .financing .heading,
.click-drop-down-expandable-options .financing .threshold {
  padding-left: 0 !important;
}

.click-drop-down-expandable-options .financing .heading:before,
.click-drop-down-expandable-options .financing .threshold:before {
  margin: 0;
  content: "";
  display: none;
  background: none;
}

.click-drop-down-expandable-options .expandable {
  width: 100%;
  margin-left: 15px;
  border: none;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top: 0;
  padding-left: 15px;
  border-top: 2px solid #f6f6f6;
  margin-top: 0;
}

.click-drop-down-expandable-options .expandable .expand-content {
  margin-top: 20px;
}

.click-drop-down-expandable-options .expandable>h3 {
  padding-left: 0;
  padding-right: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  margin: 0;
  color: #434649;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: bold;
  white-space: pre-wrap;
  flex-wrap: wrap;
}

.click-drop-down-expandable-options .expandable>h3::-moz-selection,
.click-drop-down-expandable-options .expandable>h3 *::-moz-selection {
  background-color: white;
}

.click-drop-down-expandable-options .expandable>h3::selection,
.click-drop-down-expandable-options .expandable>h3 *::selection {
  background-color: white;
}

.click-drop-down-expandable-options .expandable .expand-select {
  cursor: pointer;
  font-weight: normal;
}

.click-drop-down-expandable-options .expandable .expand-select.expand-icon,
.click-drop-down-expandable-options .expandable .expand-select.expand-icon-inline {
  font-size: 1.3rem;
  display: flex;
  width: 100%;
}

.click-drop-down-expandable-options .expandable .expand-select.expand-icon::after,
.click-drop-down-expandable-options .expandable .expand-select.expand-icon-inline::after {
  font-weight: normal;
  margin-left: 10px;
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  margin-right: 1px;
  vertical-align: middle;
  transform: translateY(-5px) rotateZ(135deg);
}

.click-drop-down-expandable-options .expandable .expand-title {
  flex-grow: 1;
  width: 80%;
}

.click-drop-down-expandable-options .expandable .expand-title small {
  display: inline-block;
  width: 100%;
}

.click-drop-down-expandable-options .expandable.expand-open .expand-select.expand-icon:before,
.click-drop-down-expandable-options .expandable.expand-open .expand-select.expand-icon-inline:after {
  border-top: 2px solid #d5d5d5;
  border-right: 2px solid #d5d5d5;
  border-left: 0;
  transform: translateY(-5px) rotateZ(135deg);
}

.click-drop-down-expandable-options .expandable.expand-close .expand-select.expand-icon:before,
.click-drop-down-expandable-options .expandable.expand-close .expand-select.expand-icon-inline:after {
  border-top: 2px solid #d5d5d5;
  border-right: 2px solid #d5d5d5;
  border-left: 0;
  border-bottom: 0;
  transform: translateY(2px) rotateZ(-45deg);
}

.covid-message {
  color: #d00000;
  font-size: 1.1em;
}

.table-g4m-basket .covid-message {
  padding: 0 2px 10px;
}

.delivery-options .covid-message {
  margin: 10px;
  font-size: 1.2em;
}

.covid-message:not(last-child) {
  margin: 5px 0 0;
}

.covid-message+.covid-message:last-child {
  margin: 0 0 5px;
}

#paymentForm .covid-message {
  margin: 0 0 16px;
}

.duty-tariff-container {
  display: flex;
  justify-content: flex-end;
}

.duty-tariff-text {
  font-size: 1.5rem;
}

.duty-tariff-flex-column {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 992px) and (max-width: 1015px) {
  .content h1.duty-tariff-grand-total {
    font-size: 1.56rem;
  }
}

.duty-tariff-popover-wrapper {
  align-self: flex-end;
  display: inline-flex;
  position: relative;
}

.duty-tariff-popover {
  position: absolute;
  right: 100%;
  top: -1.5px;
}

.category-page {
  width: 1128px;
  margin: 0 auto;
  padding-bottom: 7rem;

  .discountManat {
    path {
      fill: #ff2459 !important;
    }
  }

  @media screen and (max-width: 1199px) {
    width: 100%;
    padding: 0 16px;
  }

  .breadcrumb {
    padding-bottom: 3.6rem;
  }

  .category-wrapper {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: auto;
    grid-gap: 0 16px;

    .main-section {
      grid-column: 1/7;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 3.4rem 1.6rem;
      height: fit-content;

      @media (max-width: 375px) {
        display: block !important;
      }
    }

    .side-section {
      grid-column: 7/-1;

      .side-banner {
        margin-bottom: 1.6rem;
      }

      .category-new-products,
      .category-discount-products,
      .category-most-sale-products {
        .header {
          height: 4rem;
          background: #1b1c1f;
          border-radius: 3px 3px 0px 0px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 100%;
          color: #ffffff;
          padding-left: 2.4rem;

          svg {
            margin-right: 6px;
            width: 22px;
            height: 24px;
          }
        }

        .cat-product-list {
          margin-bottom: 12px;
          padding-left: 2.4rem;

          &_item {
            padding: 12px 0;
            border-bottom: 1px solid rgba($color: #c4c4c4, $alpha: 0.25);

            a {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .product-detail {
                padding-right: 1.6rem;
                display: block;

                .product-name {
                  font-style: normal;
                  font-weight: 300;
                  font-size: 1.4rem;
                  line-height: 125%;
                  color: #3a4155;
                  display: -webkit-box;
                  white-space: pre-wrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  margin-bottom: 8px;
                }

                .product-price {
                  display: flex;
                  align-items: baseline;
                  justify-content: flex-start;
                  border-bottom: none !important;
                  padding-bottom: 0 !important;

                  .old-price {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1.4rem;
                    line-height: 1.4rem;
                    text-decoration-line: line-through;
                    color: rgba(58, 65, 85, 0.25);
                    display: none;

                    &.active {
                      display: flex;
                    }
                  }

                  .new-price {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1.6rem;
                    line-height: 100%;
                    color: #3a4155;
                    margin-right: 6px;
                  }

                  .manatIcon {
                    width: 1.5rem;
                    height: 1.2rem;
                  }
                }
              }

              .img-container {
                min-width: 9.6rem;
                height: 9.6rem;
                border-radius: 3px;
                overflow: hidden;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }
        }
      }
    }
  }

  .brand-carousel {
    margin-bottom: 9.6rem;

    .brands-banner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2.4rem;

      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 2.4rem;
        line-height: 2.9rem;
        color: #000000;
      }

      .link {
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 1.4rem;
        color: #ff2459;
      }
    }

    .container {
      position: relative;
      width: 100%;

      #daily.swiper-container {
        position: unset;
        max-width: 112.8rem;

        @media screen and (max-width: 768px) {
          padding-left: 2rem;
        }

        .swiper-button-prev {
          display: none;
          // background: radial-gradient(90.16% 143.01% at 15.32% 21.04%,
          //         rgba(165, 239, 255, 0.2) 0%,
          //         rgba(110, 191, 244, 0.0447917) 77.08%,
          //         rgba(70, 144, 213, 0) 100%),
          // url("../../../../assets/img/arrow-white.svg");
          background-blend-mode: overlay, normal;
          backdrop-filter: blur(80px);
          border-radius: 16px;
          background-repeat: no-repeat;
          background-position: center;
          width: 40px;
          height: 40px;
          position: absolute;
          top: 50%;
          transform: scaleX(-1);
          left: -20px;
          border: 1px solid rgba(44, 20, 79, 0.1);
          border-radius: 50%;
          box-shadow: 0px 16px 48px rgba(28, 27, 107, 0.06);

          @media screen and (max-width: 1128px) {
            left: 0;
          }

          @media screen and (max-width: 768px) {
            display: none;
          }

          &.swiper-button-disabled {
            opacity: 0;
            // background-image: url("../../../../assets/img/right.png");
            transform: scaleX(1);
          }

          &:after {
            display: none;
          }
        }

        .swiper-button-next {
          display: none;
          // background: radial-gradient(90.16% 143.01% at 15.32% 21.04%,
          //         rgba(165, 239, 255, 0.2) 0%,
          //         rgba(110, 191, 244, 0.0447917) 77.08%,
          //         rgba(70, 144, 213, 0) 100%),
          // url("../../../../assets/img/arrow-white.svg");
          background-blend-mode: overlay, normal;
          backdrop-filter: blur(80px);
          border-radius: 16px;
          background-repeat: no-repeat;
          background-position: center;
          width: 40px;
          height: 40px;
          position: absolute;
          top: 50%;
          right: -20px;
          border: 1px solid rgba(44, 20, 79, 0.1);
          border-radius: 50%;
          box-shadow: 0px 16px 48px rgba(28, 27, 107, 0.06);

          @media screen and (max-width: 1128px) {
            right: 0;
          }

          @media screen and (max-width: 768px) {
            display: none;
          }

          &.swiper-button-disabled {
            opacity: 0;
            // background-image: url("../../../../assets/img/right.png");
            transform: scaleX(-1);
          }

          &:after {
            display: none;
          }
        }
      }

      @media screen and (max-width: 1199px) {
        grid-template-columns: repeat(1, 1fr) !important;

        #daily.swiper-container {
          max-width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 767.9px) {
    .brand-carousel-card {
      min-width: 100%;
    }

    .swiper-container {
      padding: 0 !important;
    }
  }

  .category-wrapper-child {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-column: 1/7;
    display: grid;

    @media (max-width: 375px) {
      display: flex !important;
    }
  }

  .category-class-name {
    display: flex;
    flex-direction: column;
    margin-bottom: 26px;
    min-width: 100%;
    // min-width: 100%;

    .category-page-title {
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 100%;
      color: #1b1c1f;
      margin-bottom: 12px;
      text-transform: capitalize;
    }

    .category-page-desc {
      min-width: 100%;

      p {
        border: none !important;
        margin: 0 !important;
        padding: 0 !important;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        color: #777f95;
        min-width: 100%;
      }
    }
  }

  @media (max-width: 991.9px) {
    .main-section {
      grid-template-columns: repeat(2, 1fr) !important;
      grid-column: 1/9 !important;
    }

    .category-wrapper {
      display: flex;
      grid-template-columns: repeat(1, 1fr);
      flex-direction: column;

      .side-section {
        grid-column: -1/-1;
      }
    }
  }

  @media (max-width: 767.9px) {
    .category-class-name {
      min-width: 100%;
    }

    .category-page-title {
      font-size: 24px !important;
      margin-bottom: 0.8rem !important;
    }

    .category-page-desc {
      p {
        font-size: 14px !important;
      }
    }

    .main-section {
      grid-template-columns: repeat(2, 1fr) !important;
      grid-column: 1/9 !important;
    }

    .category-wrapper {
      .side-section {
        // grid-column: 1/-1;
        // margin-top: 4.8rem;
        display: none;
      }
    }

    .category-card {
      max-width: 100%;
    }
  }
}

.category-page {
  width: 1289px;
  margin: 0 auto;
  padding-bottom: 7rem;

  .discountManat {
    path {
      fill: #ff2459 !important;
    }
  }

  @media screen and (max-width: 1199px) {
    width: 100%;
    padding: 0 16px;
  }

  .breadcrumb {
    padding-bottom: 3.6rem;
  }

  .category-wrapper {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: auto;
    grid-gap: 0 16px;

    .main-section {
      grid-column: 1/7;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 3.4rem 1.6rem;
      height: fit-content;
    }

    .side-section {
      grid-column: 7/-1;

      .side-banner {
        margin-bottom: 1.6rem;
      }

      .category-new-products,
      .category-discount-products,
      .category-most-sale-products {
        .header {
          height: 4rem;
          background: #1b1c1f;
          border-radius: 3px 3px 0px 0px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 100%;
          color: #ffffff;
          padding-left: 2.4rem;

          svg {
            margin-right: 6px;
            width: 22px;
            height: 24px;
          }
        }

        .cat-product-list {
          margin-bottom: 12px;
          padding-left: 2.4rem;

          &_item {
            padding: 12px 0;
            border-bottom: 1px solid rgba($color: #c4c4c4, $alpha: 0.25);

            a {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .product-detail {
                padding-right: 1.6rem;
                display: block;

                .product-name {
                  font-style: normal;
                  font-weight: 300;
                  font-size: 1.4rem;
                  line-height: 125%;
                  color: #3a4155;
                  display: -webkit-box;
                  white-space: pre-wrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  margin-bottom: 8px;
                }

                .product-price {
                  display: flex;
                  align-items: baseline;
                  justify-content: flex-start;
                  border-bottom: none !important;
                  padding-bottom: 0 !important;

                  .old-price {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1.4rem;
                    line-height: 1.4rem;
                    text-decoration-line: line-through;
                    color: rgba(58, 65, 85, 0.25);
                    display: none;

                    &.active {
                      display: flex;
                    }
                  }

                  .new-price {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1.6rem;
                    line-height: 100%;
                    color: #3a4155;
                    margin-right: 6px;
                  }

                  .manatIcon {
                    width: 1.5rem;
                    height: 1.2rem;
                  }
                }
              }

              .img-container {
                min-width: 9.6rem;
                height: 9.6rem;
                border-radius: 3px;
                overflow: hidden;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }
        }
      }
    }
  }

  .brand-carousel {
    width: 100%;

    .banner {
      font-size: 2rem;
      font-style: normal;
      font-weight: 500;
      font-size: 2rem;
      line-height: 125%;
      color: #3a4155;
      margin-bottom: 1.2rem;
    }

    #category-brand.swiper-container {
      position: unset;
      max-width: 112.8rem;

      .swiper-button-prev,
      .swiper-button-next {
        display: none;
      }

      .brand-carousel-card {
        cursor: pointer;
        max-width: 26rem;
        height: 13.4rem;
        background: #ffffff;
        border: 1px solid #eaeaea;
        box-sizing: border-box;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2.4rem;

        .img-container {
          width: 8.6rem;
          height: 8.6rem;
          border-radius: 50%;
          background: #ffffff;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12);
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 18px;

          img {
            width: 6.5rem;
            height: 6.5rem;
            border-radius: 21px;
            object-fit: contain;
          }
        }

        .content {
          .brand-name {
            font-style: normal;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 125%;
            color: #000000;
            margin-bottom: 12px;
          }

          .brand-desc {
            font-style: normal;
            font-weight: 300;
            font-size: 1.2rem;
            line-height: 100%;
            color: #3a4155;
          }
        }
      }
    }
  }

  .category-wrapper-child {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-column: 1/7;
    display: grid;
  }

  .category-class-name {
    display: flex;
    flex-direction: column;
    margin-bottom: 26px;
    min-width: 80rem;
    // min-width: 100%;

    .category-page-title {
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 100%;
      color: #1b1c1f;
      margin-bottom: 12px;
    }

    .category-page-desc {
      min-width: 100%;

      p {
        border: none !important;
        margin: 0 !important;
        padding: 0 !important;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        color: #777f95;
        min-width: 100%;
      }
    }
  }

  @media (max-width: 991.9px) {
    .main-section {
      grid-template-columns: repeat(2, 1fr) !important;
      grid-column: 1/9 !important;
    }

    .category-wrapper {
      display: flex;
      grid-template-columns: repeat(1, 1fr);
      flex-direction: column;

      .side-section {
        grid-column: -1/-1;
      }
    }
  }

  @media (max-width: 767.9px) {
    .category-class-name {
      min-width: 100%;
    }

    .category-page-title {
      font-size: 24px !important;
      margin-bottom: 0.8rem !important;
    }

    .category-page-desc {
      p {
        font-size: 14px !important;
      }
    }

    .main-section {
      grid-template-columns: repeat(2, 1fr) !important;
      grid-column: 1/9 !important;
    }

    .category-wrapper {
      .side-section {
        // grid-column: 1/-1;
        // margin-top: 4.8rem;
        display: none;
      }
    }

    .category-card {
      max-width: 100%;
    }
  }
}

.category-class-name {
  p {
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    color: #777f95;
    min-width: 100%;
  }
}

#daily {
  position: unset;
  width: 100% !important;

  .swiper-wrapper {
    padding-left: 0 !important;
  }
}

.brand-carousel {
  margin-bottom: 9.6rem;

  .brands-banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.4rem;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 2.9rem;
      color: #000000;
    }

    .link {
      font-style: normal;
      font-weight: normal;
      font-size: 1.4rem;
      line-height: 1.4rem;
      color: #ff2459;
    }
  }

  .container {
    position: relative;

    #daily.swiper-container {
      position: unset;
      max-width: 112.8rem;

      @media screen and (max-width: 768px) {
        padding-left: 2rem;
      }

      .swiper-button-prev {
        display: none;
        // background: radial-gradient(90.16% 143.01% at 15.32% 21.04%,
        //         rgba(165, 239, 255, 0.2) 0%,
        //         rgba(110, 191, 244, 0.0447917) 77.08%,
        //         rgba(70, 144, 213, 0) 100%),
        // url("../assets/img/arrow-white.svg");
        background-blend-mode: overlay, normal;
        backdrop-filter: blur(80px);
        border-radius: 16px;
        background-repeat: no-repeat;
        background-position: center;
        width: 40px;
        height: 40px;
        position: absolute;
        top: 50%;
        transform: scaleX(-1);
        left: -20px;
        border: 1px solid rgba(44, 20, 79, 0.1);
        border-radius: 50%;
        box-shadow: 0px 16px 48px rgba(28, 27, 107, 0.06);

        @media screen and (max-width: 1128px) {
          left: 0;
        }

        @media screen and (max-width: 768px) {
          display: none;
        }

        &.swiper-button-disabled {
          opacity: 0;
          // background-image: url("../../../../assets/img/right.png");
          transform: scaleX(1);
        }

        &:after {
          display: none;
        }
      }

      .swiper-button-next {
        display: none;
        // background: radial-gradient(90.16% 143.01% at 15.32% 21.04%,
        //         rgba(165, 239, 255, 0.2) 0%,
        //         rgba(110, 191, 244, 0.0447917) 77.08%,
        //         rgba(70, 144, 213, 0) 100%),
        // url("../../../../assets/img/arrow-white.svg");
        background-blend-mode: overlay, normal;
        backdrop-filter: blur(80px);
        border-radius: 16px;
        background-repeat: no-repeat;
        background-position: center;
        width: 40px;
        height: 40px;
        position: absolute;
        top: 50%;
        right: -20px;
        border: 1px solid rgba(44, 20, 79, 0.1);
        border-radius: 50%;
        box-shadow: 0px 16px 48px rgba(28, 27, 107, 0.06);

        @media screen and (max-width: 1128px) {
          right: 0;
        }

        @media screen and (max-width: 768px) {
          display: none;
        }

        &.swiper-button-disabled {
          opacity: 0;
          // background-image: url("../../../../assets/img/right.png");
          transform: scaleX(-1);
        }

        &:after {
          display: none;
        }
      }
    }

    @media screen and (max-width: 1199px) {
      grid-template-columns: repeat(1, 1fr) !important;

      #daily.swiper-container {
        max-width: 100%;
      }
    }
  }
}

@media screen and (max-width: 767.9px) {
  .brand-carousel-card {
    min-width: 100%;
  }

  .swiper-container {
    padding: 0 !important;
  }
}

.brand-carousel-card {
  cursor: pointer;
  max-width: 100%;
  width: 100%;
  min-width: 240px;
  height: 13.4rem;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.4rem;

  .img-container {
    width: 8.6rem;
    height: 8.6rem;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 18px;

    img {
      width: 6.5rem;
      height: 6.5rem;
      border-radius: 21px;
      object-fit: contain;
    }
  }

  .content {
    .brand-name {
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 125%;
      color: #000000;
      margin-bottom: 12px;
    }

    .brand-desc {
      font-style: normal;
      font-weight: 300;
      font-size: 1.2rem;
      line-height: 100%;
      color: #3a4155;
    }
  }
}

.breadcrumb {
  width: 100%;
  grid-column: 1/-1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 3.6rem;

  @media only screen and (max-width: 767.9px) {
    padding-top: 2rem;
    white-space: nowrap;
    overflow: auto;

    &::-webkit-scrollbar {
      height: 0;
    }

    a {
      white-space: nowrap;
    }
  }

  a {
    font-style: normal;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 125%;
    color: rgba($color: #3a4155, $alpha: 0.75);

    @media only screen and (max-width: 767.9px) {
      font-size: 1.6rem;
    }
  }

  .breadcrumb-seperator {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgba($color: #3a4155, $alpha: 0.75);
    margin: 0 12px;
    min-width: 6px;
  }
}

.breadcrumb {
  margin-top: 20px;

  a {
    position: relative;
    margin-right: 3px;
    text-decoration: unset;
    padding: 2px 7px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      height: 50%;
      width: 100%;
      background: rgba(0, 0, 0, 0.05);
      opacity: 1;
      top: 0;
      -webkit-transform: skew(30deg);
      transform: skew(30deg);
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      height: 50%;
      width: 100%;
      bottom: 0;
      -webkit-transform: skew(-30deg);
      transform: skew(-30deg);
      background: rgba(0, 0, 0, 0.05);
      opacity: 1;
    }
  }

  >span {
    display: none !important;
  }
}

// about

.about {
  width: 1287px;
  margin: 0 auto;
  padding-bottom: 7rem;

  @media (max-width: 1199px) {
    width: 100%;
    padding: 0 16px;
  }
}

.about .about-header {
  background: url("../assets/img/account-bg.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 27rem;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2.4rem;
  margin-bottom: 5.6rem;
  margin-top: 24px;
}

.about .about-header .breadcrumb a {
  color: #fff;
}

.about .about-content h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 150%;
  color: #040941;
  margin-bottom: 24px;

  @media (max-width: 991.9px) {
    font-size: 28px !important;
    margin-bottom: 11px !important;
  }
}

.about .about-content p {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #989eaf;

  @media screen and (max-width: 991.9px) {
    font-size: 16px !important;
  }
}

.locationsFlex {
  display: flex;
  flex-wrap: wrap;

  &__item {
    width: calc((100% / 4) - 40px);
    padding: 20px;

    img {
      width: 100%;
    }
  }
}

// product-list
.product-list {
  width: 100%;
}

.breadcrumb {
  width: 100%;
  grid-column: 1/-1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 3.6rem;

  @media only screen and (max-width: 600px) {
    padding-top: 2rem;
  }

  a {
    font-style: normal;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 125%;
    color: rgba($color: #3a4155, $alpha: 0.75);

    @media only screen and (max-width: 600px) {
      font-size: 1.5rem;
    }
  }

  .breadcrumb-seperator {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgba($color: #3a4155, $alpha: 0.75);
    margin: 0 12px;
  }
}

.product-list-body {
  width: 1128px;
  margin: 0 auto;
  padding-bottom: 7rem;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 0 1.6rem;

  @media screen and (max-width: 1199px) {
    width: 100%;
    padding: 0 1.6rem;
  }

  .category-main-section-div {
    grid-column: 3/-1;
    display: grid;
    width: 100%;
    height: fit-content;

    .category-class-name {
      margin-bottom: 24px;
      display: flex;
      flex-direction: column;
      margin-bottom: 26px;

      .category-page-title {
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 100%;
        color: #1b1c1f;
        margin-bottom: 12px;
        margin-top: 0;
      }

      .category-page-desc {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        color: #777f95;
      }
    }
  }

  .mobile-sidebar-header {
    display: none;
  }

  .breadcrumb {
    padding-bottom: 3.6rem;
  }

  .mobile-sidebar {
    grid-column: 1/3;
    background-color: #fff;

    .filter-header {
      display: flex;
      padding: 2.4rem 2.4rem 0 2.4rem;
      margin-bottom: 2.4rem;

      .filter-header-wrapper {
        width: 100%;
        border-bottom: 1px solid #c4c4c4;
        padding-bottom: 2.4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-style: normal;
          font-weight: normal;
          font-size: 1.4rem;
          line-height: 100%;
          color: #000000;
        }

        button {
          cursor: pointer;
          font-style: normal;
          font-weight: 300;
          font-size: 1.2rem;
          line-height: 100%;
          color: #8b8e97;

          &:hover {
            color: #000000;
          }
        }
      }
    }
  }

  .main-section {
    grid-column: 3/-1;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    grid-gap: 2.4rem 1.6rem;
    align-content: flex-start;
    height: fit-content;

    .result {
      grid-column: 1/-1;
      width: 100%;
      padding: 1.2rem 0;
      border-top: 2px solid rgba($color: #d0d4de, $alpha: 0.25);
      border-bottom: 2px solid rgba($color: #d0d4de, $alpha: 0.25);
      display: flex;
      align-items: center;
      justify-content: space-between;

      h4 {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 20px;
        color: #1b1c1f;
      }

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 20px;
        color: #ff4c4c;
      }
    }
  }
}

.sidebar-category-card {
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(27, 28, 31, 0.06);
  border-radius: 6px;
  padding: 3.6rem;
  margin-bottom: 3.6rem;

  .checkbox-group {
    margin-bottom: 1.4rem;
  }
}

@media screen and (max-width: 767.9px) {
  .category-main-section-div {
    display: block !important;
    grid-column: auto !important;
  }

  .product-list-body {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;

    .main-section {
      grid-column: 3/-3;
      // grid-template-columns: repeat(1, 1fr) !important;
      padding: 24px 0 96px;
    }

    .side-section {
      display: none;
    }
  }

  .filter-svg {
    transition: all 0.3s ease;
  }

  .mobile-sidebar.active {
    .side-section {
      display: block;
    }

    .filter-svg {
      transform: (rotate(180deg));
    }
  }

  .mobile-sidebar-header {
    display: flex !important;
    border: 1px solid #d0d4de;
    box-sizing: border-box;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    @media (max-width: 767px) {
      height: 53px;
    }

    h5 {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 16px;
      color: #1b1c1f;
      display: flex;
      align-items: center;

      svg {
        margin-right: 6px;
      }
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #ff4c4c;

      svg {
        margin-left: 60px;
      }
    }
  }

  .result {
    display: none !important;
  }
}

.question {
  width: 100%;
  border-top: 1px solid rgba($color: #0c0d1e, $alpha: 0.1);
  border-bottom: 1px solid rgba($color: #0c0d1e, $alpha: 0.1);
  cursor: pointer;
  margin-bottom: 1.6rem !important;
}

.question h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 100%;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    margin-right: 6px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 18px;
  }
}

.question .acc-cont {
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 170%;
  color: rgba($color: #0c0d1e, $alpha: 0.5);
  padding-bottom: 3.8rem;
  padding-left: 2.4rem;
  padding-top: 2.6rem;
  max-height: 40rem;
  overflow: auto;

  @media only screen and (max-width: 600px) {
    font-size: 1.5rem;
  }

  &::-webkit-scrollbar {
    width: 2.4rem;
  }

  &::-webkit-scrollbar-track {
    margin-bottom: 2.4rem;
    border-radius: 0.2rem;
    height: 100%;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba($color: #0c0d1e, $alpha: 0.1);
    border-radius: 1.6rem;
    border: 10px solid transparent;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track-piece {
    background: rgba($color: #0c0d1e, $alpha: 0.1);
    background: transparent;
  }

  .popup-buy-as-guest {
    .container {
      .header {
        display: none;
      }

      .body {
        padding: 0;
        margin-top: 0;

        .fast-buy-inputs {
          margin: 0;
        }
      }

      .footer-popup {
        position: relative;
        padding: 0;
      }
    }
  }
}

.question header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  background: #1b1c1f;
  padding-left: 2.4rem;
  padding-right: 6px;
  border-radius: 3px;

  .header-wrapper {
    display: flex;
    align-items: center;
  }
}

.product-list .question header h4 {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 16px */

  color: #ffffff !important;

  svg {
    display: none;
  }
}

.btn {
  border-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  align-self: center;
  transition: all 0.3s;
  outline: none;
  width: 2.8rem;
  height: 2.8rem;
  // background: linear-gradient(224.46deg, #444444 0%, rgba(68, 68, 68, 0) 95.54%);
  border-radius: 3px;

  @media only screen and (max-width: 600px) {
    width: 2rem;
    height: 2rem;
  }

  svg {
    width: 1.6rem;
    height: 1.6rem;
    object-fit: cover;

    path {
      stroke: #444444;
    }

    @media only screen and (max-width: 600px) {
      width: 2rem;
      height: 2rem;
    }
  }
}

.question.acc-side {
  width: 100%;
  border: none;
  padding: 0;
  border-radius: 0;
  margin-bottom: 0;

  h4 {
    font-weight: 400;
    color: #444444 !important;
  }

  p {
    // max-height: 25rem;
    // overflow-y: auto;
  }

  .btn {
    height: 28px;
    width: 28px;
    background: transparent;
    padding: 0;
    min-width: auto;

    &.active {
      transform: rotate(-180deg);
    }
  }

  .product-list {
    .question.acc-side {
      .header-wrapper {
        h4 {
          color: #fff !important;
        }
      }
    }
  }

  .checkbox-group {
    padding-bottom: 1.2rem;

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.custom-checkbox {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #1b1c1f;

  &__input {
    width: 14px;
    height: 14px;
    margin-right: 12px;
  }
}

.range-slider {
  display: flex;
  flex-direction: column;
  padding-right: 2.4rem;

  .range {
    margin-bottom: 2.4rem;
  }

  .input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      width: 100%;
      height: 4.8rem;
      border: 1px solid rgba($color: #05061e, $alpha: 0.1);
      box-sizing: border-box;
      border-radius: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 170%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: rgba($color: #05061e, $alpha: 0.5);
      outline: none;

      @media only screen and (max-width: 600px) {
        font-size: 1.75rem;
      }

      &:first-child {
        margin-right: 1.6rem;
      }

      &:focus {
        border: 1px solid rgba($color: #ffda93, $alpha: 0.5);
      }
    }
  }

  .submit-btn {
    background-color: #ffda93;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 100%;
    color: #ffffff;
    border-radius: 3px;
    margin-top: 12px;
    height: 4.2rem;
    width: 100%;
    outline: 0;
    border: none;
  }
}

.product-detail {
  width: 100%;
  // display: grid;
  // grid-template-columns: repeat(1, 1fr);
  // grid-template-rows: auto;
  // grid-gap: 0 2.4rem;

  .breadcrumb {
    width: 100%;
    grid-column: 1/-1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 3.6rem;
    padding-bottom: 2.4rem !important;

    @media only screen and (max-width: 600px) {
      padding-top: 2rem;
    }

    a {
      font-style: normal;
      font-weight: 300;
      font-size: 1.4rem;
      line-height: 125%;
      color: rgba($color: #3a4155, $alpha: 0.75);

      @media only screen and (max-width: 600px) {
        font-size: 1.5rem;
      }
    }

    .breadcrumb-seperator {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: rgba($color: #3a4155, $alpha: 0.75);
      margin: 0 12px;
    }
  }

  &_content {
    // display: grid;
    // grid-template-columns: max-content 1fr;
    width: 100%;
    position: relative;

    #zoom {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 9999999999999;

      @media only screen and (max-width: 768px) {
        display: none;
      }
    }

    .features {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 3.6rem;

      .new-sale {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 6px;
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 100%;
        color: #ffffff;
        background-color: #06be87;
        width: auto;
        height: 3.6rem;
        min-width: 7.5rem;
        border-radius: 18px;
        margin-right: 24px;
        padding: 6px 12px 6px 6px;

        .icon-background {
          width: 24px;
          height: 24px;
          background: linear-gradient(212.23deg, #05ae7c 13.64%, #0cdd9f 95.78%);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 6px;
        }
      }

      .most-sale {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 6px;
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 100%;
        color: #ffffff;
        background-color: #3a4155;
        min-width: 12rem;
        width: auto;
        height: 3.6rem;
        border-radius: 18px;
        padding: 6px 12px 6px 6px;
        margin-right: 24px;

        .icon-background {
          width: 24px;
          height: 24px;
          background: linear-gradient(212.23deg, #353b4d 13.64%, #576180 95.78%);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 6px;
        }
      }

      .warranty {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 6px;
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 100%;
        color: #ffffff;
        background-color: #ff4c4c;
        min-width: 13.4rem;
        width: auto;
        height: 3.6rem;
        border-radius: 18px;
        padding: 6px 12px 6px 6px;

        .icon-background {
          width: 24px;
          height: 24px;
          background: linear-gradient(212.23deg, #f34141 13.64%, #ff6767 70.54%, #ff4c4c 95.78%);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 6px;
        }
      }

      @media (max-width: 560px) {
        flex-wrap: wrap;

        &>div {
          margin-bottom: 10px;
        }
      }
    }

    .product-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .product-name {
        width: 100%;
        font-style: normal;
        font-weight: normal;
        font-size: 3.6rem;
        line-height: 125%;
        color: #3a4155;
        display: -webkit-box;
        white-space: pre-wrap;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 14px;
      }

      .product-statistics {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 2.4rem;

        @media (max-width: 575px) {
          flex-wrap: wrap;
        }

        a {
          @media (max-width: 575px) {
            margin-top: 10px;
          }
        }

        .rating {
          font-style: normal;
          font-weight: normal;
          font-size: 1.4rem;
          line-height: 100%;
          color: #989eaf;
          display: flex;
          align-items: center;

          svg {
            margin-left: 6px;
            height: 14px;
            width: 14px;
          }

          &:after {
            content: "";
            width: 1px;
            height: 18px;
            background: #c4c4c4;
            border-radius: 1px;
            margin: 0 12px;
          }
        }

        .comment {
          display: flex;
          font-style: normal;
          font-weight: normal;
          font-size: 1.4rem;
          line-height: 100%;
          color: #989eaf;
          display: flex;
          align-items: center;
          white-space: nowrap;

          &:after {
            content: "";
            width: 1px;
            height: 18px;
            background: #c4c4c4;
            border-radius: 1px;
            margin: 0 12px;
          }
        }

        .brand {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100% !important;
          padding-bottom: 0 !important;

          &-name {
            margin-bottom: 0;
            font-size: 15px;
            margin-left: 2px;
          }

          .img-container {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 8px;
            filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.12));

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              object-fit: contain;
            }

            .brand-name {
              font-style: normal;
              font-weight: normal;
              font-size: 1.4rem;
              line-height: 100%;
              color: #3a4155;
            }
          }
        }
      }

      .product-price {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid rgba($color: #989eaf, $alpha: 0.25);
        border-bottom: 1px solid rgba($color: #989eaf, $alpha: 0.25);

        .price-section {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-top: 2.4rem;
          padding-bottom: 1.8rem;

          .discount {
            width: 6.2rem;
            height: 6.2rem;
            background: linear-gradient(223.94deg, #fb0944 0.88%, #ff4572 97.34%);

            border-radius: 6px;
            border: 5px solid #ff2459;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 18px;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
            margin-right: 1.8rem;

            .manatIconX {
              width: 15px !important;
              height: 15px !important;
              margin-left: 5px;

              path {
                fill: #fff !important;
              }
            }
          }

          .prices {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .old-price {
              font-style: normal;
              font-weight: 300;
              font-size: 1.8rem;
              line-height: 100%;
              color: #444;
              text-decoration: line-through;
              margin-bottom: 12px;

              svg {
                width: 16px;
                height: 14px;
                margin-left: 2px;

                path {
                  fill: #444;
                }
              }
            }

            .new-price {
              font-style: normal;
              font-weight: 500;
              font-size: 3.6rem;
              line-height: 100%;
              letter-spacing: -0.05em;
              color: #ff4c4c;
            }
          }
        }

        .product-code {
          font-style: normal;
          font-weight: normal;
          font-size: 1.6rem;
          line-height: 125%;
          color: #989eaf;

          span {
            color: #ff4c4c;
          }
        }
      }

      .product-audio {
        width: 100%;
      }

      .product-buttons {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4.8rem 0 3.6rem;

        .buy-now {
          background-color: #06be87;
          font-style: normal;
          font-weight: 500;
          font-size: 1.8rem;
          line-height: 100%;
          color: #ffffff;
          border-radius: 3px;
          height: 6rem;
          width: 100%;
          max-width: 18.4rem;
          margin-right: 1.6rem;
          cursor: pointer;
          box-shadow: 0px 2px 6px -4px rgba(0, 0, 0, 0.12);
        }

        .add-cart {
          background-color: #000;
          font-style: normal;
          font-weight: 500;
          font-size: 1.8rem;
          line-height: 100%;
          color: #ffffff;
          border-radius: 3px;
          height: 6rem;
          width: 100%;
          margin-right: 1.6rem;
          cursor: pointer;
          box-shadow: 0px 2px 6px -4px rgba(0, 0, 0, 0.12);
        }

        .whatsapp-btn {
          background-color: #25d366;
          border-radius: 3px;
          height: 6rem;
          width: 100%;
          max-width: 6rem;
          cursor: pointer;
          display: inline-flex !important;
          align-items: center;
          justify-content: center;
          border: none;
        }
      }
    }
  }

  .product-detail_carousel {
    position: relative;
    // display: grid;
    // grid-template-columns: repeat(2, 1fr);
  }

  .produc-detail-carousel-inner-child {
    // display: grid !important;
    // grid-template-columns: max-content 1fr;
    width: 100%;
    position: sticky;
    // top: 32px;
    height: 100%;
  }

  /* like button */
  .product-heart-div {
    border: 2px solid rgba(255, 36, 89, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .product-heart {
    background: linear-gradient(220.03deg, #fc184f 7.14%, rgba(253, 51, 100, 0.25) 89.91%);
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 999;

    @media only screen and (max-width: 600px) {
      width: 4.5rem;
      height: 4.5rem;
      top: 8px;
      right: 8px;
    }

    &.remove {
      background-color: #f92356;
      border: 2px solid #f92356;
    }
  }

  .product-heart svg {
    width: auto;
    height: auto;

    @media only screen and (max-width: 600px) {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  .str0 {
    stroke: #fff;
  }

  .like-button {
    display: none;
  }

  .like-button:checked+svg {
    g {
      opacity: 1;
    }

    .fil0 {
      fill: #fff;
      stroke: #fff;
    }
  }

  /* like button ///*/

  .product-inner-video {
    width: -webkit-fill-available;
    margin: 1.2rem 0;
    margin-right: 1.2rem;
    height: 30rem;
  }

  .accordion-product {
    margin-bottom: 3.6rem;

    .acc-cont .wrapper {
      .delivery-features {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 2.4rem;

        &:last-child {
          margin-bottom: 0;
        }

        .icon-container {
          margin-right: 2rem;
        }

        .content {
          display: flex;
          flex-direction: column;

          .title {
            font-style: normal;
            font-weight: normal;
            font-size: 1.8rem;
            line-height: 125%;
            color: #3a4155;
            margin-bottom: 12px;
          }

          .subtitle {
            font-style: normal;
            font-weight: 300;
            font-size: 1.6rem;
            line-height: 125%;
            color: #989eaf;
          }
        }
      }

      .customer-comments {
        .comments-header {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: auto;
          padding-bottom: 1.2rem;
          border-bottom: 1px solid rgba(196, 196, 196, 0.25);

          .count {
            font-style: normal;
            font-weight: normal;
            font-size: 1.4rem;
            line-height: 125%;
            color: #989eaf;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
              display: inline-block;
              color: #3a4155;
              margin: 0 4px;
            }

            &:after {
              display: inline-block;
              content: "";
              width: 1px;
              height: 16px;
              background: #c4c4c4;
              border-radius: 1px;
              margin: 0 12px;
            }
          }

          .rating {
            font-style: normal;
            font-weight: normal;
            font-size: 1.4rem;
            line-height: 125%;
            color: #3a4155;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            svg {
              margin-left: 4px;
            }
          }
        }

        .comments-body {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 2.4rem 0;
          border-bottom: 1px solid rgba(196, 196, 196, 0.25);

          .comment-info {
            display: flex;
            margin-bottom: 12px;

            .user-name {
              font-style: normal;
              font-weight: normal;
              font-size: 1.4rem;
              line-height: 125%;
              color: #3a4155;
              display: flex;
              align-items: center;

              &:after {
                display: inline-block;
                content: "";
                width: 1px;
                height: 16px;
                background: #c4c4c4;
                border-radius: 1px;
                margin: 0 12px;
              }
            }

            .raiting-stars {
              font-style: normal;
              font-weight: normal;
              font-size: 1.4rem;
              line-height: 14px;
              color: #3a4155;
              display: flex;
              align-items: center;

              span {
                display: inline-block;
                margin-right: 12px;
              }
            }

            .date {
              font-style: normal;
              font-weight: 300;
              font-size: 1.4rem;
              line-height: 125%;
              color: #989eaf;
              display: flex;
              align-items: center;

              &:before {
                display: inline-block;
                content: "";
                width: 1px;
                height: 16px;
                background: #c4c4c4;
                border-radius: 1px;
                margin: 0 12px;
              }
            }
          }
        }
      }
    }
  }

  &_carousel {
    height: fit-content;
    display: flex;
    // flex-direction: column;
    margin-bottom: 36px;

    .product-detail-carousel-inner {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 36px;
      width: 555px;

      @media (max-width: 1128px) {
        width: 100%;
      }
    }
  }

  .related-products {
    grid-column: 1/-1;

    .new-products {
      margin-bottom: 5.6rem;

      .swiper-slide {
        a {
          width: 100%;
          display: block;
        }
      }
    }
  }

  .similar-products {
    grid-column: 1/-1;

    .new-products {
      margin-bottom: 5.6rem;

      .swiper-slide {
        a {
          width: 100%;
          display: block;
        }
      }
    }
  }
}

@media only screen and (max-width: 1128px) {
  .product-detail_carousel {
    position: static;
    display: flex;
    flex-direction: column;
  }

  .produc-detail-carousel-inner-child {
    width: 100vw !important;
    margin-left: -50vw;
    margin-right: -50vw !important;
    position: relative !important;
    left: 50%;
    right: 0;
  }
}

@media only screen and (max-width: 767.9px) {
  .product-detail {
    .content {
      .subtitle {
        font-size: 14px !important;
      }

      .title {
        font-size: 18px !important;
      }
    }

    .product-inner-video {
      width: -webkit-fill-available;
      margin: 1.2rem 0;
      margin-right: 0;
      height: 20rem;
    }
  }
}

.product-detail-page {
  width: 1300px;
  margin: 0 auto;
  padding-bottom: 15rem;

  @media screen and (max-width: 1199px) {
    width: 100%;
    padding: 0 1.6rem;
  }
}

.product-stock {
  height: 6rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 16px;
  background: #000;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  color: #ffffff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 36px;
}

.rhap_container .rhap_time.rhap_current-time,
.rhap_time.rhap_total-time {
  color: white;
}

.rhap_container button {
  color: white;
}

.audioBox {
  width: 100%;
  background-color: rgb(243, 243, 243);
  border-radius: 1rem;
  margin-top: 10px;

  .selectBox {
    display: flex;
    justify-content: center;
  }

  .rhap_container {
    box-shadow: unset;
  }

  .changeMp3 {
    height: 30px;
    margin: 5px 0 0 0;
    outline: 0;
    background-color: transparent;
    border: none;
    font-size: 20px;
    // max-width: 40px;
  }

  #rhap_current-time {
    color: black;
  }

  .rhap_download-progress {
    background-color: #dddddd;
  }

  .rhap_progress-filled {
    background-color: black;
  }

  .rhap_progress-indicator {
    background-color: black;
  }

  .rhap_time {
    color: black;
  }

  .rhap_button-clear {
    svg {
      color: black;
    }
  }

  .rhap_volume-bar-area {
    .rhap_volume-bar {
      background-color: black;
    }

    .rhap_volume-indicator {
      background-color: black;
    }
  }
}

.banner {
  grid-column: 1/-1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.4rem;

  @media screen and (max-width: 768px) {
    margin-bottom: 2.4rem;
    // padding: 0 2rem;
  }

  @media screen and (max-width: 600px) {
    margin-bottom: 3rem;
  }

  h1,
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.9rem;
    color: #000000;

    @media screen and (max-width: 768px) {
      font-size: 1.6rem;
      line-height: 130%;
    }

    @media screen and (max-width: 600px) {
      font-size: 2rem;
      line-height: 130%;
    }
  }

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 14px;
    color: #444444;
    margin-top: 1.2rem;
  }
}

.new-products {
  margin-bottom: 7rem;

  .container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0 16px;
    position: relative;

    .swiper-container {
      position: unset !important;
      max-width: 112.8rem !important;
      min-width: 112.8rem !important;

      @media screen and (max-width: 768px) {
        padding-left: 2rem;
        position: unset !important;
        max-width: 100% !important;
        min-width: 100% !important;
      }

      .swiper-wrapper {
        padding-bottom: 5px;
      }

      .swiper-button-prev {
        background: radial-gradient(90.16% 143.01% at 15.32% 21.04%,
            rgba(165, 239, 255, 0.2) 0%,
            rgba(110, 191, 244, 0.0447917) 77.08%,
            rgba(70, 144, 213, 0) 100%),
          url("../assets/img/arrow-white.svg");
        background-blend-mode: overlay, normal;
        backdrop-filter: blur(80px);
        border-radius: 16px;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 50%;
        transform: scaleX(-1);
        left: -20px;
        width: 32px;
        height: 32px;

        //   display: block;
        //   background-image: url("../../assets//img/right.png");
        //   background-repeat: no-repeat;
        //   background-size: cover;
        //   border: 1px solid rgba(44, 20, 79, 0.1);
        //   border-radius: 50%;
        //   box-shadow: 0px 16px 48px rgba(28, 27, 107, 0.06);
        @media screen and (max-width: 1128px) {
          left: 0;
        }

        @media screen and (max-width: 768px) {
          display: none;
        }

        &.swiper-button-disabled {
          opacity: 0;
          // background-image: url("../assets/img/right.png");
          transform: scaleX(1);
        }

        &:after {
          display: none;
        }
      }

      .swiper-button-next {
        background: radial-gradient(90.16% 143.01% at 15.32% 21.04%,
            rgba(165, 239, 255, 0.2) 0%,
            rgba(110, 191, 244, 0.0447917) 77.08%,
            rgba(70, 144, 213, 0) 100%),
          url("../assets/img/arrow-white.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-blend-mode: overlay, normal;
        //backdrop-filter: blur(80px);
        border-radius: 16px;
        position: absolute;
        top: 50%;
        right: -20px;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 1128px) {
          right: 0;
        }

        @media screen and (max-width: 768px) {
          display: none;
        }

        &.swiper-button-disabled {
          opacity: 0;
          // background-image: url("../assets/img/right.png");
          transform: scaleX(-1);
        }

        &:after {
          display: none;
        }
      }
    }
  }

  .news-product-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (max-width: 1199px) {
  .new-products {
    .container {
      grid-template-columns: repeat(1, 1fr) !important;
      max-width: 100%;

      .swiper-container {
        width: 100% !important;
        max-width: 100% !important;
        min-width: 100% !important;
      }
    }
  }
}

@media screen and (max-width: 767.9px) {
  .new-products {
    margin-bottom: 48px !important;
  }
}

.button:disabled {
  opacity: 0.5;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($color: #3a4155, $alpha: 0.25);
  width: 100vw;
  height: 100vh;
  z-index: 999999999999999;

  .add-address-form.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    width: 53.5rem;
    height: auto;
    padding: 2.4rem 2.4rem 3.6rem 2.4rem;
  }

  .add-address-form {
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 2.4rem;
      border-bottom: 2px solid rgba($color: #d0d4de, $alpha: 0.25);

      .title {
        font-style: normal;
        font-weight: normal;
        font-size: 2.4rem;
        line-height: 100%;
        color: #3a4155;
      }

      .close-btn {
        width: 2.8rem;
        height: 2.8rem;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent !important;

        svg {
          width: 8px;
          height: 8px;
        }
      }
    }

    .body {
      padding: 3.6rem 0 1.2rem 0;
      border-bottom: 2px solid rgba($color: #d0d4de, $alpha: 0.25);

      .form-control-container {
        display: flex;

        .form-control:first-child {
          margin-right: 16px;
        }
      }
    }

    .submit-btn {
      margin-top: 2.4rem;
      height: 6rem;
      width: 100%;
      background: #ff4c4c;
      border-radius: 6px;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 20px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.form-control {
  width: 100%;
  display: flex;
  flex-direction: column;

  input:-webkit-autofill {
    -webkit-text-fill-color: #444444 !important;
    transition: background-color 5000s;
    background-color: #fff;
  }

  input {
    width: 100%;
    border: 1px solid #d0d4de;
    background-color: #fdfdfd;
    border-radius: 6px;
    outline: none;
    height: 6rem;
    padding: 0 2.4rem;
    transition: all 0.3s;
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
    line-height: 100%;
    color: #444444;

    @media only screen and (max-width: 600px) {
      height: 6rem;
      font-size: 1.75rem;
    }

    &:focus {
      // border: 1px solid rgba($color: #FF4C4C, $alpha: .5);
    }

    &::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 2rem;
      line-height: 100%;
      color: #989eaf;

      @media only screen and (max-width: 600px) {
        font-size: 1.75rem;
        color: rgba($color: #0c0d1e, $alpha: 0.75);
      }
    }
  }

  label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 100%;
    color: #3a4155;
    margin-bottom: 12px;

    @media only screen and (max-width: 600px) {
      font-size: 1.75rem;
      color: rgba($color: #0c0d1e, $alpha: 0.75);
    }

    a {
      font-style: normal;
      font-weight: 300;
      font-size: 1.2rem;
      line-height: 100%;
      text-align: right;
      color: #ff4c4c;
    }

    .label-note {
      color: rgba($color: #05061e, $alpha: 0.3);
      font-weight: normal;
    }
  }

  small {
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 170%;
    color: #ff4c4c;
    margin-bottom: 4px;

    @media only screen and (max-width: 600px) {
      font-size: 1.5rem;
      margin-top: 1rem;
    }

    //margin-top: 1rem;
  }

  .password-container {
    position: relative;

    .eye {
      position: absolute;
      right: 24px;
      transform: translate(-0%, -50%);
      top: 50%;
      height: 1.6rem;
      width: 2rem;
      cursor: pointer;

      img {
        height: 1.6rem;
        width: 2rem;
        object-fit: cover;

        @media only screen and (max-width: 600px) {
          height: 2rem;
          width: 2rem;
        }
      }
    }
  }
}

.react-select-container-cities {
  height: 6rem;

  @media only screen and (max-width: 600px) {
    height: 6rem;
  }

  .react-select-cities__single-value {
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
    line-height: 100%;
    color: #989eaf;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 600px) {
      font-size: 1.75rem;
    }
  }

  .react-select-cities__control {
    border: 1px solid #d0d4de;

    height: 6rem;

    @media only screen and (max-width: 600px) {
      height: 6rem;
    }

    &:hover,
    &:active {
      border-color: #d0d4de;
    }

    &--is-focused {
      // border: 1px solid rgba($color: #FF4C4C, $alpha: .5);
      box-shadow: none;
      color: #444444;
    }
  }

  .react-select-cities__value-container {
    height: 6rem;

    .react-select-cities__placeholder {
      font-size: 2rem;
      color: #989eaf;
    }

    @media only screen and (max-width: 600px) {
      height: 6rem;
    }
  }

  .react-select-cities__indicators {
    span.react-select-cities__indicator-separator {
      display: none;
    }
  }

  .react-select-cities__menu-list {
    max-height: 130px;
    overflow-y: scroll;
    z-index: -1;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      margin-bottom: 0.4rem;
      border-radius: 0.2rem;
      height: 100%;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba($color: #0c0d1e, $alpha: 0.1);
      border-radius: 1.6rem;
      border: 2px solid transparent;
      background-clip: padding-box;
    }

    &::-webkit-scrollbar-track-piece {
      background: rgba($color: #0c0d1e, $alpha: 0.1);
      background: transparent;
    }

    .react-select-cities__option {
      font-style: normal;
      font-weight: normal;
      font-size: 1.6rem;
      line-height: 170%;
      display: flex;
      align-items: center;
      color: rgba($color: #05061e, $alpha: 0.75);
      padding: 4px 8px;
      outline: none;

      &:hover,
      &:active {
        background: rgba($color: #05061e, $alpha: 0.03);
      }
    }

    .react-select-cities__option--is-focused {
      background-color: transparent;
    }

    .react-select-cities__control--menu-is-open {
      box-shadow: 0 0 0 1px rgba($color: #05061e, $alpha: 0.07);
    }

    .react-select-cities__control--menu-is-focused {
      box-shadow: none;
      border: none;

      &.css-1pahdxg-control {
        box-shadow: 0 0 0 1px rgba($color: #05061e, $alpha: 0.07);
        border: none;
      }
    }

    .react-select-cities__option--is-selected {
      background: rgba($color: #05061e, $alpha: 0.03);
    }
  }
}

@media screen and (max-width: 767.9px) {
  .add-address-form.popup {
    position: fixed;
    width: 90vw !important;
    height: auto;
    padding: 1.2rem 1.2rem 2.4rem 1.2rem !important;
  }
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #989eaf;

  strong {
    font-style: normal;
    font-weight: normal;
    color: #444444;
  }

  a {
    color: #ff2459;
  }
}

.submit-btn {
  margin-top: 12px;
}

.account-logged-in_svg__st0 {
  fill: #222;
}

.account {
  width: 1128px;
  margin: 0 auto;
  margin-top: 2.4rem;
  padding-bottom: 7rem;

  @media screen and (max-width: 1199px) {
    width: 100%;
    padding: 0 16px;
  }

  .account-header {
    background: url("../assets/img/contactBg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 27rem;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2.4rem;

    .breadcrumb {
      padding-top: 0;
      display: block;

      a {
        color: #fff;
      }

      .breadcrumb-seperator {
        background-color: #fff;
      }
    }

    .info {
      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 3.2rem;
        line-height: 100%;
        color: #ffffff;
        margin-bottom: 6px;
      }

      .customer-code {
        font-style: normal;
        font-weight: normal;
        font-size: 1.2rem;
        line-height: 125%;
        color: #fdfdfd;
      }
    }
  }

  .account-container {
    padding-top: 5.6rem;

    .account-wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-gap: 0 2.4rem;

      .tabs {
        grid-column: 1/3;

        .tabs-titles {
          background: #f4f6fa;
          border-radius: 6px;
          padding: 1.2rem;
          margin-bottom: 2.4rem;

          .tab-title {
            height: 6rem;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 2.4rem;
            border-radius: 6px;
            margin-bottom: 6px;
            text-decoration: unset;

            p {
              font-style: normal;
              font-weight: normal;
              font-size: 1.6rem;
              line-height: 100%;
              text-align: center;
              color: #989eaf;
            }

            .logo-container {
              width: 16px;
              height: 16px;
              margin-right: 12px;

              svg {
                width: 16px;
                height: 16px;
                object-fit: cover;
              }
            }

            &:hover,
            &.tab-title--active {
              background: #fff;

              p {
                color: #3a4155;
              }

              .logo-container {
                svg {
                  g {
                    opacity: 1;
                  }

                  path {
                    stroke: #6a94ff;
                  }
                }
              }
            }
          }
        }

        .log-out-btn {
          width: 100%;
          height: 6rem;
          background: #ffffff;
          border: 1px solid #f4f6fa;
          box-sizing: border-box;
          border-radius: 6px;
          padding-left: 3.6rem;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-style: normal;
          font-weight: normal;
          font-size: 1.6rem;
          line-height: 100%;
          color: #777f95;
          cursor: pointer;

          svg {
            margin-right: 1.2rem;
          }

          &:hover {
            color: #3a4155;

            svg {
              g {
                opacity: 1;
              }

              path {
                stroke: #6a94ff;
              }
            }
          }
        }
      }

      .tab-content {
        grid-column: 3/-1;
      }
    }
  }

  .breadcrumb {
    display: none;
  }
}

@media screen and (max-width: 991.9px) {
  .account {
    .tabs {
      grid-column: 1/4 !important;
    }

    .tab-content {
      grid-column: -1/4 !important;
    }

    .settings-form-control {
      flex-direction: column;
      align-items: flex-start !important;

      label {
        margin-bottom: 12px;
        display: block;
      }
    }

    .settings-card-info {
      grid-template-columns: repeat(1, 1fr) !important;
      display: flex !important;
    }

    .input-container {
      width: 100% !important;

      input {
        width: 100% !important;
      }
    }

    .settings-form {
      grid-column: 7/1 !important;
    }
  }
}

@media screen and (max-width: 767.9px) {
  .account-wrapper {
    grid-gap: 0 !important;
    grid-template-columns: repeat(1, 1fr) !important;
    display: block !important;
  }

  .account {
    .account-container {
      padding-top: 0;
    }

    .breadcrumb {
      display: block !important;
      margin-bottom: 24px;
    }

    .tabs {
      grid-column: 1/4 !important;
      overflow: auto;
      width: 100vw !important;
      margin-left: -50vw;
      margin-right: -50vw !important;
      position: relative;
      left: 50%;
      right: 0;
      border-radius: 0 !important;
      margin-bottom: 36px !important;
      display: flex;
      align-items: center;
      overflow: auto;
      background: #f4f6fa;
      border-radius: 6px;
      padding: 1.2rem;

      .tabs-titles {
        display: flex;
        width: fit-content !important;
        // overflow: hidden;
        margin: 0 !important;
        padding: 0 !important;

        .tab-title {
          width: 190px !important;
          display: flex;
          align-items: center !important;
          justify-content: center !important;
          padding-left: 0 !important;
        }
      }

      .log-out-btn {
        // display: none !important;
        width: 190px !important;
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        padding: 0 16px !important;
        white-space: nowrap;
        background: transparent !important;
      }
    }

    .settings-card-password {
      width: 100%;
      padding: 24px !important;
    }

    .tab-content {
      grid-column: -1/4 !important;

      .settings-card-info {
        padding: 24px !important;
      }

      .settings-submit {
        width: 100% !important;
      }
    }

    .settings-form-control {
      flex-direction: column;
      align-items: flex-start !important;

      label {
        margin-bottom: 12px;
        display: block;
      }
    }

    .settings-card-info {
      grid-template-columns: repeat(1, 1fr) !important;
      display: flex !important;
    }

    .input-container {
      width: 100% !important;

      input {
        width: 100% !important;
      }
    }

    .settings-form {
      grid-column: 7/1 !important;
    }
  }

  .account-header {
    height: 150px !important;
    justify-content: flex-end !important;
    width: 100vw !important;
    margin-left: -50vw;
    margin-right: -50vw !important;
    position: relative;
    left: 50%;
    right: 0;
    border-radius: 0 !important;

    .breadcrumb {
      display: none !important;
    }

    .info {
      .title {
        font-size: 28px !important;
      }

      .customer-code {
        font-size: 14px !important;
      }
    }
  }
}

.addresses {
  width: 100%;
  padding-bottom: 18rem;

  .addresses-header {
    width: 100%;
    margin-bottom: 3.6rem;

    .title {
      font-style: normal;
      font-weight: normal;
      font-size: 2.4rem;
      line-height: 100%;
      color: #3a4155;
      margin-bottom: 12px;
    }

    .order-count {
      font-style: normal;
      font-weight: normal;
      font-size: 1.6rem;
      line-height: 100%;
      color: #989eaf;
    }
  }

  .addresses-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    grid-gap: 2.4rem 1.6rem;

    .add-address-card {
      width: 100%;
      height: 28.4rem;
      background: #ffffff;
      border: 1px solid #ecedee;
      box-sizing: border-box;
      border-radius: 12px;
      padding: 12px;
      cursor: pointer;

      &_bg {
        width: 100%;
        height: 100%;
        background: url("../assets/img/add-address-bg.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 2rem;

        .button {
          width: 100%;
          background: #ff4c4c;
          border-radius: 100px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 4rem;
          border: none;

          svg {
            margin-right: 6px;
            width: 16px;
            height: 20px;
          }
        }
      }
    }

    .address-card {
      background: #ffffff;
      border: 1px solid #ecedee;
      box-sizing: border-box;
      border-radius: 12px;
      width: 100%;
      // height: 28.4rem;
      padding: 12px;

      .address-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .address-title {
          font-style: normal;
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 100%;
          color: #3a4155;
          padding-right: 10px;
        }

        .address-btns {
          display: flex;
          align-items: center;
          justify-content: center;

          .edit-btn {
            margin: 0 12px;
            width: 28px;
            height: 28px;
            background: #ccc;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: none;
            outline: 0;
          }

          .delete-btn {
            width: 28px;
            height: 28px;
            background: #ccc;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: none;
            outline: 0;
          }
        }
      }

      .address-body {
        padding-top: 4.8rem;

        .top {
          padding-bottom: 2.4rem;

          .name {
            font-style: normal;
            font-weight: normal;
            font-size: 1.8rem;
            line-height: 100%;
            color: #777f95;
            margin-bottom: 12px;
          }

          .phone {
            font-style: normal;
            font-weight: 300;
            font-size: 1.4rem;
            line-height: 100%;
            color: #777f95;
          }
        }

        .bottom {
          background: #f4f6fa;
          border-radius: 3px;
          width: 100%;
          height: 100%;
          min-height: 11.6rem;
          padding: 2.4rem 1.2rem;

          .city-zipcode {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            color: #777f95;
            margin-bottom: 1.2rem;
          }

          .full-address {
            font-style: normal;
            font-weight: normal;
            font-size: 1.4rem;
            line-height: 150%;
            color: #777f95;
          }
        }
      }

      &.checked {
        border: 3px solid #ff4c4c;

        .address-header {
          .address-btns {
            .toggle-switch.small-switch {
              .toggle-switch-label {
                .toggle-switch-switch {
                  border: 1.5px solid #ff4c4c;
                }
              }
            }
          }
        }
      }
    }
  }
}

.cart-page {
  width: 1128px;
  margin: 0 auto;
  margin-top: 2.4rem;
  padding-bottom: 18rem;

  @media screen and (max-width: 1199px) {
    width: 100%;
    padding: 0 16px;
  }

  .cart-header {
    // background: url("../../assets//img/cart-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2.4rem;
    margin-bottom: 5.6rem;

    .breadcrumb {
      padding-top: 0;

      a {
        color: #1b1c1f;
        font-size: 16px;
      }

      .breadcrumb-seperator {
        background-color: #1b1c1f;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 4.2rem;
        line-height: 100%;
        color: #1b1c1f;
        margin-bottom: 6px;
        text-transform: uppercase;
      }

      .customer-code {
        font-style: normal;
        font-weight: normal;
        font-size: 2.2rem;
        line-height: 125%;
        color: #1b1c1f;
      }
    }
  }

  .cart-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 0 2.4rem;

    .main-section {
      grid-column: 1/6;
      height: auto;
    }

    .side-section {
      grid-column: 6/-1;
      background: #f4f6fa;
      border-radius: 12px;
      //padding: 2.4rem;
      padding: 6px;

      .title {
        background: #ffffff;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
        border-radius: 6px;
        height: 7.6rem;
        padding: 0 3rem;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #1b1c1f;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .wrapper {
        padding: 0 18px;
      }

      .cart-calculations {
        // padding: 0 2.4rem;
        width: 100%;
        padding-top: 2rem;
        padding-bottom: 3.6rem;
        border-bottom: 2px solid rgba($color: #d0d4de, $alpha: 0.25);

        .total,
        .discount {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .left,
          .right {
            font-style: normal;
            font-weight: 300;
            font-size: 1.8rem;
            line-height: 125%;
            color: #444444;

            .manatIcon {
              width: 2.2rem;
              height: 1.8rem;
            }
          }
        }

        .total {
          margin-bottom: 2.4rem;

          .right {
            font-weight: normal;

            path {
              fill: #444444;
            }
          }
        }

        .discount {
          .right {
            font-weight: normal;
            color: #ff4c4c;
          }
        }
      }

      .login-span {
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 12px;
        color: #1b1c1f;
        text-align: center;
        margin-top: 12px;
        display: block;
      }

      .total-amount {
        // padding: 0 2.4rem;
        padding-top: 3.6rem;

        .total-amount-text {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .left {
            font-style: normal;
            font-weight: normal;
            font-size: 2.4rem;
            line-height: 125%;
            color: #3a4155;

            .manatIcon {
              width: 2.2rem;
              height: 1.8rem;
            }
          }

          .right {
            font-style: normal;
            font-weight: normal;
            font-size: 2.4rem;
            line-height: 125%;
            color: #ff4c4c;

            .manatIcon {
              width: 2.2rem;
              height: 1.8rem;
            }
          }
        }

        .make-order-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #ff4c4c;
          border-radius: 6px;
          font-style: normal;
          font-weight: normal;
          font-size: 24px;
          line-height: 24px;
          color: #ffffff;
          height: 6rem;
          width: 100%;
          margin-top: 2.4rem;
          text-decoration: unset;
        }

        .guest-order-btn {
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 20px;
          border: 2px solid #1b1c1f;
          box-sizing: border-box;
          border-radius: 6px;
          color: #1b1c1f;
          margin-top: 24px;
          height: 6rem;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
      }

      .accordion-container {
        padding: 0 1.2rem;
        margin-top: 3.6rem;
        padding-bottom: 2.4rem;
      }
    }
  }

  .desk-none {
    display: none;
  }

  @media screen and (max-width: 767.9px) {
    width: 100%;
    padding: 0 16px;
    margin: 0;

    .desk-none {
      display: block;
      margin-bottom: 24px;
    }

    .empty-cart {
      padding: 24px 16px;
    }

    .empty-cart__shopping-btn {
      margin-top: 24px;
      font-size: 24px;
    }

    .empty-cart__ellipse {
      width: 172.82px;
      height: 132px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .empty-cart__title {
      font-size: 32px;
      margin-bottom: 12px;
    }

    .cart-header {
      height: 150px;
      justify-content: flex-end;
      width: 100vw;
      margin-left: -50vw;
      margin-right: -50vw !important;
      position: relative;
      left: 50%;
      right: 0;
      border-radius: 0;

      .breadcrumb {
        display: none;
      }

      .info {
        .title {
          font-size: 20px;
        }

        .customer-code {
          font-size: 14px;
          margin-top: 12px;
        }
      }
    }

    .cart-container {
      .main-section {
        grid-column: 9/1;
        height: auto;
        margin-bottom: 36px;
      }

      .side-section {
        grid-column: 9/1;
        margin-bottom: 32px;

        .accordion-container {
          padding: 0;
        }

        .guest-order-btn {
          border: 1px solid #1b1c1f !important;
        }

        .title {
          width: 100%;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .cart-table {
      align-items: flex-start;

      .content {
        width: 100%;
        display: flex;
        align-items: flex-start !important;
        justify-content: space-between;
        position: relative;
        flex-direction: column !important;

        .cart-table-functions {
          margin-top: 20px !important;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .quantity-container {

            .minus,
            .plus {
              svg {
                width: 12px !important;
                height: 12px !important;

                path {
                  stroke: #1b1c1f;
                }
              }
            }

            .quantity {
              border: 1px solid #444444 !important;
            }
          }

          .delete-btn {
            background: linear-gradient(224.46deg, #ffffff 0%, rgba(91, 102, 132, 0) 95.54%) !important;
            border-radius: 3px !important;
            width: 32px !important;
            height: 32px !important;
          }
        }
      }
    }
  }
}

.cardEnd {
  margin-top: 20px;

  &__flexBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__left {
    display: inline-flex;
    align-items: center;

    img {
      width: 80px;
      height: 80px;
      margin-right: 26px;
    }

    p {
      font-size: 2rem;
      line-height: 125%;
      color: #3a4155;
      margin-bottom: 0;

      &:first-child {
        margin-bottom: 10px;
      }
    }
  }

  &__content {
    font-size: 2rem;
    line-height: 125%;
    color: #3a4155;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
  }

  &__manat {
    width: 18px;
    height: 18px;
    margin-left: 5px;

    path {
      fill: #1b1c1f;
    }
  }
}

.cart-table {
  display: flex;
  align-items: center;
  padding: 2.4rem 0;
  border-bottom: 1px solid rgba($color: #d0d4de, $alpha: 0.25);

  &:first-child {
    padding-top: 0;
  }

  .img-container {
    background: #ffffff;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.06);
    border-radius: 3px;
    width: 10rem;
    height: 10rem;
    margin-right: 2.4rem;

    img {
      border-radius: 3px;
      width: 10rem;
      height: 10rem;
      object-fit: contain;
    }
  }

  .content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;

    .ordered-product {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .product-name {
        font-style: normal;
        font-weight: normal;
        font-size: 2rem;
        line-height: 125%;
        color: #3a4155;
        margin-bottom: 12px;
      }

      .product-count {
        .quantity {
          font-style: normal;
          font-weight: 300;
          font-size: 1.4rem;
          line-height: 125%;
          color: #3a4155;
        }

        svg {
          width: 6px;
          height: 6px;
          margin: 0 12px;
        }

        .price {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 18px;
          color: #ff2459;

          .manatIcon {
            width: 1.6rem;
            height: 1.3rem;
            margin: 0;
          }
        }
      }
    }

    .cart-table-delete-quatity {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // width: 100%;
    }

    .cart-table-functions {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      width: fit-content;

      .quantity-container {
        display: flex;
        margin-right: 12px;

        .plus,
        .minus {
          width: 2.8rem;
          height: 2.8rem;
          border-radius: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &:hover {
            background-color: rgba($color: #d0d4de, $alpha: 0.25);
          }

          svg {
            width: 8px;
            height: 8px;
          }
        }

        .quantity {
          display: inline-block;
          min-width: 2.8rem;
          height: 2.8rem;
          border: 1px solid #989eaf;
          box-sizing: border-box;
          border-radius: 3px;
          font-style: normal;
          font-weight: normal;
          font-size: 1.4rem;
          line-height: 125%;
          color: #3a4155;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .delete-btn {
        background: linear-gradient(224.46deg, #ffffff 0%, rgba(91, 102, 132, 0) 95.54%);
        border-radius: 3px;
        width: 28px;
        height: 28px;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          background: rgba($color: #ff2459, $alpha: 0.25);
          transition: all 0.3s;

          svg {
            path {
              stroke: #ff2459;
            }
          }
        }
      }

      .error-message {
        // position: absolute;
        // top: -20px;
        // right: 0%;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 100%;
        color: #ff2459;
        background: rgba($color: #ff2459, $alpha: 0.25);
        border-radius: 3px;
        padding: 8px 12px;
        margin-top: 8px;
      }
    }
  }

  @media (max-width: 767.9px) {
    .content {
      .cart-table-delete-quatity {
        width: 100%;
      }

      .cart-table-functions {
        .error-message {
          width: 100%;
          font-size: 10px;
        }
      }
    }
  }
}

.checkout-page {
  width: 1128px;
  margin: 0 auto;
  margin-top: 2.4rem;
  padding-bottom: 18rem;

  .totalFlex {
    display: flex !important;
    width: 100% !important;

    .main-section-login {
      width: 40% !important;
    }

    @media (max-width: 996px) {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 1199px) {
    width: 100%;
    padding: 0 16px;
  }

  .total-amount-text-total {
    svg {
      path {
        fill: #444444;
      }
    }
  }

  .noMargin {
    margin-top: 0 !important;
  }

  .checkout-top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 3.6rem 0;

    h4 {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 24px;
      color: #1b1c1f;

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        color: #444444;
        margin-top: 12px;
      }
    }

    a {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 16px;
      color: #444444;
    }
  }

  .cart-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 0 2.4rem;

    .main-section {
      grid-column: 1/5;
      display: grid;
      height: auto;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1.6rem;
      margin-bottom: 24px;

      .add-address-card {
        width: 100%;
        height: 28.4rem;
        background: #ffffff;
        border: 1px solid #ecedee;
        box-sizing: border-box;
        border-radius: 12px;
        padding: 12px;
        cursor: pointer;

        &_bg {
          width: 100%;
          height: 100%;
          background: url("../assets/img/add-address-bg.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 2rem;

          .button {
            width: 100%;
            background: #ff4c4c;
            border-radius: 100px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 4rem;

            svg {
              margin-right: 6px;
              width: 16px;
              height: 20px;
            }
          }
        }
      }
    }

    .main-section-login {
      grid-column: 1/5;
      display: grid;
      height: auto;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1.6rem;

      input {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height, or 125% */

        /* second */

        color: #444444;

        &::placeholder {
          font-size: 16px;
        }
      }
    }

    .side-section {
      grid-column: 5/-1;
      height: fit-content !important;
      background: #f4f6fa;
      border-radius: 12px;
      padding: 6px;

      .title {
        background: #ffffff;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
        border-radius: 6px;
        height: 7.6rem;
        padding: 0 3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 20px;
          color: #1b1c1f;
        }

        .right {
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 24px;
          color: #ff4c4c;
        }
      }

      .total-amount-bottom {
        padding: 2.4rem 3.6rem 3.6rem;

        .total-amount-text {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 24px;

          .left {
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 18px;
            color: #444444;

            .manatIcon {
              width: 2.2rem;
              height: 1.8rem;
            }
          }

          .right {
            font-family: "Nunito";
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 18px;
            color: #444444;

            &.discount {
              color: #ff4c4c;
            }

            .manatIcon {
              width: 2.2rem;
              height: 1.8rem;
            }
          }
        }

        .cash {
          display: flex;
          align-items: center;
          margin-top: 3.6rem;

          label {
            display: flex;
            width: 100%;
            position: relative;
            cursor: pointer;

            input {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              cursor: pointer;
              outline: none;
              z-index: -1;
            }

            div {
              background: #fdfdfd;
              border: 1px solid #d0d4de;
              box-sizing: border-box;
              border-radius: 6px;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 78px;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 14px;
              color: #1b1c1f;
              cursor: pointer;

              svg {
                margin-right: 12px;
              }
            }

            &:first-child {
              margin-right: 1.6rem;
            }
          }
        }

        .make-order-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #ff4c4c;
          border-radius: 6px;
          height: 68px;
          width: 100%;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 20px;
          color: #ffffff;
          margin-top: 2.4rem;
        }
      }
    }
  }

  .add-address-form {
    background: #ffffff;
    /* star */

    border: 1px solid #d0d4de;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 3.2rem 3.6rem;

    .submit-btn {
      margin-top: 2.4rem;
      height: 6rem;
      width: 100%;
      background: #ff4c4c;
      border-radius: 6px;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 20px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: 991.9px) {
    .cart-container {
      width: 100%;

      .main-section-login {
        grid-column: 1/6;
      }

      .main-section {
        grid-column: 1/6;
      }

      .side-section {
        grid-column: 6/-1;
      }
    }
  }

  @media screen and (max-width: 767.9px) {
    .cart-container {
      width: 100%;

      .main-section-login {
        grid-column: 9/1;
        grid-template-columns: repeat(1, 1fr);
        margin-bottom: 24px;
      }

      .main-section {
        grid-column: 9/1;
        grid-template-columns: repeat(1, 1fr);
        margin-bottom: 52px;

        .add-address-card {
          height: 372px;

          .button {
            padding: 14px 44px;
            width: fit-content;
            height: fit-content;
          }
        }
      }

      .side-section {
        grid-column: 9/1;
        margin-bottom: 32px;

        .total-amount-bottom {
          padding: 24px;
        }

        .title {
          .left {
            font-size: 20px;
          }
        }
      }
    }

    .checkout-top {
      h4 {
        font-size: 24px;

        p {
          font-size: 14px;
        }
      }

      a {
        display: none;
      }
    }
  }
}

.main-setion-2 {
  display: unset !important;
}

.form-control {
  border: unset !important;
}

.loader {
  width: 45px;
  height: 45px;
  display: inline-block;
  padding: 0;
  border-radius: 100%;
  border-color: currentcolor;
  border-color: #fe6f42 rgba(5, 6, 30, 0.1) rgba(5, 6, 30, 0.1) #fe6f42;
  border-style: solid;
  border-width: 5px;
  -webkit-animation: loader4 1s ease-in-out infinite;
  animation: loader4 1s ease-in-out infinite;
}

.loader {
  width: 45px;
  height: 45px;
  display: inline-block;
  padding: 0px;
  border-radius: 100%;
  border: 5px solid;
  border-top-color: #fe6f42;
  border-bottom-color: rgba(5, 6, 30, 0.1);
  border-left-color: #fe6f42;
  border-right-color: rgba(5, 6, 30, 0.1);
  -webkit-animation: loader4 1s ease-in-out infinite;
  animation: loader4 1s ease-in-out infinite;
}

@keyframes loader4 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.g4m-grid-item {
  @media (max-width: 767px) {
    margin-right: 0 !important;

    &:nth-child(odd) {
      margin-right: 15px !important;
    }

    width: calc(50% - 8px) !important;
  }

  @media (max-width: 575px) {
    width: 100% !important;

    &:nth-child(odd) {
      margin-right: 0 !important;
    }
  }
}

@media (max-width: 423px) {
  .header-button {
    width: 43px !important;
    height: 43px !important;
  }

  .header-button svg {
    width: 25px !important;
    height: 25px !important;
  }
}

.langSelect {
  border: none !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: #666;
  background-color: #f5f5f5;
  border-radius: 2px;
  width: 47px;
  height: 28px;
  margin-right: 36px;
}

#langModal {
  opacity: 1 !important;
}

.favoriteSvgP {
  width: 47px !important;
  height: 21px !important;
}

.product-discount-rec.active {
  position: absolute;
  left: 20px;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.4rem;
  width: 4.4rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 100%;
  color: #fff;
  min-width: 7rem;
  height: 3.2rem;
  background-color: #ff4c4c;
  border-radius: 2px;
  z-index: 1;

  span {
    background: linear-gradient(212.23deg, #f9184e 13.64%, #ff4e79 95.78%);
    width: 2.6rem;
    height: 2.6rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .manatIconList {
    width: 15px;
    height: 15px;
    margin-left: 3px;

    path {
      fill: #fff;
    }
  }
}

.can-order {
  display: flex;
  align-items: center;

  svg {
    margin-left: 8px;
  }
}

.old-price {
  font-size: 18px;
  color: #d0d4de;
  text-decoration-line: line-through !important;
  margin-right: 8px;

  svg {
    margin-left: 5px;
  }
}

.gallery-des {
  display: flex;
  flex-direction: column;
  padding: 30px;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 187px;

  span {
    font-size: 25px;
    color: #fff;
    font-weight: bold;

    @media (max-width: 575px) {
      font-size: 18px;
    }

    &.title {
      font-size: 30px;
      font-weight: bold;

      @media (max-width: 575px) {
        font-size: 18px;
        padding-top: 15px;
      }
    }
  }
}

.grecaptcha-badge {
  display: none !important;
}

.capital {
  text-transform: capitalize !important;
}

.banner-1 {
  height: 300px;

  >section {
    height: 100%;

    a {
      height: 300px !important;
    }

    img {
      height: 100%;
    }
  }
}

.banner-2 {
  height: 440px;

  >section {
    height: 100%;

    a {
      height: 432px !important;
    }

    img {
      height: 100%;
    }
  }
}

.banner-3 {
  height: 258px;

  >section {
    height: 258px;

    a {
      height: 258px !important;
    }

    img {
      height: 100%;
    }
  }
}

.product__only_order {
  font-size: 20px;
  color: #ff0000;
  margin-bottom: 0;
}

.calks {
  width: 100%;
  margin-top: 20px;
}

.calks__top {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  margin-bottom: 6px;
}

.calks__span {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #777;
  margin-bottom: 15px;
}

.calks__wr {
  height: 91px;
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  padding: 12px 20px 12px 0px;
  margin-bottom: 20px;
  max-width: 100%;
  justify-content: space-between;

  @media (max-width: 500px) {
    padding-left: 12px;
  }

  @media (max-width: 390px) {
    padding: 10px;
    height: 69px;
  }
}

.calks__circles {
  font-weight: 500;
  font-size: 12px;
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 50%;
  line-height: 12px;
  letter-spacing: -0.02em;
  margin-left: 15px;
  position: relative;
  cursor: pointer;

  span {
    position: absolute;
    top: -13px;
    text-align: center;
    left: 0;
    right: 0;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #ff003c;
  }

  &:first-of-type {
    margin-left: 0;
  }
}

.calks__circle {
  font-weight: 500;
  font-size: 12px;
  min-width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  line-height: 12px;
  letter-spacing: -0.02em;
  background: #f3f3f3;
  margin-left: 5px;
  position: relative;
  cursor: pointer;

  @media (max-width: 480px) {
    margin-left: 7px;

    &:first-child {
      margin-left: 0;
    }
  }

  @media (max-width: 390px) {
    min-width: 31px;
    height: 31px;
    font-size: 10px;
  }
}

.calks__wrt {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 178px;
  border-left: 1px solid #eaeaea;

  @media (max-width: 480px) {
    width: 90px;
  }
}

.calks__wrt span {
  margin-bottom: 9px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.02em;

  @media (max-width: 480px) {
    font-size: 10px;
    line-height: normal;
  }
}

.calks__wrt strong {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;

  @media (max-width: 480px) {
    font-size: 12px;
    line-height: normal;
  }
}

.buy-with-loan {
  background-color: transparent !important;
  border: 1px solid #000 !important;
  color: #000 !important;
}

.calks__circle--active {
  background: #323232;
  color: #fff;
}

.site-header {
  @media(max-width:575px) {
    margin-top: 10px;
  }
}

.mobileTelTop {
  display:none;
  margin-top:20px;
  margin-left:20px;

  svg{
    margin-right:5px;
  }

  @media(max-width:575px) {
    display:flex;
    align-items:center;
    color: #222;
    
  }
  
}